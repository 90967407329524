import { LinearProgress, FormHelperText } from "@mui/material";
import { Formik, Form } from "formik";
import React, { useMemo, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useParams, useNavigate } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { FMSelectTransactionType } from "../components/Elements/select";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { CustomerPolicyModel_VS } from "../models/CustomerPolicyModel";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { apiClient } from "../helper/api";


var formData = new FormData();
var files: File[];

export interface ReqDocuments {
    documentName: string,
    modelId: number
}
const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#41729F',
    borderStyle: 'dashed',


    backgroundColor: '#fafafa',
    // color: '#bdbdbd',
    color: '#41729F',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};


export function StyledDropzoneUploadModelDocument(props: any) {
    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop:
            (files) => {
                formData.delete('ModelDocument');
                formData.append('ModelDocument', files[0]);
            }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    const files = acceptedFiles.map(
        file =>
        (
            <React.Fragment>

                <FilePresentIcon fontSize='inherit' />
                <li key={file.name}>
                    {file.name} - {(file.size / 1024).toFixed(2)} kb
                </li>
            </React.Fragment>
        )

    );

    return (
        <React.Fragment>
            <div className="card-body col-lg-12">
                <div {...getRootProps({ style })}>

                    <input {...getInputProps()} name='ModelDocument' />
                    <p>Drop Document for Training</p>

                </div>
                <aside>
                    <ul>{files}</ul>
                </aside>
            </div>

        </React.Fragment>
    );
}


// export function GenericStyledDropZone(props: any & ReqDocuments) {
//     console.log(props);
//     const {
//         getRootProps,
//         getInputProps,
//         acceptedFiles,
//         isFocused,
//         isDragAccept,
//         isDragReject
//     } = useDropzone({
//         accept: { 'application/pdf': ['.pdf'], 'image/*': [] }, onDrop:
//             (files) => {
//                 formData.delete(props.doc.documentName);
//                 formData.delete(props.doc.documentName);
//                 formData.append(props.doc.modelId + "__"+ props.doc.documentName, files[0]);
//             }
//     });

//     const style = useMemo(() => ({
//         ...baseStyle,
//         ...(isFocused ? focusedStyle : {}),
//         ...(isDragAccept ? acceptStyle : {}),
//         ...(isDragReject ? rejectStyle : {})
//     }), [
//         isFocused,
//         isDragAccept,
//         isDragReject
//     ]);


//     const files = acceptedFiles.map
//         (
//             file =>
//             (
//                 <React.Fragment>
//                     <FilePresentIcon fontSize='inherit' />
//                     <li key={file.name}>
//                         {file.name} - {(file.size / 1024).toFixed(2)} kb
//                     </li>
//                 </React.Fragment>
//             )

//         );

//     return (
//         <React.Fragment>
//             <div className="card-body col-lg-12">
//                 <div {...getRootProps({ style })}>

//                     <input {...getInputProps()} name={props.doc.documentName} />
//                     <p>Drop {props.doc.documentName}</p>

//                 </div>
//                 <aside>
//                     <ul>{files}</ul>
//                 </aside>
//             </div>

//         </React.Fragment>
//     );
// }

// interface props {
//     fetchData: () => void
// }


export const UploadModelDocument: React.FC = () => {
    const initialValues: { ModelId: number, } = { ModelId: 0 }
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {


    }, [])
    return (


        <div className="card">
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <React.Fragment>
                <Formik initialValues={initialValues}
                    validateOnChange={true}
                    // validationSchema={CustomerPolicyModel_VS}
                    onSubmit={(data, { setSubmitting }) => {
                        setloading(true);
                        console.log(data);
                        let ID = params.modelId?.toString() ?? "";
                        formData.append('ModelId', ID);

                        apiClient(`/ButlerModelDocument/UploadModelDocuments/`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    // 'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: formData  // <-- Post parameters

                            })
                            .then((res) => {
                                if (!res) return

                                // Do something with the response

                            }).finally(() => {
                                setSubmitting(false);
                                formData = new FormData();
                                setloading(true);
                               
                            });

                    }} >
                    {({ values, isSubmitting,submitForm }) =>
                    (
                        <Form>
                            <div className="card-body">
                                <div className="row row-cols-1 row-cols-md-3 g-1">

                                    <StyledDropzoneUploadModelDocument />
                                    

                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3 g-1 m-0 ">
                                <div className="col-lg-12 col">

                                    <div className="card">
                                        <div className="card-body">
                                            <div className='col-lg-6 offset-lg-3'>

                                            <button type="submit"  onClick={submitForm} disabled={isSubmitting} className="btn btn-primary login-btn  loginpage-samespace">upload</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </Form>

                    )}
                </Formik>
            </React.Fragment>

        </div>


    );
}

