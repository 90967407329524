var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, makeStyles } from '@material-ui/core';
import { DocumentDisplayer } from 'documentLabeler/components/documentPanel/displayer/DocumentDisplayer';
import { useDocumentDisplayer } from 'documentLabeler/components/documentPanel/displayer/useDocumentDisplayer';
import { DocumentBlockLayer } from 'documentLabeler/components/documentPanel/documentBlockLayer/DocumentBlockLayer';
import { DocumentContainer } from 'documentLabeler/components/documentPanel/documentContainer/DocumentContainer';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { withSize } from 'react-sizeme';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        flex: 1,
    },
    DocumentContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '&.FullHeight': {
            height: '100%',
        },
    },
    PreviewCard: {
        width: '100%',
    },
    DropzoneWrapper: {
        height: theme.spacing(50),
        padding: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    TableHeightCover: {
        height: theme.spacing(41),
    },
}); });
var DocumentPanelInternal = withSize({
    monitorHeight: true,
})(function (_a) {
    var _b, _c;
    var size = _a.size;
    var classes = useStyles();
    var state = useDocumentLabeler().state;
    var width = (_b = size === null || size === void 0 ? void 0 : size.width) !== null && _b !== void 0 ? _b : 0;
    var height = (_c = size === null || size === void 0 ? void 0 : size.height) !== null && _c !== void 0 ? _c : 0;
    var docDisplayerState = useDocumentDisplayer(state.docInfo.mimeType, { width: width, height: height }, state.docInfo.tempDocUrl);
    return (_jsx(Box, __assign({ className: classes.Root }, { children: _jsxs(Box, __assign({ className: classes.DocumentContainer }, { children: [_jsxs(DocumentContainer, __assign({ className: classes.PreviewCard }, { children: [_jsx(DocumentBlockLayer, { docPageHeights: docDisplayerState.pageHeights, width: width }), _jsx(DocumentDisplayer, { mimeType: state.docInfo.mimeType, width: width, document: state.docInfo.tempDocUrl, loaders: docDisplayerState.loaders, pages: docDisplayerState.pages })] })), state.localState.activeField &&
                    state.localState.activeField.type === FieldType.Table && (_jsx(Box, { className: classes.TableHeightCover }))] })) })));
});
var useExternalStyles = makeStyles(function (theme) { return ({
    Root: {
        display: 'flex',
        flex: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        overflow: 'scroll',
        maxHeight: '100%',
    },
}); });
/**
 * Component responsible for displaying & labeling the document.
 */
export var DocumentPanel = function () {
    var classes = useExternalStyles();
    return (_jsx(Box, __assign({ className: classes.Root }, { children: _jsx(DocumentPanelInternal, {}) })));
};
