export var BlockType;
(function (BlockType) {
    BlockType["Word"] = "Word";
    BlockType["Checkbox"] = "Checkbox";
})(BlockType || (BlockType = {}));
export var FieldType;
(function (FieldType) {
    FieldType["Text"] = "Text";
    FieldType["Checkbox"] = "Checkbox";
    FieldType["Signature"] = "Signature";
    FieldType["Table"] = "Table";
})(FieldType || (FieldType = {}));
export var Confidence;
(function (Confidence) {
    Confidence["High"] = "High";
    Confidence["Low"] = "Low";
    Confidence["UserReviewed"] = "UserReviewed";
})(Confidence || (Confidence = {}));
export var MimeType;
(function (MimeType) {
    MimeType["Pdf"] = "application/pdf";
    MimeType["Png"] = "image/png";
    MimeType["Jpg"] = "image/jpeg";
})(MimeType || (MimeType = {}));
