export var ButlerColorSet = [
    '#386DF4',
    '#36B37E',
    '#00C7E6',
    '#491FE0',
    '#FFAB00',
    '#FF5630',
];
/**
 * Given a color set, returns the appropriate color for an item of the given index
 * @returns string
 */
var getColorFromColorSet = function (index, colorSet) {
    if (colorSet === void 0) { colorSet = ButlerColorSet; }
    return colorSet[index % colorSet.length];
};
export var ColorUtils = {
    ButlerColorSet: ButlerColorSet,
    getColorFromColorSet: getColorFromColorSet,
};
