const formatPrice = (amount: string): string => {
   
    try {
        if(amount.trim() == "" || amount.trim() == "$-")
        {
            return "";
        }
        amount = amount.replace("$","");
        const price = parseFloat(amount);
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
          });
    } catch (err) {
        return amount;
    }
   
}

export const CorrectedformatCurrency = (value: string): string => {
    if (!value) {
      return ""; // Return empty string if the input is empty
    }
  
    const numValue = parseFloat(value); // Convert string to number
  
    if (isNaN(numValue)) {
      return ""; // Return empty string if the conversion results in NaN
    }
  
    if (numValue < 0) {
      return `(${Math.abs(numValue).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      })})`;
    } else {
      return numValue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
    }
  }

export default formatPrice;