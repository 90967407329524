import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid } from '@mui/material';
import { Form, Formik } from 'formik';
import { FMTextField } from '../Elements/input';


interface Iprops {
  handleClose: () => void;
  open: boolean;
  setOffsetNotes: (notes: string) => void; // Add the prop type
  handleUpdateOffsetNotes: (notes: string) => void; // Add the prop type
}

const initialValues = {
  notes: '',
};

export const OffsetNotes: React.FC<Iprops> = ({ open, handleClose, setOffsetNotes, handleUpdateOffsetNotes }) => {
    
  const handleSubmit = (data: typeof initialValues) => {
    setOffsetNotes(data.notes); 
    handleUpdateOffsetNotes(data.notes); // Update the rows with new note
    handleClose(); 
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <DialogTitle className='card-title'>Offset Notes</DialogTitle>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(data) => {
          handleSubmit(data);
        }}
      >
        {({isSubmitting }) => (
          <Form className='main-form'>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl sx={{ width: '100%', minWidth: '500px' }}>
                    <FormHelperText className='formtexthelper-heading'>
                      Note
                    </FormHelperText>
                    <FMTextField name="notes" label={null} rows={3} type="text" fullWidth variant="outlined" sx={{ m: 2 }} multiline />
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <button className='btn-2 btn d-flex btn-primary search-btn' type='button' onClick={handleClose}>Cancel</button>
              <button className='btn btn-primary active-btn-blue search-btn' type='submit' disabled={isSubmitting}>Submit</button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
