import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { Copyright } from "@mui/icons-material";
import { InsuranceComapnyList } from "../components/Lists/InsuranceCompanyList";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { RightNav } from "../components/Nav/RightNav";
import { ReportModuleList } from "../components/Lists/ReportModuleList";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { ReportModuleModel_Resp } from "../models/ReportModuleModel";
import { apiClient } from "../helper/api";




export const ReportModule = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initValue: ReportModuleModel_Resp = {
        getData_ReportingModule_data: [],
        totalCount: 0
    }


    const [ReportModule, setReportModule] = useState<ReportModuleModel_Resp>(initValue)

    const params = useParams();
    const [StateFilter, setstateFilter] = useState<string>("");
    const [CarrierFilter, setCarrierFilter] = useState<string>("");
    const [CustomerFilter, setCustomerFilter] = useState<string>("");

    let stateId: string | null = "";
    var carrierId: string | null;
    var customerId: string | null;


    const fetchDataMain = (stateId: string, carrierId: string, customerId: string, outsourcestaffId: string, pageSize: number, currentPage: number) => {
        setloading(true);

        // if (stateid != null || undefined) {

        //     stateId = stateid;
        //     console.log("inside if state " + stateId);
        // }
        // if (carrierid != null || undefined) {
        //     carrierId = carrierid;
        // }
        // if (customerid != null || undefined) {
        //     customerId = customerid;
        // }


        apiClient(`/ReportModule/getData_ReportingModule?stateId=${stateId}&&carrierId=${carrierId}&&customerId=${customerId}&&outsourcestaffId=${outsourcestaffId}&&pageSize=${pageSize}&&currentPage=${currentPage}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                //body: JSON.stringify(FitlerData)
            })
            .then((res) => {
                if (!res) return

                console.log("reportlistdata", res.data);


                // Do something with the response
                if (res.data != null) {
                    setReportModule(res.data)
                }
            }).finally(() => {
                setloading(false)
            });

    }

    useEffect(() => {
        fetchDataMain("", "", "", "", 50, 1);
    }, [])

    return (
       
        <ReportModuleList ReportModuleCL={ReportModule} fetchDataMain={fetchDataMain} />
                                            
    );

}
