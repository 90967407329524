import { Button, LinearProgress, Link } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CustomerPolicyDocumentDataList } from "../components/Lists/CustomerPolicyDocumentDataList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { ButlerResponseModel } from "../models/ButlerResponseModel";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { apiClient } from "../helper/api";

export const CustomerPolicyDocumentData = () => {
    //
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const params = useParams();
    const [PolicyCheckData, setPolicyCheckData] = useState<{ documentId: string, fieldName: string, policyChecklistResultId: number, isMatching: boolean, errorMessage: string }[]>([]);
    const categoryId = window.location.href.split('#')[1].split('@')[0];
    const initialData: ButlerResponseModel = {
        hasNext: false, hasPrevious: false, totalCount: 1, items: [{
            blob_Url: "", documentId: "", documentStatus: "", fileName: "", mimeType: "", documentType: "", confidenceScore: "", formFields: [{ fieldName: "", value: "", confidenceScore: "" }],
            tables:
                [
                    {
                        tableName: "",
                        confidenceScore: "",
                        rows: [{
                            cells: [
                                {
                                    columnName: "",
                                    confidenceScore: "",
                                    value: "",
                                }]
                        }]
                    }]
        }
        ]
    }

    const [ResponseData, setResponseData] = React.useState([initialData]);

    const fetchDataDocument = () => {
        setloading(true);
        apiClient(`/CustomerPolicyDocument/getCustomerPolicyLogbyCustPolicyid?custPolicyId=${params.custPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                console.log("document log count", res.data);
                // Do something with the response
                if (res.data != null) {
                    setResponseData(res.data)
                    console.log("Butler response",res.data);

                }

            }).finally(() => {
                setloading(false);
            });

    }

    const fetchPolicyChecksWithDocumentId = () => {

        setloading(true);
        apiClient(`/ProcessPolicyChecks/getPolicyCheckListWithDocumentId?custpolicyid=${params.custPolicyId}&&categoryId=${categoryId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                console.log("Checks", res.data);
                // Do something with the response
                if (res.data != null) {
                    setPolicyCheckData(res.data);
                }

            }).finally(() => {
                setloading(false);
            });

    }


    useEffect(() => {
        fetchDataDocument();
        fetchPolicyChecksWithDocumentId();
    }, [])

    return (

        <React.Fragment>


            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <CustomerPolicyDocumentDataList customerPolicyDetails={null} ResponseData={ResponseData} loading={loading} setloading={setloading} PolicyCheckData={PolicyCheckData} fetchDataDocument={fetchDataDocument} fetchPolicyChecksWithDocumentId={fetchPolicyChecksWithDocumentId} />
        </React.Fragment>


    );

}
