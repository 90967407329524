
import * as yup from 'yup'
export interface LineOfBusinessModel {
    id: number,
    businessCode?: string
    stateId?: number
    createdBy: number
    modifiedBy: number
    dateCreated?: Date
    dateModified?: Date
    isDeleted?: boolean
    stateName?: string
    coverageName?: string
    typeofRisk?:string

}

export const LineOfBusinessModel_VS = yup.object({
    businessCode: yup.string().required("Please enter coverage name").trim().typeError("Please Select BusinessCode"),
    stateId: yup.number().required("Please select a state").typeError("invalid value"),

})