var ICON_COLUMN_WIDTH = 24;
// calculates static column widths for the extracted table
// editor and display components so that column widths don't
// change as the user edits the values
var calcColumnSpecs = function (numColumns) {
    // we want each column to have equal width, except the final column
    // which contains only icon buttons
    var columnWidth = "calc(".concat(100 / numColumns, "% - ").concat(ICON_COLUMN_WIDTH / numColumns, "px)");
    var finalColumnWidth = "".concat(ICON_COLUMN_WIDTH, "px");
    return Array.from(Array(numColumns))
        .map(function () { return ({ width: columnWidth }); })
        .concat([{ width: finalColumnWidth }]);
};
export var ColumnSpecsUtil = {
    calcColumnSpecs: calcColumnSpecs,
};
