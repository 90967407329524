var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper, Table, TableContainer } from '@material-ui/core';
/**
 * Reusable Component for rendering a DataTable. Should be
 * used in conjunction with the useDataTable component.
 * @param props
 */
export var DataTable = function (_a) {
    var children = _a.children, classes = _a.classes, elevation = _a.elevation, columnSpecs = _a.columnSpecs;
    return (_jsx(Paper, __assign({ className: classes === null || classes === void 0 ? void 0 : classes.paper, elevation: elevation }, { children: _jsx(TableContainer, __assign({ className: classes === null || classes === void 0 ? void 0 : classes.container }, { children: _jsxs(Table, __assign({ stickyHeader: true }, { children: [columnSpecs && (_jsx("colgroup", { children: columnSpecs.map(function (spec, idx) { return (_jsx("col", { style: { width: spec.width } }, idx)); }) })), children] })) })) })));
};
