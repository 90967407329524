import React, { Fragment, useState } from "react";
import useAuth, { apiResponse, authData } from "../context/AuthProvider";
import { Box, LinearProgress, TextField, Dialog, Grid, IconButton, InputAdornment } from "@mui/material";
import Button from "@mui/material/Button";
import { config } from "../Constants";
import { Field, Form, Formik } from "formik";
import { LoginModel_VS } from "../models/LoginModel";
import { CustomInputComponent, CustomInputComponentPassword } from "../components/Elements/input";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { apiClient } from "../helper/api";
interface Values {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const { login, error } = useAuth();
  const [loading, setloading] = useState(false);
  const [showpassword, setshowpassword] = React.useState<boolean>(false);
  const { user } = useAuth();
  const token = user?.token;

  const handleLogin = (resp: apiResponse<authData>) => {
    login(resp);
  };

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const openForgotPasswordDialog = () => {
    setIsDialogOpen(true);
  };

  const closeForgotPasswordDialog = () => {
    setIsDialogOpen(false);
    setSelectedEmail("");
  };

  const [selectedEmail, setSelectedEmail] = useState("");

  const sendResetEmail = (email: string) => {
    setloading(true);
    if (selectedEmail.trim() === "") {
      alert("Please select an email");

      return;
    }
    apiClient(`/Users/checkEmailForForgetPassword?email=${email}`,
      {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          // 'Authorization': `Bearer ${token}`,
        }),
      }
    )
      .then((responseJson) => {
        if (!responseJson) return
        closeForgotPasswordDialog();
        console.log("emaildata", responseJson);
        if (responseJson.resp == 0) {
          Swal.fire({
            icon: "success",
            title: "Email Sent!",
            text: "Password reset instructions have been sent to your email.",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Email Not Found",
            text: "The provided email does not exist in our records.",
          });
        }
        setloading(false);
      })
      // .catch((error) => {
      //   Swal.fire({
      //     icon: "error",
      //     title: "Error",
      //     text: "Something went wrong while processing your request.",
      //   });
      //   console.error(error);
      // });
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <React.Fragment>
      {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}

      <section className="main-section">
        <div className="container">
          <div style={{ textAlign: "center" }} className="filemule-main">
            <img
              src="/assets/Brand/MISC_Full_Type_Lockup_Primary.png"
              className="img-logo"
              alt="logo"
            />
            <p>Enter your credentials to access your portal</p>
            <Formik
              initialValues={{ Username: "", password: "" }}
              validationSchema={LoginModel_VS}
              validateOnChange={true}
              onSubmit={(data, { setSubmitting }) => {
                setloading(true);

                apiClient("/Auth/Login", {
                  method: "POST",
                  headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  }),

                  body: JSON.stringify(data), // <-- Post parameters
                })
                  .then((res) => {
                    if (!res) return
                    // Do something with the response
                    setloading(false);
                    handleLogin(res);
                    setSubmitting(false);
                  })
              }}
            >
              {({ submitForm, isSubmitting }) => (
                <Form className="loginpage-samespace">
                  {error && <p className="red">Bad login/password </p>}
                  <div className="text-fields">
                    <Field
                      className="input-box"
                      component={CustomInputComponent}
                      type="text"
                      label="Username"
                      name="Username"
                      placeholder="Username/Email"
                      style={{ color: "white" }}
                    />
                  </div>
                  <div className="forgot-password-section">
                    <Field
                      component={CustomInputComponent}
                      label="Password"
                      name="password"
                      id="password"
                      type={showPassword ? 'text' : "password"}
                      className="input-box"
                      placeholder="Password"
                      style={{ color: "white" }}

                    />
                    <div className="forget-password">
                      <a href="#" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? 'Hide Password' : "Show Password"}
                       
                      </a>
                    </div>
                    <div className="forget-password">
                      <a href="#" onClick={openForgotPasswordDialog}>
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                  <Dialog
                    open={isDialogOpen}
                    onClose={closeForgotPasswordDialog}
                    fullWidth
                    maxWidth="sm"
                    sx={{ textAlign: "center" }}
                  >
                    <Box p={3}>

                      <div className="d-flex justify-content-between" >
                        {loading && (
                          <LinearProgress sx={{ backgroundColor: "#132D3D" }} />
                        )}
                        <div><label htmlFor="email" className="form-label mail-dialog">
                          Please enter your email address:
                        </label></div>
                        <div className="cut-main-btn"><Button
                          className="cross-cut-icon"
                          onClick={closeForgotPasswordDialog}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24 8L8 24M8 8L24 24"
                              stroke="#162E3C"
                              strokeWidth="2.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Button></div>
                      </div>
                      <Grid container xs={12}>
                        <TextField
                          type="email"
                          id="email"
                          name="email"
                          fullWidth
                          label="Please enter your email address:"
                          // InputLabelProps={{ shrink: true }}
                          variant="outlined"
                          sx={{ marginTop: 5 }} // Add margin top to TextField
                          // placeholder="Enter your email address"
                          value={selectedEmail}
                          onChange={(e) => setSelectedEmail(e.target.value)}
                        />
                      </Grid>
                      <button
                        className="active-btn-blue p-2"
                        onClick={() => {
                          sendResetEmail(selectedEmail);
                        }}
                        style={{
                          marginTop: "56px",
                          backgroundColor: "rgba(2, 164, 227, 1) ",
                        }}
                        disabled={isButtonDisabled}
                      >
                        Send Reset Email
                      </button>
                    </Box>
                  </Dialog>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ textAlign: "center" }}
                    className="btn-121"
                    type="submit"
                  >
                    Sign In
                  </Button>
                  {/* <div className="terms-privacy">
               <span>Terms&nbsp;&nbsp;|&nbsp;&nbsp;Privacy&nbsp;&nbsp;|&nbsp;&nbsp;Security&nbsp;&nbsp;|&nbsp;&nbsp;Get in Touch  </span>
              </div> */}
                </Form>
              )}
            </Formik>
          </div>
          <div className="terms-privacy">
            <span>Terms&nbsp;&nbsp;|&nbsp;&nbsp;Privacy&nbsp;&nbsp;|&nbsp;&nbsp;Security&nbsp;&nbsp;|&nbsp;&nbsp;Get in Touch  </span>
          </div>
        </div>
      </section>
      {/* Sign In
      
                </Button>


              </Form>
            )}
          </Formik>
        </div>


      </section> */}

    </React.Fragment>
  );
};
