var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, Button, makeStyles } from '@material-ui/core';
import { OutlinedTextField } from 'common/display/OutlinedTextField/OutlinedTextField';
var SAVE = 'Save';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        minHeight: theme.spacing(3),
        alignItems: 'center',
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
}); });
/**
 * Reusable visual component for displaying an outlined
 * text field with a save button, used throughout the
 * Create Doc Type flow to edit form field, table, and
 * column header names
 */
export var EditableNameDisplay = function (_a) {
    var name = _a.name, onSave = _a.onSave, label = _a.label, inputRef = _a.inputRef;
    var classes = useStyles();
    var _b = useState(name), currentName = _b[0], setCurrentName = _b[1];
    return (_jsxs(Box, __assign({ className: classes.Root }, { children: [_jsx(OutlinedTextField, { value: currentName, onChange: function (e) { return setCurrentName(e.target.value); }, autoFocus: true, label: label, fullWidth: true, size: "small", inputRef: inputRef }), _jsx(Button, __assign({ onClick: function () { return onSave(currentName); }, variant: "text", color: "primary" }, { children: SAVE }))] })));
};
