export const columnWidth = {
    'homeState': 150,
    'insuredName': 250,
    'policyNumber': 250,
    'invoiceNumber': 180,
    'needDiligentSearch': 220,
    'diligentSearchNotes': 220,
    'diligentSearchCompleted': 250,
    'dateCompleted': 220,
    'suspended': 150,
    'transactionType': 200,
    'carrier': 300,
    'naic': 120,
    'policyEffDate': 180,
    'policyExpDate': 180,
    'grossPremium': 180,
    'client': 250,
    'actions': 250,
}
