import * as yup from 'yup'

export interface ButlerModel {
    modelId: number,
    name?: string,
    queueId?: string,
    status?: string,
    stateId?: number,
    isDeleted?: true,
    butlerModelId?: string,
    stateName?: string,
    IsMultiFormat?:boolean


}



export const ButlerModel_VS = yup.object({
    name: yup.string().required("Please enter name").min(1).trim().max(150, "Cannot exceed 150 character in length"),
    queueId: yup.string().required("Please enter name").min(1).trim().max(150, "Cannot exceed 150 character in length"),
    butlerModelId: yup.string().required("Please enter name").min(1).trim().max(150, "Cannot exceed 150 character in length"),

    // stateId: yup.number().required("please select state").min(1, "please select State").typeError("invalid value"),

})
export const UpdateButlerModel_VS = yup.object({
    queueId: yup.string().required("Please enter queue id").min(1).trim().max(150, "Cannot exceed 150 character in length"),
    butlerModelId: yup.string().required("Please enter butler model id").min(1).trim().max(150, "Cannot exceed 150 character in length"),

})