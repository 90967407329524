import { LinearProgress } from "@mui/material";
import { Console } from "console";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { StateRuleMatrixList } from "../components/Lists/StateRuleMatrixList";
import { StateStampListList } from "../components/Lists/StateStampListList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { FillingsByUser } from "./FillingsByUser";
import { apiClient } from "../helper/api";

export const StateRuleMatrix = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const [StateRule, setStateRule] = useState([]);

    const fetchData = () => {
        debugger
        setloading(true);
        apiClient('/StateRuleMatrix/getAllStateRuleMatrix',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response

                setStateRule(responseJson.data);
                console.log(responseJson.data);


            }).finally(() => {
                setloading(false);
            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{backgroundColor : "whitesmoke"}}>

        //                         <LoggedInUserInfo ButtonText="Add New State Rule" Link="" />


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="main shadow rounded" style={{backgroundColor : "#ffffff" }}>


        //                                     <div style={{padding : "1rem" , paddingTop : "2rem", paddingBottom: "4rem"}}>
        //                                         <div className="table-responsive">
        //                                             {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                    < StateRuleMatrixList StateStampRuleMatrixCL={StateRule} fetchData={fetchData} />
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>

    )

}