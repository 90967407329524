import { Dialog, DialogActions, DialogTitle, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, OutlinedInput, Radio, RadioGroup, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { FieldAttributes, Form, Formik, useField } from 'formik';
import React, { useEffect, useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import * as yup from 'yup';
import { config } from '../../Constants';
import useAuth from "../../context/AuthProvider";
import { FMTextField } from '../Elements/input';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import TextField from '@mui/material/TextField';

import { FMSelectTaxTypeField, FMSelectTrueFalse } from '../Elements/select';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { height } from '@mui/system';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { apiClient } from '../../helper/api';



export interface TaxesAndFeeDataModel {
    paymentFilingScheduleId: number
    dueDate: string
    stateId: number,
    taxFeename: string
    taxesAndFeesFilingSchedulePeriodId: number
    jan: string
    feb: string
    mar: string
    apr: string
    may: string
    june: string
    july: string
    aug: string
    sept: string
    nov: string
    oct: string
    dec: string
    annualDate: string
    quarter1: string
    quarter2: string
    quarter3: string
    quarter4: string
    semiAnnualFirstHalf: string
    semiAnnualSecondHalf: string
    zeroFiling: string
    taxesAndFeesFilingSchedulePeriod?: string
    stateName: string
    taxesAndFilingSchedulePeriodId: number
    monthlyReportingDueDate: string
    withInDays?: string
    withInForteenDays: boolean
    dueImmediatelyUponInvoicing: boolean

    payment?: boolean
    report?: boolean
    wetSignature?: boolean
    notaryStamp?: boolean
    zeroIndividual?: boolean
    zeroEntity?: boolean
    paymentType?: string
    notes?: string
    name?: string

}


export const ModelCheck = yup.object({
    // taxFeename: yup.string().required('Select Tax').typeError("inavlid value"),
    // stateId: yup.number().min(1, "Select State").required("please select state").typeError("invalid value"),
    taxFeename: yup.string().required('Select Tax/Fee').typeError("Invalid Selection"),
    stateId: yup.number().min(1, "Please slecet state").required('Please select state').typeError("Invalid selection"),

})

interface Iprops {
    formValues: TaxesAndFeeDataModel
    handleClose: () => void,
    Open: boolean,
    handleSubmit: (StateID: string) => void
    RadioSelectedValue: string
    setRadioSelectedValue: React.Dispatch<React.SetStateAction<string>>
    setRadioSelectedValueZeroFiling: React.Dispatch<React.SetStateAction<string>>
    RadioSelectedValueZeroFiling: string
    RadioSelectValueDueImmediatlyUponInvoicing: string
    setRadioSelectedValueDueImmeditaly: React.Dispatch<React.SetStateAction<string>>

}

export const AddEditTaxFeeDate: React.FC<Iprops> = ({ formValues, handleClose, Open, handleSubmit, RadioSelectedValue, setRadioSelectedValue, setRadioSelectedValueZeroFiling, RadioSelectedValueZeroFiling, RadioSelectValueDueImmediatlyUponInvoicing, setRadioSelectedValueDueImmeditaly }) => {


    // const params = useParams();
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const initialData = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialData]);
    const [TaxandFees, setTaxandFees] = useState<string[]>([]);

    const fetchData = () => {
        apiClient("/States/getStates", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((responseJson) => {
                if (responseJson?.data != null) {
                    setStates(responseJson.data);
                }
                console.log("statesData", responseJson.data);
            })
    };

    const fetchTaxandFeesData = () => {
        apiClient(`/PolicyFilingType/GetAll`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((responseJson) => {
                if (responseJson?.data != null) {
                    setTaxandFees(responseJson.data);
                }
                console.log("TaxandFees", responseJson.data);
            })
    };

    useEffect(() => {
        fetchData();
        fetchTaxandFeesData();
    }, [token]);

    // const FMSelectState: React.FC<FieldAttributes<SelectProps>> = ({
    //     variant,
    //     ...props
    // }) => {
    //     const [field, meta] = useField<{}>(props);
    //     const errorText = meta.error && meta.touched ? meta.error : "";
    //     const { user } = useAuth();
    //     const token = user?.token;
    //     const initialdata = { stateId: 0, stateName: "" };
    //     const [States, setStates] = useState([initialdata]);

    //     const fetchData = () => {
    //         fetch(config.url.API_URL + "/States/getStates", {
    //             method: "GET",
    //             headers: new Headers({
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`,
    //             }),
    //         })
    //             .then((response) => {
    //                 if (response.ok) {
    //                     return response.json();
    //                 }
    //                 throw new Error("Something went wrong");
    //             })
    //             .then((responseJson) => {
    //                 // Do something with the response
    //                 if (responseJson.data != null) {
    //                     setStates(responseJson.data);
    //                 }

    //                 console.log("statesDAta", responseJson.data);
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             });
    //     };


    //     useEffect(() => {
    //         fetchData();
    //     }, []);

    //     return (
    //         <FormControl error={!!errorText} fullWidth>
    //             {/* <FormHelperText>Select State</FormHelperText> */}
    //             <InputLabel id="State-Select-Label"></InputLabel>
    //             <Select
    //                 {...field}
    //                 labelId="State-Select-Label"
    //                 // label="State"
    //                 IconComponent={ExpandMoreIcon}
    //                 label={null}
    //                 className="border-round custom-menu-item "
    //             >
    //                 <MenuItem key={0} value={0}>
    //                     {" "}
    //                     {" "}
    //                 </MenuItem>
    //                 {States.map((state, key) => (
    //                     <MenuItem key={key} value={state.stateId}>
    //                         {" "}
    //                         {state.stateName}{" "}
    //                     </MenuItem>
    //                 ))}
    //             </Select>
    //         </FormControl>
    //     );
    // };

    const RadioValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRadioSelectedValue((event.target as HTMLInputElement).value);

    };
    const RadioValueChangefiling = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRadioSelectedValueZeroFiling((event.target as HTMLInputElement).value);

    };
    const RadioValueChangeDueImmediatlyUponInvoicing = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRadioSelectedValueDueImmeditaly((event.target as HTMLInputElement).value);

    };
    const FMSelectState: React.FC<FieldAttributes<SelectProps>> = ({
        variant,
        ...props
    }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (
            <FormControl error={!!errorText} fullWidth>
                <InputLabel id="State-Select-Label"></InputLabel>
                <Select
                    {...field}
                    labelId="State-Select-Label"
                    IconComponent={ExpandMoreIcon}
                    label={null}
                    className="border-round custom-menu-item"
                >
                    <MenuItem key={0} value={0}>{" "}</MenuItem>
                    {States.map((state, key) => (
                        <MenuItem key={key} value={state.stateId}>
                            {" "}
                            {state.stateName}{" "}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const FMSelectTrueFalseCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (
            <React.Fragment>
                <FormControl error={!!errorText} className="border-round select-input-border" fullWidth>
                    <Select {...field} label={null}
                        IconComponent={ExpandMoreIcon}
                    >
                        <MenuItem value={true as any}>True</MenuItem>
                        <MenuItem value={false as any}>False</MenuItem>

                    </Select>
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>

        )
    };
   
    const FMSelectTaxAndFeesustom: React.FC<FieldAttributes<SelectProps>> = ({
        variant,
        ...props
    }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;

        // useEffect(() => {
        //     fetchTaxandFeesData();
        // }, []);
        return (
            <FormControl error={!!errorText} fullWidth>
                <InputLabel id="State-Select-Label"></InputLabel>
                <Select {...field} labelId="State-Select-Label" label="TaxandFees"
                    className="border-round main-form-2"
                    IconComponent={ExpandMoreIcon}
                >

                    {TaxandFees.map((taxandfees, key) => (
                        <MenuItem key={key} value={taxandfees}>
                            {" "}
                            {taxandfees}{" "}
                        </MenuItem>
                    ))}
                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        );
    };



    return (
        <Dialog open={Open} onClose={handleClose} maxWidth="xl">
            <DialogTitle className='card-title'  >Policy Schedule for {formValues.stateName} </DialogTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <div >
                    <Formik initialValues={formValues}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validationSchema={ModelCheck}
                        onSubmit={(data, { setSubmitting }) => {

                            if (data.stateId === null || data.stateId === 0) {
                                alert("please Select State");
                                setSubmitting(false);
                                return;
                            }
                            if (data.taxFeename === null || data.taxFeename === '') {
                                alert("please Select Tax/Fee");
                                setSubmitting(false);
                                return;
                            }
                            data.taxesAndFeesFilingSchedulePeriodId = Number(RadioSelectedValue)
                            // data.taxFeename = params.type == "pf" ? "Policy Filing" : params.type == "slt" ? "Surplus Lines Tax" : params.type == "sf" ? "Stamping Fee" : ""
                            // if (RadioSelectedValue == "1") {
                            //     data.annualDate = ''
                            // }
                            if (RadioSelectedValue === "12")
                                RadioSelectedValue = "0";
                            if (data.taxFeename !== "Stamping Fee" && RadioSelectValueDueImmediatlyUponInvoicing === "12")
                                RadioSelectValueDueImmediatlyUponInvoicing = "0"


                            if (RadioSelectedValueZeroFiling === "7" && data.zeroFiling === "") {
                                alert("please enter zero filing date");
                                setSubmitting(false);
                                return;
                            }
                            if (RadioSelectedValue === "0" && RadioSelectValueDueImmediatlyUponInvoicing !== "12") {

                                alert("please select any Schedule Period");
                                setSubmitting(false);
                                return;
                            }
                            if (RadioSelectedValue === "9")
                                data.withInForteenDays = true;
                            else
                                data.withInForteenDays = false;

                            if (RadioSelectedValue !== "11")
                                data.withInDays = "";

                            if (RadioSelectValueDueImmediatlyUponInvoicing === "12") {
                                data.dueImmediatelyUponInvoicing = true;
                                if (RadioSelectedValue === "0")
                                    data.taxesAndFeesFilingSchedulePeriodId = Number(RadioSelectValueDueImmediatlyUponInvoicing)
                            }

                            else
                                data.dueImmediatelyUponInvoicing = false;
                            if (data.notes === null) {
                                data.notes = ""
                            }
                            else {
                                data.notes = data.notes;
                            }


                            console.log(data);
                            setloading(true);
                            apiClient('/TaxesAndFeesFilingSchedule/UpdateTaxesAndFeesFilingScheduleDate',
                                {
                                    method: 'POST',

                                    headers: new Headers({
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    }),
                                    body: JSON.stringify(data)  // <-- Post parameters

                                })
                                .then((res) => {
                                    if (!res) return
                                    // fetchData();
                                    // Do something with the response
                                    Swal.fire({
                                        title: res.message,
                                        text: "",
                                        icon: res.data === 1 ? "success" : res.data === 2 ? "warning" : "info",
                                        confirmButtonText: "OK",
                                    });
                                    handleClose();

                                }).finally(() => {
                                    setSubmitting(false);
                                    setloading(false);
                                })

                        }} >
                        {({ values, isSubmitting, setFieldValue }) => (
                            <Form>
                                <DialogContent>
                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                    {/* {
                                        params.type == "pf" ?
                                            <h3>Add or Update Filing Due Date</h3> : params.type == "slt" ? <h3>Add or Update Tax Due Dates</h3> : params.type == "sf" ? <h3>Add or Update Payment Due Dates</h3> : ""
                                    } */}
                                    {/* <Divider /> */}
                                    <Grid marginTop={"20px"} >
                                        <Grid container spacing={2} display='flex' flexDirection='row' xs={12}   >
                                            <Grid item xs={3}   >
                                                <FormHelperText className='formtexthelper-heading' >
                                                    States
                                                </FormHelperText>
                                                <FMSelectState autoWidth={true} variant={"standard"} name="stateId" ></FMSelectState>
                                            </Grid>

                                            <Grid item xs={3}   >

                                                {/* <FMTextField name="paymentFilingScheduleId" hidden type="text" fullWidth variant="outlined" sx={{ width: '100%' }} /> */}
                                                {/* <FMTextField name="taxesAndFeesFilingSchedulePeriodId" hidden type="text" fullWidth variant="outlined" sx={{ width: '100%' }} /> */}
                                                {/* <FMSelectTaxTypeField autoWidth={true} name="taxFeename" label="Fees" placeholder='Fees' /> */}
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        Tax and Fees
                                                    </FormHelperText>
                                                    <FMSelectTaxAndFeesustom autoWidth={true} name="taxFeename"></FMSelectTaxAndFeesustom>

                                                </FormControl>

                                            </Grid>
                                            {/* </Grid> */}

                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        Payment
                                                    </FormHelperText>
                                                    <FMSelectTrueFalseCustom name="payment" />

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        Reports
                                                    </FormHelperText>
                                                    <FMSelectTrueFalseCustom name="report" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormHelperText className='formtexthelper-heading' >
                                                    Wet Signature
                                                </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >

                                                    <FMSelectTrueFalseCustom name="wetSignature" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormHelperText className='formtexthelper-heading' >
                                                    Notary Stamp
                                                </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >

                                                    <FMSelectTrueFalseCustom name="notaryStamp" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormHelperText className='formtexthelper-heading' >
                                                    Zero Entity
                                                </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >

                                                    <FMSelectTrueFalseCustom name="zeroEntity" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}><FormHelperText className='formtexthelper-heading' >
                                                Zero Individual
                                            </FormHelperText>
                                                <FormControl sx={{ width: '100%' }} >

                                                    <FMSelectTrueFalseCustom name="zeroIndividual" />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        Payment Type
                                                    </FormHelperText>
                                                    <FMTextField name="paymentType" autoFocus margin="dense" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={9} >
                                                <FormControl sx={{ width: '100%' }}  >
                                                    <FormHelperText className='formtexthelper-heading'>Notes</FormHelperText>
                                                    <FMTextField name="notes" autoFocus margin="dense" multiline rows={1} type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                                </FormControl>
                                            </Grid>
                                        </Grid>


                                        <Grid item xs={12} display='flex' padding={2}  >
                                            <Grid item xs={3} display={'flex'} justifyContent="end"  >
                                                <label className='m-2'>Zero Filing Required</label>
                                            </Grid>
                                            <Grid item xs={2} marginLeft={2} display={'flex'} justifyContent="start">
                                                <RadioGroup
                                                    value={RadioSelectedValueZeroFiling}
                                                    onChange={(e) => {
                                                        RadioValueChangefiling(e);
                                                        setFieldValue("zeroFiling", "", false)
                                                    }}
                                                    defaultValue={''}
                                                    row
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    name="radio-buttons-group" >
                                                    <FormControlLabel value='7' control={<Radio />} label="Yes" />
                                                    <FormControlLabel value='' control={<Radio />} label="No" />
                                                </RadioGroup>

                                            </Grid>

                                            {RadioSelectedValueZeroFiling === "7" ?
                                                <Grid item xs={4} >
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <DatePicker
                                                            inputFormat="MM/DD"
                                                            label="Select Zero Filing"
                                                            value={new Date(values.zeroFiling)}

                                                            onChange={
                                                                (value) => {
                                                                    setFieldValue("zeroFiling", new Date(value ?? "").toLocaleDateString(), false)

                                                                }
                                                            }
                                                            renderInput={(params) =>
                                                                <TextField {...params} name="zeroFiling" error={false} helperText={null} />}
                                                        />
                                                    </FormControl >
                                                </Grid>
                                                : ""
                                            }

                                        </Grid>
                                        {values.stateId?.toString() === '5' && values.taxFeename === "Stamping Fee" ?
                                            <Grid item xs={12} display='flex'  >
                                                <Grid item xs={3} display={'flex'} justifyContent="end"  >
                                                    <label className='m-2'>Due Immediately Upon Invoicing</label>
                                                </Grid>
                                                <Grid item xs={2} marginLeft={2} display={'flex'} justifyContent="start">
                                                    <RadioGroup
                                                        value={RadioSelectValueDueImmediatlyUponInvoicing}
                                                        onChange={(e) => {
                                                            RadioValueChangeDueImmediatlyUponInvoicing(e);
                                                            // setFieldValue("dueImmediatelyUponInvoicing", "", false)
                                                        }}
                                                        row
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        name="radio-buttons-group" >
                                                        <FormControlLabel value='12' control={<Radio />} label="Yes" />
                                                        <FormControlLabel value='' control={<Radio />} label="No" />
                                                    </RadioGroup>

                                                </Grid>

                                            </Grid> : ""
                                        }
                                    </Grid>


                                    <Grid container spacing={2} xs={12} marginBottom="30px" >
                                        <Grid item xs={12} justifyContent="center" display='flex' >
                                            <RadioGroup
                                                value={RadioSelectedValue}
                                                onChange={RadioValueChange}
                                                row

                                                aria-labelledby="demo-radio-buttons-group-label"

                                                // defaultValue={formValues.taxesAndFeesFilingSchedulePeriodId.toString() }
                                                name="radio-buttons-group" >
                                                <div className='col-12 text-center' style={{ marginLeft: "20px" }}>
                                                    <FormControlLabel value='2' control={<Radio />} label="Annual Pay Due Date" />
                                                    <FormControlLabel value='6' control={<Radio />} label="Semi-Annual Pay Due Date" />
                                                </div>
                                                <div className='col-12 text-center' style={{ marginLeft: "10px" }}>
                                                    {/* <FormControlLabel value='1' control={<Radio />} label="Monthly Pay Due Date" /> */}
                                                    {/* <FormControlLabel value='3' control={<Radio />} label="Quarterly Pay Due Date" /> */}
                                                    {/* <FormControlLabel value='8' control={<Radio />} label="Within Days" /> */}
                                                    <FormControlLabel value='1' control={<Radio />} label="Monthly Pay Due Date" />
                                                    <FormControlLabel value='3' control={<Radio />} label="Quarterly Pay Due Date" />

                                                    <FormControlLabel value='10' control={<Radio />} label="Monthly Reporting Due Date" />

                                                    <FormControlLabel value='11' control={<Radio />} label="Due within DAYS of policy effective date" />
                                                    {/* <FormControlLabel value='8' control={<Radio/>} label="Monthly Reporting Due Date" /> */}
                                                    <FormControlLabel value='9' control={<Radio />} label="Within 14 Days of policy effective date" />
                                                    {/* { values.stateId.toString() === '5' && values.taxFeename =="Stamping Fee" ?
                                                    <FormControlLabel value='12' control={<Radio />} label="Due Immediately Upon Invoicing" />: ""} */}



                                                </div>
                                            </RadioGroup>

                                        </Grid>

                                        <Grid item xs={12} display='flex'  >
                                            <Grid item xs={3} display={'flex'} justifyContent="end"  >

                                            </Grid>


                                            {RadioSelectedValue === "10" ?
                                                <Grid item xs={4} >
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <DatePicker
                                                            inputFormat="MM/DD"
                                                            label="Select Monthly Reporting Due Date"
                                                            value={new Date(values.monthlyReportingDueDate)}

                                                            onChange={
                                                                (value) => {
                                                                    setFieldValue("monthlyReportingDueDate", new Date(value ?? "").toLocaleDateString(), false)

                                                                }
                                                            }
                                                            renderInput={(params) =>
                                                                <TextField {...params} name="monthlyReportingDueDate" error={false} helperText={null} />}
                                                        />
                                                    </FormControl >
                                                </Grid>
                                                : ""

                                            }

                                            {RadioSelectedValue === "11" ?
                                                <Grid item xs={4} >
                                                    <FormControl sx={{ width: '100%' }}>
                                                        <FMTextField inputProps={{
                                                            minDays: '0',
                                                            maxDays: '30',
                                                        }} name="withInDays" label="With In Days" type="Text" fullWidth />
                                                    </FormControl>
                                                </Grid> : ""
                                            }
                                        </Grid>
                                    </Grid>

                                    {RadioSelectedValue === "3" ?
                                        <Grid container spacing={2} xs={12} item justifyContent="center" marginBottom="30px">
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="Quarter1" label="Quarter-1" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="Quarter-1"

                                                        value={new Date(values.quarter1)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("quarter1", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="semiAnnualSecondHalf" error={false} helperText={null} />}
                                                    />
                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="Quarter1" label="Quarter-1" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="Quarter-2"

                                                        value={new Date(values.quarter2)}
                                                        onChange={
                                                            (value) =>
                                                                // setFieldValue("quarter2", new Date(value ?? "").toLocaleDateString(), false)
                                                                setFieldValue("quarter2", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="quarter2" error={false} helperText={null} />}
                                                    />
                                                </FormControl >
                                            </Grid>
                                            <Grid container spacing={2} xs={12} item justifyContent="center" marginBottom="30px">
                                                <Grid item xs={3}>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        {/* <FMTextField name="Quarter1" label="Quarter-1" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                        <DatePicker
                                                            inputFormat="MM/DD"
                                                            label="Quarter-3"
                                                            value={new Date(values.quarter3)}
                                                            onChange={
                                                                (value) =>
                                                                    setFieldValue("quarter3", new Date(value ?? "").toLocaleDateString(), false)
                                                            }
                                                            renderInput={(params) =>
                                                                <TextField {...params} name="quarter3" error={false} helperText={null} />}
                                                        />
                                                    </FormControl >
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormControl sx={{ width: '100%' }}>
                                                        {/* <FMTextField name="Quarter1" label="Quarter-1" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                        <DatePicker
                                                            inputFormat="MM/DD"
                                                            label="Quarter-4"
                                                            value={new Date(values.quarter4)}
                                                            onChange={
                                                                (value) =>
                                                                    setFieldValue("quarter4", new Date(value ?? "").toLocaleDateString(), false)
                                                            }
                                                            renderInput={(params) =>
                                                                <TextField {...params} name="quarter4" error={false} helperText={null} />}
                                                        />
                                                    </FormControl >
                                                </Grid>
                                            </Grid>
                                        </Grid> : ""
                                    }

                                    {RadioSelectedValue === "8" ?
                                        <Grid container spacing={2} xs={12} item justifyContent="center" marginBottom="30px">
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="Monthly Reporting Due Date"
                                                        value={new Date(values.monthlyReportingDueDate)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("monthlyReportingDueDate", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="monthlyReportingDueDate" error={false} helperText={null} />}
                                                    />
                                                    {/* <TextField
                                                        inputProps={{
                                                            minDays: '1',
                                                            maxDays: '30',
                                                        }}
                                                    /> */}
                                                </FormControl >
                                            </Grid>
                                        </Grid>

                                        : ""}


                                    {RadioSelectedValue === "6" ?
                                        <Grid container spacing={2} xs={12} item justifyContent="center" marginBottom="30px">
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="Quarter1" label="Quarter-1" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="Semi-Annual-First-Half"
                                                        value={new Date(values.semiAnnualFirstHalf)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("semiAnnualFirstHalf", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="semiAnnualFirstHalf" error={false} helperText={null} />}
                                                    />
                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="Quarter1" label="Quarter-1" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="Semi-Annual-Second-Half"
                                                        value={new Date(values.semiAnnualSecondHalf)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("semiAnnualSecondHalf", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="semiAnnualSecondHalf" error={false} helperText={null} />}
                                                    />
                                                </FormControl >
                                            </Grid>


                                        </Grid> : ""
                                    }
                                    {RadioSelectedValue === "1" ?

                                        <Grid container spacing={2} xs={12} item justifyContent="center" marginBottom="30px"  >
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="jan" label="Januay" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="Januay"
                                                        value={new Date(values.jan)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("jan", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="jan" error={false} helperText={null} />}
                                                    />
                                                </FormControl >
                                            </Grid>


                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="feb" label="February" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="February"
                                                        value={new Date(values.feb)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("feb", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="feb" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="mar" label="March" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="March"
                                                        value={new Date(values.mar)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("mar", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="mar" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="apr" label="April" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="April"
                                                        value={new Date(values.apr)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("apr", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="apr" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="may" label="May" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="May"
                                                        value={new Date(values.may)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("may", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="may" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="june" label="June" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="June"
                                                        value={new Date(values.june)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("june", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="june" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="july" label="july" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="July"
                                                        value={new Date(values.july)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("july", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="july" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="aug" label="August" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="August"
                                                        value={new Date(values.aug)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("aug", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="aug" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="sept" label="September" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="September"
                                                        value={new Date(values.sept)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("sept", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="sept" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="oct" label="October" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="October"
                                                        value={new Date(values.oct)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("oct", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="oct" error={false} helperText={null} />}
                                                    />
                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="nov" label="November" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="November"
                                                        value={new Date(values.nov)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("nov", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="nov" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    {/* <FMTextField name="dec" label="December" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="December"
                                                        value={new Date(values.dec)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("dec", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="dec" error={false} helperText={null} />}
                                                    />

                                                </FormControl >
                                            </Grid>

                                        </Grid>
                                        : ""}
                                    {RadioSelectedValue === "2" ?

                                        <Grid item xs={12} display='flex' flexDirection='row' justifyContent="center" marginBottom="30px" >

                                            <Grid item xs={6}>
                                                <FormControl sx={{ width: '100%' }}>
                                                    <DatePicker
                                                        inputFormat="MM/DD"
                                                        label="Annual Date"
                                                        value={new Date(values.annualDate)}
                                                        onChange={
                                                            (value) =>
                                                                setFieldValue("annualDate", new Date(value ?? "").toLocaleDateString(), false)
                                                        }
                                                        renderInput={(params) =>
                                                            <TextField {...params} name="annualDate" error={false} helperText={null} />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>


                                        : ""
                                    }






                                    <DialogActions>
                                        <button className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type="button" >Cancel</button>
                                        <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                                    </DialogActions>


                                </DialogContent>


                            </Form>

                        )
                        }


                    </Formik >

                </div >
            </LocalizationProvider >
        </Dialog >

    )
}