import * as React from 'react';
import { Policies } from './Policies';
import { RightNav } from '../components/Nav/RightNav';
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo';
import { FillingsByUser } from './FillingsByUser';
import { CustomerPolicyList } from '../components/Lists/CustomerPolicyList';
import useAuth from '../context/AuthProvider';
import { config } from '../Constants';
import { Button, LinearProgress, Link } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { InsuredSubmissionsList } from '../components/Lists/InsuredSubmissionsList';
import { apiClient } from '../helper/api';


export const InsuredSubmissions = () => {
    const [CustomerPolicy, setCustomerPolicy] = React.useState([]);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const [loading, setloading] = React.useState(false);
    const fetchData = () => {
        setloading(true);
        apiClient(`/Insured/getSubmissionsByInsured?InsuredId=${params.insuredId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                if (responseJson.data != null) {
                    console.log(responseJson.data);
                    setCustomerPolicy(responseJson.data)
                }
            }).finally(() => {
                setloading(false);
            });


    }


    React.useEffect(() => {
        fetchData();

    }, [])

    return (

        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>
        //                         <LoggedInUserInfo ButtonText="" Link="" />
        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="main shadow rounded" style={{ backgroundColor: "#ffffff" }}>
        //                                     <div className="py-5 p-4">
        //                                         <div className="table-responsive">
        //                                             {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                    <InsuredSubmissionsList CustomerPolicyModelCL={CustomerPolicy} fetchData={fetchData} />
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* <RightNav /> */}

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>



    );

}





