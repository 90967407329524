import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useAuth from '../context/AuthProvider';
import { config } from '../Constants';
import { useParams } from 'react-router-dom';
import { apiClient } from '../helper/api';

export interface LateFilingRequest {
    reason: string;
    additionalReasonDetails?: string;
    userhash?: string;
}

export const LateFilingForm: React.FC = () => {
    const [reason, setReason] = useState<string>('');
    
    const [additionalReason, setAdditionalReason] = useState<string>('');
    const [formSubmitted, setFormSubmitted] = useState<boolean | null>(null);
    const [showThanksMessage, setShowThanksMessage] = useState<boolean>(false);

    const params = useParams<{ UserHash: string }>();


    const handleReasonChange = (event: SelectChangeEvent<string>) => {
       
        const selectedReason = event.target.value;
        setReason(selectedReason);

        if (selectedReason !== 'Other') {
            setAdditionalReason('');
        }
    };

    const ref = useRef<HTMLButtonElement>(null);

    const initialValues: LateFilingRequest = {
        reason: '',
        additionalReasonDetails: '',
        userhash: '',
    };

    const handleAdditionalReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdditionalReason(event.target.value);
    };

    const fetchDataforCheckSubmission = () => {
        apiClient(`/CustomerPolicy/checkLateFilingSubmission?userhash=${params.UserHash}`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                // Authorization: `Bearer ${token}`,
            }),
        })
            .then((responseJson) => {
                if (!responseJson) return
                setFormSubmitted(responseJson.data);
            })
    };

    useEffect(() => {
        fetchDataforCheckSubmission();
    }, []);

    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-8 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">
                                            <div className="table-responsive">
                                                <div className="">
                                                    <div className="row col-lg-12">
                                                        <div className="col-lg-4 d-flex" style={{ marginBottom: '0px' }}></div>
                                                        <div className="col-lg-4" style={{ textAlign: 'center' }}>
                                                            <span className="card-title">
                                                                <b>Late Filing Reason</b>
                                                            </span>
                                                        </div>
                                                        <div className="col-lg-4" style={{ textAlign: 'right' }}></div>
                                                    </div>
                                                    {formSubmitted === false && (
                                                        <React.Fragment>
                                                            <Formik
                                                                initialValues={initialValues}
                                                                onSubmit={(data, { setSubmitting }) => {
                                                                    data.userhash = params.UserHash ?? '';
                                                                    data.reason = reason ?? '';
                                                                    data.additionalReasonDetails = additionalReason ?? '';
                                                                    console.log(data);
                                                                    apiClient(`/CustomerPolicy/latefilingReason`, {
                                                                        method: 'POST',
                                                                        headers: new Headers({
                                                                            'Content-Type': 'application/json',
                                                                        }),
                                                                        body: JSON.stringify(data),
                                                                    })
                                                                        .then((res) => {
                                                                            setSubmitting(true);
                                                                            setFormSubmitted(res.resp);
                                                                            setShowThanksMessage(res.resp === 1)
                                                                            console.log(res.resp)
                                                                        })
                                                                }}
                                                            >
                                                                {({ submitForm }) => (
                                                                    <Form>
                                                                        <div className="m-3 row col">
                                                                            {/* <div className="col-lg-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}> */}
                                                                            <Grid container spacing={1} margin={3} justifyContent="center" alignItems="center">
                                                                                <Grid item xs={4} marginLeft={5}>

                                                                                    <FormControl fullWidth>
                                                                                        <InputLabel id="State-Select-Label">Select Late Filing Reason</InputLabel>
                                                                                        <Select

                                                                                            value={reason}
                                                                                            labelId="State-Select-Label"
                                                                                            label="Select Late Filing Reason"
                                                                                            onChange={handleReasonChange}
                                                                                            sx={{ minWidth: "400px" }}

                                                                                        >
                                                                                            <MenuItem value="Agent delay in returning SL2">Agent delay in returning SL2</MenuItem>
                                                                                            <MenuItem value="UW delay in sending policy documents">UW delay in sending policy documents</MenuItem>
                                                                                            <MenuItem value="Backdating endorsement">Backdating endorsement</MenuItem>
                                                                                            <MenuItem value="Other">Other</MenuItem>
                                                                                        </Select>
                                                                                    </FormControl>
                                                                                </Grid>

                                                                                <Grid item xs={8} marginLeft={5}>
                                                                                    {reason === 'Other' && (
                                                                                        <div className='flex'>
                                                                                            <label>Other Reason:</label>
                                                                                            <input className='col-lg-10 m-2' multiple type="text" value={additionalReason} onChange={handleAdditionalReasonChange} />
                                                                                        </div>
                                                                                    )}

                                                                                </Grid>

                                                                                <div className="row col-lg-12 m-3 ">
                                                                                    <button type="button" onClick={submitForm} ref={ref} className="btn btn-primary submit-btn mt-gutter">
                                                                                        Confirm
                                                                                    </button>
                                                                                </div>
                                                                            </Grid>
                                                                            {/* </div> */}
                                                                        </div>
                                                                    </Form>
                                                                )}
                                                            </Formik>
                                                        </React.Fragment>
                                                    )}
                                                    {showThanksMessage === true && (
                                                        <div className="alert alert-info mt-3" role="alert">
                                                            Thanks for submitting the form!
                                                        </div>
                                                    )}
                                                    {formSubmitted === true && (
                                                        <div className="alert alert-info mt-3" role="alert">
                                                            You have already submitted the form using this link.
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </section >
    );
};
