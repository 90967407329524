var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var SAVE = 'Save';
var ConfigurationContext = React.createContext(null);
/**
 * Provider used to share the configuration object to it's children (Document Labeler)
 * @returns
 */
export var BBConfigurationProvider = function (_a) {
    var children = _a.children, config = _a.config;
    return (_jsx(ConfigurationContext.Provider, __assign({ value: __assign(__assign({}, config), { saveActionButtonText: (config === null || config === void 0 ? void 0 : config.saveActionButtonText) || SAVE }) }, { children: children })));
};
export var useBBConfiguration = function () {
    var context = React.useContext(ConfigurationContext);
    if (!context) {
        throw new Error('useBBConfiguration must be used within a BBConfigurationProvider');
    }
    return context;
};
