import { useState, useEffect } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { StateLoginsList } from "../components/Lists/StateLoginsList";
import { apiClient } from "../helper/api";

export const StateLogins = () => {
    const [stateLogins, setStateLogins] = useState([])
    const [loading, setloading] = useState(false);

    const { user } = useAuth();
    const token = user?.token;
    const fetchData = () => {
        setloading(true);

        apiClient('/StateLogins/getAllStateLogins',
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) throw new Error('Something went wrong')
                setloading(false);
                console.log("User",user)
                // Do something with the response
                if (!res.data) return
                setStateLogins(res.data);
                console.log("listData", res.data);
            }).catch((error) => {
                setloading(false);

                console.log(error)
            });
    }

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <StateLoginsList data={stateLogins} fetchData={fetchData} />
    )
}