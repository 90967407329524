import * as yup from 'yup'

export interface EmailTemplateModel {
    emailTemplateId: number,
    templateName?: string,
    subject?: string,
    content?: string,
    createdBy?: number,
    modifiedBy?: number,
    dateCreated?: Date,
    dateModified?: Date,
    isDeleted?: boolean,
    cc: string,
    bcc: string,    
}

// export const EmailTampateMOdel_VS = yup.object({
//     templateName: yup.string().required("Please enter Template Name").min(1).trim().max(150, "Cannot exceed 150 character in length")
//         // stateId: yup.number().required("please select state").min(1, "please select State").typeError("invalid value"),
// })


export const EmailTampateMOdel_VS = yup.object({
    templateName: yup.string()
        .required(() => <span style={{ fontSize: '14px' }}>Please enter Template Name</span>)
        .min(1)
        .trim()
        .max(150, () => <span style={{ fontSize: '12px' }}>Cannot exceed 150 characters in length</span>)
        
});

