import *  as yup from 'yup'
export interface DocumentTypeModel {
    documentTypeId?: number,
    documentName?: string,
    dateCreated?: Date,
    dateUpdated?: Date,
    isDeleted?: boolean
}

export const documentModelVS = yup.object({

    documentName: yup.string().required("Please enter Document Type Name").trim().typeError('Invalid value'),

  
  })