var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, makeStyles, TableBody, TableCell, TableRow, Typography, } from '@material-ui/core';
var useStyles = makeStyles(function (theme) { return ({
    TableCell: {
        padding: theme.spacing(8),
        borderBottom: 'none',
    },
    TextBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    Text: {
        color: theme.palette.text.disabled,
        textAlign: 'center',
        maxWidth: '50%',
    },
}); });
/**
 * Component used for displaying FTUX helper text within tables.
 * @param props
 */
export var DataTableFtux = function (_a) {
    var ftuxText = _a.ftuxText, numColumns = _a.numColumns, dataCy = _a.dataCy;
    var classes = useStyles();
    return (_jsx(TableBody, { children: _jsx(TableRow, { children: _jsx(TableCell, __assign({ colSpan: numColumns, className: classes.TableCell }, { children: _jsx(Box, __assign({ className: classes.TextBox }, { children: _jsx(Typography, __assign({ variant: "h5", className: classes.Text, "data-cy": dataCy }, { children: ftuxText })) })) })) }) }));
};
