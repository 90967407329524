/**
 * Corresponds to the Butler Color Palette
 */
export var ButlerColorPalette = {
    primary: {
        main: '#386DF4',
        light: '#5081FF',
        dark: '#264CAD',
    },
    secondary: {
        main: '#491FE0',
        light: '#6138F4',
        dark: '#1E008A',
    },
    error: {
        main: '#FF5630',
        light: 'rgb(250, 113, 82, 0.40)',
        dark: '#ED350C',
    },
    warning: {
        main: '#FFAB00',
        light: 'rgb(254, 195, 74, 0.40)',
        dark: '#AB7D22',
    },
    info: {
        main: '#386DF4',
        light: 'rgba(56, 109, 244, 0.40);',
        dark: '#264CAD',
    },
    success: {
        main: '#36B37E',
        light: 'rgb(68, 224, 158, 0.40)',
        dark: '#2B8D64',
    },
    common: {
        black: '#0A0E17',
        white: '#FFFFFF',
        divider: 'rgba(0, 0, 0, 0.12)',
    },
    text: {
        primary: '#0A0E17',
        secondary: '#424550',
        disabled: '#A2A5AE',
        hint: 'rgba(0, 0, 0, 38%)',
    },
    background: {
        paper: '#FFFFFF',
        default: '#F5F6F8',
        secondary: '#C2D3FF33',
        dark: '#282A36',
        light: '#F6FAFC',
    },
    icon: {
        default: '#C2D3FF',
    },
    brand: {
        main: '#386DF4',
        teal: '#00B8D9',
    },
};
