import * as yup from 'yup'

export const LoginModel_VS = yup.object({
    Username: yup.string().required("Please enter Username").max(150, "Cannot exceed 150 character in length"),
    password: yup.string()
        .required('No password provided.')

})

export interface AuthRespModel {

    token: string,
    userFirstName: string,
    userRoles: string,
    userlastName: string,
    userId: number
}