import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import React, { useEffect, useState } from "react";
// import { Form } from "react-bootstrap";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { ButlerModel, ButlerModel_VS } from "../../models/ButlerModel";
import { FMTextField } from "../Elements/input";
import { FMSelectState } from "../Elements/select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";
import { apiClient } from "../../helper/api";

interface Iprops {
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    fetchData: () => void
    initvalues: ButlerModel
    setSelectedOption: React.Dispatch<React.SetStateAction<string | string[]>>
    selectedOptions: string[] | string
}

export const AddButlerModels: React.FC<Iprops> = ({ Open, handleClose, fetchData, handleSubmit, initvalues, selectedOptions, setSelectedOption }) => {

    const { user } = useAuth();
    const token = user?.token;


    const FMSelectState: React.FC<FieldAttributes<SelectProps>> = ({
        variant,
        ...props
    }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { stateId: 0, stateName: "" };
        const [States, setStates] = useState([initialdata]);

        const fetchData = () => {
            apiClient("/States/getStates", {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson?.data == null) return
                    setStates(responseJson.data);

                    console.log("statesDAta", responseJson.data);
                })
        };

        useEffect(() => {
            fetchData();
        }, []);

        return (
            <FormControl error={!!errorText} fullWidth>
                {/* <FormHelperText>Select State</FormHelperText> */}
                <InputLabel id="State-Select-Label">All State</InputLabel>
                <Select
                    {...field}
                    labelId="State-Select-Label"
                    // label="State"
                    IconComponent={ExpandMoreIcon}
                    label="All State"
                    className="border-round main-form-2 "
                >
                    {/* <MenuItem key={0} value={0}>
                        {" "}
                        All State{" "}
                    </MenuItem> */}
                    {States.map((state, key) => (
                        <MenuItem key={key} value={state.stateId}>
                            {" "}
                            {state.stateName}{" "}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };




    const FMSelectActiveInactiveCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (

            <FormControl error={!!errorText} fullWidth className="main-form-2" >
                <InputLabel id="State-Select-Label">Status</InputLabel>
                <Select {...field} labelId="Select-Label" IconComponent={ExpandMoreIcon} label="Status"    >

                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"NeedsTraining"}>NeedsTraining</MenuItem>

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    useEffect(() => {

        // fetchStateData();
    }, [])

    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className="card-title" >Create Model</DialogTitle>

            <React.Fragment>
                <Formik

                    initialValues={initvalues}
                    validateOnChange={true}
                    enableReinitialize={true}
                    validationSchema={ButlerModel_VS}
                    onSubmit={(data, { setSubmitting }) => {
                        // setloading(true);

                        //data.selectedFields = selectedOptions.map(x => x.fieldName).join(",");

                        console.log(data);


                        apiClient(`/ButlerModel/AddEditButler`, {
                            method: 'POST',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)
                        })
                            .then((res) => {
                                if (!res) return
                                setSubmitting(false);
                                fetchData();
                                handleClose();

                                if (res.resp === 1) {
                                    Swal.fire({
                                        title: res.message,
                                        text: "",
                                        icon: "warning",
                                        confirmButtonText: "OK",
                                    });
                                    console.log("response", res);
                                } else if (res.resp === 0) {

                                    Swal.fire({
                                        title: "Data Successfully saved",
                                        text: "",
                                        icon: "success",
                                        confirmButtonText: "OK",
                                    });
                                } else {
                                    Swal.fire({
                                        title: "Unexpected response",
                                        text: "The response code was not recognized.",
                                        icon: "warning",
                                        confirmButtonText: "OK",
                                    });

                                    console.log("response", res);
                                }
                            })
                            .catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: error.message,
                                    icon: "error",
                                    confirmButtonText: "OK",
                                });
                                console.error('Error:', error);
                            });


                    }} >
                    {({ submitForm, isSubmitting }) =>
                    (
                        <Form className="main-form" >
                            <DialogContent >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FMSelectState autoWidth={true} variant={"standard"} name="stateId" ></FMSelectState>
                                        {/* <FMSelectStateCustomcopy autoWidth={true} variant={"standard"} name="stateId" ></FMSelectStateCustomcopy> */}

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="name" autoFocus margin="dense" label="Enter Model Name" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="queueId" autoFocus margin="dense" label="Enter Queue ID" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="butlerModelId" autoFocus margin="dense" label="Enter Model ID" type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMSelectActiveInactiveCustom autoWidth={true} fullWidth variant={"standard"} type="text" name="status" autoFocus margin="dense" label="Status" sx={{ width: '100%' }} ></FMSelectActiveInactiveCustom>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                        <Checkbox disabled name="isMultiFormat"></Checkbox>
                                        <span>Is Multi Format Available?</span>
                                    </Grid> */}
                                    {/* <Grid item xs={6}>

                                        <FMSelectPartner autoWidth={true} variant={"standard"} name="partnerId" ></FMSelectPartner>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minHeight: "200px" }} >
                                            <Multiselect
                                                displayValue="fieldName"
                                                id=''

                                                options={FieldData}
                                                onSelect={(selectedList, selectedItem) => {
                                                    setSelectedOption(selectedList);
                                                    console.log(selectedList);

                                                }}
                                                onRemove={(selectedList, selectedItem) => {
                                                    setSelectedOption(selectedList);
                                                    console.log(selectedList);

                                                }}

                                            selectedValues={FieldData}
                                            />
                                        </FormControl>
                                    </Grid> */}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <button className=" btn-2 btn d-flex btn-primary search-btn  " type="button" onClick={handleClose}  >Cancel</button>
                                <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' onClick={submitForm} disabled={isSubmitting} >Submit</button>
                            </DialogActions>


                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        </Dialog >




    );
}

