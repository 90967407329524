import *  as yup from 'yup'
export interface StateLoginsModel {
    stateLoginsId: number,
    stateId?: number,
    statename?: string,
    standardAbbv?: string,
    postableAbbv?: string,
    partnersId?: number,
    licensee?: string,
    website?: string,
    username?: string,
    password1?: string,
    password2?: string,
    client?: string,
}

export const StateLoginsModel_VS = yup.object({
    partnersId: yup.number().min(1,"please select Licensee").required("Please select a Licensee").typeError('Invalid Value'),
    stateId: yup.number().min(1,"please select State").required("Please select a State").typeError('Invalid Value'),
    licensee: yup.string().required("please enter Licensee").trim().typeError('Invalid value'),
    website: yup.string().required("please enter Website").trim().typeError('Invalid value'),
    username: yup.string().required("please enter Username").trim().typeError('Invalid value'),
    password1: yup.string().required("please enter Password1").trim().typeError('Invalid value'),
    // password2: yup.string().required("please enter Password2").trim().typeError('Invalid value'),
    // notes: yup.string().required("please enter Notes").trim().typeError('Invalid value'),
    // client: yup.string().required("please enter Client").trim().typeError('Invalid value'),
})
