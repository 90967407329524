import * as yup from 'yup'


export interface InsuranceCompanyModel {
  insuranceCompanyId: number,
  naicNumber?: string,
  name?: string,
  createdBy?: number,
  stateId?: number,
  modifiedBy?: number,
  dateCreated?: Date,
  dateModified?: Date,
  isDeleted?: boolean,
  stateName: string
  isActive?: boolean,
  startDate?: string
}


// export const InsuranceCompanyModel_VS = yup.object({

//   InsuranceCompanyData: yup.object({
//     startDate: yup.date().nullable().required("Date is required"),
//     naicNumber: yup.string().required("Please enter NAIC Number").max(150, "Cannot exceed 150 character in length"),
//     name: yup.string().required("Please enter Carrier").max(150, "Cannot exceed 150 character in length"),
//     // isActive: yup.bool().required()
//   }),

  export const InsuranceCompanyModel_VS = yup.object({
    InsuranceCompanyData: yup.object({
      startDate: yup.date().required("Start Date is required"),
      naicNumber: yup.string().trim("Naic Number can not include leading and trailing spaces").strict(true).required("Please enter NAIC Number").typeError("invalid value").max(150, "Cannot exceed 150 characters in length"),
      // taxingJurisdiction: yup.string().required("Please enter taxing Jurisdiction").trim().typeError("invalid value"),
      name: yup.string().trim("Carrier name can not include leading and trailing spaces").strict(true).required("Please enter Carrier").typeError("invalid value").max(150, "Cannot exceed 150 characters in length"),
      isActive: yup.bool().nullable().required("Please specify the status"),
      
    }),


})

