var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ListUtil } from 'common/util/ListUtil/ListUtil';
import { ColorUtils } from 'documentLabeler/color/ColorUtils';
/**
 * Helper function to look up active field information for a specified
 * field Id in the document labeler state.
 * @param state
 * @param fieldId
 * @returns the field information and its index in the fields list
 */
var getFieldFromState = function (state, fieldId) {
    var fields = state.docInfo.results.fields;
    var idx = fields.findIndex(function (field) { return field.id === fieldId; });
    if (idx === -1) {
        throw new Error("Did not find unique field info in document labels when looking up field id ".concat(fieldId));
    }
    return {
        field: fields[idx],
        idx: idx,
    };
};
/**
 * Helper function to look up table information for a specified
 * table Id in the document labeler state.
 * @param state
 * @param fieldId
 * @returns the table information and its index in the tables list
 */
var getTableFromState = function (state, fieldId) {
    var tables = state.docInfo.results.tables;
    var idx = tables.findIndex(function (table) { return table.id === fieldId; });
    if (idx === -1) {
        throw new Error("Did not find unique field info in document labels when looking up field id ".concat(fieldId));
    }
    return {
        table: tables[idx],
        idx: idx,
    };
};
var updateStateWithNewField = function (state, updatedField, fieldIdx) {
    var fields = state.docInfo.results.fields;
    var updatedFields = ListUtil.replaceElementAtIndex(updatedField, fieldIdx, fields);
    return __assign(__assign({}, state), { docInfo: __assign(__assign({}, state.docInfo), { results: __assign(__assign({}, state.docInfo.results), { fields: updatedFields }) }) });
};
var updateStateWithNewTable = function (state, updatedTable, fieldIdx) {
    var tables = state.docInfo.results.tables;
    var updatedTables = ListUtil.replaceElementAtIndex(updatedTable, fieldIdx, tables);
    return __assign(__assign({}, state), { docInfo: __assign(__assign({}, state.docInfo), { results: __assign(__assign({}, state.docInfo.results), { tables: updatedTables }) }) });
};
/**
 * Function used to get all fields for the current document, with associated colors
 * @param data
 */
var getAllColoredFields = function (data) { return ({
    fields: data.results.fields.map(function (field, index) { return ({
        info: field,
        color: ColorUtils.getColorFromColorSet(index),
    }); }),
    tables: data.results.tables.map(function (table, index) { return ({
        info: table,
        color: ColorUtils.getColorFromColorSet(index + data.results.fields.length),
    }); }),
}); };
/**
 * Function used to get the color of a given field
 * @param state: DocumentLabelerInternalState
 * @param fieldId: string
 * @returns Color(string)
 */
var getColorFromFieldId = function (state, fieldId) {
    var _a = getAllColoredFields(state.docInfo), fields = _a.fields, tables = _a.tables;
    var idx = __spreadArray(__spreadArray([], fields, true), tables, true).findIndex(function (field) { return field.info.id === fieldId; });
    if (idx === -1) {
        throw new Error("No Field found for Field Id ".concat(fieldId, " when looking up field color"));
    }
    return ColorUtils.getColorFromColorSet(idx);
};
/**
 * Function used to get selectedTable from selectedField
 * @param state: ModelDetailsInternalState
 * @returns
 */
var getSelectedTable = function (state) {
    // if ModelFieldType is table, then field is guaranteed to be
    // of type TrainingTableLabeledResultDto
    var selectedTable = getAllColoredFields(state.docInfo).tables.find(function (table) { var _a; return table.info.id === ((_a = state.localState.activeField) === null || _a === void 0 ? void 0 : _a.id); });
    if (!selectedTable) {
        throw new Error('Could not field selected table in state');
    }
    return selectedTable.info;
};
var getCellInfoFromTable = function (table, activeCell) {
    var rowIdx = table.rows.findIndex(function (row) { return row.id === activeCell.rowId; });
    if (rowIdx === -1) {
        throw new Error('No row found when looking up cell in table');
    }
    var rows = table.rows;
    var row = rows[rowIdx];
    var columnIdx = row.cells.findIndex(function (cell) { return cell.columnId === activeCell.columnId; });
    if (columnIdx === -1) {
        throw new Error('No cell found when looking up cell in table');
    }
    var cells = row.cells;
    var cell = cells[columnIdx];
    return {
        rowIdx: rowIdx,
        columnIdx: columnIdx,
        cell: cell,
    };
};
var updateTableWithNewCell = function (table, cell, rowIdx, columnIdx) {
    var row = table.rows[rowIdx];
    var updatedRow = __assign(__assign({}, row), { cells: ListUtil.replaceElementAtIndex(cell, columnIdx, row.cells) });
    return __assign(__assign({}, table), { rows: ListUtil.replaceElementAtIndex(updatedRow, rowIdx, table.rows) });
};
export var DocumentLabelerReducerUtils = {
    getFieldFromState: getFieldFromState,
    getTableFromState: getTableFromState,
    getAllColoredFields: getAllColoredFields,
    getColorFromFieldId: getColorFromFieldId,
    getCellInfoFromTable: getCellInfoFromTable,
    getSelectedTable: getSelectedTable,
    updateStateWithNewField: updateStateWithNewField,
    updateStateWithNewTable: updateStateWithNewTable,
    updateTableWithNewCell: updateTableWithNewCell,
};
