import *  as yup from 'yup'
export interface StateRuleMatrixModel {
    stateRuleMatrixId: number,
    stateId: number,
    ruleDescription: string
    isRuleApplied: boolean,
    isPermitted: boolean,
    operatorId: number,
    rateType: number,
    value: number,
    ruleName: string,
    isDeleted: boolean,
    effectiveDate?:string,
    rateTypeId: number,
  
}

export interface getStateRuleMatrixModel {
    stateRuleMatrixId: number
    statename: string,
    standardAbbv: string,
    postablAbbv: string,
    isRuleApplied: boolean,
    isPermitted: boolean,
    operatorId: number,
    ruleDescription: string,
    rateType: string,
    rateTypeId: number,
    value: number,
    ruleName: string,
    isDeleted: boolean,
    effectiveDate:Date
}

export interface getStateRulePolicyFeeDataModel {
    customerpolicyId: number,
    ruleDescription: string,
    premium: number,
    policyFee: number,
    operator: string,
    calculatedPolicyFee: number,
    isRuleMatching: boolean
}


export interface RuleComparsionmodel {
    message: string;
    resp: number;
    data: getStateRulePolicyFeeDataModel;
}

export const StateRuleMatrixModel_VS = yup.object({
    ruleName: yup.string().required("please enter Rule Name").trim().typeError('Invalid value'),
    stateId: yup.number().min(1,"please select state").required("Please select a state").typeError('Invalid Value'),
    operatorId: yup.number().min(1,"please select operator").required("please select operator").typeError('Invalid Value'),
    //  rateTypeId: yup.number().min(1,"please").required("please select RateType").typeError('Invalid Value'),
    value: yup.number().min(0.01,"Please Enter value").required("please Enter value").typeError('Invalid value'),
    effectiveDate :yup.date().required(),
})
