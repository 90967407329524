// Constants.js

export const prod = {
  url: {
    // API_URL: "https://filemule-api.azurewebsites.net/api"
    API_URL: "https://filemule-api-uat.azurewebsites.net/api"
    //API_URL: "https://filemule-api-live.azurewebsites.net/api"

    // API_URL: "https://api.filemule.io/api"

  }

}

export const dev = {
  url: {
    API_URL: "http://localhost:5274/api"
  }


}

export const live = {
  url: {
    API_URL: "http://filemule-api-live.azurewebsites.net/api"
  }

}

export const uat = {
  url: {
    API_URL: "https://filemule-api-uat.azurewebsites.net/api"
  }
}



export const config = process.env.NODE_ENV === "development" ? dev : process.env.NODE_ENV === "live" ? live : process.env.NODE_ENV === "uat" ? uat : prod;  