var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var toCommaDelimintedString = function (list) {
    return list.join(', ');
};
var replaceElementAtIndex = function (newItem, replaceIdx, currentArray) {
    if (replaceIdx < 0 || replaceIdx > currentArray.length) {
        throw new Error("Invalid replace index ".concat(replaceIdx, " for array of length ").concat(currentArray.length));
    }
    return __spreadArray(__spreadArray(__spreadArray([], currentArray.slice(0, replaceIdx), true), [
        newItem
    ], false), currentArray.slice(replaceIdx + 1), true);
};
export var ListUtil = {
    toCommaDelimintedString: toCommaDelimintedString,
    replaceElementAtIndex: replaceElementAtIndex,
};
