import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, IconButton, Link } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton } from '@mui/x-data-grid';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { ButlerModel } from "../../models/ButlerModel";
import { AddButlerModels } from "../AddEdit/AddButlerModel";
import { SubmissionDataModel_SD } from '../../models/SubmissionDataModel_SD';

interface Iprops {
    SubmissionDataCL: SubmissionDataModel_SD[],
    fetchData: () => void,
}

export const SubmissionDataList: React.FC<Iprops> = ({ SubmissionDataCL, fetchData }) => {
    const { user } = useAuth();
    const token = user?.token;



    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row col-lg-12" align-items-center style={{ marginBottom: "10px" }}>

                    <div className="col-lg-4" style={{ fontSize: "32px" }}>
                        <span className="d-flex">Butler Model</span>
                    </div>
                    <div className="col-lg-4" >
                        <span className="card-title" ><GridToolbarFilterButton /></span>
                    </div>
                </div>
            </Box>
        );

    }
    const [staticGridId] = useState('staticGridId'); 

    const columns: GridColDef[] = [

        { field: 'state', type: 'text', headerName: 'state', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'coverageCode', type: 'text', headerName: 'coverageCode', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'effectiveDate', type: 'text', headerName: 'effectiveDate', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'expirationDate', type: 'text', headerName: 'expirationDate', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'insuredAddress', type: 'text', headerName: 'insuredAddress', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'insuredName', type: 'text', headerName: 'insuredName', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'insurer', type: 'text', headerName: 'insurer', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'locationAddress', type: 'text', headerName: 'locationAddress', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'mga', type: 'text', headerName: 'mga', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'policyFee', type: 'text', headerName: 'policyFee', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'policyNumber', type: 'text', headerName: 'policyNumber', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'premium', type: 'text', headerName: 'premium', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'reportingEntity', type: 'text', headerName: 'reportingEntity', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'taxStatus', type: 'text', headerName: 'taxStatus', flex: 1, headerAlign: 'center', align: 'center' },
        { field: 'transactionType', type: 'text', headerName: 'transactionType', flex: 1, headerAlign: 'center', align: 'center' },


    ];

    return (

        <div style={{ height: '75vh', width: '100%' }}>

            <DataGrid
                getRowId={() => staticGridId}
                rows={SubmissionDataCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                style={{ textAlign: 'center' }}

            
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif",
                        border: "0px",
                        fontSize: "16px"



                    }
                }
            />

        </div>



    )
}