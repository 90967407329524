import { Button, LinearProgress, Link } from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { BtisAPIDataList } from "../components/Lists/BtisAPIDataList";
import { apiClient } from "../helper/api";

export const BtisApiData = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const params = useParams();

    const [ResponseData, setResponseData] = React.useState("");

    const fetchApiData = () => {
        setloading(true);
        apiClient(`/CustomerPolicyApiData/GetCustomerPolicyApiData?custPolicyId=${params.CustomerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                // console.log(res.data);
                // Do something with the response
                if (res.data != null) {
                    console.log(res.data)

                    setResponseData(res.data)

                }

            }).finally(() => {
                setloading(false);
            });

    }

    useEffect(() => {
        fetchApiData();

    }, [])

    return (

        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

                            <LoggedInUserInfo ButtonText="" Link=""/>


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">

                                            <div className="row col-lg-12">
                                                <div className="col-lg-4 d-flex">
                                                    <div style={{ marginRight: "2px" }}>
                                                        <Link href="/">
                                                            <Button type="button" title="Home"
                                                                className="btn action-btn" ><HomeIcon />
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Button

                                                            className=" btn action-btn"
                                                            onClick={goBack}
                                                            title="Back"
                                                        >
                                                            <ArrowBackIosIcon />
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="col-lg-4" style={{ textAlign: "center" }}>
                                                    <span className="card-title">API Data</span>
                                                </div>
                                                <div className="col-lg-4" >

                                                </div>


                                            </div>


                                            <div className="table-responsive m-2">
                                                <div style={{ height: "75vh" }}>


                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                    <BtisAPIDataList apiDataString={ResponseData} />

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>



    );

}
