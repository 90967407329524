import { Box, FormControl, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../Constants";
import { BatchModel } from "../../models/BatchModel";

interface Iprops {
Batchcl: BatchModel
}

export const BatchInfo: React.FC<Iprops> = ({Batchcl}) => {


 
    const params = useParams();

    return (
        <Box  sx={{
            display: 'flex',
            flexDirection: 'row',
        }}      >
                 <Box
                      sx={{
                        m: 1,
                        width:'90%',
                        height: 'auto',
                        ml:4
                     
                    }}                   
                  >
                        <h2 >Batch {params.batchId}: Details </h2>
                        <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 400 }} aria-label="simple table">
                         
                          <TableBody>
                          <TableRow>
                                <TableCell>Batch ID</TableCell>
                                <TableCell>{Batchcl.batchId}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>ipaddress</TableCell>
                                <TableCell>{Batchcl.ipaddress}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>Received On</TableCell>
                                <TableCell>{Batchcl.receivedOn?.toString()}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>mule Response</TableCell>
                                <TableCell>{Batchcl.muleResponse}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>Mule Responded On</TableCell>
                                <TableCell>{Batchcl.muleRespondedOn?.toString()}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>Mule Error Code</TableCell>
                                <TableCell>{Batchcl.muleErrorCode}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>Mule Error Message</TableCell>
                                <TableCell>{Batchcl.muleErrorMessage}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>SLA response</TableCell>
                                <TableCell>{Batchcl.slaresponse}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>SLA error Code</TableCell>
                                <TableCell>{Batchcl.slaerrorCode}</TableCell>
                          </TableRow>
                          <TableRow>
                                <TableCell>SLA Error Message</TableCell>
                                <TableCell>{Batchcl.slaerrorMessage}</TableCell>
                          </TableRow>
                           
                          </TableBody>
                        </Table>
                      </TableContainer>
                </Box>
                </Box>
                       
        // <div>
        //     <h1>Batch: {params.batchId} Details </h1>
        //     <Box
        //         sx={{
        //             display: 'flex',
        //             flexWrap: 'wrap',
        //             '& > :not(style)': {
        //                 m: 1,
        //                 width: '100%',
        //                 height: 'auto',
        //             },
        //         }}
        //     >

        //         <Paper elevation={3}>
        //             <Box
        //                 component="form"
        //                 sx={{

        //                     '& .MuiTextField-root': { m: 2, width: '30ch' },
        //                     width: '100%'
        //                 }}
        //                 noValidate
        //                 autoComplete="off"
        //                 width='100vh'

        //             >


        //                 <FormControl  >
        //                     {/* <label htmlFor="outlined-required">BatchID</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-required"

        //                         value={Batchcl.batchId}
        //                         helperText="BatchID"
        //                     />
        //                 </FormControl>

        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">Recived On</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-disabled"
        //                         value={Batchcl?.slaresponse}
        //                         helperText="SLA Response"
        //                     /></FormControl>

        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">Total Number of Plolicies</label> */}
        //                     <TextField
        //                         disabled
        //                         value={Batchcl?.muleResponse}
        //                         id="outlined-password-input"
        //                         autoComplete="current-password"
        //                         helperText="Mule Response"
        //                     />
        //                 </FormControl>

        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">BatchID</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-required"
        //                         defaultValue={Batchcl?.receivedOn}
        //                         helperText="Recived on"
        //                     />
        //                 </FormControl>

        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">Recived On</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-disabled"
        //                         value={Batchcl?.slaerrorCode}
        //                         helperText="SLA Error Code"
        //                     /></FormControl>

        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">Total Number of Plolicies</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-password-input"
        //                         autoComplete="current-password"
        //                         defaultValue={Batchcl?.muleErrorCode}
        //                         helperText="Mule Error Code"
        //                     />
        //                 </FormControl>


        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">Recived On</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-disabled"
        //                         value=""
        //                         helperText="Total number of Policies"
        //                     /></FormControl>

        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">Total Number of Plolicies</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-password-input"
        //                         autoComplete="current-password"
        //                         value={Batchcl?.slaerrorCode}
        //                         multiline
        //                         maxRows={4}
        //                         helperText="SLA Error Message"
        //                     />
        //                 </FormControl>

        //                 <FormControl>
        //                     {/* <label htmlFor="outlined-required">Total Number of Plolicies</label> */}
        //                     <TextField
        //                         disabled
        //                         id="outlined-password-input"
        //                         autoComplete="current-password"
        //                         value={Batchcl?.muleErrorCode}
        //                         multiline
        //                         maxRows={4}
        //                         helperText="Mule Error Message"
        //                     />
        //                 </FormControl>
        //             </Box>
        //         </Paper>
        //     </Box>
        // </div>

    )


}
