var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Tooltip, Typography, } from '@material-ui/core';
var DEFAULT_MAX_LENGTH = 250;
var useStyles = makeStyles(function () {
    return createStyles({
        Truncate: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    });
});
/**
 * Visual component for displaying text that
 * will truncate based on the size of the parent component.
 *
 * NOTE: In order to truncate correctly within flex display components,
 * the parent component must have an "overflow: hidden" style applied to it.
 *
 * Based off:
 * https://stackoverflow.com/questions/56588625/react-show-material-ui-tooltip-only-for-text-that-has-ellipsis
 *
 * @param props
 */
export var TruncatableTypography = function (_a) {
    var _b;
    var value = _a.value, typographyProps = _a.typographyProps, expandable = _a.expandable, _c = _a.maxCharLength, maxCharLength = _c === void 0 ? DEFAULT_MAX_LENGTH : _c, _d = _a.paragraph, paragraph = _d === void 0 ? true : _d;
    var classes = useStyles();
    // Create Ref
    var textElementRef = useRef(null);
    // Define state and function to update the value
    var _e = useState(false), shouldTruncate = _e[0], setShouldTruncate = _e[1];
    var compareSize = function () {
        if (textElementRef === null || textElementRef.current === null)
            return;
        var shouldTruncate = textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
        setShouldTruncate(shouldTruncate);
    };
    // compare once and add resize listener on "componentDidMount" and also
    // if the value changes while on screen
    useEffect(function () {
        if (expandable && value.length > maxCharLength) {
            setShouldTruncate(true);
        }
        else {
            compareSize();
            window.addEventListener('resize', compareSize);
        }
        return function () {
            window.removeEventListener('resize', compareSize);
        };
    }, [value]);
    var displayValue = useMemo(function () {
        if (expandable && value.length > maxCharLength) {
            return "".concat(value.substring(0, maxCharLength), "...");
        }
        else {
            return value;
        }
    }, [value]);
    return (_jsx(Tooltip, __assign({ title: value, interactive: true, disableHoverListener: !shouldTruncate }, { children: _jsx(Typography, __assign({ ref: textElementRef, 
            // Need to force the underlying component to be a paragraph
            // so that the styles are applied correctly
            paragraph: paragraph }, typographyProps, { className: clsx((_b = {},
                _b[classes.Truncate] = !expandable,
                _b), [typographyProps === null || typographyProps === void 0 ? void 0 : typographyProps.className]) }, { children: displayValue })) })));
};
