import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Button, Link, SelectChangeEvent, IconButton, Box, SelectProps, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { LineOfBusinessModel } from "../../models/LineOfBusinessModel";
import { config } from "../../Constants";
import { AddEditLineOfBusiness } from "../AddEdit/AddEditLineOfBusiness";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from "../../context/AuthProvider";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { FieldAttributes } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RiExpandUpDownLine } from "react-icons/ri";
import { apiClient } from "../../helper/api";
interface Iprops {
    LineOfBusinessCL: LineOfBusinessModel[],
    fetchData: () => void
    selectedState: string
    selectedStatename: string | undefined
    setSelectedStates: React.Dispatch<React.SetStateAction<string>>
    fetchLOBByStateId: (stateId: string) => void
    States: { stateId: number, stateName: string }[]
}

export const LineOfBusinessList: React.FC<Iprops> = ({ LineOfBusinessCL, fetchData, selectedState, selectedStatename, setSelectedStates, fetchLOBByStateId, States }) => {
    const initialLBData: LineOfBusinessModel = {
        id: 0, businessCode: "", stateId: 0, createdBy: 0, modifiedBy: 0, dateCreated: new Date(), dateModified: new Date(), isDeleted: false,
        stateName: "", typeofRisk: ""
    }


    const { user } = useAuth();
    const token = user?.token;
    const [Open, setOpen] = React.useState(false);
    const [Formdata, setFormData] = React.useState(initialLBData);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const FMSelectStateCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { stateId: 0, stateName: "" };
        const params = useParams();
        const handleChange = (event: SelectChangeEvent) => {
            fetchLOBByStateId(event.target.value);
            setSelectedStates(event.target.value);
        };
        useEffect(() => {

        }, [])
        return (
            <FormControl fullWidth  >
                {/* <InputLabel >State</InputLabel> */}
                <Select
                    onChange={handleChange}
                    labelId="State-Select-Label"
                    label={null}
                    className="border-round custom-menu-item"
                    IconComponent={ExpandMoreIcon}
                    value={selectedState}
                >
                    <MenuItem key={0} value={0}> {'All States'} </MenuItem>
                    {States.map((state, key) =>
                        <MenuItem key={key} value={state.stateId}> {state.stateName} </MenuItem>
                    )}
                </Select>

                <FormHelperText></FormHelperText>
            </FormControl>


        )
    }

    const columns: GridColDef[] = [

        { field: 'coverageName', headerName: 'Coverage Code', flex:4, align: 'center',hideSortIcons: true,
        renderHeader: (params) => (
            <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
            </div>
        ), headerAlign: 'center'  },
        { field: 'businessCode', headerName: 'Coverage Name', flex: 4 ,hideSortIcons: true,
        renderHeader: (params) => (
            <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
            </div>
        ),headerAlign: "center", align: 'center' },

        { field: 'typeofRisk', headerName: 'Type of Risk', flex: 4,hideSortIcons: true,
        renderHeader: (params) => (
            <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
            </div>
        ), headerAlign: "center", align: 'center'},
        // { field: 'stateName', headerName: 'State', flex: 1 },
        {
            field: 'id', flex: 4, headerName: "Action",  align: 'center',hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center' ,  renderCell: (params) => (<div>
                <IconButton
                    color="primary"
                    onClick={() => { handleEdit(params.row) }}

                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </IconButton >
                {user?.userRoles.split(",").includes("Super Admin") ?
                    <IconButton
                        color="error"
                        onClick={() => { handleDelete(params.row.id) }}

                    >
                        {params.row.isDeleted ? <RestoreFromTrashIcon /> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    </IconButton >
                    : ""}
            </div>)
        }


    ];

    const handleClickOpen = () => {
        setFormData(initialLBData);
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };
    const handleEdit = (ILobData: LineOfBusinessModel) => {
        setFormData(ILobData);
        setOpen(true);
    };
    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    apiClient("/LineOfBusiness/Delete?id=" + `${id}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "Delete",
                        }
                    )
                        .then((res) => {
                            if (!res) return
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success')
                            fetchData();
                            setOpen(false);
                        }).catch((error) => {
                            console.log(error)
                        });
                 
                    
                }
            })
        //call api to save data
        // fetch(config.url.API_URL + "/Users/Delete?id=" + `${id}`,
        //     {
        //         headers: new Headers({
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`,
        //         }),
        //         method: "Delete",
        //     }
        // )
        //     .then((response) => {
        //         if (response.ok) {
        //             return response.json();
        //         }
        //         throw new Error('Something went wrong');
        //     })
        //     .then((res) => {
        //         alert("Data Successfully Deleted")
        //         fetchData();
        //         setOpen(false);
        //     }).catch((error) => {
        //         console.log(error)
        //     });


    };
    function EditToolbar() {

        return (
            <Box
                sx={{

                    borderBottom: 1,
                    borderColor: 'divider',
                    marginRight: "10px"

                }}
            >
                <div className="d-flex justify-content-between align-items-center mb-3" >
                    <div className=" card-title">
                        Line Of Business

                    </div>

                    <div className='d-flex align-items-center '>
                        <div className=" filter-blue" style={{ marginRight: "20px", padding: "10px" }}>
                            <GridToolbarFilterButton style={{ marginRight: "20px" }} />

                        </div>


                        <div className="" style={{ marginRight: "20px", minWidth: "300px", maxWidth:"500px" }}>
                            <FMSelectStateCustom name='state' style={{ marginRight: "20px" }} />
                        </div>
                      
                        <button

                            className=" active-btn-blue "
                            onClick={() => {
                                initialLBData.stateId = Number(selectedState)
                                handleClickOpen()
                            }}
                        >
                            Add New
                        </button>


                    </div>



                </div>
            </Box>

        );

    }

    // function EditToolbar() {

    //     return (
    //         <Box
    //             sx={{
    //                 borderBottom: 1,
    //                 borderColor: 'divider',
    //                 p: 1,
    //             }}
    //         >
    //             <div className="row col-lg-12">
    //                 <div className="col-lg-4 d-flex">

    //                 </div>
    //                 <div className="col-lg-4" style={{ textAlign: "center" }}>
    //                     <GridToolbarFilterButton />
    //                 </div>

    //                 <div className="col-lg-4" style={{marginRight:"20px", minWidth:"300px"}}>
    //                         <FMSelectStateCustom name='state' style={{ marginRight: "20px" }} />
    //                     </div>
    //                 <div className="col-lg-4" style={{ textAlign: "right" }}>
    //                     <button 

    //                        className="btn active-btn-blue"
    //                         onClick={() => {
    //                             initialLBData.stateId = Number(selectedState)
    //                             handleClickOpen()
    //                         }}


    //                     >
    //                         Add New
    //                     </button>
    //                 </div>



    //             </div>



    //         </Box>

    //     );

    // }


    return (
        <div style={{ height: '70vh', width: '100%' }}>
            <DataGrid

                getRowId={(r) => r.id}
                rows={LineOfBusinessCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                columnVisibilityModel={{
                    typeofRisk: LineOfBusinessCL.find(x => x.stateId === 2 || x.stateId === 3) ? true : false,

                }}
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px",
                        height:"70vh"

                    }
                }

            />
            <AddEditLineOfBusiness handleClose={handleClose} Open={Open} LineOfBusinessCL={Formdata} handleSubmit={handleSubmit} initValues={initialLBData} ></AddEditLineOfBusiness>

        </div>



    )




}