import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, Grid, Link, } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuredModel } from '../../models/InsuredsModel';
import moment from 'moment';

export interface getCustomerPolicyDocumentDetails {
    blobAddress?: string,
    documentName?: string,
    documentType?: string
    dateUploaded?: number,
    username?: number,
    fileSizeKB?: number,
    numberOfPages?: number
    isDeleted?: boolean
    isResubmittedDocument?: boolean
}

interface Iprops {
    documentList: getCustomerPolicyDocumentDetails[],
    handleClose: () => void,
    Open: boolean,

}

export const PolicyDocumentsPopup: React.FC<Iprops> = ({ handleClose, Open, documentList }) => {
    const initialValues: getCustomerPolicyDocumentDetails[] = documentList;
    return (

        <Dialog
            PaperProps={{ sx: { borderRadius: "28px", height: "60vh" } }}
            className='rounded' open={Open} onClose={handleClose} fullWidth maxWidth={'xl'}>
            <div >
                <Button style={{float:"right"}} onClick={handleClose} ><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 8L8 24M8 8L24 24" stroke="#162E3C" strokeWidth="2.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg></Button>
                
            </div>
            <DialogTitle className='card-title'>Documents</DialogTitle>


            <DialogContent style={{ minWidth: "80%", textAlign: 'left' }}>
                <div >
                    <table className=" table  table-hover ">
                        <thead className="table-header  " style={{ position: "sticky", top: "0", backgroundColor: "white" }}>
                            <tr >
                                <th style={{ width: "12%", textAlign: "center" }}> Document Name</th>
                                <th style={{ width: "10%", textAlign: "center" }}> Removed</th>
                                <th style={{ width: "12%" , textAlign: "center"}}>Re-Submitted</th>
                                <th style={{ width: "15%" , textAlign: "center"}}>Document Type</th>

                                <th style={{ width: "15%", textAlign: "center" }}>Date Uploaded</th>
                                <th style={{ width: "15%" , textAlign: "center"}}>Uploaded By</th>
                                <th style={{ width: "10%" , textAlign: "center"}}>File Size</th>
                                <th style={{ width: "10%", textAlign: "center" }}> Pages</th>

                            </tr>
                        </thead>
                        <tbody >
                            {
                                documentList?.map((row, index) => (
                                    <tr key={index}>
                                        <td className='Popuptd ' style={{textAlign:"center"}} >
                                            <Link href={row.blobAddress}>
                                                {row.documentName}
                                            </Link>

                                        </td>
                                        <td style={{textAlign:"center"}} >
                                            {row.isDeleted ? "Removed" : ""}
                                        </td>
                                        <td style={{textAlign:"center"}} >
                                            {row.isResubmittedDocument ? "Re-Submitted" : ""}
                                        </td>
                                        <td style={{textAlign:"center"}} >
                                            {row.documentType}
                                        </td>
                                        <td style={{textAlign:"center"}} >
                                            {row?.dateUploaded ? moment(row?.dateUploaded).format("MM-DD-YYYY") : ""}
                                            {/* {row.dateRecived?.toString()} */}
                                        </td>
                                        <td style={{textAlign:"center"}} >
                                            {row.username}
                                        </td>
                                        <td style={{textAlign:"center"}} >
                                            {row.fileSizeKB} Kb
                                        </td>
                                        <td style={{textAlign:"center"}} >
                                            {row.numberOfPages}
                                        </td>


                                    </tr>
                                ))}
                        </tbody>
                    </table>

                </div>


            </DialogContent>
            {/* <DialogActions>
                <Button variant="outlined" color="secondary"  >Close</Button>

            </DialogActions> */}

        </Dialog >
    )
}
