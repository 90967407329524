import {  Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import React, { useEffect } from "react";
// import { Form } from "react-bootstrap";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { ButlerModel, ButlerModel_VS, UpdateButlerModel_VS } from "../../models/ButlerModel";
import { FMTextField } from "../Elements/input";
import { FMSelectState } from "../Elements/select";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiClient } from "../../helper/api";
interface Iprops {
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    fetchData: () => void
    initvalues: ButlerModel
   // setSelectedOption: React.Dispatch<React.SetStateAction<string | string[]>>
   // selectedOptions: string[] | string
}
export const UpdateButlerModel: React.FC<Iprops> = ({ Open, handleClose, handleSubmit, initvalues  }) => {
    const { user } = useAuth();
    const token = user?.token;
    const initialLBData: ButlerModel = {
        modelId: 0,
        queueId: "",
        status: "",
        butlerModelId: "",
        IsMultiFormat:false
    }
    const FMSelectActiveInactiveCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        return (
            <FormControl error={!!errorText} fullWidth className="main-form-2" >
                <InputLabel id="State-Select-Label">Status</InputLabel>
                <Select {...field} labelId="Select-Label" label="Status"  IconComponent={ExpandMoreIcon}  >
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"NeedsTraining"}>NeedsTraining</MenuItem>
                </Select>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        )
    }
    useEffect(() => {
        // fetchStateData();
    }, [])
    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            <DialogTitle className="card-title" >Create Model</DialogTitle>
            <React.Fragment>
                <Formik
                    initialValues={initialLBData}
                    validateOnChange={true}
                    enableReinitialize={true}
                    validationSchema={UpdateButlerModel_VS}
                    onSubmit={(data, { setSubmitting }) => {
                        // setloading(true);
                        //data.selectedFields = selectedOptions.map(x => x.fieldName).join(",");
                        console.log(data);
                        data.name=initvalues.name;
                        data.stateId=initvalues.stateId;
                        data.modelId=initvalues.modelId;
                        data.IsMultiFormat=true;
                        if(initvalues.name){
                        apiClient(`/ButlerModel/UpdateExistingButlerModelFormat`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: JSON.stringify(data)
                            })
                            .then((res) => {
                                // Do something with the response
                                // setloading(false);
                                handleClose();
                                handleSubmit();
                            }).finally(() => {
                                setSubmitting(false);
                                // setloading(false);
                                // navigate("/NewSubmission");
                            });
                        }
                    }} >
                    {({ submitForm, isSubmitting }) =>
                    (
                        <Form className="main-form">
                            <DialogContent >
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="queueId" autoFocus margin="dense" label="Enter Queue ID"  type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="butlerModelId" autoFocus margin="dense" label="Enter Model ID"  type="text" fullWidth variant="outlined" sx={{ width: '100%' }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMSelectActiveInactiveCustom autoWidth={true} fullWidth variant={"standard"} type="text" name="status" autoFocus margin="dense" label="Status" sx={{ width: '100%' }} ></FMSelectActiveInactiveCustom>
                                        </FormControl>
                                    </Grid>
                                    {/* <Grid item xs={6}>
                                       <Checkbox></Checkbox>
                                      <span>Is there any new model for this state?</span>
                                    </Grid> */}
                                    {/* <Grid item xs={6}>
                                        <FMSelectPartner autoWidth={true} variant={"standard"} name="partnerId" ></FMSelectPartner>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minHeight: "200px" }} >
                                            <Multiselect
                                                displayValue="fieldName"
                                                id=''
                                                options={FieldData}
                                                onSelect={(selectedList, selectedItem) => {
                                                    setSelectedOption(selectedList);
                                                    console.log(selectedList);
                                                }}
                                                onRemove={(selectedList, selectedItem) => {
                                                    setSelectedOption(selectedList);
                                                    console.log(selectedList);
                                                }}
                                            selectedValues={FieldData}
                                            />
                                        </FormControl>
                                    </Grid> */}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <button className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type="button" >Cancel</button>
                                <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' onClick={submitForm} disabled={isSubmitting} >Submit</button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        </Dialog >
    );
}