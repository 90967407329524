import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../Constants";
import { DocumentList } from "../components/Lists/DocumentList";
import { TransactionInfo } from "../components/InfoPages/TransactionInfo";
import useAuth from "../context/AuthProvider";
import { setLocale } from "yup";
import { UploadDocuments } from "../Pages/UploadDocument";
import { apiClient } from "../helper/api";

export const TransactionDetails = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [documents, setDocuments] = useState([])
    const params = useParams();
    const [loading, setloading] = useState(false);

    const fetchData = () => {
        apiClient(`/Document/getTransactionDocumentById?transactionID=${params.transactionID}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                // Do something with the response
                setDocuments(res.data)
            })


    }

    useEffect(() => {
        fetchData();


    }, [])


    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                width: '100vh'
            }}
        >
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={3}>
                    {/* Batches */}
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                height: 'auto',

                            }}
                        >
                            {/* <TransactionInfo /> */}

                            <UploadDocuments fetchData={fetchData} />

                            <h1>List of Documents for Transaction: {params.transactionID} </h1>
                            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}


                            <DocumentList DocumentCL={documents} fetchData={fetchData} />
                        </Paper>
                    </Grid>

                </Grid>
                <Copyright sx={{ pt: 4 }} />
            </Container>
        </Box>


    );

}
