import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Button, Link, SelectChangeEvent, IconButton, Box, SelectProps, FormControl, InputLabel, MenuItem, Select, LinearProgress } from "@mui/material";
import { LineOfBusinessModel } from "../../models/LineOfBusinessModel";
import { config } from "../../Constants";
import { AddEditLineOfBusiness } from "../AddEdit/AddEditLineOfBusiness";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useAuth from "../../context/AuthProvider";
import { getAllPayables, PayablesModel } from "../../models/PayablesModel";
import { AddEditPayables } from "../AddEdit/AddEditPayables";
import moment from "moment";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { apiClient } from "../../helper/api";



interface Iprops {
    PayablesList: getAllPayables[],
    fetchDataMain: (selectedPartnerId: string) => void
}

export const PayablesList: React.FC<Iprops> = ({ PayablesList, fetchDataMain }) => {
    const initialData: PayablesModel = {
        payablesId: 0,
        account: "",
        location: "",
        payablePayoutId: 0,
        payablePaymentTypeId: 0,
        partnersId: 0,
        payableCategoryId: 0,
        amount: 0
    }

    const { user } = useAuth();
    const token = user?.token;
    const [Open, setOpen] = React.useState(false);
    const [Formdata, setFormData] = React.useState(initialData);
    const [payOutid, setPaoutId] = useState("0")
    const [CustomerSelected, setCustomerSelected] = React.useState("");
    const [loading, setloading] = React.useState(false);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const FMSelectPartnerCustomCustomer: React.FC<SelectProps> = ({ variant, ...props }) => {
        // const [field, meta] = useField<{}>(props);
        // const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { partnersId: 0, customerName: "" };
        const [Customer, setCustomer] = useState([initialdata])
        const handleChange = (event: SelectChangeEvent) => {
            setloading(true);

            setCustomerSelected(event.target.value);
            fetchDataMain(event.target.value);

        }
        const fetchCustomer = () => {

            apiClient('/Partners/getPartners',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                })
                .then((responseJson) => {
                    if (!responseJson) return
                    // Do something with the response

                    setCustomer(responseJson.data)

                })


        }
        useEffect(() => {
            fetchCustomer();

        }, [])
        return (

            <FormControl fullWidth  >
                <InputLabel id="State-Select-Label">Customers</InputLabel>
                <Select

                    onChange={handleChange}
                    labelId="State-Select-Label"
                    label="Partners"
                    value={CustomerSelected}
                >
                    <MenuItem value={""}> No Filter</MenuItem>
                    {Customer.map((item, key) =>
                        <MenuItem key={key} value={item.partnersId}> {item.customerName} </MenuItem>

                    )}

                </Select>
            </FormControl>

        )

    }



    const handleClickOpen = () => {
        setFormData(initialData);

        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchDataMain(CustomerSelected);
        setOpen(false);
    };
    const handleEdit = (row: getAllPayables) => {
        let formData: PayablesModel = {
            payablesId: row.payablesId,
            account: row.account,
            location: row.location,
            payablePayoutId: row.payablePayoutId ?? 0,
            payablePaymentTypeId: row.payablePaymentTypeId ?? 0,
            partnersId: row.partnersId ?? 0,
            payableCategoryId: row.payableCategoryId ?? 0,
            amount: row.amount
        }
        setPaoutId(formData.payablePayoutId.toString())
        setFormData(formData);
        setOpen(true);
    };

    const handleDelete = (payablesId: number) => {

        apiClient(`/Payables/DeletePayable?payablesId=${payablesId}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                fetchDataMain(CustomerSelected);
            })


    };

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });





    function EditToolbar() {
        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <Button
                    onClick={handleClickOpen}
                    color="primary"
                    variant="outlined"
                >
                    Add
                </Button>
                <div className="col-log-4" style={{ textAlign: "center" }}>
                        <span className="card-title" > <GridToolbarFilterButton /></span>
                    </div>
            </Box>

        );

    }

    return (
        // <div style={{ height: '75vh', width: '100%' }}>
        //     <DataGrid
        //         getRowId={(r) => r.payablesId}
        //         rows={PayablesList}
        //         columns={columns}
        //         pageSize={50}
        //         rowsPerPageOptions={[50]}
        //         components={{
        //             Toolbar: EditToolbar,
        //         }}
        //         sx={
        //             {
        //                 fontFamily: "'Proxima Nova', sans-serif;",
        //                 border: "0px",
        //                 fontSize: "16px"



        //             }
        //         }

        //     />
        //     <AddEditPayables handleClose={handleClose} Open={Open} PayablesCL={Formdata} handleSubmit={handleSubmit} initValues={initialData} ></AddEditPayables>

        // </div>
        <div className="">
            <div className="row col-lg-12">
                <div className="col-lg-4 d-flex">
                    <div style={{marginRight:"2px"}}>
                        <Link href="/">
                            <Button type="button" title="Home"
                                className="btn action-btn" ><HomeIcon />
                            </Button>
                        </Link>
                    </div>
                    <div>
                        <Button

                            className=" btn action-btn"
                            onClick={goBack}
                            title="Back"
                        >
                            <ArrowBackIosIcon />
                        </Button>
                    </div>
                </div>
                <div className="col-lg-4" style={{ textAlign: "center" }}>
                    <span className="card-title">Payables</span>
                </div>
                <div className="col-lg-4" >
                    <span style={{ float: "right" }}>
                        <Button style={{ height: "5vh" }}
                            onClick={handleClickOpen}
                            color="primary"
                            variant="contained"
                        >
                            Add New
                        </Button>
                    </span>
                    <span style={{ float: "right",width: "250px", marginRight: "30px" }}>
                        <FMSelectPartnerCustomCustomer style={{ height: "5px" }} />
                    </span>
                </div>


            </div>
            <div className="table-responsive mt-3">
                <div style={{ height: "70vh", overflow: "auto" }}>
                    <table className="table">
                        <thead style={{ position: "sticky", top: "0", backgroundColor: "white" }}>
                            <tr >
                                <th>Account</th>
                                <th>Category</th>
                                <th>Location</th>
                                {/* <th>Amount</th> */}
                                <th>Payout</th>
                                <th>Payment Type</th>
                                <th>Customer</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody >


                            {
                                PayablesList?.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            {row.account}
                                        </td>
                                        <td>
                                            {row.categoryName}
                                        </td>
                                        <td>
                                            {row.location}
                                        </td>
                                        {/* <td>
                                            { row.payablePayoutId == 4 || row.payablePayoutId == 5 || row.payablePayoutId == 6 ?
                                               USDollar.format(row.amount)
                                               :""
                                               }
                                        </td> */}
                                        <td>
                                            {row.payoutName}
                                        </td>
                                        <td>
                                            {row.paymentName}
                                        </td>
                                        <td>
                                            {row.customerName}
                                        </td>
                                        <td>
                                            <button type="button" onClick={() => handleEdit(row)} className="btn  action-btn">Edit <i className="fa-solid fa-right-long"></i></button>
                                            {/* <IconButton
                                                color="primary"
                                                onClick={() => { handleEdit(row) }}
                                            >
                                                <EditIcon />
                                            </IconButton > */}
                                        </td>

                                        <td>
                                            <button type="button" onClick={() => handleDelete(row.payablesId)} className="btn action-btn"><DeleteIcon /></button>
                                            {/* <IconButton
                                                color="error"
                                                onClick={() => { handleDelete(row.payablesId) }}
                                            >
                                                {row.isDeleted ? <RestoreFromTrashIcon /> : <DeleteIcon />}
                                            </IconButton > */}

                                        </td>



                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>

            </div>
            <AddEditPayables handleClose={handleClose} Open={Open} PayablesCL={Formdata} handleSubmit={handleSubmit} initValues={initialData} ></AddEditPayables>

        </div>




    )




}

