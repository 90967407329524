import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../Constants";
import { FMTextField } from "../components/Elements/input";
import useAuth from "../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiClient } from "../helper/api";
export const CheckNIPR = () => {
    const { user } = useAuth();
    const token = user?.token;
    // const [loading, setloading] = useState(false);
    const params = useParams();

    let initialValues: { NIPR: string, StateId: number } = { NIPR: "", StateId: 0 }
    if (params.fieldValue?.toString() !== undefined || null) {
        if (params.fieldValue?.toLocaleLowerCase()?.startsWith('o')) {
            initialValues.NIPR = params.fieldValue.replace('o', '0').replace('O', '0');
        }
        else {
            initialValues.NIPR = params.fieldValue ?? "";
        }

    }
    // const [NIPRStatus, setNIPRStatus] = useState("");
    const [SelectedState, setSelectedState] = React.useState<string>('-1');
    // let initData: CustomerPolicyModel = {
    //     customerPolicyId: 0,
    //     masterPolicyTypeId: 0,
    //     documentId: 0, 
    //     policyStatus: "",
    //     policyNumber: "",
    //     isSubmitted: false,
    //     isProcessed: false,
    //     userId: 0,
    //     policyId: 0
    // };
    // const [custPolicyDetails, setcustPolicyDetails] = useState(initData);


    const fetchData = () => {
        // setloading(true);
        apiClient(`/CustomerPolicy/getbyCustPolicyId?custPolicyId=${params.customerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson?.data != null) {



                    // setcustPolicyDetails(responseJson.data);
                    setSelectedState(responseJson.data.stateId);

                }
                // setloading(false);
            })

    }


    const FMSelectStateCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { stateId: 0, stateName: "" };
        const [States, setStates] = useState([initialdata])


        const handleChange = (event: SelectChangeEvent) => {
            // setloading(true);
            setSelectedState(event.target.value);


        };


        const fetchData = () => {

            apiClient('/FillingsByUser/getUserFillingStates',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                })
                .then((responseJson) => {
                    if (!responseJson) return
                    // Do something with the response

                    setStates(responseJson.data)

                })

        }
        useEffect(() => {
            fetchData();

        }, [])

        return (

            <FormControl error={!!errorText} fullWidth  >
                <InputLabel id="State-Select-Label">State</InputLabel>
                <Select

                    {...field}
                    onChange={handleChange}
                    labelId="State-Select-Label"
                    label="State"
                    value={SelectedState}
                    IconComponent={ExpandMoreIcon}
                >
                    {/* <MenuItem  value={-1}> Select State</MenuItem> */}

                    {States.map((state, key) =>
                        <MenuItem key={key} value={state.stateId}> {state.stateName} </MenuItem>

                    )}

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        <div >
            <Formik initialValues={initialValues}
                validateOnChange={true}
                //   validationSchema ={PolicyModel_VS}
                onSubmit={(data, { setSubmitting }) => {
                    data.StateId = Number(SelectedState);
                    apiClient(`/NIPR/getLicensingDetails?licenseNumber=${data.NIPR}&&stateId=${data.StateId}`,
                        {
                            method: 'POST',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            // body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((res) => {
                            if (!res) return
                            // Do something with the response
                            console.log(res);
                            setSubmitting(false)
                            if (res.resp === 0) {

                                let parser = new DOMParser();
                                let xmlDoc = parser.parseFromString(res.data, "text/xml");
                                // documentElement always represents the root node

                                // let x = xmlDoc.documentElement.getElementsByTagName("NAME" || "NAME_COMPANY" || "FIRM");
                                let x = xmlDoc.documentElement.childNodes;
                                console.log(x);

                                var txt = "";
                                if (x[0].textContent === "ERROR") {
                                    txt = "License Number could not be found.";

                                } else {
                                    x.forEach(element => {

                                        // txt += <b> {element.nodeName} </b> + ": " + element.textContent + "<br/>";

                                        if (element.childNodes.length > 0) {

                                            element.childNodes.forEach(xc => {


                                                txt += "<b>" + xc.nodeName + ":</b> " + xc.textContent + "<br/>";

                                            });
                                        }


                                    });
                                }
                                var response = document.getElementById("Response");
                                if (response != null) {
                                    response.innerHTML = txt;
                                }
                                // setNIPRStatus(txt);

                            }

                        })

                }} >
                {({ values, isSubmitting }) => (
                    <Form>
                        <div>
                            <div className="text-center pb-4 ">
                                <span className="card-title ">Check License Number Against NIPR</span>
                            </div>
                            
                            <Box sx={{ m: 2 }}>

                                <Grid container spacing={2} xs={12} item justifyContent="center" display='flex'  >

                                    <Grid item xs={12} >
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="NIPR" autoFocus margin="dense" label="Enter License number" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMSelectStateCustom name="StateId"></FMSelectStateCustom>
                                        </FormControl>
                                    </Grid>
                                    
                                </Grid>
                                <Grid item xs={12} className="text-center mt-4">
                                        <button className="active-btn-blue" type="submit" disabled={isSubmitting} >Check License</button>
                                    </Grid>
                            </Box>
                            <Box sx={{ display: 'flex', m: 2 }}>
                                <div id="Response">
                                </div>
                                <span ></span>
                            </Box>
                        </div>






                    </Form>

                )}
            </Formik>
        </div>

    )

}