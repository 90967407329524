import * as yup from 'yup'
export interface PolicyModel 
{
    batchId: number,
    policyId: number,
    customPolicyId?: string,
    policyNumber: string,
    previousPolicyNumber?: string,
    binderNumber: string,
    effectiveDate:Date,
    policyStatusId:number,
    statusNotes:string
    insured_Name: string,
    insured_AddressLine1: string,
    insured_AddressLine2: string,
    insured_City: string,
    insured_StateOrProvince: string,
    insured_PostalCode: string,
    insured_Country: string,
    dateCreated?:Date
}
   
export const PolicyModel_VS = yup.object({
    StatusNotes: yup.string().required("Please enter Status Notes")
  })