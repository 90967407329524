import * as React from 'react';
import { Policies } from './Policies';
import { RightNav } from '../components/Nav/RightNav';
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo';
import { FillingsByUser } from './FillingsByUser';
import { CustomerPolicyList } from '../components/Lists/CustomerPolicyList';
import useAuth from '../context/AuthProvider';
import { config } from '../Constants';
import { Box, Button, LinearProgress, Link } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { GridToolbarFilterButton } from '@mui/x-data-grid';
import { apiClient } from '../helper/api';



export const Submissions = () => {
    const [CustomerPolicy, setCustomerPolicy] = React.useState([]);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = React.useState(false);
    const fetchData = () => {
        setloading(true);
        apiClient('/CustomerPolicy/getAllCustomerPolicy',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response

                setCustomerPolicy(responseJson.data)
            }).finally(() => {
                setloading(false);
            });


    }

    React.useEffect(() => {
        fetchData();

    }, [])

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

     

    return (


        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

        //                     <LoggedInUserInfo ButtonText="" Link=""/>


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="card dashboard-table">



        //                                     <div className="table-responsive">
        //                                         <div style={{ height: "1vh" }}>
        //                                             {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
        //                                         </div>

        //                                         <div className="row col-lg-12">
        //                                             <div className="col-lg-4 d-flex">
        //                                                 <div style={{ marginRight: "2px" }}>
        //                                                     <Link href="/">
        //                                                         <Button type="button" title="Home"
        //                                                             className="btn action-btn" ><HomeIcon />
        //                                                         </Button>
        //                                                     </Link>
        //                                                 </div>
        //                                                 <div>
        //                                                     <Button

        //                                                         className=" btn action-btn"
        //                                                         onClick={goBack}
        //                                                         title="Back"
        //                                                     >
        //                                                         <ArrowBackIosIcon />
        //                                                     </Button>
        //                                                 </div>
        //                                             </div>
                                                    
                                                  
        //                                             <div className="col-lg-4" style={{ textAlign: "center" }}>
        //                                                 <span className="card-title" >Submissions</span>
        //                                             </div>
        //                                             <div className="col-lg-4" style={{ textAlign: "right" }}>
        //                                                 <Box
        //                                                     sx={{

        //                                                        // borderBottom: 1,
        //                                                         borderColor: 'divider',
        //                                                         p: 1,
        //                                                     }}
        //                                                 >
        //                                                     <Link href={'/NewSubmission/'} color="#1976D2">
        //                                                         <Button

        //                                                             color="primary"
        //                                                             variant="contained"
        //                                                             style={{ fontSize: "0.8rem" }}
        //                                                         >
        //                                                             Create New Submission
        //                                                         </Button>

        //                                                     </Link>
        //                                                     <Button
        //                                                         color="primary"
        //                                                         variant="contained"
        //                                                         style={{ marginLeft: "16px", fontSize: "0.8rem" }}
        //                                                         onClick={() => { fetchData() }}

        //                                                     >
        //                                                         Refresh Status
        //                                                     </Button >


        //                                                 </Box>
        //                                             </div>
        //                                         </div>
                                                <CustomerPolicyList CustomerPolicyModelCL={CustomerPolicy} fetchData={fetchData} />
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>

    );

}





