var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useCallback, useState } from 'react';
/**
 * Hook that is used to keep track of drag state
 * @param stepLabels
 */
export var useDragBlockSelect = function () {
    var _a = useState(), dragCoords = _a[0], setDragCoords = _a[1];
    var onDragStart = useCallback(function (startCoords) {
        setDragCoords({
            start: startCoords,
            end: startCoords,
        });
    }, []);
    var onDragEnd = useCallback(function () {
        setDragCoords(undefined);
    }, []);
    var onDrag = useCallback(function (newMouseCoords) {
        setDragCoords(function (curDragCoords) {
            if (!curDragCoords) {
                throw new Error('Invalid state reached. No drag in progress');
            }
            return __assign(__assign({}, curDragCoords), { end: newMouseCoords });
        });
    }, []);
    return {
        onDragStart: onDragStart,
        onDrag: onDrag,
        onDragEnd: onDragEnd,
        dragRectangle: dragCoords,
    };
};
