import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import { CustomerPolicyModel } from "../../models/CustomerPolicyModel";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import UploadIcon from '@mui/icons-material/Upload';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { margin } from "@mui/system";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import moment from "moment";
import { LogsModel } from "../../models/LogsModel";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import { ArrowRight } from "@mui/icons-material";
import { Pagination } from '@mui/material';
import { RiExpandUpDownLine } from "react-icons/ri";
interface Iprops {
    LogsCL: LogsModel[],
    fetchData: () => void
}

export const LogsList: React.FC<Iprops> = ({ LogsCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(0); // Track the current page
    const [pageSize, setPageSize] = useState(50); // Track page size
    const { user } = useAuth();
    const token = user?.token;
    const navigate = useNavigate();

    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };
    useEffect(() => {

    }, []);

    const goBack = () => {
        navigate(-1);
    }
    function EditToolbar() {

        return (
            <Box
                sx={{

                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                {/* <div className="row col-lg-12"  style={{ marginBottom: "10px",alignItems: "center"  }}> */}
                {/* <div className="col-lg-4 d-flex">
                        <div style={{ marginRight: "2px" }}>
                            <Link href="/">
                                <Button type="button" title="Home"
                                    className="btn action-btn" ><HomeIcon />
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Button

                                className=" btn action-btn"
                                onClick={goBack}
                                title="Back"
                            >
                                <ArrowBackIosIcon />
                            </Button>
                        </div>
                    </div> */}
                <div className="d-flex justify-content-between log-title">
                    <div className="" >
                        <span className="card-title" >Logs</span>
                    </div>
                    <div className="" style={{ textAlign: "center" }}>
                        <span className="filter-blue-1" > <GridToolbarFilterButton /></span>
                    </div>
                </div>
                {/* </div> */}
            </Box>

        );

    }


    const columns: GridColDef[] = [

        { field: 'errorId', headerName: 'Error ID',hideSortIcons: true,headerAlign: "center", align: 'center',
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), flex: 1.4 },
        { field: 'application', type: 'string', headerName: 'Application',hideSortIcons: true,headerAlign: "center", align: 'center',
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), flex: 1 },
        { field: 'host', type: 'string', headerName: 'Host',hideSortIcons: true,headerAlign: "center", align: 'center',
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), flex:1 },
        { field: 'type', type: 'string', headerName: 'Type',hideSortIcons: true,headerAlign: "center", align: 'center',
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), flex: 1 },
        { field: 'source', type: 'string', headerName: 'Source',hideSortIcons: true,headerAlign: "center", align: 'center',
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), flex: 0.5 },
        { field: 'message', type: 'string', headerName: 'Message',hideSortIcons: true,headerAlign: "center", align: 'center',
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), flex: 1 },
        { field: 'user', type: 'string', headerName: 'User',hideSortIcons: true,headerAlign: "center", align: 'center',
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), flex:1.4 },

    ];

    const totalCount = 50;

    return (

        <div style={{ height: '70vh', width: '100%' }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DataGrid
                pagination
                paginationMode="server"
                page={page}
                pageSize={pageSize}
                onPageChange={(newPage) => handlePageChange(newPage)}
                rowCount={totalCount} // Update this with total rows count
                components={{
                    Toolbar: EditToolbar,
                    Pagination: (props) => (
                        <div className="pagination-container d-flex justify-content-end m-1 pr-3">
                            <nav>
                                <ul className="pagination">
                                    <li className="page-item" data-page="prev">
                                        <button
                                            className="page-link"
                                            aria-label="Previous"
                                            onClick={() => handlePageChange(page - 1)}
                                            disabled={page === 0}
                                        >
                                            <i className="fas fa-chevron-left"></i>
                                        </button>
                                    </li>

                                    <li className="page-item" data-page="prev">
                                        <a href="#" className="page-link">
                                            <span className="">
                                                {page + 1} of {Math.ceil(totalCount / pageSize)}
                                            </span>
                                        </a>
                                    </li>
                                    <li className="page-item" data-page="next" id="prev">
                                        <button
                                            className="page-link"
                                            aria-label="Next"
                                            onClick={() => handlePageChange(page + 1)}
                                            disabled={page === Math.ceil(totalCount / pageSize) - 1}
                                        >
                                            <i className="fas fa-chevron-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    ),
                }}
                getRowId={(r) => r.errorId}
                rows={LogsCL}
                columns={columns}
                sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                        whiteSpace: "normal",
                        lineHeight: "normal",
                        padding: "12px 0px"
                    },
                    fontFamily: "'Proxima Nova', sans-serif;",
                    border: "12px",
                    fontSize: "16px",
                }}
            />

        </div>


    )
}


