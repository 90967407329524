import { Autocomplete, DialogTitle, FormControl, FormHelperText, InputLabel, LinearProgress, Link, MenuItem, Select, SelectChangeEvent, SelectProps, TextField } from "@mui/material";
import { Formik, Field, FieldAttributes, useField } from "formik";
import React, { FormEvent, ReactNode, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { FMSelectInsuranceCompany, FMSelectMasterPolicyType, FMSelectPartners, FMSelectState, FMSelectTransactionType, FMSelectUserFillingState, } from "../components/Elements/select";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { CustomerPolicyModel_VS } from "../models/CustomerPolicyModel";
import { NewSubmissionModelVS } from "../models/NewSbubmissionModel";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { toast } from "react-toastify";
import { fontSize, height } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; 
import { apiClient } from "../helper/api";


var formData = new FormData();
var files: File[];

export const CreateNewSubmission: React.FC = () => {

    const initialValues: { usstate: number, InsuranceCompany: number, MasterPolicyType: number, IsSingleDocument: boolean | undefined, TransactionType: number | undefined, PartnerId: number } =
    {
        usstate: 0, InsuranceCompany: 0, MasterPolicyType: 0, IsSingleDocument: undefined,
        TransactionType: undefined,
        PartnerId: 0
    }
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();
    const [SelectedState, setSelectedState] = React.useState<string>('-1');
    const initialInsuranceCompany = { insuranceCompanyId: 0, insuranceCompanyName: "" };
    const [SelectedCarrier, setSelectedCarrier] = React.useState<string>('');

    const [InurenaceComapny, setInsuranceCompany] = useState([initialInsuranceCompany]);
    const initialPolicyType = { lineOfBusinessId: 0, businessCode: "" };

    const [PolicyType, setPolicyType] = useState([initialPolicyType]);
    const navigate1 = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const [policyTypeValue, setPolicyTypeValue] = React.useState('');

    const handlePolicyTypeChange = (event: SelectChangeEvent) => {
        setPolicyTypeValue(event.target.value as string);
    };


    const FMSelectCarrier: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;

        const handleCarrierChange = (event: SelectChangeEvent) => {
            console.log("inside carrier changes ", event.target.value);
            console.log("inside state changes ", selectedOption?.stateId);
            setloading(true);
            setSelectedCarrier(event.target.value);
            // console.log(event.target.value);

            apiClient(`/LineOfBusiness/getPolicyType?InsuranceCompanyId=${event.target.value}&stateId=${selectedOption?.stateId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                })
                .then((responseJson) => {
                    if (!responseJson) return
                    // Do something with the response
                    setloading(false);
                    console.log("policy type values ", responseJson.data);

                    setPolicyType(responseJson.data)

                })
        };

        return (

            <FormControl className="" error={!!errorText} fullWidth  >
                <InputLabel id="demo-simple-select-label" className="border-round">Select Carrier</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...field}
                    onChange={handleCarrierChange}
                    label={"Select Carrier"}
                    value={SelectedCarrier}
                    IconComponent={ExpandMoreIcon}
                >
                    {InurenaceComapny?.map((company, key) =>
                        <MenuItem key={key} value={company.insuranceCompanyId}> {company.insuranceCompanyName} </MenuItem>
                    )}
                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }


    const FMSelectTrueFalse: React.FC<FieldAttributes<SelectProps>> = ({
        variant,
        ...props
    }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (
            <FormControl className="" error={!!errorText} fullWidth>
                <InputLabel id="TrueFlase-simple-select-label" className="border-round ">Upload All Documents in One File?</InputLabel>
                <Select
                    {...field}
                    className="border-round "
                    id="demo-simple-select"
                    labelId="TrueFlase-simple-select-label"
                    label={"Upload All Documents in One File?"}

                    IconComponent={ExpandMoreIcon}

                >
                    <MenuItem value={undefined}></MenuItem>
                    <MenuItem value={true as any}>Yes</MenuItem>
                    <MenuItem value={false as any}>No</MenuItem>
                </Select>
                {/* <FormHelperText>{errorText}</FormHelperText> */}
            </FormControl>


        );
    };

    const FMSelectPolicyTypeCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;

        return (

            <FormControl className="" error={!!errorText} fullWidth  >
                <InputLabel id="State-Select-Label" className="border-round">Select Coverage Type</InputLabel>
                <Select
                    {...field}
                    IconComponent={ExpandMoreIcon}
                    labelId="State-Select-Label"
                    label="Select Coverage Type"
                    onChange={handlePolicyTypeChange}
                    value={policyTypeValue}
                >
                    {/* <MenuItem  value={-1}> Select State</MenuItem> */}
                    {PolicyType?.map((PolicyType, key) =>
                        <MenuItem key={key} value={PolicyType?.lineOfBusinessId?.toString()}> {PolicyType.businessCode} </MenuItem>
                    )}

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    const partnerfetchData = () => {
        apiClient('/Users/getLoggdInUserPartnerId',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                setloading(false);

                // Do something with the response
                if (!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))) {
                    initialValues.PartnerId = Number(res.data);
                }

                //setloadingPartner(false);
                //console.log("in fetchuser", initialValues);

            })
    }

    const FMSelectPartnersCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { partnersId: 0, customerName: "" };
        const [Partner, setpartnerData] = useState([initialdata]);

        const fetchData = () => {
            apiClient('/Partners/getPartners',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                })
                .then((responseJson) => {
                    if (!responseJson) return
                    // Do something with the response

                    setpartnerData(responseJson.data)

                })
        }


        useEffect(() => {
            fetchData();

        }, [])

        return (

            <FormControl className="" error={!!errorText} fullWidth  >
                <InputLabel id="State-Select-Label" className="border-round">Select Customer/Partner  </InputLabel>
                <Select
                    labelId="State-Select-Label"
                    id="State-Select-Label"
                    {...field}
                    IconComponent={ExpandMoreIcon}
                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                    value={undefined}
                    label={"Select Customer/Partner"}
                >
                    {Partner?.map((Name, key) =>
                        <MenuItem key={key} value={Name.partnersId}> {Name.customerName} </MenuItem>

                    )}
                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }
    interface statelist {
        stateId: number, stateName: string
    }
    const initialdata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialdata])
    const fetchStates = () => {

        apiClient('/FillingsByUser/getUserFillingStates',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response

                setStates(responseJson.data)

            })
    }
    const [selectedOption, setSelectedOption] = useState<statelist | null>(null);

    const FMSelectStateCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const handleChange = (event: statelist | null) => {


            apiClient(`/InsuranceCompany/getPartnerInsuranceCompanyByState?stateId=${event?.stateId ?? "0"}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                })
                .then((responseJson) => {
                    if (!responseJson) return
                    // Do something with the response
                    setloading(false);

                    setInsuranceCompany(responseJson.data)

                })
        };



        const handleOptionChange = (event: React.ChangeEvent<{}>, newValue: statelist | null) => {
            setSelectedOption(newValue);
            handleChange(newValue);
        };



        return (

            <FormControl className="" error={!!errorText} fullWidth >
                <InputLabel id="State-Select-Label" ></InputLabel>
                <Autocomplete
                    options={States}
                    getOptionLabel={(option) => option.stateName}
                    value={selectedOption}
                    onChange={handleOptionChange}
                    className="border-round box-he"
                    popupIcon={<ExpandMoreIcon/>}
                    renderInput={(params) => (
                        <TextField

                            sx={{
                                "& .MuiOutlinedInput-root": {
                                    borderRadius: "50px",
                                },
                            }}


                            {...params} label="Select State" className="border-round" variant="outlined" />
                    )}
                    


                />

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    useEffect(() => {
        fetchStates();
        partnerfetchData();
    }, [])

    const handleCancelClick = () => {
        //goBack();
        window.location.href = "/";
    }

    return (
        <React.Fragment>
            <div style={{ height: "100%" }} >
                <Formik

                    initialValues={initialValues}
                    validateOnChange={true}
                    // validationSchema={NewSubmissionModelVS}
                    onSubmit={(data, { setSubmitting }) => {

                        data.usstate = Number(selectedOption?.stateId);
                        data.InsuranceCompany = Number(SelectedCarrier);
                        data.MasterPolicyType = Number(policyTypeValue);
                        if (selectedOption?.stateId == undefined) {
                            alert("Select State");
                            setSubmitting(false);
                            return;
                        }
                        if (data.InsuranceCompany == -1) {

                            alert("Select Carrier");
                            setSubmitting(false);
                            return;
                        }
                        if (data.MasterPolicyType == 0) {

                            alert("Select PolicyType");
                            setSubmitting(false);
                            return;
                        }
                        if (data.TransactionType == 0) {
                            alert("Select TransactionType");
                            setSubmitting(false);
                            return;
                        }
                        if (data.PartnerId == 0) {
                            alert("Select Partner");
                            setSubmitting(false);
                            return;
                        }

                        setloading(true);
                        console.log(data);

                        apiClient(`/NewSubmission/CreateCustomerPolicy`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: JSON.stringify(data)

                            })
                            .then((res) => {

                                // Do something with the response
                                setSubmitting(false);
                                setloading(false);

                                if (res.resp == 200) {
                                    // console.log("Sucess");
                                    navigate("/CreateCustomerPolicy/" + res.data);
                                }


                                formData = new FormData();

                            }).catch((error) => {
                                setSubmitting(false);
                                alert("There was an error Creating the Submission");
                                formData = new FormData();
                                // console.log(error)
                                setloading(false);
                                navigate("/NewSubmission");
                            });

                    }} >
                    {({ submitForm, isSubmitting, errors }) =>
                    (<>
                        <Form >
                            <div  >
                                <div className="col-lg-12"   >
                                    <div className=" pading-left-2 card-title  ">
                                        <span>New Submission</span>
                                    </div>
                                    <div className="row col-lg-12">

                                        <div className="card-body col-lg-5 " >
                                            {/* <FormHelperText>Select State</FormHelperText> */}
                                            <FMSelectStateCustom autoWidth={true} variant={"standard"} name="usstate" ></FMSelectStateCustom>
                                        </div>
                                        <div className="card-body col-lg-5">
                                            {/* <FormHelperText>Select Carrier</FormHelperText> */}
                                            <FMSelectCarrier autoWidth={true} variant={"standard"} name="InsuranceCompany"></FMSelectCarrier>
                                        </div>

                                    </div>
                                    <div className="row col-lg-12">
                                        <div className="card-body  col-lg-5">

                                            {/* <FormHelperText>Select Policy Type</FormHelperText> */}
                                            <FMSelectPolicyTypeCustom autoWidth={true} variant={"standard"} name="MasterPolicyType"></FMSelectPolicyTypeCustom>
                                        </div>
                                        <div className="card-body col-lg-5">

                                            {/* <FormHelperText>Upload all documents in one file?</FormHelperText> */}
                                            <FMSelectTrueFalse autoWidth={true} variant={"standard"} label="True/False" name="IsSingleDocument"></FMSelectTrueFalse>
                                        </div>
                                    </div>
                                    <div className="row col-lg-12">
                                        <div className="card-body col-lg-5">

                                            {/* <FormHelperText>Select Transaction Type</FormHelperText> */}
                                            <FMSelectTransactionType autoWidth={true} variant={"standard"} name="TransactionType" ></FMSelectTransactionType>
                                        </div>
                                        <div className="card-body col-lg-5">
                                            {(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer")) ?

                                                <React.Fragment>


                                                    {/* <FormHelperText>Select Customer/Partner</FormHelperText> */}
                                                    <FMSelectPartnersCustom autoWidth={true} variant={"standard"} label="Select Customer/Partner" name="PartnerId"></FMSelectPartnersCustom>
                                                </React.Fragment>
                                                : ""}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Form>
                        <footer>
                            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                            <div className="  next-cancel-btn  bottom-sticky-button"  >
                                <button className="btn  btn-primary active-btn-blue  me-2" type="submit" onClick={submitForm} disabled={isSubmitting} >Next</button>
                                <button className=" active-btn-white " type="button" onClick={handleCancelClick}>Cancel</button>
                            </div>
                        </footer>
                    </>

                    )}
                </Formik>

            </div>
        </React.Fragment>


    )
}