import * as yup from 'yup'
export interface ButlerMappings {



    mappingId?: number,
    carrier?: string,
    insuranceCompanyId?: number
    lOB?: string
    id?: number
    butlerModel?: string
    modelId?: number
    isActive?: boolean,
    transactionName?: string,
    commaSeparatedTransactionIds?: string,
    documentName?: string,
    documentTypeId?: number,
    partnerName?: string,
    partnersId?: number
    isRequired?: boolean,
    stateId?: number,
    transactionId?: number[]


}


export const ButlerMapping_VS = yup.object({
    insuranceCompanyId: yup.number().required("Please select Carrier").min(1, "Please select Carrier").typeError("invalid value"),
    //carrier : yup.string().required("Please select Carrier").min(1, "Please select Carrier").typeError("invalid value"),
    // id: yup.number().required("Please select Line of Business").min(1, "Please select Line Business").typeError("invalid value"),
    // modelId: yup.number().required("please select Model").min(1, "please select Model").typeError("invalid value"),
    transactionId: yup.number().required("Please select Transaction").typeError("invalid value"),
    documentTypeId: yup.number().required("Please select Document").min(1, "Please select Document").typeError("invalid value"),
    // partnersId: yup.number().required("Please select partner").min(1, "Please select partner").typeError("invalid value"),
    // modelId : yup.number().required("Please select Model").min(1, "Please select Model").typeError("invalid value"),


})