import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import useAuth from "../../context/AuthProvider";
import { EmailKeysModel } from './AddEditEmailTemplate';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';


interface Iprops {
    EmailKeysCL: EmailKeysModel[],
    handleClose: () => void,
    Open: boolean,

    initValues: EmailKeysModel
}


export const KeyInformationPopUp: React.FC<Iprops> = ({ handleClose, Open, EmailKeysCL, initValues }) => {
    const initialValues: EmailKeysModel[] = initValues ? EmailKeysCL : initValues;

    const { user } = useAuth();
    const token = user?.token;


    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle className='card-title' >Available Keys In System</DialogTitle>


            <DialogContent>
                <div style={{ overflow: "auto" }}>

                    <table className="table" id="myTable">
                        <thead style={{ position: "sticky", top: "0", backgroundColor: "white" }}>
                            <tr >
                                <th style={{ width: "5%" }}>Key Name</th>
                                <th style={{ width: "10%" }}>Key</th>
                                <th style={{ width: "10%" }}>Copy</th>


                            </tr>
                        </thead>
                        <tbody >
                            {
                                EmailKeysCL?.map((row, index) => (
                                    <React.Fragment>
                                        <tr key={row.key} id={row.key?.toString()} >
                                            <td>
                                                {row?.keyName}

                                            </td>
                                            <td >
                                                {row.key}
                                            </td>

                                            <td>
                                                <CopyToClipboard text={row.key}
                                                    onCopy={() => alert("Copied Sucessfully!")}>
                                                    <IconButton
                                                        className='color-primary'
                                                        // onClick={() => { navigator.clipboard.writeText(row.key); alert("Copied Sucessfully!") }}
                                                        title="Copy"
                                                    >
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5M11 9H20C21.1046 9 22 9.89543 22 11V20C22 21.1046 21.1046 22 20 22H11C9.89543 22 9 21.1046 9 20V11C9 9.89543 9.89543 9 11 9Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>

                                                    </IconButton>
                                                </CopyToClipboard>

                                            </td>

                                        </tr>

                                    </React.Fragment>
                                ))}
                        </tbody>
                    </table>
                </div>
            </DialogContent>
            <DialogActions className='' >
                <button className='active-btn-white' onClick={handleClose} type="button" >Cancel</button>
            </DialogActions>

        </Dialog>

    );
}

