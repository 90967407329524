import { Copyright } from '@mui/icons-material'
import { Box, Container, Grid, Link, Paper, Toolbar } from '@mui/material'
import * as React from 'react'
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo'
import { RightNav } from '../components/Nav/RightNav'

export const AccessDenied = () => {

    return (


        <h1>You are not authorized to view this page, Please login with correct user credentials.
        </h1>


    )

}