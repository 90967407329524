import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { PartnersList } from "../components/Lists/PartnersList";
import { PayablesList } from "../components/Lists/PayablesList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { apiClient } from "../helper/api";

export const Payables = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const [Payables, setPayables] = useState([]);

    const params = useParams();
    const [CustomerFilter, setCustomerFilter] = useState<string>();

    var selectedPartnerId: string | null;

    const fetchDataMain = (selectedPartnerId: string) => {

        setloading(true);
        apiClient(`/Payables/getAllpayables?selectedPartnerId=${selectedPartnerId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                if (responseJson.data != null) {

                    setPayables(responseJson.data);

                }
            }).finally(() => {
                setloading(false);
            });
    }
    useEffect(() => {
        fetchDataMain("0");

    }, [])


    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                            <LoggedInUserInfo ButtonText="" Link=""/>
                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">
                                            <div className="table-responsive">
                                                <div style={{ height: "1vh" }}>
                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                </div>
                                                <PayablesList PayablesList={Payables} fetchDataMain={fetchDataMain} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}
                        </div>
                    </div>
                </div>
            </main>
        </section>
    )

}