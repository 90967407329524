var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { Box, IconButton, makeStyles, TableCell, } from '@material-ui/core';
import clsx from 'clsx';
import { EditableNameDisplay } from 'documentLabeler/components/tableLabeler/editableNameDisplay/EditableNameDisplay';
import { TruncatableTypography } from 'common/display/TruncatableTypography/TruncatableTypography';
import { Edit } from '@material-ui/icons';
var MAX_CELL_CHAR_LIMIT = 100;
var CELL_VALUE = 'Cell Value';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        borderRight: "1px solid ".concat(theme.palette.divider),
        '&.Header': {
            backgroundColor: theme.palette.background.default,
        },
        '&.Active': {
            // Append the 1A to make background color only 10% opacity
            backgroundColor: "".concat(theme.palette.primary.main, "1A"),
        },
        '&.MinWidth': {
            minWidth: theme.spacing(25),
        },
    },
    EditContainer: {
        backgroundColor: theme.palette.background.default,
    },
    Content: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(1),
    },
    Text: {
        height: theme.spacing(3),
        whiteSpace: 'nowrap',
    },
    IconButton: {
        padding: 0,
    },
    Disabled: {
        color: theme.palette.text.disabled,
    },
    InvisibleSpacing: {
        opacity: '0%',
    },
}); });
/**
 * Component responsible for:
 * 1. Rendering Column Headers in the Table Labeler
 * 2. Rendering Table Cells in the Table Labeler
 * @param Props
 */
export var DocumentLabelerTableCell = function (_a) {
    var _b;
    var textValue = _a.textValue, placeholder = _a.placeholder, _c = _a.header, header = _c === void 0 ? false : _c, _d = _a.displayActions, displayActions = _d === void 0 ? false : _d, _e = _a.isActive, isActive = _e === void 0 ? false : _e, _f = _a.showEdit, showEdit = _f === void 0 ? false : _f, _g = _a.actionOnly, actionOnly = _g === void 0 ? false : _g, onClick = _a.onClick, onSaveValue = _a.onSaveValue;
    var classes = useStyles();
    // state variable used to conditionally render either
    // the display row or the editable name display
    var _h = useState(showEdit), editingValue = _h[0], setEditingValue = _h[1];
    var inputRef = useRef(null);
    useEffect(function () {
        var _a;
        if (editingValue) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [editingValue, inputRef.current]);
    var shouldDisplayPlaceholder = !textValue && isActive;
    var needsInvisibleSpacing = !textValue && !isActive;
    var handleEditSubmit = function (name) {
        onSaveValue === null || onSaveValue === void 0 ? void 0 : onSaveValue(name);
        setEditingValue(false);
    };
    return (_jsx(TableCell, __assign({ className: clsx(classes.Root, {
            Header: header,
            Active: isActive,
            MinWidth: !actionOnly,
        }), onClick: onClick }, { children: _jsx(Box, __assign({ className: classes.Content }, { children: editingValue ? (_jsx(EditableNameDisplay, { name: textValue, onSave: handleEditSubmit, label: CELL_VALUE, inputRef: inputRef })) : (_jsxs(_Fragment, { children: [_jsx(TruncatableTypography, { typographyProps: {
                            variant: header ? 'subtitle1' : 'body2',
                            className: clsx(classes.Text, (_b = {},
                                _b[classes.Disabled] = shouldDisplayPlaceholder,
                                _b[classes.InvisibleSpacing] = needsInvisibleSpacing,
                                _b)),
                        }, expandable: true, maxCharLength: MAX_CELL_CHAR_LIMIT, paragraph: false, value: shouldDisplayPlaceholder
                            ? placeholder
                            : textValue
                                ? textValue
                                : // the period will not be visible to the end user
                                    // since the opacity is 0, but it resolves spacing issues
                                    '.' }), displayActions && (_jsx(IconButton, __assign({ className: classes.IconButton, onClick: function () { return setEditingValue(true); } }, { children: _jsx(Edit, {}) })))] })) })) })));
};
