import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import { CustomerPolicyModel } from "../../models/CustomerPolicyModel";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import UploadIcon from '@mui/icons-material/Upload';
import GetAppIcon from '@mui/icons-material/GetApp';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { margin } from "@mui/system";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import moment from "moment";
import { apiClient } from "../../helper/api";

interface Iprops {
    CustomerPolicyModelCL: CustomerPolicyModel[],
    fetchData: () => void
}

export const CustomerPolicyList: React.FC<Iprops> = ({ CustomerPolicyModelCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;

    const handleCheckStatus = (id: number) => {
        setloading(true);
        //call api to save data
        apiClient("/CustomerPolicy/CheckCustomerPolicyDocumentStatus?customerpolicyid=" + `${id}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((res) => {
                if (!res) return
                // Do something with the response
                fetchData();

                alert(res.data)
            }).finally(() => {
                setloading(false);
            });

    };

    const handleGetResponse = (id: number) => {
        setloading(true);
        //call api to save data
        apiClient("/CustomerPolicy/GetCustomerPolicyResponse?customerpolicyid=" + `${id}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((res) => {
                if (!res) return
                // Do something with the response
                fetchData();

                alert(res.data)
            }).finally(() => {
                setloading(false);

            });

    };



    function EditToolbar() {

        return (
            
            <div className="col-lg-12" style={{ textAlign: "right" }}>
            <Box
                sx={{

                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
              
                <div className="col-log-4" style={{ textAlign: "center" }}>
                        <span className="card-title" > <GridToolbarFilterButton /></span>
                    </div>

            </Box>
        </div>
            

        );

    }


    const columns: GridColDef[] = [

        { field: 'policyNumber', headerName: 'Policy Number', flex: 1 , headerAlign: 'center',  align: 'center'},
        { field: 'policyStatus', type: 'string', headerName: 'Policy Status', flex: 1, headerAlign: 'center',  align: 'center' },
        { field: 'isSubmitted', type: 'boolean', headerName: 'Is Submitted', flex: 0.5 , headerAlign: 'center',  align: 'center'},
        { field: 'isProcessed', type: 'boolean', headerName: 'Is Processed', flex: 0.5 },
        { field: 'isDuplicate', type: 'boolean', headerName: 'Duplicate', flex: 0.5 },
        {
            field: 'dateCreated', type: 'date', headerName: 'Date Submitted', flex: 0.5, headerAlign: 'center',  align: 'center', renderCell: (params) =>
            (
                <div>
                     {params.row.dateCreated ? moment(params.row.dateCreated).format("MM-DD-YYYY") : ""}
                    {/* {params.row.dateCreated?.toString().split('T')[0]} */}
                </div>

            )
        },


        // {
        //     field: 'status', flex: 1, headerName: "Check Upload Status", renderCell: (params) => (<div>

        //         <IconButton
        //             className="btn btn-primary action-btn"

        //             onClick={() => { handleCheckStatus(params.row.customerPolicyId) }}
        //         >
        //             <UploadIcon />
        //         </IconButton >


        //     </div>)
        // },
        // {
        //     field: 'Response', flex: 1, headerName: "Response Status", renderCell: (params) => (<div>

        //         <IconButton
        //             className="btn btn-primary action-btn"

        //             onClick={() => { handleGetResponse(params.row.customerPolicyId) }}
        //         >
        //             <GetAppIcon />
        //         </IconButton >


        //     </div>)
        // },

        {
            field: 'policyId', flex: 0.5, headerName: "Check Policy", headerAlign: 'center',  align: 'center', renderCell: (params) => (<div>
                <Link href={'/Transaction/' + params.row.policyId} color="#1976D2">
                    {
                        params.row.isProcessed ? (<IconButton
                            className="btn btn-primary action-btn"

                        >
                            <ArrowRightAltIcon />
                        </IconButton>)
                            : ""}


                </Link>



            </div>)
        },
        {
            field: 'uploadodcs', flex: 0.7, headerName: "Re-Submit Documents",  align: 'center', renderCell: (params) => (<div>
                <Link href={'/ResubmitCustomerPolicyDocs/' + params.row.customerPolicyId} color="#1976D2">

                    <IconButton
                        className="btn btn-primary action-btn"

                    >
                        <UploadFileIcon />
                    </IconButton>



                </Link>



            </div>)
        }

    ];

    return (

        <div style={{ height: '75vh', width: '100%' }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DataGrid
                getRowId={(r) => r.customerPolicyId}
                rows={CustomerPolicyModelCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px",




                    }
                }
            />
        </div>


    )
}