import React, { useEffect, useRef, useState } from "react";
import { RiExpandUpDownLine } from "react-icons/ri";
import { LinearProgress, } from "@mui/material";
import useAuth from "../../context/AuthProvider";
import { FMFilters, TaxTransactionReportModel, ReconciliationTransactionReportModel_Resp, ReconciliationTransactionReportModel } from "../../models/TaxTransactionReportModel";
import moment from "moment";
import { DataGridPro, GridColDef, GridInputSelectionModel, GridRowId, GridRowModesModel, GridSelectionModel, GridSortModel } from "@mui/x-data-grid-pro";
import formatPrice from "../../helper/currencyFormatter";
import { getRowsPerPageOptions } from "../AddEdit/CustomFunctions";
import { ReconTabs } from "../../Pages/ReconciliationQueue";
import { DataGrid, GridFilterModel } from "@mui/x-data-grid";

interface Iprops {
    ReportModuleCL: ReconciliationTransactionReportModel_Resp;
    // fetchDataMain: (
    //     action: string,
    //     pageSize: number,
    //     currentPage: number,
    // ) => void;

    fetchDataMain: (
        pageSize: number,
        currentPage: number,
        field: string,
        sort?: string
    ) => void;
    selectedButton: number
    setSelectedRows: React.Dispatch<React.SetStateAction<ReconciliationTransactionReportModel[] | null>>
    selectedRows: ReconciliationTransactionReportModel[] | null
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    _currentPage: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    _pageSize: number
    debouncedOnFilterChange: ((filterModel: GridFilterModel, tabValue: number, pageSize: number, pageNumber: number, field: string, sort?: string) => void)
    queryOptions: FMFilters | undefined
    fetchDataForFilter: (action: string, newValue: number, pageSize: number, pageNumber: number, newFilters?: FMFilters, field?: string, sort?: string ) => void;
}

export const ReconciliationOpenList: React.FC<Iprops> = ({
    ReportModuleCL,
    fetchDataMain,
    selectedButton,
    setSelectedRows,
    selectedRows,
    setCurrentPage,
    _currentPage,
    setPageSize,
    _pageSize,
    debouncedOnFilterChange,
    queryOptions,
    fetchDataForFilter
}) => {

   // const [_currentPage, setCurrentPage] = React.useState(1);
   // const [_pageSize, setPageSize] = React.useState(50);
    const { user } = useAuth();
    const [loading, setloading] = React.useState(false);

    const columns: GridColDef[] = [
        {
            field: "client", headerName: "Client", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },
        {
            field: "invoiceNumber", headerName: "InvoiceNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },

        {
            field: "insuredName", headerName: "InsuredName", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },
        {
            field: "policyNumber", headerName: "PolicyNumber", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },

        {
            field: "homeState", headerName: "HomeState", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 2,
        },

        {
            field: "taxType", headerName: "TaxType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },


        {
            field: "transactionType", headerName: "TransactionType", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },
        // {
        //     field: "invDate", headerName: "InvoiceDate", type: "date", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
        //         <div>
        //             <span className="table-card-title" >{params.colDef.headerName}</span>
        //             <RiExpandUpDownLine />
        //         </div>
        //     ), valueGetter: (params) =>
        //     (
        //         new Date(params.row.invDate)
        //     ),
        //     renderCell: (params) =>
        //     (
        //         <div>
        //             {(params.row.invDate != null && params.row.invDate != '') ? moment(params.row.invDate).format("MM/DD/YYYY") : ""}
        //         </div>
        //     ),
        //     align: "center", flex: 3,
        // },
        {
            field: "invDate",
            headerName: "InvoiceDate",
            type: "date",
            headerAlign: "center",
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            valueGetter: (params) => (
                params.row.invDate ? new Date(params.row.invDate) : null
            ),
            renderCell: (params) => {
                if (!params.value) return ""; // Return an empty string if no date value is present
        
                const date = new Date(params.value);
        
                // Check for the "1900-01-01" placeholder date or other invalid dates
                if (date.getFullYear() === 1900 && date.getMonth() === 0 && date.getDate() === 1) {
                    return ""; // Return empty if it matches the 1900 placeholder
                }
        
                // Format the date as MM/DD/YYYY
                const formattedDate = date.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric"
                });
        
                return formattedDate;
            },
            align: "center",
            flex: 3,
        },
        {
            field: 'policyEffDate',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            type: 'date',
            headerName: 'PolicyEffectiveDate',
            flex: 4,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) => (
                params.row.policyEffDate ? new Date(params.row.policyEffDate) : null
            ),
            renderCell: (params) => {
                if (!params.value) return ""; // If there's no value, return an empty string
        
                const date = new Date(params.value);
        
                // Check if the date is the placeholder "1900-01-01"
                if (date.getFullYear() === 1900 && date.getMonth() === 0 && date.getDate() === 1) {
                    return ""; // Return an empty string for the placeholder date
                }
        
                // Format the date as MM/DD/YYYY
                const formattedDate = date.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric"
                });
        
                return formattedDate;
            }
        }
        ,

        {
            field: "policyExpDate",
            headerName: "PolicyExpirationDate",
            type: "date",
            headerAlign: "center",
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center",
            flex: 4,
            valueGetter: (params) => (
                params.row.policyExpDate ? new Date(params.row.policyExpDate) : null
            ),
            renderCell: (params) => {
                if (!params.value) return ""; // Return an empty string if no date value is present
        
                const date = new Date(params.value);
        
                // Check for the placeholder date "1900-01-01"
                if (date.getFullYear() === 1900 && date.getMonth() === 0 && date.getDate() === 1) {
                    return ""; // Return an empty string if it's the placeholder date
                }
        
                // Format the date as MM/DD/YYYY
                const formattedDate = date.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric"
                });
        
                return formattedDate;
            }
        }
        ,

        {
            field: "grossPremium", 
            headerName: "GrossPremium", 
            type: "string", 
            headerAlign: "center", 
            hideSortIcons: true, 
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title word-wrap-1 p-0">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            renderCell: (params) => {
                let grossPremium = params.row.grossPremium;
                
                // Trim any spaces and remove the dollar sign and commas
                let cleanedValue = grossPremium.replace(/[,\$]/g, "").trim();
                
                // If the value is in parentheses, it's negative, so handle that
                if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
                    cleanedValue = "-" + cleanedValue.slice(1, -1); // Remove parentheses and add negative sign
                }
                
                // Convert to number
                const numericValue = parseFloat(cleanedValue);
                
                // If numericValue is a valid number, format it; otherwise, return the original string
                return isNaN(numericValue) ? grossPremium : `${formatPrice(numericValue.toString())}`;
            },
            align: "center", 
            flex: 3,
        },
        {
            field: "taxAmt", 
            headerName: "TaxAmount", 
            type: "string", 
            headerAlign: "center", 
            hideSortIcons: true, 
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            renderCell: (params) => {
                let taxAmt = params.row.taxAmt;
                
                // Trim any spaces and remove the dollar sign and commas
                let cleanedValue = taxAmt.replace(/[,\$]/g, "").trim();
                
                // Check if the value is in parentheses, treat it as negative if necessary
                if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
                    cleanedValue = "-" + cleanedValue.slice(1, -1);  // Remove parentheses and add negative sign
                }
                
                // Convert to number
                const numericValue = parseFloat(cleanedValue);
                
                // If numericValue is a valid number, format it with a dollar sign; otherwise, return the original string
                return isNaN(numericValue) ? taxAmt : `${formatPrice(numericValue.toString())}`;
            },
            align: "center", 
            flex: 3,
        },
        {
            field: "dateFiled",
            headerName: "DateFiled",
            type: "date",
            headerAlign: "center",
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center",
            flex: 3,
            renderCell: (params) => {
                if (!params.value) return ""; // If there's no value, return an empty string
        
                const date = new Date(params.value);
        
                // Check if the date is "1900-01-01T00:00:00" or a similar representation
                if (params.value === "1900-01-01T00:00:00" || date.getFullYear() === 1900) {
                    return ""; // Return an empty string or null for the placeholder date
                }
        
                // Format the date as MM/DD/YYYY
                const formattedDate = date.toLocaleDateString("en-US", {
                    month: "2-digit",
                    day: "2-digit",
                    year: "numeric"
                });
        
                return formattedDate;
            }
        },
        

        {
            field: "status", headerName: "Status", type: "string", headerAlign: "center", hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: "center", flex: 3,
        },


    ];

    const sortModelRef = useRef<GridSortModel>([]);
    const onRowsSelectionHandler = (ids: GridSelectionModel | undefined) => {
        const selectedIDs = new Set(ids);

        const uniqueRowsIds = new Set<GridRowId>();
        const selectedRowData = ReportModuleCL.getData_Resp.filter(
            (row: ReconciliationTransactionReportModel) => {
                if (selectedIDs.has(row?.rowNum ?? "") && !uniqueRowsIds.has(row?.rowNum ?? "")) {
                    uniqueRowsIds.add(row?.rowNum ?? "");
                    return true;
                }
                return false;
            }
        );
        setSelectedRows(selectedRowData); // Store full row data
    };

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        console.log("ReconTabs.Open",ReconTabs.Open)
        console.log("filterModel",filterModel)
        debouncedOnFilterChange(filterModel, ReconTabs.Open, _pageSize, _currentPage, field, sort);
    }, []);


    const handleNextPreviousPage = (newPage: number ) => {
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        setCurrentPage(newPage);
        fetchDataForFilter("getReconQueueOpenFilterData", 1, _pageSize, newPage, queryOptions, field, sort);
        // fetchDataMain(_pageSize, newPage, field, sort);
    };

    const handlePageSize = (newPageSize: number) => {
        var field = sortModelRef.current[0]?.field ?? '';
        var sort = sortModelRef.current[0]?.sort ?? '';
        setPageSize(newPageSize);
        fetchDataForFilter("getReconQueueOpenFilterData", 1, newPageSize, _currentPage, queryOptions, field, sort);
        //fetchDataMain(newPageSize, _currentPage, field, sort);
    };

    const handleSortModelChange = (sortModel: GridSortModel) => {
        sortModelRef.current = sortModel;
        console.log("sortModelRef",sortModelRef);
        if (queryOptions?.items && queryOptions.items.length > 0) {
            fetchDataForFilter("getReconQueueOpenFilterData", 1, _pageSize, _currentPage, queryOptions, sortModel[0]?.field ?? '', sortModel[0]?.sort ?? '');
        } else {
            fetchDataMain(
                _pageSize,
                _currentPage,
                sortModel[0]?.field ?? '',
                sortModel[0]?.sort ?? '' 
            );
        }
    }

    function EditToolbar() {
        return (
            <React.Fragment>
                <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
                    {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
                </div>

            </React.Fragment>
        )
    }
    // const validRows = ReportModuleCL?.getReconciliationTransactionReport_Respdata?.filter(
    //     (row) => row.rowNum !== undefined
    // );

    return (
        <React.Fragment>
            <div style={{ height: "100%", overflowX: "scroll" }}>
                <DataGridPro
                    getRowId={(r) => r?.rowNum}
                    rows={ReportModuleCL.getData_Resp}
                    //rows={validRows} // Only valid rows
                    columns={columns}
                    pageSize={_pageSize}
                    pagination
                    rowCount={ReportModuleCL.totalCount}
                    rowsPerPageOptions={getRowsPerPageOptions(ReportModuleCL?.totalCount, _pageSize)}

                    // rowsPerPageOptions={[25, 50, 100, ReportModuleCL?.totalCount].sort((a, b) => a - b)}
                    paginationMode="server"
                    onSortModelChange={handleSortModelChange}
                    sortModel={sortModelRef.current}
                    filterMode="server"
                    onFilterModelChange={onFilterChange}
                    sortingOrder={['desc', 'asc']}
                    onPageChange={(newPage) => handleNextPreviousPage(newPage + 1)}
                    onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}
                    style={{ textAlign: "center" }}

                    checkboxSelection={
                        user?.userRoles.split(",").includes("Super Admin") ||
                            user?.userRoles.split(",").includes("MISC State Reporter")
                            ? true : false
                    }
                    onSelectionModelChange={user?.userRoles.split(",").includes("Super Admin") ||
                        user?.userRoles.split(",").includes("MISC State Reporter") ? (newRowSelectionModel) => {
                            onRowsSelectionHandler(newRowSelectionModel);
                        } : undefined}
                    selectionModel={selectedRows?.map((row) => row.rowNum) as GridRowId[]}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                    sx={{
                        border: "0px",
                        fontSize: "16px",
                        '& .MuiDataGrid-columnHeaderTitle': {
                            overflow: 'visible',
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            textOverflow: 'clip',
                        },
                        '& .MuiDataGrid-cell': {
                            overflow: 'visible',
                            whiteSpace: 'normal',
                            lineHeight: 'normal',
                            wordWrap: 'break-word',
                            textOverflow: 'clip',
                        },
                        ".custom-cell": {
                            button: {
                                padding: 0,
                                width: '100%',
                                height: '100%',
                                textAlign: 'center',
                                WebkitLineClamp: 2,
                                lineClamp: 2,
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                textOverflow: 'ellipsis',
                            }
                        },
                    }}
                    experimentalFeatures={{ newEditingApi: true }}
                    // columnVisibilityModel={{

                    //     paymentDate: user?.userRoles.split(",").includes("Super Admin")
                    //         ? true
                    //         : false,
                    //     paymentAmount: user?.userRoles.split(",").includes("Super Admin")
                    //         ? true
                    //         : false,
                    //     lineOfBusiness: user?.userRoles.split(",").includes("Super Admin")
                    //         ? true
                    //         : false,
                    // }}
                />
            </div>

        </React.Fragment>
    );
};
