import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import useAuth from "../../context/AuthProvider";
import GetAppIcon from '@mui/icons-material/GetApp';
import { StateStampListModel, StateStampSPModel } from "../../models/StateStampListModel";
import { getStateRuleMatrixModel, StateRuleMatrixModel } from "../../models/StateRuleMatrixModel";
import { AddEditStateRuleMatrix } from "../AddEdit/AddEditStateRuleMatrix";
import moment from "moment";
import { InsuredModel } from "../../models/InsuredsModel";
import { AddEditInsured } from "../AddEdit/AddEditInsured";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";



interface Iprops {
    InsuredList: InsuredModel[],
    fetchData: () => void
}

export const InsuredList: React.FC<Iprops> = ({ InsuredList, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;

    const initialLBData: InsuredModel = {
        insuredId: 0,
        insuredName: "",
        insuredAddressLine1: "",
        insuredCity: "",
        stateId: 0,
        insuredZipCode: 0,
        createdDate: new Date
    }

    const [Open, setOpen] = React.useState(false);
    const [Formdata, setFormData] = React.useState(initialLBData);

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };
    const handleClickOpen = () => {
        setFormData(initialLBData);
        setOpen(true);
    };

    const handleEdit = (ILobData: InsuredModel) => {
        setFormData(ILobData);
        setOpen(true);
    };
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >

                {/* <Button
                    color="primary"
                    onClick={handleClickOpen}
                    variant="contained"

                >
                    Add New Insured
                </Button> */}
                <div className="col" style={{ textAlign: "center" }}>
                        <span className="card-title" > <GridToolbarFilterButton /></span>
                    </div>

            </Box>

        );

    }


    const columns: GridColDef[] = [


        { field: 'insuredName', type: 'text', headerName: 'Name', flex: 1 , headerAlign: 'center', align: 'center' },


        { field: 'insuredAddressLine1', type: 'text', headerName: 'Address', flex: 1 , headerAlign: 'center', align: 'center' },



        { field: 'insuredCity', type: 'text', headerName: 'City', flex: 1 , headerAlign: 'center', align: 'center' },
        { field: 'insuredZipCode', type: 'text', headerName: 'Zip Code', flex: 0.4 , headerAlign: 'center', align: 'center' },
        // { field: 'effectiveDate', type: 'date', headerName: 'value' , flex: 1 },


        {
            field: 'createdDate', headerName: 'Date Added', type: 'text', flex: 0.5, headerAlign: 'center', align: 'center' , renderCell: (params) =>
            (
                <div>

                    {/* {params.row?.effectiveDate ? moment(params.row?.effectiveDate).format("MM/DD/YYYY") : ""} */}
                    {params.row.createdDate?.toString().split('T')[0]}
                </div>

            )
        },
        {
            field: 'insuredId', flex: 0.5, headerName: "View Submissions", headerAlign: 'center', align: 'center' , renderCell: (params) => (<div>
                <Link href={'/InsuredSubmissions/' + params.row.insuredId} color="#1976D2">
                    {
                        <IconButton
                            className="btn btn-primary action-btn"

                        >
                            <ArrowRightAltIcon />
                        </IconButton>
                    }


                </Link>



            </div>)
        },

        // {
        //     field: 'insuredId', flex: 1, headerName: "Edit", renderCell: (params) => (<div>

        //         <IconButton  
        //            className="btn btn-primary action-btn"
        //             onClick={() => { handleEdit(params.row) }}

        //         >
        //           Edit <i className="fa-solid fa-right-long"></i>
        //            </IconButton>

        //     </div>)
        // },


    ];

    return (

        <div style={{ height: '75vh', width: '100%' }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <div className="row col-lg-12">
                <div className="col-lg-4 d-flex">
                    <div style={{marginRight:"2px"}}>
                        <Link href="/">
                            <Button type="button" title="Home"
                                className="btn action-btn" ><HomeIcon />
                            </Button>
                        </Link>
                    </div>
                    <div>
                        <Button

                            className=" btn action-btn"
                            onClick={goBack}
                            title="Back"
                        >
                            <ArrowBackIosIcon />
                        </Button>
                    </div>
                </div>
                <div className="col-lg-4" style={{ textAlign: "center" }}>
                    <span className="card-title">Insureds</span>
                </div>

            </div>
            <DataGrid
                getRowId={(r) => r.insuredId}
                rows={InsuredList}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: EditToolbar,    
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif",
                        border: "0px",
                        fontSize: "16px"


                    }
                }
            />
            <AddEditInsured handleClose={handleClose} Open={Open} InsuredCL={Formdata} handleSubmit={handleSubmit} initValues={initialLBData} />

        </div>



    )
}