export enum PolicyStatuses {
    MissingDocuments = 'Missing Documents',
    PendingDocuments = 'Pending Documents',
    NotStarted = 'Not Started',
    Flagged = 'Flagged',
    WaitingOnClient = 'Waiting on Client',
    ReadyToFile = 'Ready to File',
    PendingAutofile = 'Pending Autofile',
    PendingStateReview = 'Pending state review',
    StateFlagged = 'State Flagged',
    Filed = 'Filed',
    IsClosed = 'IsClosed' ,
    FiledOnBinder = 'Filed - Binder'
}

export function isValid(value: any): boolean {
    return value !== '' && value !== undefined && value !== null && value !== 0;
}

