import *  as yup from 'yup'

export interface StatesZeroFilingRuleModel
{
    stateId: string,
    name: string,
    postalAbbv: string,
    standardAbbv: string,
    isElectronicSignature: boolean,
    isZeroFilingState: boolean
}
export const StatesZeroFilingRuleModel_VS = yup.object({
    stateId: yup.number().required("Please select a state").typeError('Invalid Value'),
    isElectronicSignature: yup.bool().required("please select is Electronic Signature").typeError('Invalid Value'),
    isZeroFilingState: yup.bool().required("please select State").typeError('Invalid Value'),
    postalAbbv: yup.string().required("please enter Postal Abbv").trim().typeError('Invalid value'),
    standardAbbv: yup.string().required("please enter Standard Abbv").trim().typeError('Invalid value'),
    
})
