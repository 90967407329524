import { useEffect, useRef, useState } from "react"

import { OTRModuleList } from "../../components/Lists/OTRModuleList"
import { OTRModuleModel, OTRModuleModelDTO, OTRModuleModel_Resp, PartnerDataType } from "../../models/OTRModuleModel"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"

import {
  Box,
  FormControl,
  Grid,
  LinearProgress,
} from "@mui/material"
import useAuth from "../../context/AuthProvider"
import { config } from "../../Constants"
import { compactString, convertDateFromXlsx, isEmptyOrUndefined, isInvoiceNumber, isNanOrZero, readXlsxFile, toCurrency, writeXlsxFile } from "../../helper/utils"
import moment from "moment"
import { bool, boolean, number } from "yup"
import Swal from "sweetalert2"
import { FastField } from "formik"
import { GridSelectionModel } from "@mui/x-data-grid-pro"
import {
  columnList,
  excelExportTypes,
  // excelItems,
  similarList,
  taxTypeList,
  transactionTypeList
} from "./constants"
import { debug } from "console"
import ImportStatusDialog from "../../components/Elements/ImportStatusPopup"
import { CorrectedformatCurrency } from "../../helper/currencyFormatter"
import { apiClient } from "../../helper/api"

export const OpenTaxReport = () => {
  const { user } = useAuth()
  const token = user?.token
  const [loading, setloading] = useState(false)

  const initValue: OTRModuleModel_Resp = {
    items: [],
    totalCount: 0,
    pageCount: 0,
    pageSize: 25,
    currentPage: 0,
  }
  const [dataOTR, setDataOTR] = useState<OTRModuleModel[]>(initValue.items)
  const [currentPage, setCurrentPage] = useState(initValue.currentPage)
  const [pageSize, setPageSize] = useState(initValue.pageSize)
  const [selectedRows, setSelectedRows] = useState<OTRModuleModel[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [status, setstatus] = useState(false);
  const [openImportStatusDialog, setOpenImportStatusDialog] = useState(false);
  const isImportCompleted = useRef<string>("");

  async function checkProcessStatusOriginal() {
    const intervalTime = 5000; // 5 seconds
    const maxTime = 30000; // 30 seconds
    const startTime = Date.now();
    // Wait for 2 seconds before continuing
    await new Promise(resolve => setTimeout(resolve, 5000));

    const intervalId = setInterval(async () => {
      try {
        // console.log("Statuses: ", isImportCompleted);
        if (isImportCompleted.current === "In Progress") {
          CheckImportStatus();
        }

        if (isImportCompleted.current === "Completed" || isImportCompleted.current === "Not Importing") {
          // setStatus("completed");
          // console.log("inside the completed");
          isImportCompleted.current = 'Completed';
          clearInterval(intervalId);
        } else if (Date.now() - startTime >= maxTime) {
          // setStatus("timeout");
          // console.log("inside the 30 sec timeout");
          isImportCompleted.current = 'Timeout';
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching process status:", error);
      }
    }, intervalTime);

    // Stop checking after 30 seconds to avoid indefinite looping
    if (isImportCompleted.current === 'In Progress') {
      setTimeout(() => {
        if (Date.now() - startTime >= maxTime) {
          clearInterval(intervalId);
          // console.log("inside the final timeout");
          isImportCompleted.current = 'Timeout';
          // setStatus("timeout");
        }
      }, maxTime);
    }
  }

  async function checkProcessStatus() {
    let tempStatus = "In Progress";
    const intervalTime = 5000; // 5 seconds
    const maxTime = 40000; // 30 seconds
    const startTime = Date.now();

    // Wait for 2 seconds before continuing
    // await new Promise(resolve => setTimeout(resolve, 5000));

    const intervalId = setInterval(async () => {
      try {
        if (Date.now() - startTime >= maxTime) {
          // console.log("Process timeout after 30 seconds.");
          await new Promise(resolve => setTimeout(resolve, 3000));
          isImportCompleted.current = "Timeout";
          tempStatus = "Timeout";
          clearInterval(intervalId);
          return; // Exit the function early to avoid further processing
        }
        // console.log("Statuses: ", isImportCompleted);

        // Assuming CheckImportStatus is updating isImportCompleted
        if (isImportCompleted.current === "In Progress") {
          CheckImportStatus();
          getDataOTR();
        }
        if (isImportCompleted.current === "Completed" || isImportCompleted.current === "Not Importing") {
          // console.log("Process completed.");
          isImportCompleted.current = "Completed";
          tempStatus = "Completed";
          await new Promise(resolve => setTimeout(resolve, 2000));
          handleCloseImportStatusDialog();
          clearInterval(intervalId);
        }
        if (isImportCompleted.current === "") {
          // console.log("Cleared");
          getDataOTR();
          clearInterval(intervalId);
        }
        // else if (Date.now() - startTime >= maxTime) {
        //   console.log("Process timeout after 30 seconds.");
        //   isImportCompleted.current = "Timeout";
        //   tempStatus = "Timeout";
        //   clearInterval(intervalId);
        //   return;
        // }
      } catch (error) {
        console.error("Error fetching process status:", error);
      }
    }, intervalTime);
  }


  const fetchOTRData = async ({ carrierId, pageSize, currentPage }: { carrierId: string, pageSize: number, currentPage: number }) => {
    setloading(true)

    const res = await apiClient(`/OpenTaxReport/getOpenTaxReport?pageSize=${0}&&currentPage=${0}`, {
      method: "GET",
      headers: new Headers({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }),
    }).finally(() => {
      setloading(false)
    })

    // Do something with the response
    if (!res?.data?.items) return
    const updates = res.data.items.map((item: OTRModuleModel, index: number) => ({
      rowNum: (index + 1).toString(),
      ...item,
    }))
    setDataOTR(updates)

  }
  const updateOTRData = async (items: OTRModuleModel[], action: number, file: File | null = null) => {

    const formData = new FormData();
    formData.append('openTaxReportq', JSON.stringify(items));
    if (file) {
      formData.append('missingFile', file);
    }
    setloading(true)
    const res = await apiClient(`/OpenTaxReport/SaveOrUpdate`, {
      method: "POST",
      headers: new Headers({
        // 'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      }),
      // body: JSON.stringify(items),
      body: formData
    }).finally(() => {
      setloading(false)
    })
    if (!res?.data) return

    if (action === 1) {
      // CheckImportStatus();
      isImportCompleted.current = "In Progress";

      checkProcessStatus();
      // setIsImportCompleted(responseJson.data)
      setOpenImportStatusDialog(true);
    }

  }


  const SendEmail = async (items: OTRModuleModelDTO) => {
      const result = await apiClient(`/OpenTaxReport/SendMissingFieldEmail`, {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(items), // Send as an object with 'field' property
      });

      return result;
  }

  const deleteOTRData = async (items: OTRModuleModel[]) => {
      // for (const item of items) {
      setloading(true);
      await apiClient(`/OpenTaxReport/Delete`, {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(items),
      }).then((response) => {
        if (!response) return
        Swal.fire({
          title: "Data Successfully Deleted",
          text: "",
          icon: "success",
          confirmButtonText: "OK",
        });
        setloading(false)
      })
      // }
  }

  const offSetOTRData = async (items: OTRModuleModel[]) => {
      await apiClient(`/OpenTaxReport/Offset`, {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(items),
      }).then((response) => {
        setloading(false)
      })
  }
  const approveOTRData = async (items: OTRModuleModel[]) => {
      await apiClient(`/OpenTaxReport/Approve`, {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
        body: JSON.stringify(items),
      }).then((response) => {
        if (!response) return
        setloading(false)
      })
  }


  const getDataOTR = async () => {
    fetchOTRData({
      carrierId: "",
      pageSize: 0,
      currentPage,
    })
  }
  const partnerDataDetails: PartnerDataType = {
    customerName: "",
    partnersId: 0
  }
  const [excelFormatList, setExcelFormatList] = useState<PartnerDataType[]>([partnerDataDetails])
  const [excelFormatListName, setExcelFormatListName] = useState<string[]>([])
  const fetchCustomer = async () => {
    return apiClient("/Partners/getPartners", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response
        const update = responseJson.data.map((item: any) => item.customerName)
        setExcelFormatList(responseJson.data);
        setExcelFormatListName(update);
        return update
      })
  };

  // const [taxandFeesList, settaxandFeesList] = useState<string[]>([])
  const fetchTaxandFees = async (items: string) => {
    setloading(true);
      const res = await apiClient(`/OpenTaxReport/GetTaxandFeeNameByState?HomeState=${items}`, {
        method: "POST",
        headers: new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }),
      });

      setloading(false);
      if (!res) return
      const taxAndFeesList = res.data;
      // settaxandFeesList(taxAndFeesList);
      return taxAndFeesList
  };
  const CheckImportStatus = () => {
    apiClient(`/OpenTaxReport/CheckImportStatus`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((responseJson) => {
        if (responseJson?.data != null) {
          isImportCompleted.current = responseJson.data;
          // console.log("Repsone from CheckImportStatus:", isImportCompleted.current);
        }
      })

  };
  const handleCloseImportStatusDialog = () => {
    setOpenImportStatusDialog(false);
    getDataOTR();
    isImportCompleted.current = "";
  };

  useEffect(() => {
    // console.log("inside parent useEffect");
    fetchCustomer()
    getDataOTR()
  }, []);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     CheckImportStatus();
  //   }, 10000);
  //   return () => clearInterval(intervalId);
  // }, [])


  const determineExcel = (xlsHeaders: (string | undefined)[]) => {
    return excelExportTypes[0]
    let excelType = -1
    xlsHeaders.forEach((header) => {
      if (!header) return
      const column = columnList.find(column => column.xlsHeader.map(compactString).includes(compactString(header)))
      if (!column || !column.excelType) return
      if (excelType >= 0 && column.excelType !== excelType) {
        excelType = 0
      } else {
        excelType = column.excelType
      }
    })
    if (excelType < 0) excelType = 0

    const excelItem = excelExportTypes.find(item => item.excelType === excelType) ?? excelExportTypes[0]
    return excelItem
  }

  const handlePageChange = (val: number) => {
    setCurrentPage(val)
    // fetchOTRData('', pageSize, val)
  }
  const handlePageSizeChange = (val: number) => {
    if (val <= 0) return
    setPageSize(val)
    // fetchOTRData('', val, currentPage)
  }
  const formatCurrency = (value: number): string => {
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
  };

  // List of fields that need currency formatting
  const currencyFields = ['taxAmt', 'grossPremium', 'taxableFees', 'taxBalance', 'nonAdmittedPremium', 'nonTaxableFees'];
  const dateInvoiceAddedField = 'dateInvoiceAdded';
  const exportXlsx = async (items: OTRModuleModel[], excelExportType: number) => {
    if (items.length === 0) {
      return
    }
    const excelItem = excelExportTypes.find(x => x.excelType === excelExportType)
    if (!excelItem) return

    const updates1: { [x: string]: any[] } = {}
    // excelItems.forEach(ex => {
    const headerRow = excelItem.columnOrder.map(idx => columnList[idx].xlsHeader[0])
    updates1[excelItem.name] = [headerRow]
    // })

    items.forEach(item => {
      const xlsHeaders: string[] = []
      Object.keys(item).map((field) => {
        if (!item[field as keyof OTRModuleModel]) return
        const column = columnList.find(col => col.field === field)
        if (!column?.xlsHeader) return
        xlsHeaders.push(column.xlsHeader[0])
      })
      // const excelItem = determineExcel(xlsHeaders)
      const update1 = excelItem.columnOrder.map(idx => {
        const column = columnList[idx]
        const field = column.field as keyof OTRModuleModel
        const value = item[field]
        if (value === undefined || value === null) return
        switch (column.inputType) {
          case 'string':
            if (field === dateInvoiceAddedField) {
              return moment(value as string).format('MM-DD-YYYY HH:mm');
            }
            return value.toString()
          case 'date':
            return value ? moment(value as string).format('MM-DD-YYYY HH:mm') : undefined;
          case 'number':
            if (currencyFields.includes(field as string)) {
              return CorrectedformatCurrency(value.toString());
            }
            return +value;
          case 'bool':
            return !!value
          default:
            return value
        }
      })
      updates1[excelItem.name].push(update1)
    })

    // excelItems.forEach(excelItem => {
    if (!updates1[excelItem.name] || updates1[excelItem.name].length <= 1) return
    const timeStamp = moment().format('YYYY.M.D')
    return writeXlsxFile(updates1[excelItem.name], `${timeStamp}_${excelItem.name}.xlsx`)
    // })
  }
  const handleExportXlsx = async (items: OTRModuleModel[]) => {
    // Export as 'OpenTaxReport'
    exportXlsx(items, excelExportTypes[0].excelType)
  }

  const isXlsxHeaderExist = (xlsHeaders: string[], fieldName: string) => {
    const columns = columnList.filter(col => col.field === fieldName)
    if (!columns.length) return false
    const possibleHeaders = (columns.map(c => c.xlsHeader)).flat(1)
    const columnHeader = possibleHeaders.map(compactString).map(compactString)
    const headerIndex = xlsHeaders.findIndex((header: string) => columnHeader.includes(compactString(header)))
    return headerIndex > -1
  }
  //function to determine the transaction type
  const determineTransactionType = (policyNumber: string, endNumber: string | undefined): string => {

    const suffix = policyNumber.split('-').pop();
    if (suffix === undefined) {
      return "Undefined";
    }
    if (endNumber !== undefined && endNumber !== "") {
      return "Endorsement";
    } else if (suffix === "00" || suffix === "01") {
      return "New Business";
    } else if (suffix >= "02") {
      return "Renewal Business";
    } else {
      return "Undefined";
    }
  };

  const handleImportXlsx = async (file: File, client: string) => {
    setloading(true)
    if (status == false) {
      setstatus(true)
    }
    const sheetsData = await readXlsxFile(file)
    if (!sheetsData || Object.keys(sheetsData).every((sheetName: string) => !sheetsData[sheetName] || sheetsData[sheetName].length <= 1)) {
      alert("The file is empty or invalid. Please check the file.");
      setloading(false)
      return;
    }

    const missingHeaderList: string[] = []
    const updateList: OTRModuleModel[] = []

    let missingUpdates: { [key: string]: OTRModuleModel[] } = {
      client_Required: [],
      grossPremium_Required: [],
      SLTax_StampFee_0: [],
      TaxAmt_TaxBase_Required: [],
    }

    for (const sheetName in sheetsData) {
      const sheet = sheetsData[sheetName]
      const xlsHeaders = sheet[0]
      if (!xlsHeaders || xlsHeaders.length < 1) continue
      // _rowNum is a number matched with excel file's row number. (debug purpose)
      xlsHeaders[0] = '_rowNum'

      const content = sheet.slice(1).filter((item: any) => {
        if (!item || item.length <= 1) return false
        return true
      })
      console.log('excel items:', content.length, { content })
      // const excelItem = determineExcel(xlsHeaders)
      let updates: OTRModuleModel[] = []


      // const hasIntrepidClient = content.some((item: any) => item.includes('Intrepid Direct Insurance Agency, LLC' || 'Intrepid Direct Insurance Agency LLC'));
      // if (hasIntrepidClient && !xlsHeaders.includes('taxType')) {
      //   xlsHeaders.push('taxType');
      // }
      xlsHeaders.forEach((header: string, idx: number) => {
        const column = columnList.find(column =>
          column.xlsHeader.map(compactString).includes(compactString(header))
        );
        if (!column && header) {
          missingHeaderList.push(header);
        }
      });

      content.forEach((item: any, index: number) => {
        const isValidItem = item.filter((val: any) => val !== undefined || val === '').length > 5
        if (!isValidItem) return
        var isReturn = 0;

        const update: OTRModuleModel = {}

        update.openTaxReportId = 0
        var taxType: string | undefined;
        // if (hasIntrepidClient && item.includes('Intrepid Direct Insurance Agency, LLC')) {
        //   const coverageIndex = xlsHeaders.indexOf('Coverage');

        //   if (coverageIndex !== -1) {
        //     taxType = item[coverageIndex];
        //   }
        // }
        xlsHeaders.forEach((header: string, idx: number) => {
          const column = columnList.find(column => column.xlsHeader.map(compactString).includes(compactString(header)))
          if (!column) {
            // Ignored when excel header name is not registered (missingHeaders)
            return
          }

          if (column.field === 'taxType') {
            item[idx] = item[idx].split('-').at(-1).trim();
            if (item[idx].trim().toLowerCase() == 'state tax') {
              item[idx] = 'Surplus Lines Tax'
            }
          }
          const field = column.field as keyof OTRModuleModel
          switch (column.inputType) {
            case 'string':
              // if (hasIntrepidClient && field === 'taxType') {
              //   break;
              // }
              update[field] = item[idx]
              if (similarList[field]) {
                const scen = similarList[field].find(scen => scen.possibleValues.map(compactString).includes(compactString(item[idx])))
                update[field] = scen?.value ?? item[idx]?.toString()
              } else {
                update[field] = item[idx]?.toString()
              }
              break;


            case 'date':
              const dVal = new Date(item[idx]).valueOf();
              if (!item[idx]) break;

              let dateString: string | undefined = '';

              if (dVal > new Date('1990/1/1').valueOf() && dVal < new Date('2990/1/1').valueOf()) {
                // Keeping the date in local time without converting to UTC
                const date = new Date(dVal);
                dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T00:00:00`; // Format manually in local time
              } else {
                const parsedDate = convertDateFromXlsx(dVal);
                const date = parsedDate ? new Date(parsedDate) : undefined;
                dateString = date ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}T00:00:00` : undefined; // Format manually
              }

              update[field] = dateString as any;
              break;


            case 'number':
              const nVal = (item[idx] === "" || item[idx] === undefined) ? undefined : (+item[idx])?.toFixed(2).replace(/\.?0+$/, '')
              update[field] = nVal as any
              break;
            case 'bool':
              update[field] = (!!item[idx]) as any
              break;
            default:
              update[field] = item[idx]?.toString()
              break;
          }
        })

        // update['filerNotes'] = ''
        update['dateInvoiceAdded'] = new Date().toISOString()

        // If "transEffDate" header not exist, update it from "effDate"
        if (!isXlsxHeaderExist(xlsHeaders, 'transEffDate') && isXlsxHeaderExist(xlsHeaders, 'effDate')) {
          update['transEffDate'] = update['effDate']
          // update['clientDiv'] = 'Vally View'
        }
        // If "policyEffDate" header not exist, update it from "effDate"
        if (!isXlsxHeaderExist(xlsHeaders, 'policyEffDate') && isXlsxHeaderExist(xlsHeaders, 'effDate')) {
          update['policyEffDate'] = update['effDate']
          // update['clientDiv'] = 'Vally View'
        }
        // If "client" header not exist, update value
        if (isEmptyOrUndefined(update['client'])) {
          update['client'] = client
        } else {
          // const isExistClient = excelFormatList.findIndex(c => c.customerName === update['client'])
          if ((update['client'] ?? '').trim().toString().length > 10) {
            update['client'] = (update['client'] ?? '').toString().trim().slice(0, -6).trim()
          }
          const isExistClient = excelFormatList.findIndex(c =>
            c.customerName.toLowerCase().includes((update['client'] ?? '').toString().toLowerCase())
          );
          if (isExistClient == -1) {
            missingUpdates.client_Required.push({ ...update, incorrectField: 'client is incorrect' })
            // return
            isReturn++;
          } else {
            update['client'] = excelFormatList[isExistClient].customerName;
          }
        }
        // If "effDate" exist, update "clientDiv" value as "Vally View"
        // const Client = update['client']
        // if (!isXlsxHeaderExist(xlsHeaders, 'policyEffDate') && Client === 'Cardigan General Insurance Services' || Client === 'Cardigan General Insurance Services, LLC') {
        //   update['clientDiv'] = 'Vally View'
        // }


        const singlePartnerData = excelFormatList.find((p: PartnerDataType) => p.customerName === client);
        update['partnersId'] = singlePartnerData?.partnersId;

        // If "grossPremium" is null or 0, ignore update
        if (isNanOrZero(update['grossPremium'])) {
          missingUpdates.grossPremium_Required.push({ ...update, incorrectField: 'grossPremium is required' })
          // return
          isReturn++;
        }


        if (isXlsxHeaderExist(xlsHeaders, 'taxSL') && isXlsxHeaderExist(xlsHeaders, 'stampFee')) {
          // If "taxSL" and "stampFee" fields exists
          if (!isNanOrZero(update['taxSL'])) {
            // const newUpdate = JSON.parse(JSON.stringify(update));
            update['taxType'] = 'Surplus Lines Tax';
            // update['taxAmt'] = newUpdate['taxSL'];
            // updates = [...updates, newUpdate];
          }

          if (!isNanOrZero(update['stampFee'])) {
            // const newUpdate = JSON.parse(JSON.stringify(update));
            update['taxType'] = 'Stamping Fee';
            // update['taxAmt'] = newUpdate['stampFee'];
            // updates = [...updates, newUpdate];
          }
          // Ignore when taxSL or stampFee = 0
          if ((isNanOrZero(update['taxSL'])) || (isNanOrZero(update['stampFee']))) {
            missingUpdates.SLTax_StampFee_0.push({ ...update, incorrectField: `"S/L Tax" or "Stamp Fee" should not be 0` })
            // return
            isReturn++;
          }
        }
        else if (isXlsxHeaderExist(xlsHeaders, 'taxAmt') && !isNanOrZero(update['taxAmt'])) {
          if (isReturn > 0) {
            return
          }
          // If "taxAmt" field exists
          if (isReturn > 0) {
            return
          }
          updates = [...updates, update]
          return
        } else {
          // Ignore when taxAmt = 0
          missingUpdates.TaxAmt_TaxBase_Required.push({ ...update, incorrectField: `"Tax Amount" or "TaxAmt" or "Tax Base" is required` })
          // return
          isReturn++;
        }
      })

      const missingCounts = Object.values(missingUpdates).reduce((sum, arr) => sum + arr.length, 0)
      console.log('missing items:', missingCounts, { missingUpdates })

      let index = 0;
      while (index < updates.length) {
        const element = updates[index];
        const transactionType = element['transactionType'];
        const transIndex = transactionTypeList.findIndex(tr => compactString(tr.value) === compactString(transactionType));
        if (transIndex < 0) {
          updates[index].transactionType = determineTransactionType(element.policyNumber ?? '', element.endNumber);
        }
        if (compactString(element['carrier']) === 'lloyd' || element['carrier'] === "Lloyd's") {
          updates[index].carrier = "Certain Underwriters at Lloyd's of London";
        }

        //Case for Venbrook Insurance Services: when client name came  as VA Changing it to Venbrook Insurance Services
        const client = element['client'];
        if (client === 'VA') {
          updates[index].client = 'Venbrook Insurance Services';
        }

        if (client === 'Cardigan General Insurance Services' || client === 'Cardigan General Insurance Services, LLC') {
          const insuredName = element['insuredName'];
          if (insuredName !== undefined && insuredName.toLowerCase().includes('record(s)')) {
            updates.splice(index, 1);
            continue;
          }
        }

        const taxType = element['taxType'];
        const matchedTaxType = taxTypeList.find(tax => tax.possibleValues.some(value => compactString(value) === compactString(taxType)));
        if (matchedTaxType) {
          updates[index].taxType = matchedTaxType.value;
        }

        const invoiceNumber = element['invoiceNumber'];
        // if (!isInvoiceNumber(invoiceNumber)) {
        //   updates[index].invoiceNumber = undefined;
        // }
        index++;
      }

      updateList.push(...updates)

    }
    setCurrentPage(0)

    // const endorsementRecords = updateList.filter(record => record.transactionType === "Endorsement");
    // const remainingRecords = updateList.filter(record => record.transactionType !== "Endorsement");

    // const uniqueArray = removeDuplicatesonImport(updateList);
    const uniqueArray = updateList;


    // const uniqueOpenTaxReportsArray = [...endorsementRecords, ...uniqueArray];

    console.log('duplicated item count: ', updateList.length - uniqueArray.length)
    console.log('items to be saved:', uniqueArray.length, { updateList, uniqueArray })

    // debugger
    const missingRecords = [
      ...missingUpdates.client_Required,
      ...missingUpdates.grossPremium_Required,
      ...missingUpdates.SLTax_StampFee_0,
      ...missingUpdates.TaxAmt_TaxBase_Required,
    ]
    if (missingRecords.length > 0) {
      // Export as 'Incorrect Imports'
      const file = await exportXlsx(missingRecords, excelExportTypes[1].excelType)
      await updateOTRData(uniqueArray, 1, file);
    } else {
      await updateOTRData(uniqueArray, 1);
    }
    getDataOTR()


    setloading(false)
    // setstatus(false)
  }
  const removeDuplicates = (array: OTRModuleModel[]): OTRModuleModel[] => {
    const uniqueItems = array.reduce((acc, current) => {
      if (current?.openTaxReportId !== undefined) {
        acc[current.openTaxReportId] = current;
      }
      return acc;
    }, {} as { [key: number]: OTRModuleModel });
    return Object.values(uniqueItems);
  }
  const removeDuplicatesonImport = (array: OTRModuleModel[]): OTRModuleModel[] => {
    const uniqueItems = array.reduce((acc, current) => {
      const key = `${current.invoiceNumber}_${current.policyNumber}_${current.homeState}_${current.insuredName}_${current.taxType}_${current.coverage}
      _${current.carrier}_${current.naic}_${current.nonAdmittedPremium}_${current.grossPremium}_${current.taxAmt}_${current.transactionType}
      _${current.nonTaxableFees}_${current.taxableFees}_${current.client}_${current.clientDiv}_${current.policyEffDate}_${current.policyExpDate}_${current.status}`;
      if (!acc[key]) {
        acc[key] = current;
      }
      return acc;
    }, {} as { [key: string]: OTRModuleModel });
    return Object.values(uniqueItems);
  };

  const handleRemoveItems = async (values: OTRModuleModel[]) => {
    const uniqueOpenTaxReportsArray = removeDuplicates(values);
    await deleteOTRData(uniqueOpenTaxReportsArray)
    await getDataOTR()

  }

  const handleOffsetItems = async (values: OTRModuleModel[]) => {
    setloading(true)
    await offSetOTRData(values)
    await getDataOTR()
    setloading(false)
  }
  const handleApproveItems = async (values: OTRModuleModel[]) => {
    if (!values.length) return
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      showCancelButton: true,
      confirmButtonColor: '#02A4E3',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Approve',
      customClass: {
        confirmButton: 'btn-radius',
        cancelButton: 'btn-radius',
        popup: 'card-radius'
      }
    })

    if (result.isConfirmed) {
      setloading(true)
      const uniqueOpenTaxReportsArray = removeDuplicates(values);
      await approveOTRData(uniqueOpenTaxReportsArray)
      getDataOTR()
      setSelectedRows([]);
      setSelectionModel([]);
      setloading(false)
    }

  }

  const getOptions = (field: string) => {
    switch (field) {
      case 'excelFormatList':
        return excelFormatListName
    }
    return []
  }
  const fetchOptions = async (field: string, rowVal?: any) => {
    switch (field) {
      case 'taxType':
        const data = await fetchTaxandFees(rowVal.homeState)
        return data
      // return taxTypeList.map(t => t.value)
    }
    return []
  }
  const handleItemChange = async (item: OTRModuleModel) => {
    await updateOTRData([item], 0);
    getDataOTR()
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div style={{ position: "fixed", top: "0", left: "0", zIndex: "9990", width: "100%" }}>
        {loading && <LinearProgress color="info" sx={{ backgroundColor: "#132D3D" }} />}
      </div>
      <Box sx={{ width: '100%', height: '100%' }}>
        <div className="card-title">
          <span className="d-flex">Open Tax Report</span>
        </div>
        <Box
          overflow={"scroll"}
          sx={{
            height: "calc(100% - 60px)",
            width: "100%"
          }}
        >
          <OTRModuleList
            listData={dataOTR}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            getOptions={getOptions}
            fetchOptions={fetchOptions}
            onItemChange={handleItemChange}
            onImportXlsx={handleImportXlsx}
            onExportXlsx={handleExportXlsx}
            onRemoveItems={handleRemoveItems}
            onOffsetItems={handleOffsetItems}
            onApproveItems={handleApproveItems}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            selectionModel={selectionModel}
            setSelectionModel={setSelectionModel}
            fetchData={getDataOTR}
            status={status}
            setStatus={setstatus}
            // openImportStatusDialog ={openImportStatusDialog}
            // setOpenImportStatusDialog= {setOpenImportStatusDialog}
            isImportCompleted={isImportCompleted.current}
            // setIsImportCompleted={setIsImportCompleted}
            // checkImportStatus={CheckImportStatus}
          />
          <ImportStatusDialog
            open={openImportStatusDialog}
            onClose={handleCloseImportStatusDialog}
            isImportCompletedRef={isImportCompleted} />
        </Box>

      </Box>
    </LocalizationProvider>
  )
}
