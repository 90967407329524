var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box, makeStyles, TableCell } from '@material-ui/core';
var useStyles = makeStyles(function () { return ({
    Box: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end' },
}); });
/**
 * Reusable component for displaying an icon cell within a data table row.
 * @param props
 */
export var IconCell = function (_a) {
    var children = _a.children, className = _a.className;
    var classes = useStyles();
    return (_jsx(TableCell, __assign({ className: className }, { children: _jsx(Box, __assign({ className: classes.Box }, { children: children })) })));
};
