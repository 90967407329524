import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControl } from '@mui/material';
import { TransactionTypeModel, TransactionTypeModel_VS } from '../../models/TransactionTypeModel';
import { Formik, Form, Field } from 'formik';
import { json } from 'stream/consumers';
import { config } from '../../Constants';
import { CustomInputComponent, FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import { apiClient } from '../../helper/api';

interface Iprops {
  TransactionTypeCL: TransactionTypeModel,
  handleClose: () => void,
  Open: boolean,
  handleSubmit: () => void,
  initValues: TransactionTypeModel
}


export const AddEditTransactionType: React.FC<Iprops> = ({ handleClose, Open, TransactionTypeCL, handleSubmit, initValues }) => {
  const initialValues: TransactionTypeModel = initValues ? TransactionTypeCL : initValues;
  const { user } = useAuth();
  const token = user?.token;



  return (

    <Dialog open={Open} onClose={handleClose}>
      <DialogTitle className='sub-card-title text-center'  >Transaction Type</DialogTitle>
      <Formik
        initialValues={initialValues}
        validateOnChange={true}
        validationSchema={TransactionTypeModel_VS}
        onSubmit={(data, { setSubmitting }) => {
          setSubmitting(true);
          apiClient('/TransactionType/put',
            {
              method: 'POST',
              headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }),
              body: JSON.stringify(data)  // <-- Post parameters
            })
            .then((res) => {
              if (!res) return
              // Do something with the response
              handleSubmit();
              setSubmitting(true);
            })
        }}

      >
        {({ values, isSubmitting }) => (
          <Form>
            {/* <DialogContent>
              <FMTextField variant="outlined" placeholder="Transaction Type Name" name="transactionTypeName" as={TextField} />
              <Field component={CustomInputComponent} type="text" placeholder="Transaction Type Name" name="transactionTypeName" />

            </DialogContent> */}
            <DialogContent>
              <FormControl sx={{ width: '100%' }} >
                <FMTextField name="transactionTypeName" autoFocus margin="dense" placeholder="Transaction Type Name" label="Transaction Type Name" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
              </FormControl>
            </DialogContent>
            <DialogActions className=''>
              <button className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type="button" >Cancel</button>
              <button    className="btn  btn-primary active-btn-blue  search-btn " type="submit" disabled={isSubmitting}>Submit</button>
            </DialogActions>

          </Form>

        )}

      </Formik>
    </Dialog >

  );
}

