
import moment from 'moment';
import * as yup from 'yup'

export interface taxesandfeesModel {
    id: number,
    usstate?: string,
    postalabbreviation?: string,
    standardabbreviation?: string,
    taxName: string,
    ratetype: number,
    rate: number,
    applicableto?: string,
    lob?: string,
    payableto: string,
    notes: string,
    dateCreated?: Date,
    createdBy?: string,
    lastEditBy?: string,
    dateModified?: Date,
    effectiveDate: string,
    isDeleted?: boolean,
    stateId : number,
    lobId : number,
    commaSeparatedLOBIds?: string,
    taxPayee? : string
    //lobIds?: number[]
    

}


    
export const UsersModel_VS = yup.object({
    taxName: yup.string().required("Please select a state").typeError('Invalid Value'),
    ratetype: yup.string().required("Please select a state").typeError('Invalid Value'),
    rate: yup.number().required("Please enter Rate").typeError('Invalid Value'),
    //lob:  yup.string().required("Please select a state").typeError('Invalid Value'),
    // usstate: yup.string().required("Please select a state").typeError('Invalid Value'),
    stateId: yup.number().min(1,"Please select a state").required("Please select a state").typeError("invalid value"),
    // lobId: yup.number().strict().min(1,"Please select a LOB").required("Please select a LOB").typeError("invalid value"),
    effectiveDate: yup.string().transform(value => {return value ? moment(value).toString() : value;}).required("select a effective date")
   
  })
  

  
