import * as React from 'react';
import useAuth from "../context/AuthProvider";
import { Field, Form, Formik } from 'formik';
import { config } from '../Constants';
import { Button, FormHelperText, LinearProgress, Link } from '@mui/material';
import { CustomInputComponent } from '../components/Elements/input';
import { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { CustomerPolicyModel_VS } from '../models/CustomerPolicyModel';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { RightNav } from '../components/Nav/RightNav';
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo';
import { FMSelectState, FMSelectTransactionType } from '../components/Elements/select';
import LoadingButton from '@mui/lab/LoadingButton';
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { apiClient } from '../helper/api';

var formData = new FormData();
var files: File[];

export interface ReqDocuments {
    documentName?: string,
    modelId?: number
    isRequired?: boolean
}
const baseStyle = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: '#41729F',
    borderStyle: 'dashed',


    backgroundColor: '#fafafa',
    // color: '#bdbdbd',
    color: '#41729F',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};



export function GenericStyledDropZone(props: any & ReqDocuments) {


    const {
        getRootProps,
        getInputProps,
        acceptedFiles,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: { 'application/pdf': ['.pdf'], 'image/*': [], 'application/msg': ['.msg'] }, onDrop:
            (files) => {
                formData.delete(props.doc.modelId + "__" + props.doc.documentName);
                formData.delete(props.doc.modelId + "__" + props.doc.documentName);
                formData.append(props.doc.modelId + "__" + props.doc.documentName, files[0]);
            }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    const files = acceptedFiles.map
        (
            file =>
            (
                <React.Fragment>
                    <FilePresentIcon fontSize='inherit' />
                    <li key={file.name}>
                        {file.name} - {(file.size / 1024).toFixed(2)} kb
                    </li>
                </React.Fragment>
            )

        );

    return (
        <React.Fragment>
            <div className="card-body col-lg-12">
                <div {...getRootProps({ style })}>

                    <input {...getInputProps()} name={props.doc.documentName} />
                    <p>Drop {props.doc.documentName}</p>

                </div>
                <aside>
                    <ul>{files}</ul>
                </aside>
            </div>

        </React.Fragment>
    );
}

interface props {
    fetchData: () => void
}


export const ResubmitSingleDocumentNew: React.FC = () => {


    const navigate1 = useNavigate();

    const goBack = () => {
        navigate1(-1);
    }


    const Reqdocuments: ReqDocuments = {
        documentName: "",
        modelId: 0,
        isRequired: false,

    }

    const [RequireedDocuments, setRequiredDocuments] = useState([Reqdocuments]);
    const [IsSingleDocument, setIsSingleDocument] = useState(true);


    function CheckIsSingleDocument() {
        setloading(true);
        apiClient(`/MasterPolicyType/checkIsSingleDocument?custPolicyId=${params.CustomerPolicyId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) throw new Error('Something went wrong')

                // Do something with the response

                setIsSingleDocument(res.data);

                if (!res.data) {
                    fetchRequiredDocumentName();
                } else {
                    setRequiredDocuments([{ documentName: "Single Document", modelId: 1 }]);
                }

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }


    function fetchRequiredDocumentName() {
        setloading(true);
        apiClient(`/MasterPolicyType/getRequiredDocuments?custPolicyId=${params.CustomerPolicyId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) throw new Error('Something went wrong')
                console.log(res);
                // Do something with the response

                setRequiredDocuments(res.data);

                setloading(false);

            }).catch((error) => {
                setloading(false);
                console.log(error)
            });
    }


    // let ID: number = 0;
    // const [PolicyTypeId, setPolicyTypeId] = useState(ID);

    const initialValues: { transactionTypeId: number, UserId: number } = { transactionTypeId: 0, UserId: 0 }
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        CheckIsSingleDocument();
        // fetchRequiredDocumentName();
        //  fetchMasterPolicyTypeID();

    }, [])
    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                            <LoggedInUserInfo ButtonText="" Link=""/>
                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className=" dashboard-table">
                                            <div className="table-responsive">
                                                {/* {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />} */}
                                                <div className="row col-lg-12">
                                                    <div className="col-lg-4 d-flex">
                                                        <div style={{ marginRight: "2px" }}>
                                                            <Link href="/">
                                                                <Button type="button" title="Home"
                                                                    className="btn action-btn" ><HomeIcon />
                                                                </Button>
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <Button

                                                                className=" btn action-btn"
                                                                onClick={goBack}
                                                                title="Back"
                                                            >
                                                                <ArrowBackIosIcon />
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4" style={{ textAlign: "center" }}>
                                                        <span className="card-title"></span>
                                                    </div>

                                                </div>

                                                <div className="card-body-overflow">

                                                    <React.Fragment>
                                                        <Formik initialValues={initialValues}
                                                            validateOnChange={true}
                                                            validationSchema={CustomerPolicyModel_VS}
                                                            onSubmit={(data, { setSubmitting }) => {
                                                                

                                                                setloading(true);
                                                                formData.append('transactionTypeId', data.transactionTypeId.toString() ?? "");
                                                                let ID = params.CustomerPolicyId?.toString() ?? "";
                                                                formData.append('CustomerPolicyId', ID);

                                                                if (IsSingleDocument) {
                                                                    apiClient(`/CustomerPolicy/CorrectedDocumentSubmission/`,
                                                                        {
                                                                            method: 'POST',
                                                                            headers: new Headers({
                                                                                // 'Content-Type': 'application/json',
                                                                                'Authorization': `Bearer ${token}`,
                                                                            }),
                                                                            body: formData  // <-- Post parameters

                                                                        })
                                                                        .then((res) => {
                                                                            if (!res) throw new Error('Something went wrong')

                                                                            // Do something with the response
                                                                            setSubmitting(false);

                                                                            setloading(false);
                                                                            console.log(res);
                                                                            if (res.resp == 200) {
                                                                                alert("Docs are succesfully Submitted");
                                                                                navigate1(-1);
                                                                            } else {
                                                                                alert(res.data);
                                                                            }


                                                                            // formData = new FormData();

                                                                        }).catch((error) => {
                                                                            setSubmitting(false);
                                                                            alert("There was an error saving the Document");
                                                                            // formData = new FormData();
                                                                            console.log(error)
                                                                            setloading(true);
                                                                            navigate1(-1);
                                                                        });

                                                                } else {
                                                                    apiClient(`/CustomerPolicy/CorrectedDocumentSubmission/`,
                                                                        {
                                                                            method: 'POST',
                                                                            headers: new Headers({
                                                                                // 'Content-Type': 'application/json',
                                                                                'Authorization': `Bearer ${token}`,
                                                                            }),
                                                                            body: formData  // <-- Post parameters

                                                                        })
                                                                        .then((res) => {
                                                                            if (!res) throw new Error('Something went wrong')

                                                                            // Do something with the response
                                                                            setSubmitting(false);

                                                                            setloading(false);

                                                                            if (res.resp == 200) {
                                                                                alert("Docs Are Successfully Submitted");
                                                                                navigate1(-1);
                                                                            } else {
                                                                                alert(res.data);
                                                                            }

                                                                            // formData = new FormData();

                                                                        }).catch((error) => {
                                                                            setSubmitting(false);
                                                                            alert("There was an error saving the Document");
                                                                            // formData = new FormData();
                                                                            console.log(error)
                                                                            setloading(true);
                                                                            navigate1(-1);
                                                                        });
                                                                }

                                                            }} >
                                                            {({ values, isSubmitting }) =>
                                                            (
                                                                <Form>
                                                                    <div className="card-body">
                                                                        <div className="row row-cols-1 row-cols-md-3 g-1">



                                                                            {
                                                                                RequireedDocuments?.map(

                                                                                    (file, Key) =>
                                                                                    (

                                                                                        <div className="card col-lg-5 m-2">
                                                                                            <div className="card-body card-body-dashboard">
                                                                                                <GenericStyledDropZone doc={file} Key={Key} />
                                                                                            </div>
                                                                                        </div>

                                                                                    )


                                                                                )

                                                                            }


                                                                        </div>
                                                                    </div>



                                                                    <div className='card-body'>
                                                                        <div className="row row-cols-1 row-cols-md-3 g-1 m-0 ">
                                                                            <div className="col-lg-10 m-2 ">

                                                                                <div className="card">
                                                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                                                    <div className="card-body card-body-dashboard">

                                                                                        <div className='col-lg-5 '>


                                                                                            <button type="submit" className="btn btn-primary login-btn  loginpage-samespace" disabled={isSubmitting} >Upload </button>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </Form>

                                                            )}
                                                        </Formik>
                                                    </React.Fragment>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>




    );
}

