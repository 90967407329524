export function getRowsPerPageOptions(totalCount: any, _pageSize: any) {
    let rowsPerPageOptions = [25, 50, 100, totalCount].sort((a, b) => a - b);
    // Add _pageSize if it's not already included
    if (!_pageSize || !rowsPerPageOptions.includes(_pageSize)) rowsPerPageOptions = [...rowsPerPageOptions, _pageSize].sort((a, b) => a - b);

    return rowsPerPageOptions;
}

export const isDateGreaterThanToday = (date: Date) => {
    const minDate = new Date('1900-01-01')
    const maxDate = new Date()
    return date < minDate || date > maxDate;
};
