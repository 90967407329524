import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { taxesandfeesModel, UsersModel_VS } from '../../models/TaxesAndFeesModel'
import Moment from 'moment';
import { Field, FieldAttributes, Form, Formik, useField, useFormikContext } from 'formik';
import { config } from '../../Constants';
import { FMSelectLOBField, FMSelectOperator, FMSelectRateTypeField, FMSelectState, FMSelectTaxTypeField, FMSelectTrueFalse } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import { StateRuleMatrixModel, StateRuleMatrixModel_VS } from '../../models/StateRuleMatrixModel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from 'sweetalert2';
import { apiClient } from '../../helper/api';

interface Iprops {
    StateRuleMatrixCL: StateRuleMatrixModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: StateRuleMatrixModel
}
interface stateList {
    stateId: string, stateName: string
}

export const AddEditStateRuleMatrix: React.FC<Iprops> = ({ handleClose, Open, StateRuleMatrixCL, handleSubmit, initValues }) => {
    const initialValues: StateRuleMatrixModel = initValues ? StateRuleMatrixCL : initValues;

   

    const { user } = useAuth();
    const token = user?.token;
    const initialdata = { stateId: "", stateName: "" };
    const [states, setStates] = useState<stateList[]>([initialdata])


    function fetchStateData() {
        apiClient("/States/getStates", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson?.data != null) {
                    setStates(responseJson.data);
                }

                console.log("statesDAta", responseJson.data);
            })
    };

    useEffect(() => {
        fetchStateData();
    }, [])
    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle className='card-title' >State Rules</DialogTitle>
            <Formik initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={StateRuleMatrixModel_VS}
                onSubmit={(data, { setSubmitting }) => {

                    console.log("finaldata", data);
                    apiClient('/StateRuleMatrix/UpdateSave',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((res) => {
                            if (!res) return
                            // Do something with the response

                            handleSubmit()
                            setSubmitting(true)
                            Swal.fire({
                                title: "Data Successfully saved",
                                text: "",
                                icon: "success",
                                confirmButtonText: "OK",
                            });
                        })
                }} >
                {({ values, isSubmitting, setFieldValue, errors }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="ruleName" autoFocus margin="dense" label="Rule Name" placeholder="Rule Name" type="text" fullWidth variant="outlined" sx={{ m: 2, width: '100%' }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="ruleDescription" label="Rule Description" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>

                                    <FormControl error={!!errors.stateId} fullWidth>

                                        <Select
                                            value={initialValues.stateId}
                                            id="test-select-label"
                                            autoWidth
                                            label={null}
                                            onChange={(e) => {

                                                setFieldValue("stateId", e.target.value);
                                                initialValues.stateId = Number(e.target.value);

                                            }}

                                            name="stateId"

                                            // input={<OutlinedInput label="Multiple Select" />}
                                            className="border-round custom-menu-item"
                                            IconComponent={ExpandMoreIcon}
                                            displayEmpty

                                        >
                                            <MenuItem key={0} value={0}>

                                                Select State{" "}
                                            </MenuItem>
                                            {/* <MenuItem value={"0"}>State</MenuItem> */}
                                            {states.map((state, key) => (
                                                <MenuItem key={key} value={state.stateId}>

                                                    {state.stateName}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                        <FormHelperText>{errors.stateId}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FMSelectTrueFalse autoWidth={true} name="isRuleApplied" label="Is Rule Applied" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FMSelectTrueFalse autoWidth={true} name="isPermitted" label="Is Permitted" />
                                </Grid>

                                <Grid item xs={4}>
                                    <FMTextField name="value" label="Value" autoFocus placeholder='Value' defaultValue={0} type="number" fullWidth variant="outlined" />

                                </Grid>

                                <Grid item xs={4}>
                                    <FMSelectOperator autoWidth={true} name="operatorId" label="Operator" />
                                </Grid>
                                <Grid item xs={4}>
                                    <FMSelectRateTypeField autoWidth={true} name="rateTypeId" label="Rate Type" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }}>
                                        <FMTextField name="effectiveDate" InputLabelProps={{ shrink: true }} label="Effective Date" autoFocus margin="dense" type="date" variant="outlined" sx={{ m: 2 }} />

                                    </FormControl >
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}  >Cancel</button>
                            <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
