/**
 * Calculates if the specified element is currently in the viewport.
 *
 * Based on https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
 * @param divRef
 * @returns
 */
var isInViewport = function (divRef) {
    if (!divRef)
        return false;
    var top = divRef.getBoundingClientRect().top;
    return top >= 0 && top <= window.innerHeight;
};
/**
 * Calculates if the specified element is currently in the given container.
 *
 * @param element
 * @param container
 * @returns
 */
var isInContainer = function (element, container, options) {
    var _a, _b;
    if (!element || !container)
        return false;
    var elementRect = element.getBoundingClientRect();
    var containerRect = container.getBoundingClientRect();
    return (elementRect.top >= containerRect.top - ((_a = options === null || options === void 0 ? void 0 : options.topOffset) !== null && _a !== void 0 ? _a : 0) &&
        elementRect.top <= container.clientHeight - ((_b = options === null || options === void 0 ? void 0 : options.bottomOffset) !== null && _b !== void 0 ? _b : 0));
};
export var HtmlVisibilityUtil = {
    isInViewport: isInViewport,
    isInContainer: isInContainer,
};
