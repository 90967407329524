import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { StateStampListList } from "../components/Lists/StateStampListList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { FillingsByUser } from "./FillingsByUser";
import { apiClient } from "../helper/api";

export const StateStampList = () => {
  const { user } = useAuth();
  const token = user?.token;
  const [loading, setloading] = useState(false);

  const [stateStampList, setStateStampList] = useState([]);


  const fetchData = () => {
    setloading(true);
    apiClient("/StateStampList/getAllStateStampList", {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((responseJson) => {
        if (!responseJson) return
        // Do something with the response
        if (responseJson.data != null) {

          setStateStampList(responseJson.data);
          responseJson.data.statesStampsText = responseJson.data.statesStampsText.replace(/<p>(.*?)<\/p>/g, '$1');
          console.log("StateStampListData", responseJson.data);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (


    <StateStampListList
      StateStampListCL={stateStampList}
      fetchData={fetchData}
    />

  );
};
