import * as yup from 'yup'

export interface StateStampListModel
{
    stateId?:number,
    stateName?: string,
    statesStampsListId:number,
    statesStampStateId?: number,
    statesStampsIsStateStamp?: boolean,
    statesStampsText?: string,
    statesStampContingentUpon?: boolean,
    statesStampsFontSize?: string,
    statesStampsFontWeight?: string,
    statesStampsIsCaps: boolean,
    statesStampsColor?: string,
    statesStampsStampAfterApproval?: boolean,
    effectiveDate ?: string
    // createdBy?: number,
    // createdDate?: Date,
    // modifiedBy?: number,
    // modifiedDate?: Date
}

export interface StateStampSPModel
{
    stateName?: string,
    statePostalAbbv?: string,
    stateStamp?: string,
    statesStampsText?: string,
    contingentUponDomciledForeignAlienInsurerCompany?: string,
    statesStampsListsId ?: number
    effectiveDate?:Date
}

export const StateStampListModel_VS = yup.object().shape({
    statesStampStateId: yup.number().min(1,"Please select state").required("Please select state").typeError('Invalid Value'),
    // stateId:yup.number().min(1,"State Required").required("Please Select State").typeError('Invalid Value'),
    statesStampsIsStateStamp: yup.boolean().oneOf([true, false], 'Please select Yes or No').required('This field is required'),
    // statesStampContingentUpon: yup.boolean().required("Please select Contingent Upon").typeError('Invalid Value'),
    // statesStampsStampAfterApproval: yup.boolean().required("Please select Stamp After Approval").typeError('Invalid Value'),
    effectiveDate: yup.date().required("Please select effective date").typeError('Invalid Date'),
  });