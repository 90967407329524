import { Button, FormControl, FormControlLabel, Grid, LinearProgress, Link, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { PolicyFilingScheduleList } from "../components/Lists/PolicyFilingSheduleList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { CheckNIPR } from "./CheckNIPR";
import { ResubmitSingleDocument } from "./ResubmitSingleDocument";
import { UpdateLicense } from "./UpdateLicense";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CustomerPolicyModel } from "../models/CustomerPolicyModel";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';


export const DocumentCorrections = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const params = useParams();




    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    useEffect(() => {


    }, [])

    const [RadioSelectedValue, setRadioSelectedValue] = React.useState<string>('');
    const RadioValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setRadioSelectedValue((event.target as HTMLInputElement).value);

    };


    return (

        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>

        //                         <LoggedInUserInfo ButtonText="" Link="" />


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="card dashboard-table">
                                            
        //                                     <div>
                                                <div className="table-responsive">
                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                    <CheckNIPR />

                                                    {/* <Grid item xs={12} display='flex' flexDirection='row' justifyContent="center" >
                                                    <Grid item xs={12}   >
                                                        <FormControl sx={{ width: '100%', margin: "20px" }} >
                                                            <RadioGroup
                                                                value={RadioSelectedValue}
                                                                onChange={RadioValueChange}
                                                                row
                                                                aria-labelledby="demo-radio-buttons-group-label"
                                                                defaultValue="Annual Pay"
                                                                name="radio-buttons-group" >

                                                                <FormControlLabel value='1' control={<Radio />} label="Only update License in system" />
                                                                <FormControlLabel value='2' control={<Radio />} label="Upload document again" />
                                                            </RadioGroup>

                                                        </FormControl>

                                                    </Grid>
                                                </Grid> */}
                                                    {/* {RadioSelectedValue == '1' ? <UpdateLicense /> : RadioSelectedValue == '2' ?
                                                    <ResubmitSingleDocument />
                                                    : ""
                                                } */}
                                                </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     {/* <RightNav /> */}

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>

    )

}