import React, { useEffect, useState } from "react";
import { DataGrid, GridCellParams, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, List, ListItem, ListItemText, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ArticleIcon from '@mui/icons-material/Article';
import { ButlerResponseModel } from "../../models/ButlerResponseModel";
import LinearProgress from '@mui/material/LinearProgress';
import { apiClient } from "../../helper/api";

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: ButlerResponseModel;
    onClose: (value: string) => void;
}

interface Iprops {
    DocumentCL: {
        documentId: number,
        customDocumentId?: string,
        documentType?: string,
        documentName?: string,
        xmlDocumentId?: string,
        blobAddress?: string,
        modelId: number,
        isUploaded: boolean,
        isProcessed: boolean,
        confidanceScore: string,
        transctionId?: number

    }[],
    fetchData: () => void
}

export const DocumentList: React.FC<Iprops> = ({ DocumentCL, fetchData }) => {
    const { user } = useAuth();
    const token = user?.token;
    const initialData: ButlerResponseModel = {
        hasNext: false, hasPrevious: false, totalCount: 1, items: [{
            documentId: "", documentStatus: "", fileName: "",blob_Url:"", mimeType: "", documentType: "", confidenceScore: "", formFields: [{ fieldName: "", value: "", confidenceScore: "" }],
            tables:
                [
                    {
                        tableName: "",
                        confidenceScore: "",
                        rows: [{
                            cells: [
                                {
                                    columnName: "",
                                    confidenceScore: "",
                                    value: "",
                                }]
                        }]
                    }]
        }
        ]
    }
    const [ResponseData, setResponseData] = React.useState(initialData);
    const [loading, setloading] = useState(false);

    const columns: GridColDef[] = [


        { field: 'documentName', type: 'string', headerName: 'Document Name', flex: 1 },
        { field: 'documentType', type: 'string', headerName: 'Document Type', flex: 1 },
        { field: 'isUploaded', type: 'boolean', headerName: 'Is Uploaded', flex: 1 },
        { field: 'isProcessed', type: 'boolean', headerName: 'Is Processed', flex: 1 },

        {
            field: 'documentId', flex: 1, headerName: "Download", renderCell: (params) => (<div>

                <IconButton
                    color="primary"

                >
                    <a href={params.row.blobAddress} target="_blank" >
                        <DownloadIcon />
                    </a>
                </IconButton >
            </div>)
        },

        {
            field: 'Upload', flex: 1, headerName: "Upload Document", renderCell: (params) => (<div>

                < IconButton
                    color="primary"
                    onClick={() => { handleUpload(params.row.documentId) }}

                >
                    <UploadFileIcon />
                </IconButton >


            </div>)
        },
        {
            field: 'Response', flex: 1, headerName: "Get Response", renderCell: (params) => (<div>

                <IconButton
                    color="primary"
                    onClick={() => { handleGetResponse(params.row.documentId) }}
                >
                    {params.row.isUploaded ? <ArticleIcon /> : ""}
                </IconButton >
            </div>)
        }

    ];


    function handleUpload(documentid: number) {
        setloading(true)
        apiClient("/Butler/UploadForOcr/?DocumentId=" + `${documentid}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((res) => {
                if (!res) return
                setloading(false)
                // Do something with the response
                alert(res.message + res.data);
                fetchData();

            })

    }


    function handleGetResponse(documentid: number) {
        setloading(true)
        apiClient("/Butler/GetOcrResponse/?DocumentId=" + `${documentid}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((res) => {
                if (!res) return

                setloading(false)
                setResponseData(res.data);
                console.log(res.data);

                handleClickOpen();
                fetchData();



            })

    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);

    };



    return (
        <div style={{ height: '75vh', width: '100%' }}>
            {loading && <LinearProgress sx={{backgroundColor: "#132D3D"}} />}
            <DataGrid
                getRowId={(r) => r.documentId}
                rows={DocumentCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}

            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="ButlerOcr Response"
                aria-describedby="alert-dialog-description"
            >



                <DialogTitle id="alert-dialog-title">
                    Butler Ocr Response
                </DialogTitle>
                <DialogContent>
                    {
                        open ?
                            (
                                <ul>
                                    {ResponseData.items && ResponseData.items.map((item, i) => (
                                        
                                        <span key={i}>
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 500 }} aria-label="simple table">

                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell colSpan={3}><b>Document Details</b></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Document Name</TableCell>
                                                            <TableCell>{item.fileName}</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Document Status</TableCell>
                                                            <TableCell>{item.documentStatus}</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Overall Confidence Score</TableCell>
                                                            <TableCell>{item.confidenceScore}</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                        {item.documentStatus == "Completed" ? <React.Fragment>
                                                            <TableRow>
                                                                <TableCell colSpan={3}><b>Document Field Data</b></TableCell>
                                                            </TableRow>

                                                            <TableRow>
                                                                <TableCell><b>Field Name</b></TableCell>
                                                                <TableCell><b>Value</b></TableCell>
                                                                <TableCell><b>Confidence Score</b></TableCell>
                                                            </TableRow>


                                                            {
                                                                item.formFields.map((cel, t) => (
                                                                    <TableRow key={t}>
                                                                        <TableCell>{cel.fieldName}</TableCell>
                                                                        <TableCell>{cel.value}</TableCell>
                                                                        <TableCell>{cel.confidenceScore}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                       </React.Fragment>

                                                            : ""}


                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </span>


                                    ))}
                                </ul>


                            ) : ""
                    }

                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}