import { LinearProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { PartnersList } from "../components/Lists/PartnersList";
import { PayablesList } from "../components/Lists/PayablesList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import BasicTabs from "./Tabs";

export const PolicyDashboard = () => {
  const { user } = useAuth();
  const token = user?.token;
  const [loading, setloading] = useState(false);
  const [show, setshow] = useState(false);
    const [showRightNav, setShowRightNav] = useState(false);
  
    const toggleRightNav = () => {
      setShowRightNav(!showRightNav);
    };

    // }

  return (
                <BasicTabs />
  );
};

   