import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import { useState, useEffect } from "react";
import useAuth from "../../context/AuthProvider";
import moment from "moment";
import { PolicyDocumentsPopup, getCustomerPolicyDocumentDetails } from "./PolicyDocumentsPopup";
import React from "react";
import { config } from "../../Constants";
import { PolicyErrorPopup } from "./PolicyErrorsPopup";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { colors } from "react-select/dist/declarations/src/theme";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { AddEditResubmitSingleDocument } from "../../components/AddEdit/AddEditResubmitSingleDocument";
import CloseIcon from '@mui/icons-material/Close';
import { RiExpandUpDownLine } from "react-icons/ri";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { apiClient } from "../../helper/api";

interface FilingQueModel {
    customerName: string,
    customerPolicyId: number,
    dateRecived?: Date,
    policyNumber: string,
    doucmentsUploaded: number,
    errorsCount: number,
    insuredName: string,
    transactioType: string,
    filingState: string,
    status: string,
    insuredId?: number,
    policyId?: number,
    isProcessed: boolean,
    effectiveDate?: string,
    userName?: string,



    // agency? :  string
    // customerpolicyid? :  number
    // dateReceived? :  string
    // dueDate? :  string
    // effectiveDate? :  string
    // grossPremium? :  string
    // homeState? :  string
    // insuredName? :  string
    // invoiceDate? :  string
    // invoiceNumber? :  string
    // lob? :  string
    // policyExpDate? :  string
    // policyId? :  string
    // policyNumber? :  string
    // rowNum? :  string
    // status? :  string
    // taxAmt? :  string
    // transactionTypeName? :  string



}

interface Iprops {
    filingData: FilingQueModel[]
    refreshDataAfterUpload: () => void;
    refreshDataMain: (action: string, newValue: number) => void;
    showPayouts: (sNo: number) => void | undefined;
    sNo: number | undefined
}

export const FilingQueue: React.FC<Iprops> = ({ filingData, refreshDataAfterUpload, refreshDataMain, showPayouts, sNo }) => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const [Open, setOpen] = React.useState(false);
    let initDocData: getCustomerPolicyDocumentDetails = {}
    const [Formdata, setFormData] = React.useState([]);

    const [ErrorFormdata, setErrorFormData] = React.useState([]);

    const [OpenError, setOpenError] = React.useState(false);

    const [OpenDocResubmission, setDocResubmission] = React.useState(false);

    const [custPolId, setcustPolId] = React.useState("0");

    const handleEdit = (custPolId: number) => {
        apiClient(`/PolicyDashBoard/getCustomerPolicyDocumentDetails?CustPolicyId=${custPolId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                console.log(responseJson, "docssDataa");
                setFormData(responseJson.data);
            })

        setOpen(true);
    };

    const handleEditErrors = (custPolId: number) => {
        apiClient(`/PolicyDashBoard/getCustomerPolicyErrorDetails?CustPolicyId=${custPolId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                console.log(responseJson);
                setErrorFormData(responseJson.data);
            })


        setOpenError(true);
    };

    const handleDocumentReubmission = (custPolId: number) => {
        setcustPolId(String(custPolId));

        setDocResubmission(true);
    };

    { loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} /> }
    const columns: GridColDef[] = [
        {
            field: 'dateRecived', type: 'text', headerName: 'Date Received', flex: 0.8, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center',
            valueGetter: (params) => {
                if (!params.value) {
                    return params.value;
                }
                return moment(params.row?.dateRecived).format("MM-DD-YYYY")
            }

        },
        // {

        //     field: 'dateRecived', type: 'text', headerName: 'Date Received', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) =>
        //     (

        {
            field: 'transactioType', type: 'text', headerName: 'Transaction Type', flex: 0.8, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'policyNumber', type: 'text', headerName: 'Policy Number', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'effectiveDate', type: 'text', headerName: 'Effective Date', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', renderCell: (params) =>
            (

                <div>
                    {params.row?.effectiveDate ? moment(params.row?.effectiveDate).format("MM-DD-YYYY") : ""}
                </div>

            )
        },
        {
            field: 'insuredName', type: 'text', headerName: 'Insured Name', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', renderCell: (params) =>
            (
                <div>
                    <Link href={'/InsuredSubmissions/' + params.row?.insuredId} style={{ textDecoration: "none" }}>

                        {params.row?.insuredName}

                    </Link>
                </div>
            )
        },
        {
            field: 'carrier', type: 'text', headerName: 'Carrier', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'userName', type: 'text', headerName: 'Uploaded By', flex: 0.8, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },



        {
            field: 'filingState', type: 'text', headerName: 'State', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        // {
        //     field: 'status', type: 'text', headerName: 'Status', flex: 1, hideSortIcons: true,
        //     renderHeader: (params) => (
        //         <div>
        //             <span className="table-card-title" >{params.colDef.headerName}</span>
        //             <RiExpandUpDownLine />
        //         </div>
        //     ), headerAlign: 'center', align: 'center'
        // },
        {
            field: 'doucmentsUploaded', headerName: 'Docs', type: 'text', flex: 0.5, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', renderCell: (params) =>
            (
                <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleEdit(params.row.customerPolicyId)}>
                    {params.row.doucmentsUploaded}
                </span>
            )
        },
        // {
        //     field: 'errorsCount', flex: 0.5, hideSortIcons: true,
        //     renderHeader: (params) => (
        //         <div>
        //             <span className="table-card-title" >{params.colDef.headerName}</span>
        //             <RiExpandUpDownLine />
        //         </div>
        //     ), headerName: "Errors", headerAlign: 'center', align: 'center', renderCell: (params) =>
        //     (<div>
        //         <span style={{ color: "blue", cursor: "pointer" }} onClick={() => handleEditErrors(params.row.customerPolicyId)}>
        //             {params.row.errorsCount}
        //         </span>
        //     </div>)
        // },
        // {
        //     field: 'policyId', flex: 0.5, headerName: "Check Policy", headerAlign: 'center', align: 'center', renderCell: (params) => (<div>
        //         <Link href={'/Transaction/' + params.row?.policyId} color="#1976D2">
        //             {
        //                 params.row.isProcessed ?
        //                     (<IconButton
        //                         className="btn btn-primary  m-1 "
        //                         style={{ float: 'right', color: "white", margin: '4px' }}

        //                     >
        //                         <ArrowRightAltIcon />
        //                     </IconButton>)
        //                     : ""
        //             }
        //         </Link>
        //     </div>)
        // },
        {
            field: 'uploadodcs', flex: 1.2, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerName: "Re-Submit Documents", align: 'center', renderCell: (params) => (<div>
                <IconButton
                    onClick={() => { handleDocumentReubmission(params.row.customerPolicyId) }}
                >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15.75 11.25V14.25C15.75 14.6478 15.592 15.0294 15.3107 15.3107C15.0294 15.592 14.6478 15.75 14.25 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V11.25M12.75 6L9 2.25M9 2.25L5.25 6M9 2.25V11.25" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </IconButton >
            </div>)
        }



    ];



    const handleClose = () => {

        setOpen(false);
        setOpenError(false);
        setDocResubmission(false);
        refreshDataAfterUpload();

    };
    useEffect(() => {

    }, [])

    function EditToolbar() {
        return (
            <Box
                sx={{

                    // borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >



                {/* <div className="d-flex " style={{ float: 'right' }} >
                    <GridToolbar />
                    <button
                        className="btn  btn-primary active-btn-blue "
                        style={{ color: "white", margin: '4px' }}
                        onClick={() => {
                            user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer") ? refreshDataAfterUpload() :

                                refreshDataMain("getFileQueDataClient", 0);
                        }}

                    >
                        Refresh
                    </button >


                </div> */}

            </Box>
        )


    }


    return (

        // <div className="card" style={{ height: user?.userRoles.split(",").includes("Super Admin") ? '400px' : "55vh", width: '100%', marginTop: "10px", padding: "12px" }}>

        <div style={{ height: "59vh" }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DataGridPro
                getRowId={(r) => r.customerPolicyId}
                rows={filingData}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                disableSelectionOnClick
                components={{
                    Toolbar: EditToolbar,
                }}
                // columnVisibilityModel={{
                //     // Hide columns status and traderName, the other columns will remain visible

                //     policyId: user?.userRoles.split(",").includes("Super Admin") ? true : false,
                //     customerName: user?.userRoles.split(",").includes("Super Admin") ? true : false,
                //     errorsCount: user?.userRoles.split(",").includes("Super Admin") ? true : false

                // }}

                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif",
                        border: "0px",
                        fontSize: "16px"


                    }
                }
            />
            < PolicyDocumentsPopup handleClose={handleClose} Open={Open} documentList={Formdata} />

            < PolicyErrorPopup handleClose={handleClose} Open={OpenError} documentList={ErrorFormdata} />
        </div>
        //{OpenDocResubmission ? <AddEditResubmitSingleDocument open={OpenDocResubmission} handleClose={handleClose} custPolicyID={custPolId} /> : ""}



    )

}