import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography,
    Button,
    CircularProgress
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';

interface ImportStatusDialogProps {
    open: boolean;
    onClose: () => void;
    // isImportCompleted: string;
    isImportCompletedRef: React.MutableRefObject<string>;
}

const ImportStatusDialog: React.FC<ImportStatusDialogProps> = ({
    open,
    onClose,
    isImportCompletedRef
}) => {
    const [_, setRenderTrigger] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setRenderTrigger(prev => !prev);
        }, 1000); // Adjust the interval as needed

        return () => clearInterval(interval);
    }, []);

    const isImportCompleted = isImportCompletedRef.current;
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '1.25rem' }}>
                    Import Status
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
                    {isImportCompleted === 'In Progress' ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <CircularProgress />
                            <Typography variant="body1" color="textSecondary" marginTop={2}>
                                Import is in progress. Please wait.
                            </Typography>
                        </Box>
                    ) : isImportCompleted === 'Completed' || isImportCompleted === 'Not Importing' ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Typography variant="body1" color="success.main">
                                <CheckCircleIcon color="success" fontSize="large" />
                                Import completed successfully!
                            </Typography>
                        </Box>
                    ) : isImportCompleted === 'Timeout' ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <InfoIcon color="info" fontSize="large" />
                            <Typography variant="body1" color="info.main" marginTop={2}>
                                It's taking longer than expected to complete the import. We'll notify you via email as soon as it's done.
                            </Typography>
                        </Box>
                    ) : null}
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <Button onClick={onClose} color="primary" style={{ marginLeft: '8px' }}>
                        Close
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

export default ImportStatusDialog;
