import { jsx as _jsx } from "react/jsx-runtime";
import { alpha, Box, makeStyles } from '@material-ui/core';
import { EndUserBlockRenderUtils, } from 'documentLabeler/components/documentPanel/documentBlockLayer/utils/EndUserBlockRenderUtils';
var useStyles = makeStyles(function (theme) { return ({
    DragRectangle: {
        border: "2px solid ".concat(theme.palette.primary.main),
        borderRadius: theme.shape.borderRadius,
        // recenter blocks to offset the border radius
        marginLeft: theme.shape.borderRadius * -0.5,
        marginTop: theme.shape.borderRadius * -0.5,
    },
}); });
/**
 * Component used for rendering a drag rectangle
 * on document labeling pages.
 * @param props
 */
export var DragRectangle = function (_a) {
    var dragCoords = _a.dragCoords, bgColor = _a.bgColor;
    var classes = useStyles();
    var minMax = EndUserBlockRenderUtils.toMinMax(dragCoords);
    var rectangle = {
        left: minMax.minX,
        top: minMax.minY,
        width: minMax.maxX - minMax.minX,
        height: minMax.maxY - minMax.minY,
    };
    var style = {
        backgroundColor: bgColor ? alpha(bgColor, 0.1) : 'transparent',
    };
    if (bgColor) {
        style.border = "2px solid ".concat(bgColor);
    }
    return (_jsx(Box, { top: "".concat(rectangle.top, "px"), left: "".concat(rectangle.left, "px"), width: "".concat(rectangle.width, "px"), height: "".concat(rectangle.height, "px"), position: "absolute", className: classes.DragRectangle, style: style }));
};
