import { useEffect, useState } from "react";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { LinearProgress } from "@mui/material";
import { RightNav } from "../components/Nav/RightNav";
import { ButlerModel } from "../models/ButlerModel";
import { ButlerMappingList } from "../components/Lists/ButlerMappingList";
import { apiClient } from "../helper/api";



export const ButlerMapping = () => {
    const [ButlerMappingData, setButlerMappingData] = useState([]);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const fetchData = () => {
        setloading(true);
        apiClient('/ButlerMappings/getAllMappings',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                setloading(false);
                setButlerMappingData(responseJson.data)
                console.log("Data butler", responseJson)

            })

    }
    useEffect(() => {
        fetchData();
        console.log(config.url.API_URL);

    }, [])
    return (  
          <ButlerMappingList ButlerMappingCL={ButlerMappingData} fetchData={fetchData} />                                             

    )

}