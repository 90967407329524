import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridColumnHeaderParams, GridRenderCellParams, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, Link, IconButton, Grid, TextField, FormControl, SelectChangeEvent, InputLabel, MenuItem, Select, SelectProps } from "@mui/material";
import { config } from "../../Constants";
import { InsuranceCompanyModel } from "../../models/insuranceCompanyModel";
import { AddEditInsuranceCompany } from "../AddEdit/AddEditInsuranceCompany";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'moment';
import useAuth from "../../context/AuthProvider";
import { AddEditCarriermanagement } from "../AddEdit/AddEditCarrierManagement";
import { stateOptions } from "../AddEdit/AddEditPartners";
import { RiExpandUpDownLine } from "react-icons/ri";
import Swal from "sweetalert2";
import { AddEditCarrierManageLineOfBussinessCheckBox } from "../AddEdit/AddEditCarrierManageLineOfBussinessCheckBox";
import { apiClient } from "../../helper/api";


interface Iprops {
    InsuranceComapnyCL: InsuranceCompanyModel[],
    fetchData: () => void,
    handleMappingsUpdate: () => void
}

export interface lineOfBusinessModel {
    id: number, businessCode: string, stateId: number
}





export const CarrierManagementList: React.FC<Iprops> = ({ InsuranceComapnyCL, fetchData, handleMappingsUpdate }) => {
    const initialICData: InsuranceCompanyModel = {
        insuranceCompanyId: 0, naicNumber: "", name: "", stateId: 0, createdBy: 0, modifiedBy: 0, dateCreated: new Date(), dateModified: new Date(), isDeleted: false,
        stateName: "", isActive: true
    };

    const { user } = useAuth();
    const token = user?.token;
    const [selectedStates, setselectedStates] = useState<stateOptions[]>([]);
    const [States, setStates] = useState<stateOptions[]>([]);
    const [value, setValue] = React.useState(0);
    const [selectedLineOfBusiness, setselectedLineOfBusiness] = useState<lineOfBusinessModel[]>([]);
    const [LineOfBusiness, setLineOfBusiness] = useState<lineOfBusinessModel[]>([]);

    const [Open, setOpen] = React.useState(false);
    const [Open1, setOpen1] = React.useState(false);
    const [Formdata, setFormData] = React.useState(initialICData);
    const [currentState, setCurrentState] = React.useState(0);

    const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if (event.target.checked) {

            var id = Number(event.target.name);
            setCurrentState(id);
            getLineOfBusinessByStateId(id);

            console.log(event.target.name);
            let crrOption: stateOptions = {
                stateId: Number(event.target.name),
                stateName: '',
            }
            setselectedStates(current => [...current, crrOption])
        }

        else {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to remove all line of business related to this state",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, remove them!'
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        var id = Number(event.target.name);
                        for (var i = 0; i < selectedLineOfBusiness.length; i++) {
                            if (selectedLineOfBusiness[i].stateId == id) {
                                selectedLineOfBusiness[i].businessCode = "";
                                selectedLineOfBusiness[i].stateId = 0;
                                selectedLineOfBusiness[i].id = 0;
                                // var index = selectedLineOfBusiness.indexOf(selectedLineOfBusiness[i]);
                                // delete selectedLineOfBusiness[index];
                                // const index1 = selectedLineOfBusiness.indexOf(index);
                            }
                        }
                        setselectedStates(
                            selectedStates.filter(obj =>
                                obj.stateId != Number(event.target.name)
                            )
                        )
                    }
                })
        }
    };

    const handleStateEdit = (eventId: number) => {
        setCurrentState(eventId);
        getLineOfBusinessByStateId(eventId)

    }


    function getLineOfBusinessByStateId(id: number) {
        //setloading(true)
        apiClient("/LineOfBusiness/getLineOBusinessByStateId?stateId=" + `${id}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                method: "Get",
            }
        )
            .then((res) => {
                if (!res) return
                if (res.data.length > 0) {
                    setOpen1(true);
                    setLineOfBusiness(res.data);
                } else {
                    alert("There is no line of business for this state");
                }

            })

    }

    function selectAll() {
        setselectedStates(States);
    }

    const handleLineOfBusinessCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            console.log(event.target.name);
            var curntState = currentState;
            let crrOption: lineOfBusinessModel = {
                id: Number(event.target.name),
                businessCode: '',
                stateId: curntState
            }

            setselectedLineOfBusiness(current => [...current, crrOption]);

        } else {

            setselectedLineOfBusiness(
                selectedLineOfBusiness.filter(obj =>
                    obj.id != Number(event.target.name)
                )
            )
        }

    };
    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    apiClient("/InsuranceCompany/DeleteInsuranceCompany?id=" + `${id}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "Delete",
                        }
                    )
                        .then((res) => {
                            if (!res) return
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )

                            fetchData();
                            setOpen(false);
                        }).catch((error) => {
                            console.log(error)
                        });
                  
                }
            })
        //call api to save data
        // fetch(config.url.API_URL + "/Users/Delete?id=" + `${id}`,
        //     {
        //         headers: new Headers({
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`,
        //         }),
        //         method: "Delete",
        //     }
        // )
        //     .then((response) => {
        //         if (response.ok) {
        //             return response.json();
        //         }
        //         throw new Error('Something went wrong');
        //     })
        //     .then((res) => {
        //         alert("Data Successfully Deleted")
        //         fetchData();
        //         setOpen(false);
        //     }).catch((error) => {
        //         console.log(error)
        //     });


    };

    const columns: GridColDef[] = [
        { field: 'naicNumber', headerName: 'NAIC #', type: 'string', flex: 0.5, headerAlign: 'center',hideSortIcons: true,
        renderHeader: (params) => (
            <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
            </div>
        ), align: 'center' },
        { field: 'name', headerName: 'Carrier', flex: 1.5, headerAlign: 'center',hideSortIcons: true,
        renderHeader: (params) => (
            <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
            </div>
        ), align: 'center' },

        {
            field: 'startDate', headerName: 'Start Date', type: "date", flex: 0.5,hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', renderCell: (params) => (
                <div> {params.row.startDate ? Moment(params.row.startDate).format("MM-DD-YYYY") : ""}</div>)

        },


        // { field: 'startDate', headerName: 'Start Date', type: "date", flex: 0.5, renderCell: (params) => (<div> {params.row.startDate ? Moment(params.row.startDate).format("YYYY-MM-DD") : ""}</div>) },
        { field: 'dateModified', headerName: 'Last Modified', type: "date", flex: 0.5,hideSortIcons: true,
        renderHeader: (params) => (
            <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
            </div>
        ), headerAlign: 'center', align: 'center', renderCell: (params) => (<div> {params.row.dateModified ? Moment(params.row.dateModified).format("MM-DD-YYYY") : ""}</div>) },
        {
            field: 'id',
            sortable: false,
            disableColumnMenu: true,

            flex: 0.5, headerName: "Action", headerAlign: 'center', align: 'center',hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), renderCell: (params) => (<div>
                <IconButton
                    color="primary"
                    onClick={() => { handleEdit(params.row) }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>

                </IconButton >
                {user?.userRoles.split(",").includes("Super Admin") ?
                    <IconButton
                        color="error"
                        onClick={() => { handleDelete(params.row.insuranceCompanyId) }}

                    >
                        {params.row.isDeleted ? <RestoreFromTrashIcon /> : <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        }
                    </IconButton >
                    : ""}
            </div>)
        }


    ];

    const handleClickOpen = () => {
        setselectedStates([]);
        setselectedLineOfBusiness([]);
        fetchStates(0);
        fetchLineOfBusiness(0);
        setFormData(initialICData);
        setOpen(true);
    };
    const handleClose = () => {
        setselectedLineOfBusiness([]);
        setOpen(false);
    };

    const handleClose1 = () => {
        setOpen1(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };
    const handleSubmitLineofBusinessByState = () => {
        setOpen1(false);
    };

    const fetchStates = (insuranceCompanyId: number) => {

        apiClient('/States/getStates',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response

                setStates(responseJson.data);
                // console.log(States);

            }).then(() => {
                apiClient(`/InsuranceCompany/getInsuranceCompanyStates?insuranceCompanyId=${insuranceCompanyId}`,
                    {
                        method: "GET",
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        })
                    })
                    .then((responseJson) => {
                        if (!responseJson) return
                        // Do something with the response

                        setselectedStates(responseJson.data);
                        // setSelectedOption(States.filter(x => partnerStates.map((item)=>(item.stateId))));
                    })
            })


    }


    const fetchStateData = () => {

        apiClient('/States/getStates',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response

                setStates(responseJson.data)

            })


    }


    useEffect(() => {

        fetchStateData();
    }, [])





    const fetchLineOfBusiness = (insuranceCompanyId: number) => {

        if (insuranceCompanyId > 0) {
            // fetch(config.url.API_URL + '/LineOfBusiness/getLineOBusinessForSelect',
            //     {
            //         method: "GET",
            //         headers: new Headers({
            //             'Content-Type': 'application/json',
            //             'Authorization': `Bearer ${token}`,
            //         })
            //     }).then((response) => {
            //         if (response.ok) {
            //             return response.json();
            //         }
            //         throw new Error('Something went wrong');
            //     })
            //     .then((responseJson) => {
            //         // Do something with the response

            //         //setLineOfBusiness(responseJson.data);
            //         // console.log(States);

            //     }).then(() => {
            apiClient(`/InsuranceCompany/getInsuranceCompanyLineOfBusiness?insuranceCompanyId=${insuranceCompanyId}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                })
                .then((responseJson) => {
                    if (!responseJson) return

                    // Do something with the response
                    setselectedLineOfBusiness(responseJson.data);
                    // for (let i = 0; i < responseJson.data.length; i++) {
                    //     var id=Number(responseJson.data[i].id)
                    //     setselectedLineOfBusiness(responseJson.data);
                    // }
                })

            // }).catch((error) => {
            //     console.log(error)

            // });
        }
    }

    const handleEdit = (ICClData: InsuranceCompanyModel) => {



        setselectedStates([]);
        setselectedLineOfBusiness([]);

        ICClData.startDate = ICClData.startDate ? ICClData.startDate.toString().split('T')[0] : "";
        console.log(ICClData);

        setFormData(ICClData);
        fetchStates(ICClData.insuranceCompanyId);
        fetchLineOfBusiness(ICClData.insuranceCompanyId);
        setOpen(true);
    };

    function EditToolbar() {

        return (
            <Box

            >
                <div className="row col-lg-12" style={{ marginBottom: "10px" }}>
                    {/* <div className="col-lg-4 d-flex">
                        <div style={{ marginRight: "2px" }}>
                            <Link href="/">
                                <Button type="button" title="Home"
                                    className="btn action-btn" ><HomeIcon />
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Button

                                className=" btn action-btn"
                                onClick={goBack}
                                title="Back"
                            >
                                <ArrowBackIosIcon />
                            </Button>
                        </div>
                    </div> */}
                    <div className="d-flex justify-content-between">
                        <div className="" style={{ fontSize: "32px" }}>
                            <span className="card-title" >Carrier Management</span>
                        </div>
                        <div className=" " style={{ textAlign: "right" }}>

                            <button onClick={handleClickOpen}
                                className="active-btn-blue me-2"
                            >
                                Add New
                            </button>
                            {
                                config.url.API_URL === "http://localhost:5274/api"   || ("https://filemule-api.azurewebsites.net/api")
                                // true
                                    ?
                                    <button onClick={handleMappingsUpdate}
                                        className="btn-2 btn btn-primary"
                                    >
                                        Push to live
                                    </button>

                                    : ""
                            }
                        </div>
                    </div>
                </div>





            </Box>


        );
    }
    return (
        <React.Fragment>
            <div style={{ height: '75vh', width: '100%' }}>

                <DataGrid
                    getRowId={(r) => r.insuranceCompanyId}
                    rows={InsuranceComapnyCL}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[10]}
                    sx={
                        {
                            fontFamily: "'Proxima Nova', sans-serif;",
                            border: "0px",
                            fontSize: "16px"
                        }
                    }

                    experimentalFeatures={{ newEditingApi: true }}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                />
                <AddEditCarriermanagement setselectedLineOfBusiness={setselectedLineOfBusiness} handleClose={handleClose} Open={Open} InsuranceCompanyCL={Formdata} handleSubmit={handleSubmit} initValues={initialICData} selectedStates={selectedStates} States={States} setselectedStates={setselectedStates} setStates={setStates} handleCheckBoxChange={handleCheckBoxChange} selectedLineOfBusiness={selectedLineOfBusiness} LineOfBusiness={LineOfBusiness} handleLineOfBusinessCheckBoxChange={handleLineOfBusinessCheckBoxChange} selectAll={selectAll} handleStateEdit={handleStateEdit} ></AddEditCarriermanagement>
                <AddEditCarrierManageLineOfBussinessCheckBox currentState={currentState} handleClose={handleClose1} Open={Open1} InsuranceCompanyCL={Formdata} handleSubmit={handleSubmitLineofBusinessByState} initValues={initialICData} selectedStates={selectedStates} States={States} setselectedStates={setselectedStates} setStates={setStates} handleCheckBoxChange={handleCheckBoxChange} selectedLineOfBusiness={selectedLineOfBusiness} LineOfBusiness={LineOfBusiness} handleLineOfBusinessCheckBoxChange={handleLineOfBusinessCheckBoxChange} selectAll={selectAll} ></AddEditCarrierManageLineOfBussinessCheckBox>


            </div>
        </React.Fragment>
    )
}