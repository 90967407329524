import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { config } from "../Constants";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { ButlerModelList } from "../components/Lists/ButlerModelList";
import { RightNav } from "../components/Nav/RightNav";
import useAuth from "../context/AuthProvider";
import { apiClient } from "../helper/api";

interface butlermodel {
    stateId: string | null | string;
    stateName: string | null | string;
}
export const ButlerModel = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const [ButlerModels, setButlerModels] = useState([]);

    const fetchData = () => {
        setloading(true);
        apiClient('/ButlerModel/getAllButlerModels',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then(res => {
                if (res?.data == null) return
                
                if (Array.isArray(res.data)) {
                    res.data.forEach((item: butlermodel) => {
                        if (item.stateId == null) {
                            item.stateName = "All state";
                        }
                    });
                }
                setButlerModels(res.data)

                // Do something with the response

                // setButlerModels(responseJson.data)
                // console.log("butlerdata", res.data)
            }).finally(() => {
                setloading(false);
            })



    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>

        //                         <LoggedInUserInfo ButtonText="Add New Model" Link="" />


        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="main shadow rounded" style={{ backgroundColor: "#ffffff" }}>

        //                                     <div style={{padding : "1rem" , paddingTop : "2rem", paddingBottom: "4rem"}}>

        //                                         <div className="table-responsive">
        //                                             {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}

                                                    <ButlerModelList ButlerModelCL={ButlerModels} fetchData={fetchData} />
        //                                         </div>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>


    )

}