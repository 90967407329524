var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// Required to make sure our styles pick up the font
import 'styles/poppins.css';
import { ThemeProvider, StylesProvider } from '@material-ui/core';
import { ButlerTheme } from 'common/theme/theme';
/**
 * Component that represents the results of inserting an object into an
 * external app within the context of an AppRun.
 * @param generateClassName Override default behavior of Material UI classname
 *                          generation. Used in testing to provide stable names
 */
export var ButlerProvider = function (_a) {
    var generateClassName = _a.generateClassName, children = _a.children;
    return (_jsx(ThemeProvider, __assign({ theme: ButlerTheme }, { children: _jsx(StylesProvider, __assign({ generateClassName: generateClassName, injectFirst: true }, { children: children })) })));
};
