import React, { useEffect, useState } from "react";
import useAuth, { authData } from "../../context/AuthProvider";
import { Box, Button, Dialog, DialogContent, IconButton, LinearProgress, Modal, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import { config } from '../../Constants';
import Swal from "sweetalert2";
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { apiClient } from "../../helper/api";

var formData = new FormData();
interface Iprops {

  handleClose: () => void,
  Open: boolean,
}
interface ProfilePictureModalProps {
  open: boolean;
  onClose: () => void;
  currentPicture: string;
  onUpdatePicture: (newPicture: string) => void;
  // userFirstName : string

}
const ProfilePictureModal: React.FC<ProfilePictureModalProps> = ({
  open,
  onClose,
  currentPicture,
  onUpdatePicture,
  // userFirstName

}) => {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      formData.delete('image');
      formData.append('image', file);
      setSelectedImage(file);
    }
  };

  const [loading, setloading] = useState(false);
  const handleUpload = () => {
    if (selectedImage) {
      setloading(true);
      const objectURL = URL.createObjectURL(selectedImage);
      // onUpdatePicture(objectURL);
      //Simulate server upload
      setTimeout(() => {
        onUpdatePicture(objectURL);
        setloading(false);
      }
        , 6000
      );

    }
  };
  const handleCloseImage = () => {
    setSelectedImage(null);
    formData.delete('image');
    onClose();
  };


  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg'  >

      {loading && <LinearProgress style={{ margin: 2 }} />}
      <DialogContent
        sx={{
          // width: 380,
          // height: 480,
          // bgcolor: "background.paper",
          // boxShadow: 24,
          borderRadius: "25px",
          textAlign: "center",
          outline: "none",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0px",
          position: "relative",
        }}
      >
        <IconButton
          onClick={handleCloseImage}
          sx={{
            position: "absolute",
            top: -10,
            right: 0,
            color: "#333",
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h5"
          component="h2"
          sx={{ mb: 3, fontWeight: "bold", fontSize: "20px" }}
        >
          Edit Profile Picture
        </Typography>
        <Box
          sx={{
            width: "300px",
            height: "300px",
            borderRadius: "50%",
            border: "4px solid #ddd",
            overflow: "hidden",
            mb: 2,
            position: "relative",
          }}
        >
          <img
            src={selectedImage ? URL.createObjectURL(selectedImage) : currentPicture}
            alt="Current"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <IconButton
            component="label"
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              bgcolor: "rgba(0, 0, 0, 0.6)",
              color: "#fff",
              borderRadius: "50%",
              p: 3,
              m: 3,
            }}
          >
            <CameraAltIcon />
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="profile-picture-upload"
              type="file"
              onChange={handleFileChange}
            />
          </IconButton>
        </Box>
        {/* <h4 className="m-0">{userFirstName}</h4> */}
        {/* <b>{userFirstName}</b> */}
        <Box  >
          <div className="d-flex">


            <Button
              variant="contained"
              color="primary"
              onClick={handleUpload}
              className="btn-profile-save me-2 "
            >
              {loading ? "Saving..." : "Save"}
            </Button>
            <div>

            </div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleCloseImage}
              className="btn-profile-cancel"
            >
              Cancel
            </Button> </div>
        </Box>
      </DialogContent>

    </Dialog>
  );
};


export const RightNav: React.FC<Iprops> = ({ handleClose, Open }) => {
  const { user, logout } = useAuth();


  const logOut = () => {
    logout();
  };

  const token = user?.token;
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };
  interface UserData {
    userId: number;

  }
  let userStr: authData | undefined = user;

  // const [userProfile , setUserProfile] = useState(userStr.profilePicture);
  const [userProfile, setUserProfile] = useState<string | null>(userStr?.profilePicture ?? "");




  const [loading, setloading] = useState(false);

  const handleUpdatePicture = (newPicture: string) => {
    debugger;

    const initValues: UserData = {
      userId: user?.userId ?? 0,

    };
    formData.append("data", JSON.stringify(initValues))
    console.log("finalData", initValues)
    setloading(true);
    apiClient(`/Users/UpdateProfilePicture`, {

      method: "POST",
      headers: new Headers({
        // "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }),
      body: formData


    })
      .then((res) => {
        if (!res) return
        if (res.resp == 0) {

          // let userStr : authData | undefined =  localStorage.getItem("user");
          // userStr.profilePicture = res.data;
          console.log("profleurl", res.data);
          console.log("loalstorageData", userStr);


          if (userStr != undefined) {
            userStr.profilePicture = res.data
            localStorage.removeItem("user");
            localStorage.setItem("user", JSON.stringify(userStr));
            setUserProfile(res.data);
          }


          Swal.fire({
            title: "Profile Picture Updated Successfully",
            text: "",
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            confirmButtonColor: '#02A4E3',
            confirmButtonText: 'OK',
            customClass: {
              confirmButton: 'btn-radius',
              cancelButton: 'btn-radius',
              popup: 'card-radius'
            }
          })
            .then((result) => {
              formData.delete("data");
              formData.delete("image");
              handleModalClose();
            })
        }
        setloading(false);
      })
      .finally(() => {
        setloading(false);
      });

  };

  const fullName = (user?.userFirstName ?? "") + " " + (user?.userLastName ?? "");

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "/assets/dist/js/myscripts.js";
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };

  }, []);
  return (
    <div hidden={Open} className="me-n1 p-4 bg-dm-light nav-wrapper custom-style-nav" >
      <div className="d-flex justify-content-between align-items-center">
        <img
          src="/assets/brand/MISC_Brandmark_Primary.png"
          className="align-self-center"
          style={{ width: "34px", height: "35px" }}
          alt="FileMule"
        ></img>
        <ul className="ms-auto d-flex mb-0 flex-wrap list-unstyled">
          <li className="px-2">
            <a onClick={handleClose}>
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.25 7.75L7.75 23.25M7.75 7.75L23.25 23.25"
                  stroke="white"
                  strokeWidth="2.25"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div>

      <div className="d-flex justify-content-center align-items-center round-img-sec">
        <div
          className="profile-circle-img me-2"
          onClick={handleModalOpen}
          style={{
            cursor: "pointer",
            borderRadius: "50%",
            overflow: "hidden",
            width: "70px",
            height: "70px",
          }}
        >
          {user?.profilePicture !== null ? (
            <img
              src={userProfile ?? ""}
              // src={userStr.profilePicture}

              alt="Blob Image"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <img
              src="/assets/brand/Screen Shot 2024-03-04 at 5.16 1.png"
              alt="Avatar"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          )}
        </div>
        <div>
          <p className="round-img-p">
            Hi, <b>{user?.userFirstName}</b>
          </p>
        </div>
      </div>

      <ProfilePictureModal
        open={modalOpen}
        onClose={handleModalClose}
        currentPicture={
          userProfile ||
          "/assets/brand/Screen Shot 2024-03-04 at 5.16 1.png"
        }
        onUpdatePicture={handleUpdatePicture}
      // userFirstName ={fullName}

      />


      <div className="nav-bar-right-sides">
        <ul className="list-unstyled mt-3 navigation">

          <li className="mb-2">
            <a
              href="/PolicyDashboard"
              className="px-4 py-3 d-block text-decoration-none text-white"
              title="Filing Queue"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-15"
              >
                <path
                  d="M12 11V17M9 14H15M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z"
                  stroke="#02A4E3"
                  strokeWidth="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Filing Queue
            </a>
            <ul className="mx-3 ms-4 ps-4 list-unstyled navigation">
                  {
                    ((window.location.pathname.split('/').length == 2 && ['8', '6', '5'].includes(window.location.pathname.split('/')[1])) || 
                     window.location.pathname.includes('FillingQueue/RemovedQueue')) && 
                      (
                          <li>
                          <a
                            href="/FillingQueue/RemovedQueue"
                            className="px-4 py-3 d-block text-decoration-none text-white"
                            title="Removed Queue"
                          >
                            - Removed Queue
                          </a>
                        </li>
                      )
                  }
                  </ul>
          </li>
          {user?.userRoles.split(",").includes("Super Admin") ||
            user?.userRoles.split(",").includes("MISC State Reporter")
            ? (
              <li className="mb-2">
                    <a
                      href="/ReconciliationQueue"
                      className="px-4 py-3 d-block text-decoration-none text-white"
                      title="Reconciliation Queue"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-15"
                      >
                        <path
                          d="M12 11V17M9 14H15M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z"
                          stroke="#02A4E3"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      Reconciliation Queue
                    </a>
                    <ul className="mx-3 ms-4 ps-4 list-unstyled navigation">
                    {
                    (window.location.pathname.includes('ReconciliationQueue') ||
                     window.location.pathname.includes('ReconciliationQueue/RemovedQueue'))&& 
                      (
                          <li>
                          <a
                            href="/ReconciliationQueue/RemovedQueue"
                            className="px-4 py-3 d-block text-decoration-none text-white"
                            title="Removed Queue"
                          >
                            - Removed Queue
                          </a>
                        </li>
                      )
                  }
                  </ul>
                </li>
            ) : (
              ""
            )}
                      {user?.userRoles.split(",").includes("Super Admin") && (
            <React.Fragment>
              <li className="mb-2 has-submenu1">
                <a
                  href="/Admin/Reporting"
                  className="px-4 py-3 d-block text-decoration-none text-white"
                  title="Billing"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Billing
                </a>
                <ul className="list-unstyled sub-menu1 mx-3 ms-4 ps-4 pb-2">
                  <li>
                    <a
                      href="/Billing/MonthEndBillingModule"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Tax"
                    >
                      Month End Billing
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Billing/FiledTransactions"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Client Billing Report"
                    >
                      Filed Transactions
                    </a>

                  </li>
                </ul>
              </li>
            </React.Fragment>
          )}
          {user?.userRoles.split(",").includes("Super Admin") ||
          user?.userRoles.split(",").includes("MISC State Reporter") ||
          user?.userRoles.split(",").includes("MISC Filer") ? (
          <React.Fragment>
            <li className="mb-2">
              <a
                href="/StateLogins"
                className="px-4 py-3 d-block text-decoration-none text-white"
                title="State Logins"
              >
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                State Logins
              </a>
            </li>
            </React.Fragment>
          ) : (
          ""

          )}
          {user?.userRoles.split(",").includes("Super Admin") ||
            user?.userRoles.split(",").includes("Outsource Staff") ||
            user?.userRoles.split(",").includes("MISC Filer")
            ? (
              <React.Fragment>


                {/* {user?.userRoles.split(",").includes("Super Admin") ? (
                  <React.Fragment>
                    <li className="mb-2">
                      <a
                        href="/Logs"
                        className="px-4 py-3 d-block text-decoration-none text-white"
                        title="Logs"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="mr-15"
                        >
                          <path
                            d="M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20M4 19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H20V2H6.5C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V19.5Z"
                            stroke="#02A4E3"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        Logs
                      </a>
                    </li> </React.Fragment>
                ) : (
                  ""
                )} */}
                {user?.userRoles.split(",").includes("Super Admin")
                  // user?.userRoles.split(",").includes("MISC Filer")
                  // ||  user?.userRoles.split(",").includes("MISC State Reporter") 
                  ? (
                    <React.Fragment>
                      <li className="mb-2">
                        <a
                          href="/EmailTemplates"
                          className="px-4 py-3 d-block text-decoration-none text-white"
                          title="Message Config"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="mr-15"
                          >
                            <path
                              d="M21 11.5C21.0034 12.8199 20.6951 14.1219 20.1 15.3C19.3944 16.7118 18.3098 17.8992 16.9674 18.7293C15.6251 19.5594 14.0782 19.9994 12.5 20C11.1801 20.0035 9.87812 19.6951 8.7 19.1L3 21L4.9 15.3C4.30493 14.1219 3.99656 12.8199 4 11.5C4.00061 9.92179 4.44061 8.37488 5.27072 7.03258C6.10083 5.69028 7.28825 4.6056 8.7 3.90003C9.87812 3.30496 11.1801 2.99659 12.5 3.00003H13C15.0843 3.11502 17.053 3.99479 18.5291 5.47089C20.0052 6.94699 20.885 8.91568 21 11V11.5Z"
                              stroke="#02A4E3"
                              strokeWidth="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Message Config
                        </a>
                      </li>
                      {/* <li>
                                    <a href="/ResetPassword" className="px-3 py-2 d-block text-decoration-none text-white" title="Reset password'">Rest Password</a>
                                </li> */}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
              </React.Fragment>
            ) : (
              ""
            )}

          {user?.userRoles.split(",").includes("Super Admin") ? (
            <React.Fragment>
              {/* <li className="mb-2">
                                    <a href="/Partners" className="px-4 py-3 d-block text-decoration-none text-white" title="Customer">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-people me-2" viewBox="0 0 16 16">
                                            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z" />
                                        </svg>
                                        Customer
                                    </a>
                                </li> */}
              {/* <li className="mb-2">
                                    <a href="/Insureds" className="px-4 py-3 d-block text-decoration-none text-white" title="State Flag Review">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-shield-check me-2" viewBox="0 0 16 16">
                                            <path d="M5.338 1.59a61.44 61.44 0 0 0-2.837.856.481.481 0 0 0-.328.39c-.554 4.157.726 7.19 2.253 9.188a10.725 10.725 0 0 0 2.287 2.233c.346.244.652.42.893.533.12.057.218.095.293.118a.55.55 0 0 0 .101.025.615.615 0 0 0 .1-.025c.076-.023.174-.061.294-.118.24-.113.547-.29.893-.533a10.726 10.726 0 0 0 2.287-2.233c1.527-1.997 2.807-5.031 2.253-9.188a.48.48 0 0 0-.328-.39c-.651-.213-1.75-.56-2.837-.855C9.552 1.29 8.531 1.067 8 1.067c-.53 0-1.552.223-2.662.524zM5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z" />
                                            <path d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                                        </svg>
                                        Insureds
                                    </a>
                                </li> */}

              {/* <li className="mb-2">
                                    <a href="/ComingSoon" className="px-4 py-3 d-block text-decoration-none text-white" title="State Flag Review">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope me-2" viewBox="0 0 16 16">
                                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                                        </svg>
                                        Messages Center
                                    </a>
                                </li> */}
              <li className="mb-2 has-submenu">
                <a
                  href="#"
                  className="px-4 py-3 d-block text-decoration-none text-white"
                  title="Admin"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M17 11L19 13L23 9M12.5 7C12.5 9.20914 10.7091 11 8.5 11C6.29086 11 4.5 9.20914 4.5 7C4.5 4.79086 6.29086 3 8.5 3C10.7091 3 12.5 4.79086 12.5 7Z"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Admin

                </a>
                <ul className="list-unstyled sub-menu mx-3 ms-4 ps-4 pb-2">
                  <li>
                    <a
                      href="/Admin/TaxesAndFees"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Surplus Line Taxes"
                    >
                      Surplus Line Taxes and Fees
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/Admin/TaxesAndFees/f"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Surplus Line Fees"
                    >
                      Surplus Line Fees
                    </a>
                  </li> */}


                  {/* <details className="mb-2">
                    <summary className="px-3 py-2 d-block text-decoration-none text-white" title="Filing Schedule">
                      Taxes and Fees
                    </summary>
                    <ul className="list-unstyled sub-menu mx-3 ms-4 ps-3 pb-2">
                      <li>
                        <a href="/Admin/Policy-Filing-Schedule/pf" className="px-3 py-2 d-block text-decoration-none text-white" title="Filing Schedule">
                          Filing Schedule
                        </a>
                      </li>
                      <li>
                        <a href="/Admin/TaxScheduleByJurisdiction" className="px-3 py-2 d-block text-decoration-none text-white" title="TaxSchedule By Jurisdiction">
                          KY Tax Jurisdictions
                        </a>
                      </li>
                    </ul>
                  </details> */}






                  <li className=" has-submenu1">
                    <a
                      href="/Admin/Policy-Filing-Schedule/pf"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Filing Schedule"
                    >
                      Filing Schedule
                    </a>
                    <ul className="list-unstyled sub-menu1 mx-3 ms-4 ps-3 pb-2">
                      <li>
                        <a
                          href="/Admin/Policy-Filing-Schedule/pf"
                          className="px-3 py-2 d-block text-decoration-none text-white"
                          title="Filing Schedule"
                        >
                          Tax Schedule
                        </a>
                      </li>
                      <li>
                        <a
                          href="/Admin/TaxScheduleByJurisdiction"
                          className="px-3 py-2 d-block text-decoration-none text-white"
                          title="TaxSchedule By Jurisdiction"
                        >
                          KY Tax Jurisdictions
                        </a>
                      </li>
                    </ul>
                  </li>


                  {/* <li className="d-flex align-item-center ml-3">
                    <a
                      href="/Admin/Policy-Filing-Schedule/pf"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Filing Schedule"
                    >
                      Filing Schedule
                    </a>
                  </li> */}
                  {/* <li>
                      <a
                        href="/Admin/Policy-Filing-Schedule/slt"
                        className="px-3 py-2 d-block text-decoration-none text-white"
                        title="Tax Payment Schedule"
                      >
                        Tax Payment Schedule
                      </a>
                    </li>
                    <li>
                      <a
                        href="/Admin/Policy-Filing-Schedule/sf"
                        className="px-3 py-2 d-block text-decoration-none text-white"
                        title="Fee Payment Schedule"
                      >
                        Fee Payment Schedule
                      </a>
                    </li> */}
                  {/* <li>
                    <a
                      href="/Admin/StateLogins"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="State Logins"
                    >
                      State Logins
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/Admin/StateRuleMatrix"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="State Rules"
                    >
                      State Rules
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Admin/StateStampList"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="State Stamps"
                    >
                      State Stamps
                    </a>
                  </li>
                  <li>
                    <a
                      href="/SurplusLineForms"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="State Surplus Lines Forms"
                    >
                      State Surplus Lines Forms
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Admin/StatesZeroFilingRule"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="State Zero Filing Rules"
                    >
                      State Zero Filing Rules
                    </a>
                  </li>
                  {/* <li>
                                    <a href="/Admin/LineOfBusiness" className="px-3 py-2 d-block text-decoration-none text-white" title="State Lines Of Business">State Lines Of Business</a>
                                </li> */}
                  <li>
                    {/* <a href="/Admin/InsuranceCompany" className="px-3 py-2 d-block text-decoration-none text-white" title="State Insurance Companies">State Insurance Companies</a> */}
                  </li>
                  {/* <li>
                                            <a href="/Admin/Users" className="px-3 py-2 d-block text-decoration-none text-white" title="Users">Users</a>
                                        </li> */}
                  <li>
                    <a
                      href="/Admin/TransactionType"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Transaction Type"
                    >
                      Transaction Type
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Admin/LineOfBusiness"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Line of Business"
                    >
                      Line of Business
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Admin/CarrierManagement"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Carrier Management"
                    >
                      Carrier Management
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Admin/ButlerModel"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Butler Model"
                    >
                      Butler Model
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Admin/ButlerMappings"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Butler Mappings"
                    >
                      Butler Mappings
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Admin/DocumentType"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Document Type"
                    >
                      Document Type
                    </a>
                  </li>
                  {/* <li>
                                    <a href="/Admin/ManualStateSubmissionSchedule" className="px-3 py-2 d-block text-decoration-none text-white" title="Manual State Submission">Manual State Submission</a>
                                </li> */}
                  <li>
                    <a
                      href="/Admin/AddStateFlags"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="State Flags"
                    >
                      State Flags
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/Admin/TaxScheduleByJurisdiction"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="TaxSchedule By Jurisdiction"
                    >
                      Tax Schedule By Jurisdiction
                    </a>
                  </li> */}
                </ul>
              </li>
            </React.Fragment>
          ) : (
            ""
          )}

          {/* {user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("Outsource Staff") || user?.userRoles.split(",").includes("MISC Filer") ?
                            <React.Fragment>
                                <li className="mb-2 has-submenu">
                                    <a href="#" className="px-4 py-3 d-block text-decoration-none text-white" title="Document Training">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-wallet me-2" viewBox="0 0 16 16">
                                            <path d="M0 3a2 2 0 0 1 2-2h13.5a.5.5 0 0 1 0 1H15v2a1 1 0 0 1 1 1v8.5a1.5 1.5 0 0 1-1.5 1.5h-12A2.5 2.5 0 0 1 0 12.5V3zm1 1.732V12.5A1.5 1.5 0 0 0 2.5 14h12a.5.5 0 0 0 .5-.5V5H2a1.99 1.99 0 0 1-1-.268zM1 3a1 1 0 0 0 1 1h12V2H2a1 1 0 0 0-1 1z" />
                                        </svg>
                                        Document Training
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-caret-right-fill nav-icon" viewBox="0 0 16 16">
                                            <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                                        </svg>
                                    </a>
                                    <ul className="list-unstyled sub-menu mx-3 ms-4 ps-3 pb-2">
                                        <li>
                                            <a href="/ButlerModelList" className="px-3 py-2 d-block text-decoration-none text-white" title="Document List">Document List</a>
                                        </li>
                                    </ul>
                                </li>
                            </React.Fragment>
                            : ""
                        } */}

          {user?.userRoles.split(",").includes("Super Admin") ||
            // user?.userRoles.split(",").includes("MISC Filer") ||
            //user?.userRoles.split(",").includes("MISC State Reporter") ||
            user?.userRoles.split(",").includes("Customer Admin") ? (
            <React.Fragment>
              <li className="mb-2">
                <a
                  href="/Partners"
                  className="px-4 py-3 d-block text-decoration-none text-white"
                  title="Profile"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  {user?.userRoles.split(",").includes("Super Admin")
                    // user?.userRoles.split(",").includes("MISC Filer")
                    //|| user?.userRoles.split(",").includes("MISC State Reporter") 
                    ? (
                      <>Customer</>
                    ) : (
                      ""
                    )}
                </a>
              </li>
              {/* <li className="mb-2 has-submenu1">
                <a
                  href="/Partners"
                  className="px-4 py-2 d-block text-decoration-none text-white"
                  title="Customer"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21M16 7C16 9.20914 14.2091 11 12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7Z"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Customer
                </a>
                <ul className="list-unstyled sub-menu1 mx-3 ms-4 ps-3 pb-2">
                  {user?.userRoles.split(",").includes("Super Admin") && (
                    <React.Fragment>
                      <li>
                        <a
                          href="/Partners"
                          className="px-4 py-3 d-block text-decoration-none text-white"
                          title="Profile"
                        >

                          {user?.userRoles.split(",").includes("Super Admin") ||
                            user?.userRoles.split(",").includes("MISC Filer") ? (
                            <>Customer</>
                          ) : (
                            "Profile"
                          )}
                        </a>
                      </li>

                      <li>
                        <a
                          href="/Admin/PartnerTaxandFees"
                          className="px-3 py-2 d-block text-decoration-none text-white"
                          title="Surplus Line Taxes"
                        >
                          Partner Fees and Taxes
                        </a>
                      </li>


                    </React.Fragment>
                  )}
                </ul>
              </li> */}
            </React.Fragment>
          ) : (
            ""
          )}
          {user?.userRoles.split(",").includes("Super Admin") ||
            // user?.userRoles.split(",").includes("MISC Filer") ||
            // user?.userRoles.split(",").includes("MISC State Reporter") ||
            user?.userRoles.split(",").includes("Outsource Staff") ||
            user?.userRoles.split(",").includes("Customer Admin") ? (
            <React.Fragment>
              <li className="mb-2">
                <a
                  href="/UserMappingRequestLog"
                  className="px-4 py-3 d-block text-decoration-none text-white"
                  title="Request New Carrier"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M12 20H21M16.5 3.49998C16.8978 3.10216 17.4374 2.87866 18 2.87866C18.2786 2.87866 18.5544 2.93353 18.8118 3.04014C19.0692 3.14674 19.303 3.303 19.5 3.49998C19.697 3.69697 19.8532 3.93082 19.9598 4.18819C20.0665 4.44556 20.1213 4.72141 20.1213 4.99998C20.1213 5.27856 20.0665 5.55441 19.9598 5.81178C19.8532 6.06915 19.697 6.303 19.5 6.49998L7 19L3 20L4 16L16.5 3.49998Z"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Request New Carrier
                </a>
              </li>
              {/* <li className="mb-2">
                            <a href="/Admin/ReportModule" className="px-4 py-3 d-block text-decoration-none text-white" title="Reporting">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag me-2" viewBox="0 0 16 16">
                                    <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z" />
                                </svg>
                                Reporting
                            </a>
                        </li> */}
            </React.Fragment>
          ) : (
            ""
          )}

          {user?.userRoles.split(",").includes("Super Admin") && (
            <React.Fragment>
              <li className="mb-2 has-submenu1">
                <a
                  href="/Admin/Reporting"
                  className="px-4 py-3 d-block text-decoration-none text-white"
                  title="Reporting"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Reporting
                </a>
                <ul className="list-unstyled sub-menu1 mx-3 ms-4 ps-4 pb-2">

                  {/* <li>
                    <a
                      href="/ReconciliationQueue"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Tax Reporting"
                    >
                      Reconciliation Queue
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="/Reporting/DiligentSearch"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Tax 
                      
                      
                      "
                    >
                      Diligent Search Report
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Reporting/OpenTaxReport"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Open Tax Report"
                    >
                      Open Tax Report
                    </a>
                  </li>
                  {/* <li>
                    <a
                      href="/Reporting/ClientBillingReport"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Client Billing Report"
                    >
                      Client Billing Report
                    </a>

                  </li> */}
                  <li>
                    <a
                      href="/Reporting/EmailReportModule"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Email Reporting"
                    >
                      Email Reporting
                    </a>
                  </li>

                </ul>
              </li>
            </React.Fragment>
          )}




          {/* {user?.userRoles.split(",").includes("Super Admin") ? (
            <React.Fragment>
              <li className="mb-2 has-submenu">
                <a
                  href="#"
                  className="px-4 py-3 d-block text-decoration-none text-white"
                  title="Reporting"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M4 15C4 15 5 14 8 14C11 14 13 16 16 16C19 16 20 15 20 15V3C20 3 19 4 16 4C13 4 11 2 8 2C5 2 4 3 4 3V15ZM4 15V22"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Reporting
                </a>
                <ul className="list-unstyled sub-menu mx-3 ms-4 ps-3 pb-2">
                  <li>
                    <a
                      href="/Admin/Reporting/OpenTaxReport"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Open Tax Report"
                    >
                      Open Tax Report
                    </a>
                  </li>
                  <li>
                    <a
                      href="/Reporting/EmailReportModule"
                      className="px-3 py-2 d-block text-decoration-none text-white"
                      title="Email Reporting"
                    >
                      Email Reporting
                    </a>
                  </li>
                </ul>
              </li>
            </React.Fragment>
          ) : (
            ""
          )} */}

          {user?.userRoles.split(",").includes("Super Admin") ||

            user?.userRoles.split(",").includes("Outsource Staff") ||
            user?.userRoles.split(",").includes("Customer Admin") ? (
            <li className="mb-2">
              <a
                href="/Admin/Users"
                className="px-4 py-3 d-block text-decoration-none text-white"
                title="Users"
              >
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-flag me-2" viewBox="0 0 16 16">
                                <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001M14 1.221c-.22.078-.48.167-.766.255-.81.252-1.872.523-2.734.523-.886 0-1.592-.286-2.203-.534l-.008-.003C7.662 1.21 7.139 1 6.5 1c-.669 0-1.606.229-2.415.478A21.294 21.294 0 0 0 3 1.845v6.433c.22-.078.48-.167.766-.255C4.576 7.77 5.638 7.5 6.5 7.5c.847 0 1.548.28 2.158.525l.028.01C9.32 8.29 9.86 8.5 10.5 8.5c.668 0 1.606-.229 2.415-.478A21.317 21.317 0 0 0 14 7.655V1.222z" />
                            </svg> */}
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-15"
                >
                  <path
                    d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783 15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614 16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13M16 3.13C16.8604 3.3503 17.623 3.8507 18.1676 4.55231C18.7122 5.25392 19.0078 6.11683 19.0078 7.005C19.0078 7.89317 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
                    stroke="#02A4E3"
                    strokeWidth="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Users
              </a>
            </li>
          ) : (
            ""

          )}
          {user?.userRoles.split(",").includes("Super Admin") ? (
            <React.Fragment>
              <li className="mb-2">
                <a
                  href="/Logs"
                  className="px-4 py-3 d-block text-decoration-none text-white"
                  title="Logs"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-15"
                  >
                    <path
                      d="M4 19.5C4 18.837 4.26339 18.2011 4.73223 17.7322C5.20107 17.2634 5.83696 17 6.5 17H20M4 19.5C4 20.163 4.26339 20.7989 4.73223 21.2678C5.20107 21.7366 5.83696 22 6.5 22H20V2H6.5C5.83696 2 5.20107 2.26339 4.73223 2.73223C4.26339 3.20107 4 3.83696 4 4.5V19.5Z"
                      stroke="#02A4E3"
                      strokeWidth="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Logs
                </a>
              </li> </React.Fragment>
          ) : (
            ""
          )}
        </ul>
      </div>

    </div >

  );
};
