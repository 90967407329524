
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';

import { FieldAttributes, Form, Formik } from 'formik';
import { config } from '../../Constants';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import { StateError_VS, stateFlagsModel } from '../../models/StateFlagsModel';
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import Swal from 'sweetalert2';
import moment from 'moment';
import { apiClient } from '../../helper/api';


interface Iprops {
    stateFlagsModel: stateFlagsModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: stateFlagsModel,
    stateFlagId: string,

}

export const AddEditStateFlags: React.FC<Iprops> = ({ handleClose, Open, stateFlagsModel, handleSubmit, initValues, stateFlagId }) => {
    interface stateList {
        stateId: string, stateName: string
    }
    interface tabsList {
        tabId: string, tabName: string
    }
    const initialValues: stateFlagsModel = initValues ? stateFlagsModel : initValues;
    const { user } = useAuth();
    const token = user?.token;
    const initialdata = { stateId: "", stateName: "" };
    const [states, setStates] = useState<stateList[]>([initialdata])
    const initialdataForTabs = { tabId: "", tabName: "" };
    const [tabs, SetTabs] = useState<tabsList[]>([initialdataForTabs])

    const fetchDatadoc = () => {

        apiClient(`/States/getStates`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson?.data != null) {
                    
                    console.log(responseJson.data)
                    setStates(responseJson.data)
                }


            })


    }

    const fetchTabs = () => {

        apiClient(`/PolicyDashBoard/GetPolicyDashboardTabs`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson?.data != null) {
                    
                    console.log(responseJson.data)
                    SetTabs(responseJson.data)
                }


            })


    }

    const FMStateCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";


        return (
            <FormControl error={!!errorText} fullWidth  >
                <InputLabel id="State-Select-Label">Select State</InputLabel>
                <Select {...field}
                    labelId="State-Select-Label"
                    label="Select State"
                    value={field.value || ""}
                    className="border-round main-form-2     "
                    IconComponent={ExpandMoreIcon}
                >
                    {states.map((state, key) =>
                        <MenuItem key={key} value={state.stateId.toString()}> {state.stateName} </MenuItem>

                    )}

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl >

        )
    }


    const FMSTabsCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";


        return (
            <FormControl error={!!errorText} fullWidth  >
                <InputLabel id="Tabs-Select-Label" >Select Tab Name</InputLabel>
                <Select {...field}
                    labelId="Tabs-Select-Label"
                    id="Tabs-Select-Label"
                    label="Tabs-Select-Label"
                    value={field.value || ""}
                    className="border-round main-form-2"
                    IconComponent={ExpandMoreIcon}
                // displayEmpty
                >
                    {/* <MenuItem key={0} value={0}>
                    Select Tab Name
                    </MenuItem> */}
                    {tabs.map((tab, key) =>
                        <MenuItem key={key} value={tab.tabId.toString()}> {tab.tabName} </MenuItem>

                    )}


                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }

    useEffect(() => {
        fetchDatadoc();
        fetchTabs();
    }, [])

    return (

        <Dialog open={Open} onClose={handleClose} maxWidth="lg">
            {/* <DialogTitle className='card-title' >Add State Flags</DialogTitle> */}
            {initialValues.stateFlagId === 0 ? <h2 style={{ marginLeft: "2vw" }} className='card-title' >Add State Flags</h2> : <h2 style={{ marginLeft: "2vw" }} className='card-title'>Edit State Flags</h2>}
            <Formik initialValues={initialValues}
                validateOnChange={true}
                validationSchema={StateError_VS}
                onSubmit={(data, { setSubmitting }) => {
                    console.log(data);
                    

                    apiClient('/StateFlags/AddUpdateStateFlagsById',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((res) => {
                            if (!res) return
                            Swal.fire({
                                title: "Data Successfully saved",
                                text: "",
                                icon: "success",
                                confirmButtonText: "OK",
                            });
                            // Do something with the response
                            handleSubmit()
                            setSubmitting(true)
                        })
                }} >
                {({ values, isSubmitting }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >

                                        <FormHelperText></FormHelperText>
                                        <FMTextField name="stateFlagName" autoFocus margin="dense" label="State Flag Name" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} className='main-form' >
                                        <FormHelperText></FormHelperText>
                                        <FMTextField name="errorDescription" autoFocus margin="dense" label="Error Description" type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                        <FMStateCustom variant={"standard"} name="stateId" ></FMStateCustom>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                        <FMSTabsCustom variant={"standard"} name="tabId" ></FMSTabsCustom>
                                    </FormControl>
                                </Grid>

                            </Grid>


                        </DialogContent>

                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn " type='button' onClick={handleClose}  >Cancel</button>
                            <button className="btn  btn-primary active-btn-blue  search-btn " disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog>
    )
}
