import *  as yup from 'yup'
import { PartnerAgencyDetailsModel } from './PartnerAgencyDetails'
import { partnertaxesandfeesModel } from './PartnerTaxandFeeModel'


// export interface PartnersModel {
//     partnersId: number,
//     customerName?: string,
//     partnerType?: number,
//     streetAddress?: string,
//     suitOrUnit?: string,
//     city?: string,
//     state?: string,
//     zipCode?: string,
//     naic?:string,
//     taxId?:string
//     numberOfStates?:string
//     filingContactName?:string
//     filingContactEmail?:string
//     filingContactPhone?:string
//     accountingContactName?:string
//     accountingContactEmail?:string
//     accountingContactPhone?:string
//     paymentType?:boolean
//     paymentPartnerType?:number
//     paymentStreetAddress?:string
//     paymentSuitOrUnit?:string
//     paymentCity?:string
//     paymentState?:number
//     paymentZipCode?:number
//     paymentNaic?:string
//     paymentTaxId?:string
//     partnerIntegrationType?:number
//     agencyPolicyManagementSystem?:string
//     userName?:string
//     password?:string
//     Createdby?:number
//     modifiedBy?:number
// }


// export interface partnerTaxAndFee {
//   PartnerTaxesandFeeId: number
//   PartnerId: number
//   StateId: number
//   LOBId: number
//   Rate: number
//   RateType: string
//   IsDeleted: boolean
//   DateCreated: Date
//   DateModified: Date
//   Notes: string
//   CreatedBy: number
//   ModifiedBy: number
//   TransactionTypeId: number

// }
export interface PartnersModel {
  partnersId: number,
  customerName?: string,
  partnerTypeId?: number,
  streetAddress?: string,
  suitOrUnit?: string,
  city?: string,
  state?: number,
  zipCode?: string,
  agencyLicense?: string,
  taxId?: string,
  numberOfStates?: string,
  filingContactName?: string,
  filingContactEmail?: string,
  filingContactPhone?: string,
  accountingContactName?: string,
  accountingContactEmail?: string,
  accountingContactPhone?: string,
  licenseeContactName?: string,
  licenseeContactEmail?: string,
  licenseeContactPhone?: string,
  slaAdminContactName?: string,
  slaAdminContactEmail?: string,
  slaAdminContactPhone?: string,
  escalationContactName?: string,
  escalationContactEmail?: string,
  escalationContactPhone?: string,
  drlpContactName?: string,
  drlpContactEmail?: string,
  drlpContactPhone?: string,
  paymentType?: true,
  paymentPartnerType?: number,
  paymentStreetAddress?: string,
  paymentSuitOrUnit?: string,
  paymentCity?: string,
  paymentState?: number,
  paymentZipCode?: number,
  paymentNaic?: string,
  paymentTaxId?: string,
  partnerIntegrationType?: number,
  createdBy?: number,
  modifiedBy?: number,
  userName?: string,
  password?: string,
  agencyPolicyManagementSystem?: string,
  selectedInsuranceCompany?: string
  startDate?: Date,
  status: boolean,
  typeName?: string
  contactInformation?: string,
  accontInformation?: string,
  licenseeContactInformation?: string,
  slaAdminInformation?: string,
  escalationContactInformation?: string,
  dRLPContactInformation?: string,
  sLAApiKey?: string,
  sLABrokerNumber?: string,
  sLAUserName?: string,
  partnerAgencyDetails?: PartnerAgencyDetailsModel[]
  latestTransactionNumber?: string
  previousCredit?: number,
  originalAmount?: number,
  carryForward?: number,
  generateSL1?: boolean,
  signature?: string,
  apiToken?: string,
  isDiligentSearch?: boolean
  partnerTaxAndFeesDTO? : partnertaxesandfeesModel[],
  // Fees Atributes
  policyAmt?: string,
  endorsementAmt?: string,
  stateReportAmt?: string




}
export const PartnersModelVS = yup.object({

  customerName: yup.string().required("please enter Customer Name").trim().typeError('Invalid value'),
  // carrier : yup.number().required("please select Carrier").typeError('Invalid value').min(1 ,"Please select Carrier"),
  partnerTypeId: yup.number().required("please select Partner Type").typeError('Invalid value').min(1, "please select Customer Type"),
  state: yup.number().required("please select State").typeError('Invalid value').min(1, "please select State"),
  partnerIntegrationType: yup.number().required("please select Integration").typeError('Invalid value').min(1, "please select Integration"),
  // lineOfBusiness: yup.number().required("please select Line of Business").typeError('Invalid value').min(1 ,"please select Line of Business")

})