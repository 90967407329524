import * as yup from 'yup'

export interface UploadDocumentModel {
    Transactionid : number |string | undefined,
    ModelId : number,
    
    
   
}
    
export const UploadDocumentModel_VS = yup.object({
   
    ModelId: yup.number().min(1,"Please select a Model").required("Please select a Model")

  })
  
  