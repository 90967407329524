import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import { PartnersModel } from "../models/PartnersModel";
import useAuth from "../context/AuthProvider";
import { AddEditUsers } from "../components/AddEdit/AddEditUsers";
import { config } from "../Constants";
import moment from "moment";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { RightNav } from "../components/Nav/RightNav";
import { UsersModel } from "../models/UsersModel";
import { apiClient } from "../helper/api";

export const PartnerUsers: React.FC = () => {

    const initialUserData: UsersModel = {
        userId: 0,
        userName: "",
        password: "",
        isActive: false,
        dateCreated: new Date,
        datemodified: new Date,
        userEmail: "",
        firstName: "",
        lastName: "",
        roleIds: "",
        partnersId: 0,
        userRoles: ""
    }

    const [UsersCL, setUserCL] = React.useState([initialUserData]);

    const [loading, setloading] = useState(false);
    const params = useParams();
    const [Open, setOpen] = React.useState(false);
    const [Formdata, setFormData] = React.useState<UsersModel>(initialUserData);

    const { user } = useAuth();
    const token = user?.token;

    const [Partner,setPartner] = useState<PartnersModel>();

    const columns: GridColDef[] = [

        { field: 'userFirstName', type: 'string', headerName: 'First Name', flex: 1 },
        { field: 'userLastName', type: 'string', headerName: 'Last Name', flex: 1 },
        { field: 'username', headerName: 'Username', width: 130 },
        { field: 'userEmail', type: 'string', headerName: 'userEmail ', flex: 1 },
        { field: 'isactive', type: 'boolean', headerName: 'isactive ', flex: 1 },
        // { field: 'isDeleted', type: 'boolean', headerName: 'isDeleted ', flex: 1 },
        { field: 'dateCreated', type: 'date', headerName: 'dateCreated ', flex: 1, renderCell: (params) => (<div> {params.row.dateCreated ? moment(params.row.dateCreated).format("YYYY-MM-DD") : ""}</div>) },
        { field: 'datemodified', type: 'date', headerName: 'datemodified ', flex: 1, renderCell: (params) => (<div> {params.row.datemodified ? moment(params.row.datemodified).format("YYYY-MM-DD") : ""}</div>) },
        {
            field: 'userId', flex: 1, headerName: "Action", renderCell: (params) => (<div>

                <IconButton
                    color="primary"
                    onClick={() => { handleEdit(params.row) }}

                >
                    <EditIcon />
                </IconButton >

                <IconButton
                    color="error"
                    onClick={() => { handleDelete(params.row.userId) }}

                >
                    {params.row.isDeleted ? <RestoreFromTrashIcon /> : <DeleteIcon />}
                </IconButton >
            </div>)
        },

    ];

    const fetchData = () => {
        setloading(true);

       apiClient(`/Users/getPartnerUsers?PartnersId=${params.partnersId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return

                // Do something with the response
                setUserCL(res.data)
            }).finally(() => {
                setloading(false);
            });

           apiClient(`/Partners/getPartnerById?partnersId=${params.partnersId}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return

                // Do something with the response
                setPartner(res.data)
            }).finally(() => {
                setloading(false);
            });



    }
    useEffect(() => {
        fetchData();
    }, [])


    const handleClickOpen = () => {
        const newformdata : UsersModel ={
            userId: 0,
            userName: "",
            password: "",
            isActive: false,
            dateCreated: new Date,
            datemodified: new Date,
            userEmail: "",
            firstName: "",
            lastName: "",
            roleIds: "",
            partnersId: 0,
            userRoles: ""
        } 
        setFormData(newformdata);
        setOpen(true);
    };

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);

    };
    const handleEdit = (ILobData: UsersModel) => {
        ILobData.password = "";
        setFormData(ILobData);
        setOpen(true);
    };
    const handleDelete = (id: number) => {

        //call api to save data
       apiClient("/Users/Delete?id=" + `${id}`,
            {
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                method: "Delete",
            }
        )
            .then((res) => {
                if (!res) return
                // Do something with the response
                fetchData();
                setOpen(false);
            })

    };


    function EditToolbar() {


        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <Button
                    onClick={handleClickOpen}
                    color="primary"
                    variant="outlined"
                >
                    Add
                </Button>


            </Box>

        );

    }
    return (
        <div className='dashboard'>
         

            <div className="col-lg-10 col-md-12 col-sm-12 pl-space" >
                <h1 className="dashboard-form-heading text-center">Shield-Knot <span className="sidenav-icon"><i className='bx bx-menu'></i></span></h1>
                <svg className="MobileWaves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"></use>
                    </g>
                </svg>
                <div className="row heading align-items-center m-0">
                    <div className="col-md-7 col-12">
                        <div className="dashboard-content">
                        <LoggedInUserInfo ButtonText="" Link=""/>
                        </div>
                    </div>
                    <div className="col-md-5 col-12">
                        {/* <div className="search">
                            <i className="fa fa-search"></i>
                            <input type="text" className="searchbox" placeholder="Add a report" />
                            <button className="btn btn-primary"> <i className="fa fa-check"></i></button>
                        </div> */}
                    </div>


                </div>
                {/* <Policies/> */}
                <div className="card-body">
                    <div className="col-lg-12 col col-margin">
                        <div className="card dashboard-table">

                            <span className="card-title"> {Partner?.customerName  }</span>
                         
                            <div className="table-responsive">
                                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                <div style={{ height: 'auto', width: '100%' }}>
                                    <DataGrid autoHeight={true}
                                        getRowId={(r) => r.userId}
                                        rows={UsersCL}
                                        columns={columns}
                                        pageSize={50}
                                        rowsPerPageOptions={[5]}

                                        components={{
                                            Toolbar: EditToolbar,
                                        }}
                                        sx={
                                            {
                                                fontFamily: "'Proxima Nova', sans-serif;",
                                                border: "0px",
                                                fontSize: "16px"



                                            }
                                        }
                                    />
                                    {/* <AddEditUsers handleClose={handleClose} Open={Open} UsersBL={Formdata} handleSubmit={handleSubmit} initValues={initialUserData}  ></AddEditUsers> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            {/* <RightNav /> */}

        </div>



    )



}