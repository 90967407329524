import { Button, LinearProgress, Link } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { ButlerModelDocumentList } from "../components/Lists/ButlerModelDocumentList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { UploadModelDocument } from "./UploadModelDoocuments";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { apiClient } from "../helper/api";

export const ButlerModelDocument = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const [ButlerModelDocuments, setButlerModelDocuments] = useState([]);
    const params = useParams();

    const fetchData = () => {
        console.log("in fetchdata");
        setloading(true);
        apiClient(`/ButlerModelDocument/getByModelId?modelId=${params.modelId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response

                setButlerModelDocuments(responseJson.data)
            }).finally(() => {
                setloading(false);
            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

                            <LoggedInUserInfo ButtonText="" Link=""/>


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card-body">
                                            <div className="col-lg-12 col col-margin">
                                                <div className="card dashboard-table">
                                                    <div className="row col-lg-12">
                                                        <div className="col-lg-4 d-flex">
                                                            <div style={{ marginRight: "2px" }}>
                                                                <Link href="/">
                                                                    <Button type="button" title="Home"
                                                                        className="btn action-btn" ><HomeIcon />
                                                                    </Button>
                                                                </Link>
                                                            </div>
                                                            <div>
                                                                <Button

                                                                    className=" btn action-btn"
                                                                    onClick={goBack}
                                                                    title="Back"
                                                                >
                                                                    <ArrowBackIosIcon />
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-4" style={{ textAlign: "center" }}>
                                                            <span className="card-title">Document List</span>
                                                        </div>

                                                    </div>


                                                    <div className="table-responsive">
                                                        {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                        <ButlerModelDocumentList ButlerModelDocumentCL={ButlerModelDocuments} fetchData={fetchData} />
                                                        <UploadModelDocument />
                                                        {/* <ButlerModelList ButlerModelCL={ButlerModels} fetchData={fetchData} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>

    )

}