import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, } from '@mui/material';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { FMSelectCustomerPartnerStatus, FMSelectState } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { PartnersModel, PartnersModelVS } from '../../models/PartnersModel';
import { InsuredModel } from '../../models/InsuredsModel';
import { RiskLocationAddress } from '../../models/RiskAddressModel';
import { useParams } from 'react-router-dom';
import { apiClient } from '../../helper/api';

interface Iprops {
    riskAddressCL: RiskLocationAddress,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: RiskLocationAddress
    fetchData:()=>void
}

export const AddEditRiskAddress: React.FC<Iprops> = ({ handleClose, Open, riskAddressCL, handleSubmit, initValues ,fetchData}) => {
    const initialValues: RiskLocationAddress = initValues ? riskAddressCL : initValues;
    const { user } = useAuth();
    const token = user?.token;

    const params = useParams();

    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle  className='card-title'  >Risk Location</DialogTitle>
            <Formik

                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                onSubmit={(data, { setSubmitting }) => {
                    data.policyId = Number(params.policyid) ?? 0
                    console.log(data);
                    apiClient('/RiskLocationAddress/RiskLocationAddressUpdate',
                        {
                            method: 'POST',

                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((res) => {
                            if (!res) return
                            // Do something with the response

                            handleSubmit()
                            setSubmitting(true)
                            fetchData();
                        })
                }} >
                {({ values, isSubmitting, setFieldValue }) => (
                    <Form className='main-form'>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                    <FormHelperText className='formtexthelper-heading' >
                                            Address
                                            </FormHelperText>
                                        <FMTextField name="addressLine1"  label={null} type="Text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }}><FormHelperText className='formtexthelper-heading' >
                                    City
                                            </FormHelperText>
                                        <FMTextField name="city" InputLabelProps={{ shrink: true }} label={null} autoFocus margin="dense" type="text" variant="outlined" sx={{ m: 2 }} />
                                    </FormControl >
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} ><FormHelperText className='formtexthelper-heading' >
                                    State
                                            </FormHelperText>
                                        <FMSelectState name="stateId" placeholder='State' label={null} />
                                    </FormControl>
                                </Grid>                                
                                <Grid item xs={4}>
                                    <FormControl sx={{ width: '100%' }} >
                                    <FormHelperText className='formtexthelper-heading' >
                                    Zip
                                            </FormHelperText>
                                        <FMTextField name="zipCode"  label={null} type="number" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>

                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <button className=' btn-2 btn d-flex btn-primary search-btn ' onClick={handleClose}  >Cancel</button>
                            <button  className='btn  btn-primary active-btn-blue  search-btn ' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog >
    )
}
