import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { CustomerPolicyList } from "../components/Lists/CustomerPolicyList";
import { FillinsByUserList } from "../components/Lists/FillingsByUserList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from '../Constants'
import useAuth from "../context/AuthProvider";
import { FillingsByUser } from "./FillingsByUser";
import { apiClient } from "../helper/api";

export const FMFlagReview = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [Fillings, setFillings] = useState([]);
    const [loading, setloading] = useState(false);
    const params = useParams();


    const fetchData = () => {
        setloading(true);
        apiClient(`/FillingsByUser/getFMFlagReviewFillingsByUser?type=${params.type}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                // Do something with the response
                console.log(res.data);
                if (res.data != null) {
                    setFillings(res.data);

                }

            }).finally(() => {
                setloading(false);
            });




    }
    useEffect(() => {
        fetchData();

    }, [])


    return (


        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

                            <LoggedInUserInfo ButtonText={''} Link={''}/>


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">



                                            <div className="table-responsive">
                                                <div style={{marginBottom: "1hv"}}>
                                                    {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                                </div>

                                                <FillinsByUserList FillingsCl={Fillings} fetchData={fetchData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>

    )

}