import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useAuth from "../../context/AuthProvider";
import DialogTitle from '@mui/material/DialogTitle';
import { InsuranceCompanyModel, InsuranceCompanyModel_VS } from '../../models/insuranceCompanyModel';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { Box, Checkbox, FormControl, FormControlLabel, Grid, LinearProgress, TextField } from '@mui/material';
import { FMSelectActiveInactive, FMSelectState } from '../Elements/select';
import { StateCheckBox } from '../Elements/StateCheckBox';
import { stateOptions } from './AddEditPartners';
import { SetStateAction, useState } from 'react';
import { LineOfBusinessCheckBox } from '../Elements/LineOfBusinessCheckBox';
import { lineOfBusinessModel } from '../Lists/CarrierManagementList';
import { TurnLeftTwoTone } from '@mui/icons-material';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FMTextField } from '../Elements/input';
import { apiClient } from '../../helper/api';

interface Iprops {
    InsuranceCompanyCL: InsuranceCompanyModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void,
    initValues: InsuranceCompanyModel
    selectedStates: stateOptions[]
    States: stateOptions[],
    setselectedStates: React.Dispatch<SetStateAction<stateOptions[]>>,
    setStates: React.Dispatch<SetStateAction<stateOptions[]>>,
    handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectedLineOfBusiness: lineOfBusinessModel[],
    LineOfBusiness: lineOfBusinessModel[]
    handleLineOfBusinessCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    //handleStartDate: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectAll: () => void,
    handleStateEdit: (id: number) => void
    setselectedLineOfBusiness: React.Dispatch<React.SetStateAction<lineOfBusinessModel[]>>
}
interface IStateCheckBoxprops {
    selectedStates: stateOptions[],
    States: stateOptions[],
    handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    handleStateEdit: (id: number) => void
}

interface CustomFormData {
    InsuranceCompanyData: InsuranceCompanyModel,
    selectedStates: string,
    selectedLineOfBusiness: string,
    states: stateOptions[],
    lineOfBusiness: lineOfBusinessModel[]
    //selectedDates:Date,
}

const StateCheckBoxCustom: React.FC<IStateCheckBoxprops> = ({ selectedStates, States, handleCheckBoxChange, handleStateEdit }) => {
    const params = useParams();
    const { user } = useAuth();
    const token = user?.token;
    var [isChecked, setIsChecked] = useState(false);

    return (



        <Grid container spacing={2} style={{ overflow: "auto" }}>

            {
                States?.map((item, key) => (

                    <Grid item key={key} xs={4} maxHeight={12} marginLeft="3vw" marginTop="20px" marginBottom="20px">
                        <FormControlLabel
                            style={{ pointerEvents: "none" }}
                            control={
                                <Checkbox style={{ pointerEvents: "auto" }} key={key} name={item.stateId.toString()} onChange={handleCheckBoxChange} checked={selectedStates.some(element => {
                                    if (element.stateId === item.stateId) {
                                        isChecked = true;
                                        return true
                                    }
                                    isChecked = false;
                                    return false
                                })} />

                            }
                            label={
                                <div className="d-flex gap-2">
                                    <div >
                                        {item.stateName}
                                    </div>
                                    <div>
                                        {isChecked ? (
                                            <Button
                                                className=" btn action-btn"
                                                onClick={() => handleStateEdit(item.stateId)}
                                                title="Edit"
                                            >
                                                <i className="fa fa-edit text-primary" style={{ pointerEvents: "auto" }} ></i>
                                            </Button>

                                        ) : (
                                            <i></i>
                                        )}
                                    </div>
                                </div>
                            }
                        />

                    </Grid>
                ))}

        </Grid> 
    );
}


export const AddEditCarriermanagement: React.FC<Iprops> = ({ selectAll, handleClose, Open, handleStateEdit, InsuranceCompanyCL, handleSubmit, initValues, selectedStates, States, setselectedStates, setselectedLineOfBusiness, setStates, handleCheckBoxChange, selectedLineOfBusiness, LineOfBusiness, handleLineOfBusinessCheckBoxChange }) => {

    const initialValues: InsuranceCompanyModel = initValues ? InsuranceCompanyCL : initValues;
    const { user } = useAuth();
    const token = user?.token;

    let initFomvalues: CustomFormData = {
        InsuranceCompanyData: initialValues,
        selectedStates: '',
        selectedLineOfBusiness: '',
        states: [],
        lineOfBusiness: []
    }


    const [loading, setloading] = useState(false);

    return (

        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}   >
            <DialogTitle className='card-title' >Carrier Management</DialogTitle>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <Formik initialValues={initFomvalues}
                    validateOnChange={true}
                    validationSchema={InsuranceCompanyModel_VS}
                    onSubmit={(data, { setSubmitting }) => {
                        setloading(true);
                        data.lineOfBusiness = selectedLineOfBusiness;
                        data.states = selectedStates;
                        data.InsuranceCompanyData.startDate = data.InsuranceCompanyData.startDate ?? '';
                        if (data.states.length == 0) {
                            Swal.fire({
                                title: 'Warning',
                                text: "Please select a state",
                                icon: 'warning',
                                confirmButtonColor: '#3085d6',
                            })
                                .then((result) => {
                                    setloading(false);
                                    setSubmitting(false);
                                    return;
                                })
                        }
                        else {
                            apiClient('/InsuranceCompany/put',
                                {
                                    method: 'POST',
                                    headers: new Headers({
                                        'Content-Type': 'application/json',
                                        'Authorization': `Bearer ${token}`,
                                    }),
                                    body: JSON.stringify(data)  // <-- Post parameters

                                })
                                .then((response) => {
                                    setloading(false);

                                    if (!response) return
                                    Swal.fire({
                                        title: "Data Successfully saved",
                                        text: "",
                                        icon: "success",
                                        confirmButtonText: "OK",
                                    });
                                    handleClose();
                                    setselectedLineOfBusiness([]);
                                    // Do something with the response
                                    handleSubmit()
                                    
                                }).finally(() => {
                                    setloading(false);
                                    setSubmitting(true)
                                });
                        }

                    }} >
                    {({ values, isSubmitting, setFieldValue, touched, errors }) => (

                        <Form className="main-form" >


                            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                            <DialogContent  >
                                <Grid container spacing={2}  >


                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="InsuranceCompanyData.name" autoFocus margin="dense"  label="Carrier" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="InsuranceCompanyData.naicNumber" autoFocus margin="dense"  label="NAIC Number" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} >


                                        <FormControl sx={{ width: '100%' }} >

                                        <FMTextField name="InsuranceCompanyData.startDate" autoFocus margin="dense" label="Start Date" type="date" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{ m: 2 }} />
                                            {/* <FMTextField name="InsuranceCompanyData.startDate" label="Start Date"  autoFocus margin="dense" type="date" variant="outlined" sx={{ m: 2 }} /> */}
{/* {
                                            <DatePicker
                                                inputFormat="MM/DD/YYYY"
                                                //label="Start Date"

                                                //value={new Date(values?.InsuranceCompanyData?.startDate ?? "")}
                                                value={values?.InsuranceCompanyData?.startDate ? new Date(values.InsuranceCompanyData.startDate) : null}
                                                onChange={
                                                    (value) =>
                                                        setFieldValue("InsuranceCompanyData.startDate", new Date(value ?? "").toLocaleDateString(), false)
                                                }

                                                

                                                renderInput={(params) =>
                                                    <TextField {...params} name="InsuranceCompanyData?.startDate" error={Boolean(errors.InsuranceCompanyData?.startDate)} helperText={null} />}
                                            /> } */}

                                        </FormControl>

                                    </Grid>
                                    <Grid item xs={6} >
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMSelectActiveInactive name="InsuranceCompanyData.isActive" />
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid item xs={2}  >
                                            <button   className ="active-btn-blue p-2 mb-2" onClick={selectAll} type="button" >Select All States</button>

                                        </Grid>

                                        <Grid item xs={12} className="card " style={{ maxHeight: "500px" }}>
                                            {/* <Button variant="contained" color="primary" onClick={selectAll}  >Select All States</Button> */}

                                            <StateCheckBoxCustom   selectedStates={selectedStates} States={States} handleCheckBoxChange={handleCheckBoxChange} handleStateEdit={handleStateEdit} />
                                        </Grid>

                                    </Grid>

                                    {/* <LineOfBusinessCheckBox selectedLineOfbusiness={selectedLineOfBusiness} lineOfBusiness={LineOfBusiness} handleCheckBoxChange={handleLineOfBusinessCheckBoxChange} /> */}



                                    {/* <Grid item xs={12}> */}

                                    {/* <LineOfBusinessCheckBox selectedLineOfbusiness={selectedLineOfBusiness} lineOfBusiness={LineOfBusiness} handleCheckBoxChange={handleCheckBoxChange} /> */}
                                    {/* </Grid> */}


                                </Grid>





                            </DialogContent>

                            <DialogActions>

                            <button   className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type="button" >Cancel</button>
                            <button   className="btn  btn-primary active-btn-blue  search-btn " type="submit" disabled={isSubmitting} >Submit</button>
                            </DialogActions>





                        </Form>

                    )}
                </Formik>
            </LocalizationProvider>
        </Dialog >





    );
}

