import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import useAuth from "../../context/AuthProvider";
import { CstomerInvoiceModel } from "../../models/CustomerInvoiceModel";


interface Iprops {
    CustomerInvoiceCL: CstomerInvoiceModel[],
    fetchData: () => void
}

export const CustomerInvoiceList: React.FC<Iprops> = ({ CustomerInvoiceCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;

   

    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <Link href={'/AddCustomerInvoice' } color="#1976D2">
                    <Button
                        color="primary"
                        variant="contained"
                    >
                      Create Invoice
                    </Button>

                </Link>


            </Box>

        );

    }


    const columns: GridColDef[] = [

        { field: 'description', type: 'text', headerName: 'Description', flex: 1.5 },
        { field: 'amount', type: 'number', headerName: 'Amount' , flex: 1 },
        { field: 'isPaid', type: 'boolean', headerName: 'isPaid' , flex: 0.5 },
        { field: 'zipCode', type: 'text', headerName: 'zip Code' , flex: 1 },
        { field: 'naic', type: 'text', headerName: 'Naic' , flex: 0.5 },
        { field: 'memo', type: 'text', headerName: 'Memo' , flex: 0.5 },
        {
            field: 'dueDate', headerName: 'Due Date', flex: 1, renderCell:(params)=>
            (
              <div>
                {params.row.dueDate?.toString().split('T')[0]}
              </div>
               
            )
         },
        
        {
            field: 'customerInvoiceId',
            headerName: 'View',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<number>) => (
                <button type="button" className="btn btn-primary action-btn"><i className="fa-solid fa-right-long"></i></button>
                // <Link href={'/AddEditStateStamps/' + params.value} sx={{textDecoration : "none "}}>
                //    <button type="button" className="btn btn-primary action-btn">Edit <i className="fa-solid fa-right-long"></i></button>

                // </Link>

            ),
        },
       
       
       

    ];

    return (

        <div style={{ height: '80vh', width: '100%' }}>
            {loading && <LinearProgress sx={{backgroundColor: "#132D3D"}} />}
            <DataGrid
                getRowId={(r) => r.customerInvoiceId}
                rows={CustomerInvoiceCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                // components={{
                //     Toolbar: EditToolbar,
                // }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px"



                    }
                }
            />
        </div>


    )
}