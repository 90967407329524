import React, { useEffect, useState } from "react";
import { PolicyList } from "../components/Lists/PolicyList";
import { LinearProgress, } from "@mui/material";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { FillinsByUserList } from "../components/Lists/FillingsByUserList";
import { apiClient } from "../helper/api";

export const FillingsByUser = () => {
    const { user } = useAuth();
    const token = user?.token;
    const [Fillings, setFillings] = useState([]);
    const [loading, setloading] = useState(false);


    const fetchData = () => {
        setloading(true);
        apiClient(`/FillingsByUser/getFillingsByUser`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                // Do something with the response
               
                setFillings(res.data);

            }).finally(() => {
                setloading(false);
            });




    }
    useEffect(() => {

        fetchData();

    }, [])

    return (

        <React.Fragment>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <FillinsByUserList FillingsCl={Fillings} fetchData={fetchData} />
        </React.Fragment>



    );

}
