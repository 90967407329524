import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useEffect, useRef, useState } from 'react';

interface MenuDropdownProp {
  menuTitle?: string
  items: string[]
  onItemClick: (item: string) => void
  onMenuClick?: () => void
  type?: string
}
export const MenuDropdown = ({
  menuTitle = 'Menu', items, onItemClick, onMenuClick, type = 'primary'
}: MenuDropdownProp) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    if (!open) {
      onMenuClick?.()
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event?: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event?.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleItemClick = (item: string) => {
    onItemClick(item)
    handleClose()
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return <>
    <button
      className={type === 'primary' ? 'active-btn-blue text-center' : 'btn'}
      // style={{ minHeight: "39.5px" }}
      ref={anchorRef}
      id="composition-button"
      aria-controls={open ? 'composition-menu' : undefined}
      aria-expanded={open ? 'true' : undefined}
      aria-haspopup="true"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        handleToggle()
      }}
    >
      {menuTitle}
    </button>
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      placement="bottom-start"
      transition
      disablePortal
      sx={{
        zIndex: 1
      }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom-start' ? 'left top' : 'left bottom',
          }}
        >
          <Paper sx={{ marginTop: '5px' }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={open}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
                sx={{
                  maxHeight: '480px',
                  overflow: 'scroll',
                  paddingBottom: 0,
                }}
              >
                {items.map((item, index) => <MenuItem
                  key={index}
                  onClick={() => handleItemClick(item)}
                >
                  {item}
                </MenuItem>)}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </>
}