import React, { useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Box, Button, IconButton, Link } from "@mui/material";
import { PolicyModel } from '../../models/PolicyModel';
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import { AddEditRejectionNotePolicy } from "../AddEdit/AddEditRejectionPolicy";
import { FillingsByUserModel } from "../../models/FillingsByUserModel";
import { AddEditPaymentNotes } from "../AddEdit/AddEditPaymentNotes";
import { PaymentModel } from "../../models/PaymentsModel";
import moment from "moment";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";

interface Iprops {
    FillingsCl: FillingsByUserModel[],
    fetchData: () => void,

}


export const FillinsByUserList: React.FC<Iprops> = ({ FillingsCl, fetchData }) => {
    const { user } = useAuth();
    const token = user?.token;
    const [policyNumber, setPolicyNumber] = useState<string | undefined>("");
    const initialdata: FillingsByUserModel = {
        dateCreated: new Date,
        insured: "",
        userName: "",
        policyState: "",
        policyId: 0,
        policyNumber: "",
        policyStatus: "",
        effectiveDate: new Date,
        policyType: "",
        fileBy: "",
        customerPolicyId: 0
    }
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    // const [Formdata, setFormData] = React.useState(initialdata);

    // const handleEdit = (FilingsData: FillingsByUserModel) => {
    //     setFormData(FilingsData);
    //     setOpen(true);
    // };

    // const handleClose = () => {

    //     setOpen(false);
    // };
    // const handleSubmit = () => {
    //     fetchData();
    //     setOpen(false);
    // };



    // const handleApprove = (id: number) => {
    //     //call api to save data
    //     fetch(config.url.API_URL + "/Policy/Approve/?Policyid=" + `${id}`,
    //         {
    //             method: "POST",
    //             headers: new Headers({
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}`,
    //             })
    //         }
    //     )
    //         .then((response) => {
    //             if (response.ok) {
    //                 return response.json();
    //             }
    //             throw new Error('Something went wrong');
    //         })
    //         .then((res) => {
    //             // Do something with the response
    //             fetchData();

    //             setOpen(false);
    //         }).catch((error) => {
    //             console.log(error)

    //         });

    // };
    const initialPaymentData: PaymentModel = {
        policyPaymentsId: 0,
        notes: "",
        dateCreated: new Date,
        createdBy: 0,
        isExpired: false,
        dateModified: new Date,
        modifiedBy: 0
    }
    const [Open, setOpen] = React.useState(false);

    const [Formdata, setPaymentFormData] = React.useState(initialPaymentData);

    const handleClose = () => {

        setOpen(false);
    };
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };

    const handleClickOpen = (rowData: FillingsByUserModel) => {
        initialPaymentData.policyId = rowData.policyId;

        setPolicyNumber(rowData.policyNumber);

        setPaymentFormData(initialPaymentData);
        setOpen(true);
    };
    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row col-lg-12">
                    <div className="col-lg-4 d-flex">
                        <div style={{marginRight:"2px"}}>
                            <Link href="/">
                                <Button type="button" title="Home"
                                    className="btn action-btn" ><HomeIcon />
                                </Button>
                            </Link>
                        </div>
                        <div>
                            <Button

                                className=" btn action-btn"
                                onClick={goBack}
                                title="Back"
                            >
                                <ArrowBackIosIcon />
                            </Button>
                        </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: "center" }}>
                        <span className="card-title" >State Flag Review</span>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: "right" }}>
                        <span >
                            <Link href={'/NewSubmission/'} color="#1976D2">
                                <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    Create New Submission
                                </Button>

                            </Link>


                        </span>
                    </div>
                </div>

            </Box>

        );

    }

    const columns: GridColDef[] = [

        { field: 'insured', type: 'string', headerName: 'Insured Name', flex: 1, },
        { field: 'userName', type: 'string', headerName: 'Customer/Partner', flex: 1, },
        { field: 'policyState', type: 'string', headerName: 'State', flex: 0.5, },
        { field: 'policyNumber', type: 'string', headerName: '#ID', flex: 1.5, },

        { field: 'policyType', type: 'string', headerName: 'Type', flex: 1, },
        { field: 'policyStatus', type: 'string', headerName: 'Status', flex: 1, },
        {
            field: 'effectiveDate', headerName: 'Effective Date', flex: 1, renderCell: (params) =>
            (
                <div>
                    {params.row.effectiveDate ? moment(params.row.effectiveDate).format("MM-DD-YY") : ""}
                    {/* {params.row.effectiveDate?.toString("mm-dd-yy").split('T')[0]} */}
                </div>

            )
        },
        { field: 'fileBy', type: 'string', headerName: 'File By', flex: 0.7, },
        {
            field: 'dateCreated', type: 'date', headerName: 'Date Created', flex: 0.7, renderCell: (params) =>
            (
                <div>
                    {params.row.dateCreated ? moment(params.row.dateCreated).format("MM-DD-YY") : ""}
                    {/* {params.row.dateCreated?.toString().split('T')[0]} */}
                </div>

            )
        },
        {
            field: 'policyId',
            headerName: 'View Details',
            flex: 1,
            renderCell: (params: GridRenderCellParams<number>) => (

                <Link href={'/Transaction/' + params.value} sx={{ textDecoration: "none " }}>
                    <button type="button" className="btn btn-primary action-btn"><i className="fa-solid fa-right-long"></i></button>

                </Link>

            ),
        },
        // {
        //     field: 'paymnet', flex: 1, headerName: "Payment Link", renderCell: (params) => (
        //         <div>

        //             {params.row.isPaymentPresent == 0 ?

        //                 <Button
        //                     // className="btn btn-primary action-btn"
        //                     onClick={() => { handleClickOpen(params.row) }}

        //                 >
        //                   Generate Link
        //                 </Button>
        //                 : <Button onClick={() => { navigator.clipboard.writeText("https://filemule-web.azurewebsites.net/payment/" + params.row.isPaymentPresent); alert("link Copied to clipboard") }}>Copy Link</Button>}
        //         </div>
        //     )
        // },


    ];

    return (
        <div style={{ height: '75vh', width: '100%' }}>
            <DataGrid
                getRowId={(r) => r.customerPolicyId}
                rows={FillingsCl}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[10]}
                showCellRightBorder={false}
                showColumnRightBorder={false}
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px"



                    }
                }


            />

            <AddEditPaymentNotes handleClose={handleClose} Open={Open} PaymentCL={Formdata} handleSubmit={handleSubmit} initValues={initialPaymentData} PolicyNumber={policyNumber} />

        </div>



    )




}