import { Copyright } from "@mui/icons-material";
import { Box, Button, Container, Grid, LinearProgress, Link, Paper, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { PolicyList } from "../components/Lists/PolicyList";
import { Route, useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { PolicyInfo } from "../components/InfoPages/PolicyInfo";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import { ButlerResponseModel } from "../models/ButlerResponseModel";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { FileMuleFlagsQueue } from "./PolicyDashboardElements/FileMuleFlagsQueue";
import { components } from "react-select";




export const Transaction = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const params = useParams();

    const navigate = useNavigate();


    const goBack = () => {
        //<Link href="/FileMuleFlagsQueue"></Link>
        //navigate(-1);
        navigate(-1);
    }


    useEffect(() => {

    }, [])

    return (

        <PolicyInfo />

    );

}
