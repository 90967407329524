import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, LinearProgress, Paper, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { TaxesAndFeesList } from "../components/Lists/TaxesAndFeesList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import moment from "moment";
import { apiClient } from "../helper/api";

interface TaxAndFee {
    commaSeparatedLOBIds: string | null;
    lobId: number;
}

export const TaxesAndFees = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const params = useParams();

    const [TaxesAndFee, setTaxesAndFee] = useState([])
    const fetchData = () => {
        setloading(true);
        // fetch(config.url.API_URL + `/TaxesAndFees/getAllTaxesAndFees?type=${params.type}`,
        apiClient(`/TaxesAndFees/getAllTaxesAndFeesWithLob`,
            {

                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                console.log("getAllTaxesAndFeesWithLob", res.data)
               // 
                // Do something with the response
                if (res.data != null) {
                    res.data.effectiveDate = moment(res.data.effectiveDate).format("DD/MM/YYYY");
                    if (Array.isArray(res.data)) {
                        res.data.forEach((item: TaxAndFee) => {
                            if (item.commaSeparatedLOBIds === null || item.commaSeparatedLOBIds === "ALL") {
                                item.commaSeparatedLOBIds = "ALL";
                                item.lobId = item.lobId ?? null;
                            }
                        });
                    }
                    setTaxesAndFee(res.data)
                    console.log(res);
                }

            }).finally(() => {
                setloading(false);
            });

    }
    useEffect(() => {
        fetchData();

    }, [])

    return (
        <div >
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <TaxesAndFeesList taxesandfessCL={TaxesAndFee} fetchData={fetchData} />
        </div>
    )

}