import { FormControl, FormHelperText, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import * as React from 'react';
import { config } from '../Constants';
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo';
import { RightNav } from '../components/Nav/RightNav';
import useAuth from '../context/AuthProvider';
// import { AddEditTaxFeeUpdateDate } from '../components/AddEdit/AddEditTaxFeeUpdateDate';
import { FieldAttributes } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TaxesAndFeeScheduleList } from '../components/Lists/TaxesAndFeeFilinsScheduleList';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { apiClient } from '../helper/api';

export const TaxesAndFeesFliingSchedule = () => {
    const [StateScheduleList, setStateScheduleList] = React.useState([]);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = React.useState(false);
    const initialdata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialdata]);
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const [SelectedState, setSelectedState] = React.useState<string>('0');

    function refreshData() {
        fetchStateSchedules(SelectedState);
    }
    const fetchStateSchedules = (stateId: string) => {
        
        // fetch(config.url.API_URL + `/TaxesAndFeesFilingSchedule/getTaxesAndFilingScheduleByState?stateId=${stateId}`,
        apiClient(`/TaxesAndFeesFilingSchedule/getAllTaxesAndFilingSchedule`,
        
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response

                if (responseJson.data != null) {

                    setStateScheduleList(responseJson.data)

                    console.log("feild data", responseJson)
                }

            })
    }


    const fetchData = () => {

        apiClient(`/States/getStates`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return

                if (responseJson.data != null) {

                    setStates(responseJson.data)
                    console.log("listdata", responseJson)
                }

            })
    }

    React.useEffect(() => {
        fetchData();
        fetchStateSchedules("1");
    }, [])

    const FMSelectStateCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {


        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { stateId: 0, stateName: "" };
        // const [States, setStates] = useState([initialdata])

        const params = useParams();
        const handleChange = (event: SelectChangeEvent) => {
            // setloading(true);
            fetchStateSchedules(event.target.value);
            setSelectedState(event.target.value);
        };
        useEffect(() => {
        }, [])
        return (
            <FormControl fullWidth  >
                {/* <InputLabel id="State-Select-Label" >State</InputLabel> */}
                <Select
                    onChange={handleChange}
                    value={SelectedState}
                    id="test-select-label"
                    autoWidth
                    labelId="State-Select-Label"
                    // label="State"
                    label={null}
                    className="border-round custom-menu-item"
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                >
                    <MenuItem value={"0"}>State</MenuItem>
                    {States.map((state, key) =>
                        <MenuItem key={key} value={state.stateId}> {state.stateName} </MenuItem>
                    )}

                </Select>
                <FormHelperText></FormHelperText>
            </FormControl>

        )
    }

    return (
        // <section className="dashboard">
        //     <main className="container-fluid py-3">
        //         <div className="row">
        //             <div className="col-12 mx-auto">
        //                 <div className="d-flex">
        //                     <div className="mx-3 flex-fill pt-4 ps-md-1 dashboard-content" style={{ backgroundColor: "whitesmoke" }}>
        //                         <LoggedInUserInfo ButtonText='Add New' Link='' />
        //                         <div className="card-body">
        //                             <div className="col-lg-12 col col-margin">
        //                                 <div className="main shadow rounded" style={{ backgroundColor: "#ffffff" }}>

        //                                     {/* <div className="row col-lg-12">
        //                                         <div className="col-lg-4 d-flex">
        //                                             <div style={{ marginRight: "2px" }}>
        //                                                 <Link href="/">
        //                                                     <Button type="button" title="Home"
        //                                                         className="btn action-btn" ><HomeIcon />
        //                                                     </Button>
        //                                                 </Link>
        //                                             </div>
        //                                             <div>
        //                                                 <Button

        //                                                     className=" btn action-btn"
        //                                                     onClick={goBack}
        //                                                     title="Back"
        //                                                 >
        //                                                     <ArrowBackIosIcon />
        //                                                 </Button>
        //                                             </div>
        //                                         </div>

        //                                         <div className="col-lg-4" style={{ textAlign: "center" }}>
        //                                             <span className="card-title">Filing Schedule</span>
        //                                         </div>
        //                                         <div className="col-lg-4" >
        //                                             <FMSelectStateCustom name='state' />
        //                                         </div>


        //                                     </div> */}

        //                                     <div style={{padding : "1rem" , paddingTop : "2rem", paddingBottom: "4rem"}}>
        //                                         <div className="table-responsive m-2">

        //                                             {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}


                                                    <TaxesAndFeeScheduleList taxesAndFeeList={StateScheduleList} fetchData={refreshData} selectedState={SelectedState} FMSelectStateCustom={FMSelectStateCustom} />
                                                    /* <AddEditTaxFeeUpdateDate /> */
        //                                         </div>
        //                                     </div>
        //                                 </div>

        //                             </div>
        //                         </div>
        //                     </div>
        //                     <RightNav />

        //                 </div>
        //             </div>
        //         </div>

        //     </main>
        // </section>

    );

}





