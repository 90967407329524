import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { lineOfBusinessModel } from "../Lists/CarrierManagementList";

interface Iprops {
    selectedLineOfbusiness: lineOfBusinessModel[],
    lineOfBusiness: lineOfBusinessModel[],
    handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const LineOfBusinessCheckBox: React.FC<Iprops> = ({ selectedLineOfbusiness, lineOfBusiness, handleCheckBoxChange }) => {
    // const params = useParams();
    // const { user } = useAuth();
    // const token = user?.token;

    return (
        
        <div className="card">
            <div className="table-responsive" >
                <Grid container margin={3} >
                    {
                        lineOfBusiness?.map((item, key) => (

                            <Grid key={key} xs={2} >
                                <FormControlLabel
                                    control={
                                        <Checkbox key={key} name={item.id.toString()} onChange={handleCheckBoxChange} checked={selectedLineOfbusiness.some(element => {
                                            if (element.id === item.id) {
                                                return true
                                            }

                                            return false
                                        })} />
                                    }
                                    // label="{}"

                                    label={<span > {item.businessCode}</span>}
                                />
                            </Grid>


                        ))}

                </Grid>
            </div>
        </div>





    )
}