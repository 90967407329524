
import useAuth from "../../context/AuthProvider";
import React, { useEffect, useState } from "react";
import { DocumentTypeModel, documentModelVS } from "../../models/DocumentType";
import { config } from "../../Constants";
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, Box, FormHelperText, FormControl, Autocomplete, TextField, SelectProps, InputLabel, Select, MenuItem, Button } from "@mui/material";
import { Field, FieldAttributes, Form, Formik, useField } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { CustomInputComponent, FMTextField } from "../Elements/input";
import { apiClient } from "../../helper/api";

interface Iprops {
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    fetchData: () => void
    initvalues: DocumentTypeModel
}
export const AddEditDocumentType: React.FC<Iprops> = ({ Open, handleClose, handleSubmit, initvalues, fetchData }) => {



    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();



    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            {/* <DialogTitle className="card-title" >Create New Document Type</DialogTitle> */}
            {initvalues.documentTypeId === 0 ? <h2 className='card-title' >Create New Document Type</h2> : <h2 className='card-title'>Edit Document Type</h2>}

            <React.Fragment>
                <Formik
                    initialValues={initvalues}
                    validateOnChange={true}
                    validationSchema={documentModelVS}
                    onSubmit={(data, { setSubmitting }) => {
                        setloading(true);
                       
                        apiClient(`/DocumentType/AddEdit`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: JSON.stringify(data)

                            })
                            .then((res) => {
                                fetchData();
                                handleClose();
                            }).finally(() => {
                                setSubmitting(false);
                                setloading(false);
                            });

                    }} >
                    {({ submitForm, isSubmitting, errors }) =>
                    (
                        <Form >
                            {/* <DialogContent>
                                <Field component={CustomInputComponent} type="text" placeholder="Document Type Name" name="documentName" />
                            </DialogContent> */}
                            <DialogContent>
                                <FormControl sx={{ width: '100%' }} >
                                    <FMTextField name="documentName" autoFocus margin="dense" placeholder="Document Name" label="Document Name" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <button  className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type="button" >Cancel</button>
                                <button  className="btn  btn-primary active-btn-blue  search-btn " type='submit' onClick={submitForm} disabled={isSubmitting} >Submit</button>
                            </DialogActions>


                        </Form>
                    )}
                </Formik>
            </React.Fragment >
        </Dialog >




    );
}