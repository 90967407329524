var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
var API_BASE_URL = 'https://app.butlerlabs.ai/api';
var getAuthHeaders = function (apiKey) { return ({
    Authorization: "Bearer ".concat(apiKey),
}); };
/**
 * Creates the Get Model Details API call for the API key
 * @param apiKey string
 * @param apiBaseUrl string
 * @returns GetModelApiCall
 */
var createGetModelApiCall = function (apiKey, apiBaseUrl) {
    return function (modelId) {
        var authHeaders = getAuthHeaders(apiKey);
        var getModelUrl = "".concat(apiBaseUrl, "/models/").concat(modelId);
        return axios.get(getModelUrl, { headers: __assign({}, authHeaders) });
    };
};
/**
 * Creates the Create New Custom Model API call for the API key
 * @param apiKey string
 * @param apiBaseUrl string
 * @returns CreateNewCustomModelApiCall
 */
var createNewCustomModelApiCall = function (apiKey, apiBaseUrl) {
    return function (model) {
        var authHeaders = getAuthHeaders(apiKey);
        var createCustomModelUrl = "".concat(apiBaseUrl, "/models");
        return axios.post(createCustomModelUrl, model, {
            headers: __assign({}, authHeaders),
        });
    };
};
/**
 * Creates the Train Custom Model API call for the API key
 * @param apiKey string
 * @param apiBaseUrl string
 * @returns TrainCustomModelApiCall
 */
var createTrainCustomModelApiCall = function (apiKey, apiBaseUrl) {
    return function (modelId) {
        var authHeaders = getAuthHeaders(apiKey);
        var trainCustomModelUrl = "".concat(apiBaseUrl, "/models/").concat(modelId, "/train");
        return axios.post(trainCustomModelUrl, {}, { headers: __assign({}, authHeaders) });
    };
};
var createGetExtractionResultsApiCall = function (apiKey, apiBaseUrl) {
    return function (modelId, documentId) {
        var authHeaders = getAuthHeaders(apiKey);
        var extractionResultsUrl = "".concat(apiBaseUrl, "/models/").concat(modelId, "/documents/").concat(documentId, "/enhanced_results");
        return axios.get(extractionResultsUrl, { headers: __assign({}, authHeaders) });
    };
};
/**
 * Creates the Submit Document Labels API call for the API key
 * that is provided
 * @param apiKey
 * @param apiBaseUrl
 * @returns
 */
var createSubmitDocumentLabelsApiCall = function (apiKey, apiBaseUrl) {
    return function (modelId, documentId, labels) {
        var authHeaders = getAuthHeaders(apiKey);
        var submitLabelsUrl = "".concat(apiBaseUrl, "/models/").concat(modelId, "/documents/").concat(documentId, "/labels");
        return axios.put(submitLabelsUrl, labels, { headers: __assign({}, authHeaders) });
    };
};
/**
 * Factory used to initialize the Butler REST API using the provided API key.
 * @param apiKey Your personal API key
 * @param apiBaseUrl The API Base URL. Should be left to the default for most cases
 * @returns
 */
export var ButlerApiCallFactory = {
    create: function (apiKey, apiBaseUrl) {
        if (apiBaseUrl === void 0) { apiBaseUrl = API_BASE_URL; }
        return {
            getExtractionResults: createGetExtractionResultsApiCall(apiKey, apiBaseUrl),
            submitDocumentLabels: createSubmitDocumentLabelsApiCall(apiKey, apiBaseUrl),
            getModel: createGetModelApiCall(apiKey, apiBaseUrl),
            createCustomModel: createNewCustomModelApiCall(apiKey, apiBaseUrl),
            trainCustomModel: createTrainCustomModelApiCall(apiKey, apiBaseUrl),
        };
    },
};
