import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { IconButton, Link } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React from "react";
import { ButlerModelDocumentModel } from "../../models/ButlerModelDocumentModel";




interface Iprops {
    ButlerModelDocumentCL: ButlerModelDocumentModel[],
    fetchData: () => void
}

export const ButlerModelDocumentList: React.FC<Iprops> = ({ ButlerModelDocumentCL, fetchData }) => {
    // const [loading, setloading] = useState(false);
    // const { user } = useAuth();
    // const token = user?.token;

    // const initialLBData: ButlerModelDocumentModel = {
    //     butlerModelDocumentId: 0,
    //     modelId: 0,
    //     isDeleted: false,
    //     butlerModelId: "",
    //     documentName: "",
    //     butlerDocumentId: "",
    //     blob_Address: "",
    //     isUploaded: false,
    //     isProcessed: false
    // }

    // const [Open, setOpen] = React.useState(false);
    // const [Formdata, setFormData] = React.useState(initialLBData);

    // const handleClose = () => {

    //     setOpen(false);
    // };
    // const handleSubmit = () => {
    //     fetchData();
    //     setOpen(false);
    // };
    // const handleClickOpen = () => {
    //     setFormData(initialLBData);
    //     setOpen(true);
    // };

    // const handleEdit = (ILobData: ButlerModel) => {
    //     setFormData(ILobData);
    //     setOpen(true);
    // };


    // function EditToolbar() {

    //     return (
    //         <Box
    //             sx={{
    //                 borderBottom: 1,
    //                 borderColor: 'divider',
    //                 p: 1,
    //             }}
    //         >

    //             <Button
    //                 color="primary"
    //                 onClick={handleClickOpen}
    //                 variant="contained"

    //             >
    //                 Add new Model
    //             </Button>

    //         </Box>

    //     );

    // }


    const columns: GridColDef[] = [


        { field: 'documentName', type: 'text', headerName: 'DocumentName', flex: 1 },
        { field: 'isUploaded', type: 'boolean', headerName: 'Is Processed', flex: 1 },
        {
            field: 'butlerModelDocumentId', flex: 1, headerName: "Train", renderCell: (params) => (<div>

                <Link href={'/Train/' + params.row.butlerModelDocumentId} color="#1976D2">
                    
                        <IconButton
                            className="btn btn-primary action-btn" >
                            <ArrowRightAltIcon />
                        </IconButton>
                </Link>

            </div>)
        },

    ];

    return (

        <div style={{ height: 300, width: '100%' }}>
            {/* {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />} */}
            <DataGrid
                getRowId={(r) => r.butlerModelDocumentId}
                rows={ButlerModelDocumentCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                // components={{
                //     Toolbar: EditToolbar,
                // }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif",
                        border: "0px",
                        fontSize: "16px"



                    }
                }
            />
            {/* <AddButlerModels handleClose={handleClose} Open={Open} handleSubmit={handleSubmit} /> */}

        </div>



    )
}