import { height } from "@mui/system";
import { loadButlerBlocks } from "butler-blocks";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import useAuth from "../context/AuthProvider";
import { config } from "../Constants";
import { RightNav } from "../components/Nav/RightNav";

export const Trainer = () => {
    const params = useParams();
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    let modelId ="";
    let documentId = "";
     
   

    const butlerBlocks = loadButlerBlocks('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJnb29nbGUtb2F1dGgyfDExNzIyMzM1NTYxMzYyNDUxODU4MCIsImVtYWlsIjoiamVycnlAc3BlY3RlcnRlY2guaW8iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaWF0IjoxNjQ1MjIwMzk4NTg1fQ.zm-vVDRyENVjGjTBasUgwfVxZXEGkFBMDpTugyoTL6c');


    // This function programmatically submits labels for training
    const submitLabels = async (trainingDocumentLabels) => {
      
        // trainingDocumentLabels.docId = documentId;
        console.log("traning document labels", trainingDocumentLabels);
        console.log("modelId", modelId);
        console.log("documentId", documentId);
       
    
        await butlerBlocks.api.submitDocumentLabels(modelId, documentId, trainingDocumentLabels);
       
        // Once finished, File Mule client code should move to the next file (if exists) or should
        // call train endpoint to begin training
        
        // While training is in progress, can poll on the model status until complete at which point it will be ready
        // for further use
    }

    // This function is passed to the embedded document labeler and will be triggered on pressing the save button
    const onSaveCallback = (docInfo) => {
        submitLabels(docInfo.trainingDocumentLabels);

    };




    const fetchData = () => {
        setloading(true);
        fetch(config.url.API_URL + `/ButlerModel/getQueIdAndButlerDocId?butlerModelDocId=${params.butlerModelDocumentId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong');
            })
            .then((res) => {
                // Do something with the response
                setloading(false);

                

                modelId = res.data[0].butlerModelId;
                documentId = res.data[0].butlerDocumentId;
                
                //  console.log(documentId);
                //  console.log(modelId);



                //now call butler functions


                /**
                * Step 4 - Initialize the Embedded Document Labeler component
                *
                * This will attach the embedded UI to the div with id = #ButlerDocumentLabeler
                */


                 const initializeDocLabeler = async ({ modelId, documentId }) => {
                    const extractionResultsResponse = await butlerBlocks.api.getExtractionResults(modelId, documentId);
                    const { data } = extractionResultsResponse;
                    //  console.log(data);

                    butlerBlocks.createDocLabeler('ButlerDocumentLabeler', data, onSaveCallback);
                };

                /**
                * Step 5 - Call initialize to render component
                */
                initializeDocLabeler({ modelId, documentId});


            })
            .catch((error) => {
                setloading(false);
                console.log(error)
            });


    }

    useEffect(() => {
        fetchData();
    }, [])


    return (


        <div className='dashboard'>


            <div className="col-lg-10 col-md-12 col-sm-12 pl-space" >
                <h1 className="dashboard-form-heading text-center">Shield-Knot <span className="sidenav-icon"><i className='bx bx-menu'></i></span></h1>
                <svg className="MobileWaves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff"></use>
                    </g>
                </svg>
                <div className="row heading align-items-center m-0">
                    <div className="col-md-7 col-12">

                    <LoggedInUserInfo ButtonText="" Link=""/>
                    </div>
                    <div className="col-md-5 col-12">
                        {/* <div className="search">
                            <i className="fa fa-search"></i>
                            <input type="text" className="searchbox" placeholder="Add a report" />
                            <button className="btn btn-primary"> <i className="fa fa-check"></i></button>
                        </div> */}
                    </div>


                </div>
                <div className="card-body-overflow">
                    <div className="card-body">

                        <div className="col-lg-12 col col-margin">
                            <div className="card dashboard-table">

                                <span className="card-title"></span>

                                <div className="table-responsive">

                                    <div id="ButlerDocumentLabeler"></div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <RightNav />

        </div>


    )
}