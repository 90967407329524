import * as React from 'react';
import { Policies } from './Policies';
import { RightNav } from '../components/Nav/RightNav';
import { LoggedInUserInfo } from '../components/Elements/loggedInUserInfo';
import { FillingsByUser } from './FillingsByUser';
import { CustomerPolicyList } from '../components/Lists/CustomerPolicyList';
import useAuth from '../context/AuthProvider';
import { config } from '../Constants';
import { LinearProgress, Link } from '@mui/material';
import { getProductionInfoModel } from '../models/getProductionInfoModel';
import { useState } from 'react';
import { display } from '@mui/system';
import { FillingsByUserModel } from '../models/FillingsByUserModel';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { style } from '@mui/system/Stack/createStack';
import { apiClient } from '../helper/api';


export const AdminDashboard = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = React.useState(false);
    const initalData: getProductionInfoModel = {
        customers: 0,
        statesProcessed: 0,
        transactions: 0,
        amountFillled: 0,
        fileMuleRevenue: 0,
        numberOfDocuments: 0
    }

    const initialFMdata: FillingsByUserModel = {
        dateCreated: new Date,
        insured: "",
        userName: "",
        policyState: "",
        policyId: 0,
        policyNumber: "",
        policyStatus: "",
        effectiveDate: new Date,
        policyType: "",
        fileBy: "",
        customerPolicyId: 0,
        
    }
    const [productionInfo, setProductionInfo] = useState(initalData);
    const [productionInfoYTD, setProductionInfoYTD] = useState(initalData);

    const [FMFillings, setFMFillings] = useState([initialFMdata]);
    const [SFFillings, setSFFillings] = useState([initialFMdata]);
    
    const navigate = useNavigate();


    const fetchData = () => {

        setloading(true);
        apiClient('/Dashboard/getDashboardProductionInfo',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                setloading(false);

                setProductionInfo(responseJson.data)
            }).finally(() => {
                setloading(false);

            });

        apiClient('/Dashboard/getDashboardProductionInfoYTD',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                setloading(false);

                console.log(responseJson.data);
                setProductionInfoYTD(responseJson.data)
            }).finally(() => {
                setloading(false);

            });

        apiClient(`/FillingsByUser/getFMFlagReviewFillingsByUser?type=${'FM'}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((res) => {
                if (!res) return
                // Do something with the response
                setloading(false);
                console.log(res.data);
                if (res.data != null) {
                    setFMFillings(res.data);

                }

            }).finally(() => {
                setloading(false);
            });


    }

    React.useEffect(() => {
        fetchData();

    }, [])

    return (

        <section className="newdashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">


                            {/* <div className='table-responsive'>
                                <div className="col-12" style={{ height: "80vh" }}>
                                    <div className="d-flex"> */}
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">
                                <div className="d-flex flex-column">
                                    <div className='table-responsive'>
                                        <div className="col-12" style={{ height: "80vh" }}>
                                            <div className="flex-fill">
                                                <h3 className="mb-0">Production</h3>
                                                <p className="text-muted" style={{ textAlign: "left" }}>Past 30 Days</p>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <div style={{ background: "#5B3C1F" }} className="card card-counter animate__animated animate__zoomIn text-white  p-3 mb-3">
                                                        <div className="card-body" >
                                                            <div className="d-flex">
                                                                <div className="d-flex flex-column justify-content-center animate__animated animate__fadeIn animate__delay-_3s me-4 mb-2">
                                                                    <div className="d-flex flex-column p-2 v-pill">
                                                                        <span className="d-inline-block icon animate__animated animate__fadeIn animate__delay-_6s rounded-circle border border-danger p-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--bs-red)" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-danger animate__animated animate__fadeIn animate__delay-_6s">-0%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                    <h1 className="mb-0"><span className="stat-number">{productionInfo.customers}</span></h1>
                                                                    <p className="text-nowrap">Customers</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div style={{ background: "#2A6729" }} className="card animate__delay-_3s card-counter animate__animated animate__zoomIn text-white bg-dm-light p-3 mb-3">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="d-flex flex-column justify-content-center animate__animated animate__fadeIn animate__delay-_3s me-4 mb-2">
                                                                    <div className="d-flex flex-column p-2 v-pill">
                                                                        <span className="d-inline-block icon animate__animated animate__fadeIn animate__delay-_6s rounded-circle border border-success p-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--bs-green)" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-success animate__animated animate__fadeIn animate__delay-_6s">+0%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                    <h1 className="mb-0"><span className="stat-number">{productionInfo.statesProcessed}</span></h1>
                                                                    <p className="text-nowrap">States Processed</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div style={{ background: " #4C276A" }} className="card animate__delay-_6s card-counter animate__animated animate__zoomIn text-white bg-dm-light p-3 mb-3">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="d-flex flex-column justify-content-center animate__animated animate__fadeIn animate__delay-_3s me-4 mb-2">
                                                                    <div className="d-flex flex-column p-2 v-pill">
                                                                        <span className="d-inline-block icon animate__animated animate__fadeIn animate__delay-_6s rounded-circle border border-danger p-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--bs-red)" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-danger animate__animated animate__fadeIn animate__delay-_6s">-0%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                    <h1 className="mb-0"><span className="stat-number"> {productionInfo.transactions}</span>K</h1>
                                                                    <p className="text-nowrap">Transactions</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div style={{ background: " #496ECC" }} className="card card-counter animate__delay-_9s animate__animated animate__zoomIn text-white bg-dm-light p-3 mb-3">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="d-flex flex-column justify-content-center animate__animated animate__fadeIn animate__delay-_3s me-4 mb-2">
                                                                    <div className="d-flex flex-column p-2 v-pill">
                                                                        <span className="d-inline-block icon animate__animated animate__fadeIn animate__delay-_9s rounded-circle border border-success p-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--bs-green)" className="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                                                <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-success animate__animated animate__fadeIn animate__delay-_9s">+0%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                    <h1 className="mb-0"><span className="stat-number">{productionInfo.amountFillled}</span>M</h1>
                                                                    <p className="text-nowrap">Amount Filed</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div style={{ background: " #1C3E52" }} className="card card-counter animate__delay-_12s animate__animated animate__zoomIn text-white bg-dm-light p-3 mb-3">
                                                        <div className="card-body">
                                                            <div className="d-flex">
                                                                <div className="d-flex flex-column justify-content-center animate__animated animate__fadeIn animate__delay-_3s me-4 mb-2">
                                                                    <div className="d-flex flex-column p-2 v-pill">
                                                                        <span className="d-inline-block icon animate__animated animate__fadeIn animate__delay-_12s rounded-circle border border-danger p-2">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="var(--bs-red)" className="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                                                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                                                                            </svg>
                                                                        </span>
                                                                        <span className="text-danger animate__animated animate__fadeIn animate__delay-_12s">-0%</span>
                                                                    </div>
                                                                </div>
                                                                <div className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                    <h1 className="mb-0"><span className="stat-number">{productionInfo.fileMuleRevenue}</span>K</h1>
                                                                    <p className="text-nowrap">FileMule Revenue</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="row">
                                                <div className="col-xxl-8">
                                                    <div
                                                        className=" animate__animated animate__fadeInUp animate__delay-_3s text-white py-4 mb-3">
                                                        <div className="flex-fill">
                                                            <h3 className="mb-0">Production</h3>
                                                            {/* <p className="text-muted" style={{ textAlign: "left" }}>(2022 YTD)</p> */}
                                                        </div>
                                                        <div className="ytd-section row">
                                                            <div
                                                                className="col col-xl-4 col-sm-6 animate__animated animate__fadeIn animate__delay-_3s">
                                                                <div className="card mt-3" style={{ background: "#425E71", cursor:"pointer" }} onClick={()=>navigate("/PolicyDashboard")} >
                                                                    <div className="card-body">
                                                                        <p>Documents Received</p>
                                                                        <div className="d-flex">
                                                                            <h1 className="mb-0"><span className=" me-3"> {productionInfoYTD.numberOfDocuments}</span>
                                                                            </h1>
                                                                            <span className="badge bg-success align-self-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                    height="16" fill="currentColor"
                                                                                    className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                                                    <path fillRule="evenodd"
                                                                                        d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                                                                                </svg>
                                                                                +0%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col col-xl-4 col-sm-6 animate__animated animate__fadeIn animate__delay-_6s">
                                                                <div className="card mt-3" style={{ background: "#425E71" }}>
                                                                    <div className="card-body">
                                                                        <p>States Processed</p>
                                                                        <div className="d-flex">
                                                                            <h1 className="mb-0"><span className="stat-number me-3"> {productionInfoYTD.statesProcessed}</span>
                                                                            </h1>
                                                                            <span className="badge bg-success align-self-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                    height="16" fill="currentColor"
                                                                                    className="bi bi-graph-up-arrow" viewBox="0 0 16 16">
                                                                                    <path fillRule="evenodd"
                                                                                        d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z" />
                                                                                </svg>
                                                                                +0%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col col-xl-4 col-sm-6 animate__animated animate__fadeIn animate__delay-_9s">
                                                                <div className="card mt-3" style={{ background: "#425E71" }}>
                                                                    <div className="card-body">
                                                                        <p>Transactions</p>
                                                                        <div className="d-flex">
                                                                            <h1 className="mb-0  me-3"><span
                                                                                className=""> {productionInfoYTD.transactions}</span></h1>
                                                                            <span className="badge bg-danger align-self-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                    height="16" fill="currentColor"
                                                                                    className="bi bi-graph-down-arrow" viewBox="0 0 16 16">
                                                                                    <path fillRule="evenodd"
                                                                                        d="M0 0h1v15h15v1H0V0Zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5Z" />
                                                                                </svg>
                                                                                -0%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col col-xl-4 col-sm-6 animate__animated animate__fadeIn animate__delay-_12s">
                                                                <div className="card mt-3" style={{ background: "#425E71" }}>
                                                                    <div className="card-body">
                                                                        <p>Amount Filed</p>
                                                                        <div className="d-flex">
                                                                            <h1 className="mb-0 me-3"><span className="stat-number">{productionInfoYTD.amountFillled}</span>M
                                                                            </h1>
                                                                            <span className="badge bg-success align-self-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                    height="16" fill="currentColor"
                                                                                    className="bi bi-graph-down-arrow" viewBox="0 0 16 16">
                                                                                    <path fillRule="evenodd"
                                                                                        d="M0 0h1v15h15v1H0V0Zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5Z" />
                                                                                </svg>
                                                                                +0%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className="col col-xl-4 col-sm-6 animate__animated animate__fadeIn animate__delay-_15s">
                                                                <div className="card mt-3" style={{ background: "#425E71" }}>
                                                                    <div className="card-body">
                                                                        <p>FileMule Revenue</p>
                                                                        <div className="d-flex">
                                                                            <h1 className="mb-0 me-3"><span
                                                                                className="stat-number">{productionInfoYTD.fileMuleRevenue}</span>K</h1>
                                                                            <span className="badge bg-success align-self-center">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                    height="16" fill="currentColor"
                                                                                    className="bi bi-graph-down-arrow" viewBox="0 0 16 16">
                                                                                    <path fillRule="evenodd"
                                                                                        d="M0 0h1v15h15v1H0V0Zm10 11.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-1 0v2.6l-3.613-4.417a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61L13.445 11H10.5a.5.5 0 0 0-.5.5Z" />
                                                                                </svg>
                                                                                +0%</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div
                                                                    className="col col-xl-4 col-sm-6 animate__animated animate__fadeIn animate__delay-_15s">
                                                                    <div className="card mt-3" style={{ background: "#183a4e" }}>
                                                                        <div className="card-body p-0">
                                                                            <div id="graph" style={{ height: "120px" }}></div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-xl-6 col-lg-12">
                                                            <div style={{ maxHeight: "280px" }}
                                                                className="card animate__animated animate__fadeInUp animate__delay-_6s text-white bg-dm-light py-4 mb-3">
                                                                <div className="card-header px-4">
                                                                    <span>FileMule flags</span>
                                                                </div>
                                                                <div className="card-body" style={{ overflow: "scroll" }}>
                                                                    <div className="d-flex" style={{ height: "350px" }}>
                                                                        <div
                                                                            className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                            <table className="table table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-white" scope="col">Company Name
                                                                                        </th>
                                                                                        <th className="text-white" scope="col">Date</th>
                                                                                        <th className="text-white" scope="col"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        FMFillings?.map((row, index) => (
                                                                                            <tr key={index}>


                                                                                                <td className="text-white">{row.insured}</td>
                                                                                                <td className="text-white" style={{ minWidth: "100px" }}>

                                                                                                    {row.dateCreated ? moment(row.dateCreated).format("MM-DD-YY") : ""}
                                                                                                </td>
                                                                                                <td > <Link href={'/Transaction/' + row.policyId} sx={{ textDecoration: "none ", color: "#0d6efd" }}>
                                                                                                    View
                                                                                                </Link>
                                                                                                </td>

                                                                                            </tr>
                                                                                        ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-6 col-lg-12">
                                                            <div
                                                                className="card animate__animated animate__fadeInUp animate__delay-_9s text-white bg-dm-light py-4 mb-3">
                                                                <div className="card-header px-4">
                                                                    <span>State flags</span>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="d-flex">
                                                                        <div
                                                                            className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                            <table className="table table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-white" scope="col">Company Name
                                                                                        </th>
                                                                                        <th className="text-white" scope="col">Date</th>
                                                                                        <th className="text-white" scope="col">State</th>
                                                                                        <th className="text-white" scope="col"></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {/* <tr>
                                                                                            <td className="text-white">Company XYZ</td>
                                                                                            <td className="text-white">Jan 1, 2022</td>
                                                                                            <td className="text-white">CA</td>
                                                                                            <td className="text-white" align="right"><a href="#"
                                                                                                className="btn btn-outline-light btn-sm">View</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Company XYZ</td>
                                                                                            <td className="text-white">June 20, 2022</td>
                                                                                            <td className="text-white">CO</td>
                                                                                            <td className="text-white" align="right"><a href="#"
                                                                                                className="btn btn-outline-light btn-sm">View</a>
                                                                                            </td>
                                                                                        </tr> */}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-4 ">
                                                    <div className="row flex-xxl-column">
                                                        <div className="col-xxl-12 col-xl-6 col-lg-12">
                                                            <div
                                                                className="card animate__animated animate__fadeInUp text-white bg-dm-light py-4 mb-3">
                                                                <div className="card-header px-4">
                                                                    <span>Remmitance Schedule</span>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="d-flex">
                                                                        <div
                                                                            className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                            <table className="table table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th className="text-white" scope="col">Date</th>
                                                                                        <th className="text-white" scope="col">Scheme</th>
                                                                                        <th className="text-white" scope="col">Type</th>
                                                                                        <th className="text-white" scope="col">Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {/* <tr>
                                                                                            <td className="text-white">Jan 1, 2022</td>
                                                                                            <td className="text-white">Monthly</td>
                                                                                            <td className="text-white">Zero Filing - CA</td>
                                                                                            <td className="text-success">Open</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Mar 1, 2022</td>
                                                                                            <td className="text-white">Monthly</td>
                                                                                            <td className="text-white">Zero Filing - Co</td>
                                                                                            <td className="text-success">Open</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Jun 7, 2022</td>
                                                                                            <td className="text-white">Monthly</td>
                                                                                            <td className="text-white">Zero Filing - Co</td>
                                                                                            <td className="text-success">Open</td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Aug 12, 2022</td>
                                                                                            <td className="text-white">Monthly</td>
                                                                                            <td className="text-white">Zero Filing - Co</td>
                                                                                            <td className="text-success">Open</td>
                                                                                        </tr> */}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-12 col-xl-6 col-lg-12">
                                                            <div
                                                                className="card animate__animated animate__fadeInUp animate__delay-_3s text-white bg-dm-light py-4 mb-3">
                                                                <div className="card-header px-4">
                                                                    <span>Messages</span>
                                                                    <p>(Unread)</p>
                                                                </div>
                                                                <div className="card-body">
                                                                    <div className="d-flex">
                                                                        <div
                                                                            className="animate__animated animate__fadeIn animate__delay-_3s flex-fill">
                                                                            <table className="table table-hover">

                                                                                <tbody>
                                                                                    {/* <tr>
                                                                                            <td className="text-white">Message Title - Company
                                                                                                Name</td>
                                                                                            <td className="text-white" align="right"><a href="#"
                                                                                                className="btn btn-outline-light btn-sm">View</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Message Title - Company
                                                                                                Name</td>
                                                                                            <td className="text-white" align="right"><a href="#"
                                                                                                className="btn btn-outline-light btn-sm">View</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Message Title - Company
                                                                                                Name</td>
                                                                                            <td className="text-white" align="right"><a href="#"
                                                                                                className="btn btn-outline-light btn-sm">View</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Message Title - Company
                                                                                                Name</td>
                                                                                            <td className="text-white" align="right"><a href="#"
                                                                                                className="btn btn-outline-light btn-sm">View</a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td className="text-white">Message Title - Company
                                                                                                Name</td>
                                                                                            <td className="text-white" align="right"><a href="#"
                                                                                                className="btn btn-outline-light btn-sm">View</a>
                                                                                            </td>
                                                                                        </tr> */}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>
            </main>
        </section>



    );

}





