var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { FieldType } from 'common/types/DocumentLabelerTypes';
var clearRegionFromTable = function (state, action) {
    if (action.payload.type !== FieldType.Table || !action.payload.activeCell) {
        throw new Error('Cannot remove block from table cell if field is not a table with an active cell');
    }
    var _a = action.payload, id = _a.id, activeCell = _a.activeCell;
    var _b = DocumentLabelerReducerUtils.getTableFromState(state, id), table = _b.table, idx = _b.idx;
    var _c = DocumentLabelerReducerUtils.getCellInfoFromTable(table, activeCell), cell = _c.cell, rowIdx = _c.rowIdx, columnIdx = _c.columnIdx;
    var updatedCell = __assign(__assign({}, cell), { region: undefined });
    var updatedTable = DocumentLabelerReducerUtils.updateTableWithNewCell(table, updatedCell, rowIdx, columnIdx);
    return DocumentLabelerReducerUtils.updateStateWithNewTable(state, updatedTable, idx);
};
var clearRegionFromFormField = function (state, action) {
    var _a = DocumentLabelerReducerUtils.getFieldFromState(state, action.payload.id), field = _a.field, idx = _a.idx;
    var updatedField = __assign(__assign({}, field), { region: undefined });
    return DocumentLabelerReducerUtils.updateStateWithNewField(state, updatedField, idx);
};
var clearRegionFromField = function (state, action) {
    if (action.payload.type === FieldType.Table) {
        return clearRegionFromTable(state, action);
    }
    else {
        return clearRegionFromFormField(state, action);
    }
};
var addRegionToActiveTableCell = function (state, action, activeTable) {
    if (activeTable.type !== FieldType.Table || !activeTable.activeCell) {
        throw new Error('Cannot remove block from table cell if field is not a table with an active cell');
    }
    var id = activeTable.id, activeCell = activeTable.activeCell;
    var _a = DocumentLabelerReducerUtils.getTableFromState(state, id), table = _a.table, idx = _a.idx;
    var _b = DocumentLabelerReducerUtils.getCellInfoFromTable(table, activeCell), cell = _b.cell, rowIdx = _b.rowIdx, columnIdx = _b.columnIdx;
    var updatedCell = __assign(__assign({}, cell), { blocks: [], region: action.payload.region });
    var updatedTable = DocumentLabelerReducerUtils.updateTableWithNewCell(table, updatedCell, rowIdx, columnIdx);
    return DocumentLabelerReducerUtils.updateStateWithNewTable(state, updatedTable, idx);
};
var addRegionToActiveFormField = function (state, action, activeField) {
    var _a = DocumentLabelerReducerUtils.getFieldFromState(state, activeField.id), field = _a.field, idx = _a.idx;
    var updatedField = __assign(__assign({}, field), { blocks: [], region: action.payload.region });
    return DocumentLabelerReducerUtils.updateStateWithNewField(state, updatedField, idx);
};
var addRegionToActiveField = function (state, action) {
    if (!state.localState.activeField) {
        throw new Error('Cannot add block to active field if no field is active');
    }
    if (state.localState.activeField.type === FieldType.Table) {
        return addRegionToActiveTableCell(state, action, state.localState.activeField);
    }
    else {
        return addRegionToActiveFormField(state, action, state.localState.activeField);
    }
};
export var RegionReducerUtils = {
    clearRegionFromField: clearRegionFromField,
    addRegionToActiveField: addRegionToActiveField,
};
