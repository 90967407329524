import * as yup from 'yup';
export interface TransactionTypeModel {
    transactionId: number
    transactionTypeName: string
    createdBy: number
    modifiedBy: number
    dateCreated: Date
    dateModified: Date
    isDeleted: boolean
  }

export const TransactionTypeModel_VS = yup.object({ 
    transactionTypeName: yup.string().required("Enter  transaction type").trim().max(30,"Name cannot exceed 30 characters")
  })