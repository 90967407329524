var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { Box, makeStyles } from '@material-ui/core';
import { FieldsPanel } from 'documentLabeler/components/fieldsPanel/FieldsPanel';
import { DocumentPanel } from 'documentLabeler/components/documentPanel/DocumentPanel';
import { FieldType } from 'common/types/DocumentLabelerTypes';
import { TableLabeler } from 'documentLabeler/components/tableLabeler/TableLabeler';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flex: 1,
    },
    FieldsPanelContainer: {
        width: theme.spacing(45),
        display: 'flex',
    },
    DocumentDisplayerContainer: {
        flex: 1,
        display: 'flex',
    },
}); });
/**
 * Top level component containing the Embedded Document Labeler.  Takes in document info
 * as the data property, as well as an onSaveCallback to be executed when the save button
 * is clicked.  Handles positioning and rendering of the sub components
 * @param Props
 */
export var DocumentLabelerContent = function () {
    var classes = useStyles();
    var state = useDocumentLabeler().state;
    return (_jsxs(Box, __assign({ className: classes.Root }, { children: [_jsx(Box, __assign({ className: classes.DocumentDisplayerContainer }, { children: _jsx(DocumentPanel, {}) })), _jsx(Box, __assign({ className: classes.FieldsPanelContainer }, { children: _jsx(FieldsPanel, {}) })), state.localState.activeField &&
                state.localState.activeField.type === FieldType.Table && (_jsx(TableLabeler, {}))] })));
};
