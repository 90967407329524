var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useDocumentLabeler } from 'documentLabeler/DocumentLabelerProvider';
import { DocumentLabelerState } from 'documentLabeler/state/DocumentLabelerState';
import { useBBConfiguration } from 'documentLabeler/context/BBConfigurationProvider';
var useStyles = makeStyles(function (theme) { return ({
    Root: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    Button: {
        width: theme.spacing(12),
        textTransform: 'none',
    },
}); });
var FIELDS = 'Fields';
var SAVE = 'Save';
/**
 * Header component for the fields panel which contains the title of the panel
 * and the save button which dispatches the onSaveCallback action
 */
export var FieldsPanelHeader = function () {
    var classes = useStyles();
    var _a = useBBConfiguration(), saveActionButtonText = _a.saveActionButtonText, displayOnly = _a.displayOnly, hideSaveButton = _a.hideSaveButton, onSaveCallback = _a.onSaveCallback;
    var state = useDocumentLabeler().state;
    var onSaveClick = function () {
        return onSaveCallback(DocumentLabelerState.convertInternalStateToOutputData(state));
    };
    var shouldShowSaveButton = !hideSaveButton && !displayOnly;
    return (_jsxs(Box, __assign({ className: classes.Root }, { children: [_jsx(Typography, __assign({ variant: "subtitle2" }, { children: FIELDS })), shouldShowSaveButton && (_jsx(Button, __assign({ variant: "contained", color: "primary", className: classes.Button, onClick: onSaveClick, disableElevation: true }, { children: saveActionButtonText || SAVE })))] })));
};
