
import moment from 'moment';
import * as yup from 'yup'

export interface stateFlagsModel {
    stateFlagId: number,
    stateFlagName : string
    errorDescription: string,
    isDeleted : boolean
    stateId: number
    stateName : string
    tabId  : number    
    tabName : string
}
    
export const StateError_VS = yup.object({
    stateFlagName: yup.string().required("Please enter error message").trim().typeError('Invalid Value'),
    stateId: yup.number().required().min(1, "Please select State").typeError("invalid value"),
    tabId: yup.number().required().min(1, "Please select Tab").typeError('Invalid Value'),
  })

  export const StateErrorDD_VS = yup.object({
    stateFlagId: yup.number().required("Please select valid error").min(1).typeError('Invalid Value'),
  })

  export const SubmissionErrorDD_VS = yup.object({
    slaSubmissionNumber: yup.string().required("Please enter Submission number").trim().typeError('Invalid Value'),
  })
  