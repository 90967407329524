// Possible Display values for checkbox fields
var CheckboxDisplayValue;
(function (CheckboxDisplayValue) {
    CheckboxDisplayValue["Selected"] = "Selected";
    CheckboxDisplayValue["NotSelected"] = "Not Selected";
})(CheckboxDisplayValue || (CheckboxDisplayValue = {}));
// Converts API checkbox values to human readable display
var generateCheckboxDisplayFromValue = function (value) {
    if (value === 'SELECTED') {
        return CheckboxDisplayValue.Selected;
    }
    else if (value === 'NOT_SELECTED') {
        return CheckboxDisplayValue.NotSelected;
    }
    else {
        return value;
    }
};
export var CheckboxUtil = {
    generateCheckboxDisplayFromValue: generateCheckboxDisplayFromValue,
};
