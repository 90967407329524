import React, { useEffect, useState } from "react";
import useAuth from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";


interface Iprops {
    apiDataString: string,
    // fetchData: () => void,
}

export const BtisAPIDataList: React.FC<Iprops> = ({ apiDataString }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;


    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }


    // function jsonToTable(jsonString: string): string {
    //     try {
    //         const data = JSON.parse(jsonString);

    //         const flattenObject = (obj: any, parentKey = ''): any => {
    //             let result: any = {};

    //             for (const key in obj) {
    //                 const newKey = parentKey ? `${parentKey}.${key}` : key;

    //                 if (typeof obj[key] === 'object' && obj[key] !== null) {
    //                     result = { ...result, ...flattenObject(obj[key], newKey) };
    //                 } else {
    //                     result[newKey] = obj[key];
    //                 }
    //             }

    //             return result;
    //         };

    //         const flattenedData = flattenObject(data);

    //         let tableHtml = '<table><tbody>';

    //         for (const key in flattenedData) {
    //             tableHtml += `<tr><td>${key}</td><td>${flattenedData[key]}</td></tr>`;
    //         }

    //         tableHtml += '</tbody></table>';
    //         return tableHtml;
    //     } catch (error: any) {
    //         return `<p>Error: ${error.message}</p>`;
    //     }
    // }

    // const jsonString = ButlerModelCL; // Paste the entire JSON string here
    // const tableHtml = jsonToTable(jsonString);

    // console.log(tableHtml);

    function jsonToTable(jsonString: string): string {
        try {
            const data = JSON.parse(jsonString);

            const flattenObject = (obj: any, parentKey = ''): any => {
                let result: any = {};

                for (const key in obj) {
                    const newKey = parentKey ? `${parentKey}.${key}` : key;

                    if (typeof obj[key] === 'object' && obj[key] !== null) {
                        result = { ...result, ...flattenObject(obj[key], newKey) };
                    } else {
                        result[newKey] = obj[key];
                    }
                }

                return result;
            };

            const flattenedData = flattenObject(data);

            let tableHtml = '<table class="table"><tbody>';
            // tableHtml += `<th>key</th><th>value</th>`
            for (const key in flattenedData) {
                tableHtml += `<tr><td>${key}</td><td>${flattenedData[key]}</td></tr>`;
            }

            tableHtml += '</tbody></table>';
            return tableHtml;
        } catch (error: any) {
            return `<p>Error: ${`No API data found`}</p>`;
        }
    }

    const jsonString = apiDataString; // Paste the entire JSON string here
    const tableHtml = jsonToTable(jsonString);

    // Append the generated table to an existing element with id "tableContainer"
    const tableContainer = document.getElementById('tableContainer');
    if (tableContainer) {
        tableContainer.innerHTML = tableHtml;
    } else {
        console.log("Element with id 'tableContainer' not found.");
    }



    return (
      
            <div className="card">
                <div id="tableContainer">
                </div>

            </div>
    )
}