var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { FieldType, } from 'common/types/DocumentLabelerTypes';
var addBlockToActiveTable = function (state, action, activeTable) {
    if (!activeTable.activeCell) {
        throw new Error('Cannot add blocks to active table if no cell is selected');
    }
    var _a = DocumentLabelerReducerUtils.getTableFromState(state, activeTable.id), table = _a.table, idx = _a.idx;
    var _b = DocumentLabelerReducerUtils.getCellInfoFromTable(table, activeTable.activeCell), cell = _b.cell, rowIdx = _b.rowIdx, columnIdx = _b.columnIdx;
    // filter out any possible duplicate blocks
    var blocksToAdd = action.payload.blocks.filter(function (block) { return !cell.blocks.some(function (activeBlock) { return activeBlock.id === block.id; }); });
    var updatedCell = __assign(__assign({}, cell), { region: undefined, blocks: __spreadArray(__spreadArray([], cell.blocks, true), blocksToAdd, true) });
    var updatedFields = state.docInfo.results.fields.map(function (field) {
        // filter out blocks that were stolen from other fields
        return __assign(__assign({}, field), { blocks: field.blocks.filter(function (block) {
                return !blocksToAdd.some(function (blockToAdd) { return blockToAdd.id === block.id; });
            }) });
    });
    // filter out blocks that were stolen from other tables
    var updatedTables = state.docInfo.results.tables.map(function (table, tblIdx) { return (__assign(__assign({}, table), { rows: table.rows.map(function (row, rIdx) { return (__assign(__assign({}, row), { cells: row.cells.map(function (cell, colIdx) {
                if (colIdx === columnIdx && rIdx === rowIdx && tblIdx === idx) {
                    return updatedCell;
                }
                else {
                    return __assign(__assign({}, cell), { blocks: cell.blocks.filter(function (block) {
                            return !blocksToAdd.some(function (blockToAdd) { return blockToAdd.id === block.id; });
                        }) });
                }
            }) })); }) })); });
    return __assign(__assign({}, state), { docInfo: __assign(__assign({}, state.docInfo), { results: __assign(__assign({}, state.docInfo.results), { tables: updatedTables, fields: updatedFields }) }) });
};
var addBlockToActiveFormField = function (state, action, activeField) {
    var field = DocumentLabelerReducerUtils.getFieldFromState(state, activeField.id).field;
    // filter out any possible duplicate blocks
    var blocksToAdd = action.payload.blocks.filter(function (block) { return !field.blocks.some(function (activeBlock) { return activeBlock.id === block.id; }); });
    var updatedField = __assign(__assign({}, field), { region: undefined, blocks: __spreadArray(__spreadArray([], field.blocks, true), blocksToAdd, true) });
    var updatedFields = state.docInfo.results.fields.map(function (field) {
        if (field.id === updatedField.id) {
            return updatedField;
        }
        // filter out blocks that were stolen from other fields
        return __assign(__assign({}, field), { blocks: field.blocks.filter(function (block) {
                return !blocksToAdd.some(function (blockToAdd) { return blockToAdd.id === block.id; });
            }) });
    });
    // filter out blocks that were stolen from other tables
    var updatedTables = state.docInfo.results.tables.map(function (table) { return (__assign(__assign({}, table), { rows: table.rows.map(function (row) { return (__assign(__assign({}, row), { cells: row.cells.map(function (cell) { return (__assign(__assign({}, cell), { blocks: cell.blocks.filter(function (block) {
                    return !blocksToAdd.some(function (blockToAdd) { return blockToAdd.id === block.id; });
                }) })); }) })); }) })); });
    return __assign(__assign({}, state), { docInfo: __assign(__assign({}, state.docInfo), { results: __assign(__assign({}, state.docInfo.results), { tables: updatedTables, fields: updatedFields }) }) });
};
var addBlockToActiveField = function (state, action) {
    if (!state.localState.activeField) {
        throw new Error('Cannot add block to active field if no field is active');
    }
    if (state.localState.activeField.type === FieldType.Table) {
        return addBlockToActiveTable(state, action, state.localState.activeField);
    }
    else if (state.localState.activeField.type === FieldType.Signature) {
        throw new Error('Cannot add block to signature field');
    }
    else {
        return addBlockToActiveFormField(state, action, state.localState.activeField);
    }
};
var removeBlockFromTableCell = function (state, action) {
    var _a = action.payload, blockId = _a.blockId, field = _a.field;
    if (field.type !== FieldType.Table || !field.activeCell) {
        throw new Error('Cannot remove block from table cell if field is not a table with an active cell');
    }
    var _b = DocumentLabelerReducerUtils.getTableFromState(state, field.id), table = _b.table, idx = _b.idx;
    var _c = DocumentLabelerReducerUtils.getCellInfoFromTable(table, field.activeCell), cell = _c.cell, rowIdx = _c.rowIdx, columnIdx = _c.columnIdx;
    var updatedCell = __assign(__assign({}, cell), { blocks: cell.blocks.filter(function (block) { return block.id !== blockId; }) });
    var updatedTable = DocumentLabelerReducerUtils.updateTableWithNewCell(table, updatedCell, rowIdx, columnIdx);
    return DocumentLabelerReducerUtils.updateStateWithNewTable(state, updatedTable, idx);
};
var removeBlockFromFormField = function (state, action) {
    var blockId = action.payload.blockId;
    var _a = DocumentLabelerReducerUtils.getFieldFromState(state, action.payload.field.id), field = _a.field, idx = _a.idx;
    var updatedField = __assign(__assign({}, field), { blocks: field.blocks.filter(function (block) { return block.id !== blockId; }) });
    return DocumentLabelerReducerUtils.updateStateWithNewField(state, updatedField, idx);
};
var removeBlockFromField = function (state, action) {
    var field = action.payload.field;
    if (field.type === FieldType.Table) {
        return removeBlockFromTableCell(state, action);
    }
    else {
        return removeBlockFromFormField(state, action);
    }
};
export var BlockReducerUtils = {
    addBlockToActiveField: addBlockToActiveField,
    removeBlockFromField: removeBlockFromField,
};
