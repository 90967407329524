import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DownloadIcon from '@mui/icons-material/Download';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel, GridToolbarFilterButton } from '@mui/x-data-grid';
import moment from "moment";
import React, { useState } from "react";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { PolicyFilingsScheduleModel } from "../../models/PolicyFilingScheduleModel";

import { useNavigate } from "react-router-dom";
import { apiClient } from '../../helper/api';

interface Iprops {
    PolicyFilingsCL: PolicyFilingsScheduleModel[],
    fetchData: () => void
}

export const PolicyFilingScheduleList: React.FC<Iprops> = ({ PolicyFilingsCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;

    const initialLBData: PolicyFilingsScheduleModel = {
        insured_Name: "",
        policyNumber: "",
        policyEffectiveDate: "",
        postalAbbv: "",
        policyStatus: "",
        premium: "",



        fileBy: "",
        policyID: 0
    }
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const handleSingleDownload = (id: number) => {
        setloading(true);
        //call api to save data
        apiClient("/PolicyFilings/getStateManualSubmissionData?policyid=" + `${id}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }
        )
            .then((res) => {
                if (!res) return
                console.log(res);
                // Do something with the response
                // window.open(res.data, '_blank');
                window.open(res.data, "_blank");
                // alert(res.data)
            }).finally(() => {
                setloading(false);
            });
    };


    const [selectedRows, setSelectedRows] = useState<PolicyFilingsScheduleModel[]>();

    const onRowsSelectionHandler = (ids: GridSelectionModel) => {

        const selectedIDs = new Set(ids);
        const selectedRowData = PolicyFilingsCL.filter((row) =>
            selectedIDs.has(row?.slNo ?? "")
        );

        setSelectedRows(selectedRowData);
    };

    const handleMultipleDownload = () => {

        const MultiplePolicyIds: string = selectedRows?.map(x => x.customerPolicyId).join(",") ?? ""

        if (MultiplePolicyIds === "") {
            alert("Please Select at least one Filing");
            return;
        }
        setloading(true);
        //call api to save data
        apiClient(`/PolicyFilings/getStateManualSubmissionData?multiCustPolicyId=${MultiplePolicyIds}`,
            {
                method: "POST",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                }),
                // body: JSON.stringify(MultiplePolicyIds)  // <-- Post parameters
            }
        )
            .then((res) => {
                if (!res) return
                setloading(false);

                // Do something with the response
                // window.open(res.data, '_blank');
                console.log(res.data);
                if (res.data != "") {
                    window.open(res.data, "_blank");

                } else {
                    alert('Something went wrong');
                }
                // alert(res.data)
            }).finally(() => {
                setloading(false);
            });
    };



    // const handleEdit = (ILobData: PolicyFilingsScheduleModel) => {
    //     setPaymentFormData(ILobData);
    //     setOpen(true);
    // };


    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row col-lg-12" style={{marginBottom: "10px"}}>


                    <div className="col-lg-4" style={{ fontSize: "32px" }}>
                        <span className="d-flex" >Upcoming Filings</span>
                    </div>
                    <div className="col-lg-4" style={{display: "flex", justifyContent: "center"}}>
                        <span><GridToolbarFilterButton /></span>
                    </div>
                    <div className="col-lg-4" >
                        <div style={{ float: "right", marginRight: "15px" }} >
                            <button

                                className=" btn btn-secondary"
                                onClick={handleMultipleDownload}
                                title=""
                            >
                                Submit to State
                            </button>
                        </div>
                    </div>
                </div>
                {/* <div className="col" style={{ textAlign: "center" }}>
                    <span className="card-title" > <GridToolbarFilterButton /></span>
                </div> */}

            </Box>

        );

    }


    const columns: GridColDef[] = [

        {
            field: 'dateAdded', headerName: 'Date Submitted', flex: 0.7, renderCell: (params) =>
            (
                <div>
                    {params.row.dateAdded ? moment(params.row.dateAdded).format("MM-DD-YYYY") : ""}
                </div>


            )
        },
        { field: 'insured_Name', type: 'string', headerName: 'Insured Name', flex: 1.5 },
        { field: 'postalAbbv', type: 'string', headerName: 'Sate', flex: 0.5 },
        { field: 'policyNumber', type: 'string', headerName: 'Policy Number', flex: 1 },

        // { field: 'premium', type: 'string', headerName: 'Premium', flex: 1 },
        // { field: 'taxFeeName', type: 'string', headerName: 'Tax/FeeName', flex: 0.7 },
        // { field: 'rate', type: 'number', headerName: 'Rate', flex: 0.5 },
        // { field: 'total', type: 'string', headerName: 'Total', flex: 0.7 },
        {
            field: 'policyEffectiveDate', headerName: 'Effective Date', flex: 0.7, renderCell: (params) =>
            (
                <div>
                    {params.row.policyEffectiveDate}
                </div>

            )
        },
        { field: 'fileBy', type: 'string', headerName: 'File By', flex: 0.6 },
        {
            field: 'policyID',
            headerName: 'View Details',
            flex: 0.7,
            renderCell: (params: GridRenderCellParams<number>) => (

                <Link href={'/Transaction/' + params.value} sx={{ textDecoration: "none " }}>
                    <button type="button" className="btn btn-primaryGrid" style={{marginLeft:"10px"}}><i className="fa-solid fa-right-long" style={{marginBottom : "2PX"}}></i></button>

                </Link>

            ),
        },
        // {
        //     field: 'policy',
        //     headerName: 'Genrate Documents',
        //     flex: 0.7,
        //     renderCell: (params: GridRenderCellParams<number>) => (

        //         <IconButton
        //             className="btn btn-primary action-btn"
        //             onClick={() => { handleSingleDownload(params.row.policyID) }}
        //         >
        //             <DownloadIcon />
        //         </IconButton >

        //     ),
        // },


        // {
        //     field: 'batchSubmission', flex: 0.5, headerName: "Download Documents", renderCell: (params: GridRenderCellParams) => (<div>
        //         <IconButton
        //             className="btn btn-primary action-btn"
        //             onClick={() => { handleSingleDownload(params.row.policyID) }}
        //         >
        //             <DownloadIcon />
        //         </IconButton >
        //     </div>)
        // },


    ];



    return (

        <div style={{ height: '75vh', width: '100%' }} className="ShowScrollX">
            <div style={{ height: "1vh" }}>
                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            </div>
            {/* <div className="row col-lg-12">


                <div className="col-lg-4" style={{ fontSize: "32px" }}>
                    <span className="d-flex" >Upcoming Filings</span>
                </div>
                {/* <div className="col-lg-4" >
                    <span className="card-title" ><GridToolbarFilterButton /></span>
                </div> */}
            {/* <div className="col-lg-8" >
                    <div style={{ float: "right" , marginRight : "15px" }} >
                        <button

                            className=" btn btn-secondary"
                            onClick={handleMultipleDownload}
                            title=""
                        >
                            Submit to State
                        </button>
                    </div>
                </div>
            </div> */}
            <DataGrid
                getRowId={(r) => r.slNo}
                rows={PolicyFilingsCL}
                columns={columns}
                pageSize={50}
                // components={{
                //     Toolbar: EditToolbar

                // }}
                rowsPerPageOptions={[50]}
                checkboxSelection
                onSelectionModelChange={(newRowSelectionModel) => {
                    onRowsSelectionHandler(newRowSelectionModel);


                }}
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif",
                        border: "0px",
                        fontSize: "16px",
                        overflowX: "scroll"
                    }
                }
            />

        </div>



    )
}