var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import { DocumentLabeler } from 'documentLabeler/DocumentLabeler';
import { ButlerProvider } from 'common/theme/ButlerProvider';
import { ButlerApiCallFactory } from 'api/apiCalls';
import { BBConfigurationProvider, } from 'documentLabeler/context/BBConfigurationProvider';
/**
 * Will be in butlerBlocks.js, exported by our package and imported
 * via a require statement in our customer's js file
 */
var createDocLabeler = function (id, data, config) {
    var docLabelerContainer = document.getElementById(id);
    if (!docLabelerContainer) {
        throw new Error("Could not find container element with id ".concat(id));
    }
    ReactDOM.render(_jsx(BBConfigurationProvider, __assign({ config: config }, { children: _jsx(ButlerProvider, { children: _jsx(DocumentLabeler, { data: data }) }) })), docLabelerContainer);
};
var loadButlerBlocks = function (apiKey) {
    return {
        createDocLabeler: createDocLabeler,
        api: ButlerApiCallFactory.create(apiKey),
    };
};
export { loadButlerBlocks };
