import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps, TextField } from "@mui/material";
import { FieldAttributes, Form, Formik, useField } from "formik";
import React, { useEffect, useState } from "react";

import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { ButlerMapping_VS, ButlerMappings } from "../../models/ButlerMappings";
import { FMSelectDocumentType, FMSelectLineOfBusiness, FMSelectPartner, FMSelectTransactionType } from "../Elements/select";
import { butlerList, lobData } from "../Lists/ButlerMappingList";
import { Stack } from "@mui/system";
import { Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import { lineOfBusinessModel } from "../Lists/CarrierManagementList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { number } from "yup";
import Swal from "sweetalert2";
import { apiClient } from "../../helper/api";

interface Iprops {
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initvalues: ButlerMappings
    fetchData: () => void,
    setSselectedOption: React.Dispatch<React.SetStateAction<butlerList | null>>
    selectedOptionValue: butlerList | null
    setSelectedTrans: React.Dispatch<React.SetStateAction<string | string[]>>
    SelectedTrans: string[] | string
    SelectedState: string
    setSelectedState: React.Dispatch<React.SetStateAction<string>>
    setSelectedLOB: React.Dispatch<React.SetStateAction<string>>
    SelectedLOB: string
    fetchLOBByStateId: (stateid: string) => void
    Lineofbusiness: lobData[] | null
    setLineOfBusiness: React.Dispatch<React.SetStateAction<lobData[] | null>>
    Value: number


}
export const AddEditButlerMappings: React.FC<Iprops> = ({ setLineOfBusiness,Value, Lineofbusiness, fetchLOBByStateId, SelectedState, setSelectedState, setSelectedLOB, SelectedLOB, Open, handleClose, handleSubmit, initvalues, fetchData, setSselectedOption, selectedOptionValue, setSelectedTrans, SelectedTrans }) => {
    const { user } = useAuth();
    const token = user?.token;
    interface butlerList {
        modelId: number, name: string
    }

    const initialTrandata = { transactionId: 0, transactionTypeName: "" };
    const [TransactionType, setTransactionType] = useState([initialTrandata])
    const [loading, setloading] = useState(false);
    const fetchAllTransData = () => {

        apiClient('/CustomerPolicy/GetTransactionTypeList',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                setTransactionType(responseJson.data)

            })


    }

    const initialdata = { stateId: 0, stateName: "" };
    const [States, setStates] = useState([initialdata]);

    const FMSelectCustomLineOfBusiness: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const handleChange = (event: SelectChangeEvent) => {

            setSelectedLOB(event.target.value);
        };

        return (

            <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}  >
                <InputLabel id="State-Select-Label"> Select LOB</InputLabel>
                <Select
                    onChange={handleChange}
                    labelId="State-Select-Label"
                    label=" Select LOB"
                    value={SelectedLOB}
                    className="border-round main-form-2"
                    IconComponent={ExpandMoreIcon}
                >
                    {/* <MenuItem key={0} value={0}> Select LOB </MenuItem> */}
                    {Lineofbusiness?.map((LineOfBusiness, key) =>
                        <MenuItem key={key} value={LineOfBusiness.id}> {LineOfBusiness.businessCode} - {LineOfBusiness.coverageName} </MenuItem>

                    )}
                </Select>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        )
    }

    function fetchStateData() {
        apiClient(`/States/getStates`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((responseJson) => {

                if (responseJson?.data == null) return

                setStates(responseJson.data);
                console.log(responseJson.data, "datacheckkk")
                console.log("selectd stateid", initvalues.stateId);
                setSelectedState(initvalues.stateId?.toString() ?? '');

            })
    }

    const FMSelectStateCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { stateId: 0, stateName: "" };
        const params = useParams();
        const handleChange = (event: SelectChangeEvent) => {
            console.log("selected stae on change ", event.target.value);
            fetchLOBByStateId(event.target.value);
            setSelectedState(event.target.value);
        };

        // return (
        //     <FormControl fullWidth sx={{ m: 1 }}>
        //         <InputLabel >State</InputLabel>
        //         <Select
        //             onChange={handleChange}
        //             labelId="State-Select-Label"
        //             label="State"
        //             value={SelectedState}
        //         >
        //             {States.map((state, key) =>
        //                 <MenuItem key={key} value={state.stateId}> {state.stateName} </MenuItem>
        //             )}
        //         </Select>

        //         <FormHelperText></FormHelperText>
        //     </FormControl>
        // )
        // const allStatesOption = { stateId: 0, stateName: "All States" };

        return (
            <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel id="State-Select-Label">All States</InputLabel>
                <Select
                    onChange={handleChange}
                    labelId="State-Select-Label"
                    value={SelectedState}
                    label="All States"
                    className="border-round main-form-2"
                    IconComponent={ExpandMoreIcon}
                >
                    {/* <MenuItem key={0} value={'0'}>{""}</MenuItem> */}

                    {States.map((state, key) =>
                        <MenuItem key={key + 1} value={state.stateId}>{state.stateName}</MenuItem>
                    )}
                </Select>
                <FormHelperText></FormHelperText>
            </FormControl>
        )
    }

    const FMSelectTrueFalseCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (
            <React.Fragment>
                <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}>
                    <InputLabel id="Select-Label">Is Required</InputLabel>
                    <Select {...field} labelId="Select-Label" label="Is Required"
                        className="border-round main-form-2"
                        IconComponent={ExpandMoreIcon} >
                        {/* <MenuItem value={"0"}>Is Required</MenuItem> */}
                        <MenuItem value={true as any}>True</MenuItem>
                        <MenuItem value={false as any}>False</MenuItem>
                    </Select>
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        )
    }

    const FMSelectCarrierCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { insuranceCompanyId: 0, name: "" };
        const [Carrier, setCarrier] = useState([initialdata])

        const fetchData = () => {
            apiClient('/Carrier/getCarrierSelect',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((responseJson) => {
                    // Do something with the response
                    if (responseJson?.data == null) return
                    // console.log(responseJson.data)
                    setCarrier(responseJson.data)
                })
        }

        useEffect(() => {
            fetchData();
        }, [])

        // const handleChange = (event: SelectChangeEvent) => {
        //     console.log(event.target.value);
        //  };

        return (
            <React.Fragment>

                <FormControl error={!!errorText} fullWidth sx={{ m: 1 }} >
                    <InputLabel id="State-Select-Label">Carrier</InputLabel>
                    <Select {...field}
                        labelId="State-Select-Label"
                        label="Carrier"
                        value={field.value || ""}
                        className="border-round main-form-2"
                        IconComponent={ExpandMoreIcon}
                    >
                        {/* <MenuItem value={"0"} >Carrier</MenuItem> */}
                        {Carrier.map((carrier, key) =>
                            <MenuItem key={key} value={carrier.insuranceCompanyId}> {carrier.name} </MenuItem>
                        )}
                    </Select>
                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        )
    }

    const FMSelectCustomButlerModel: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { modelId: 0, name: "" };
        const [ButlerModelData, setButlerModelData] = useState([initialdata])
        const fetchData = () => {

            apiClient('/ButlerModel/getAllButlerModels',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((responseJson) => {
                    if (responseJson?.data == null) return
                    // Do something with the response

                    setButlerModelData(responseJson.data)
                    console.log("butlerdata", responseJson)

                })
        }
        const handleOptionChange = (event: React.ChangeEvent<{}>, newValue: butlerList | null) => {
            setSselectedOption(newValue);
        };
        console.log("handleOptionChange", handleOptionChange);

        useEffect(() => {
            fetchData();

        }, [])

        return (
            <FormControl error={!!errorText} fullWidth sx={{ m: 1 }} >
                <Autocomplete
                    className="select-input-border"
                    options={ButlerModelData}
                    getOptionLabel={(option) => option.name}
                    value={selectedOptionValue}
                    popupIcon={<ExpandMoreIcon />}
                    onChange={handleOptionChange}
                    sx={{'& input': {height: "12px",},}}
                    renderOption={(props, option) => (
                        <li {...props} key={option.modelId}>
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) => (
                        <div ref={params.InputProps.ref}>
                            <TextField {...params} label="Select Model" name="modelId" variant="outlined" />
                        </div>
                    )}
                />
            </FormControl>
        )
    }

    const FMSelectTransTypeCustomcopy: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const initialTrandata = { transactionId: 0, transactionTypeName: "" };
        const [TransactionType, setTransactionType] = useState([initialTrandata])
        const fetchAllTransData = () => {

            apiClient('/CustomerPolicy/GetTransactionTypeList',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((responseJson) => {
                    if (responseJson?.data == null) return
                    // Do something with the response

                    setTransactionType(responseJson.data)

                })
        }

        useEffect(() => {
            fetchAllTransData();
        }, []);

        return (

            <FormControl error={!!errorText} fullWidth sx={{ m: 1 }}>
                {/* {(user?.userRoles.split(",").includes("Super Admin")) ?  <InputLabel id="State-Select-Label">Roles</InputLabel> :""} */}
                <InputLabel id="Transaction type">Transaction type</InputLabel>
                <Select
                    {...field}
                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                    // hidden={!(user?.userRoles.split(",").includes("Super Admin"))}
                    label="Transaction type"
                    multiple
                    labelId="Roles"
                    value={SelectedTrans}
                    onChange={(e) => {
                        setSelectedTrans(e.target.value);
                    }}
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.toLocaleString().split(',').map((value) => (
                                <Chip
                                    key={value}
                                    label={TransactionType.find(x => x.transactionId.toString() === value)?.transactionTypeName}
                                    onDelete={() =>
                                        setSelectedTrans(SelectedTrans.toLocaleString().split(',').filter((item) => item !== value))
                                    }
                                    disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) => event.stopPropagation()}
                                        />
                                    }
                                />
                            ))}
                        </Stack>
                    )}

                // input={<OutlinedInput label="Multiple Select" />}
                >

                    {TransactionType.map((Role, key) =>
                        <MenuItem key={key} value={Role.transactionId}> {Role.transactionTypeName} </MenuItem>
                    )}
                </Select>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        )
    }

    //main component
    useEffect(() => {
        fetchStateData();
        fetchAllTransData();
        fetchLOBByStateId('0');

    }, []);

    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'lg'}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
           {initvalues.mappingId === 0 ? <h2 style={{ marginLeft: "2vw" }} className='card-title' >Create New Mapping</h2> : <h2 style={{ marginLeft: "2vw" }} className='card-title'>Edit Mapping</h2>}

            <React.Fragment>
                <Formik

                    initialValues={initvalues}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validationSchema={ButlerMapping_VS}
                    onSubmit={(data, { setSubmitting, setErrors }) => {

                        var oldDocumentTypeId = initvalues.documentTypeId !== null && initvalues.documentTypeId !== undefined && initvalues.documentTypeId > 0 ? initvalues.documentTypeId : data.documentTypeId; //initvalues.documentTypeId;
                        var oldInsuranceCompanyId = initvalues.insuranceCompanyId !== null && initvalues.insuranceCompanyId !== undefined && initvalues.insuranceCompanyId > 0 ? initvalues.insuranceCompanyId : data.insuranceCompanyId;
                        var oldModelId = initvalues.modelId !== null && initvalues.modelId !== undefined && initvalues.modelId > 0 ? initvalues.modelId : selectedOptionValue?.modelId;
                       // var oldInsuranceCompanyId = initvalues.insuranceCompanyId;
                       // var oldModelId = initvalues.modelId;
                        data.modelId = selectedOptionValue?.modelId;

                        data.id = Number(SelectedLOB);
                        data.stateId = Number(SelectedState);
                        const transactionIds: number[] = Array.isArray(SelectedTrans)
                            ? SelectedTrans.map(id => Number(id))
                            : [Number(SelectedTrans)];
                        data.transactionId = transactionIds;
                        if (!data.transactionId || data.transactionId.length < 1) {
                            setErrors({ transactionId: 'Please select transaction' });
                            setSubmitting(false);
                            return;
                        }

                        if (selectedOptionValue?.modelId === undefined || selectedOptionValue?.modelId < 1) {
                            alert("Butler Model Required");
                            setSubmitting(false);
                            setloading(false);
                            return;
                        }
                        data.commaSeparatedTransactionIds = SelectedTrans.toString();

                        setloading(true);

                        apiClient(`/ButlerMappings/AddEditButlerMappings?oldDocumentTypeId=${oldDocumentTypeId}&&oldInsuranceCompanyId=${oldInsuranceCompanyId}&&oldModelId=${oldModelId}&&Value=${Value}`,
                            {
                                method: 'POST',
                                headers: new Headers({
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}`,
                                }),
                                body: JSON.stringify(data)

                            }).then((res) => {
                                if (!res) return
                                fetchData();
                                // Do something with the response
                                Swal.fire({
                                    title: res.message,
                                    text: "",
                                    icon: res.data === 1 ? "success" : res.data === 2 ? "warning" : "info",
                                    confirmButtonText: "OK",
                                });
                                handleClose();
                            }).finally(() => {
                                setSubmitting(false);
                                setloading(false);
                            });

                    }} >
                    {({ isSubmitting, setFieldValue, errors }) =>
                    (
                        <Form   >
                            <DialogContent>
                                <Grid container spacing={2}>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectStateCustom variant={"standard"} name="stateId" ></FMSelectStateCustom>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            {/* <FMSelectLineOfBusiness autoWidth={true} variant={"standard"} name="id" ></FMSelectLineOfBusiness> */}
                                            <FMSelectCustomLineOfBusiness autoWidth={true} variant={"standard"} name="lOB" ></FMSelectCustomLineOfBusiness>

                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectCarrierCustom variant={"standard"} name="insuranceCompanyId" ></FMSelectCarrierCustom>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        {/* <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectTransTypeCustomcopy variant={"standard"} name="transactionId" ></FMSelectTransTypeCustomcopy>
                                        </FormControl> */}
                                        <FormControl error={!!errors.transactionId} fullWidth sx={{ m: 1 }}         >
                                            {/* {(user?.userRoles.split(",").includes("Super Admin")) ?  <InputLabel id="State-Select-Label">Roles</InputLabel> :""} */}
                                            <InputLabel id="Transaction type">Transaction type</InputLabel>
                                            <Select
                                                className=" main-form-2"
                                                disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                                // hidden={!(user?.userRoles.split(",").includes("Super Admin"))}
                                                label="Transaction type"
                                                name="transactionId"
                                                multiple
                                                IconComponent={ExpandMoreIcon}
                                                labelId="Roles"
                                                value={SelectedTrans}
                                                onChange={(e) => {
                                                    setSelectedTrans(e.target.value);
                                                    setFieldValue("transactionId", e.target.value);
                                                }}
                                                renderValue={(selected) => (
                                                    <Stack gap={1} direction="row" flexWrap="wrap">
                                                        {selected.toLocaleString().split(',').map((value) => (
                                                            <Chip
                                                                key={value}
                                                                label={TransactionType.find(x => x.transactionId.toString() === value)?.transactionTypeName}
                                                                onDelete={() => setSelectedTrans(SelectedTrans.toLocaleString().split(',').filter((item) => item !== value))}
                                                                disabled={!(user?.userRoles.split(",").includes("Super Admin") || user?.userRoles.split(",").includes("MISC Filer"))}
                                                                deleteIcon={<CancelIcon  onMouseDown={(event) => event.stopPropagation()} /> }
                                                            />
                                                        ))}
                                                    </Stack>
                                                )}
                                                input={<OutlinedInput label="Multiple Select" />}>

                                                {TransactionType.map((Role, key) =>
                                                    <MenuItem key={key} value={Role.transactionId}> {Role.transactionTypeName} </MenuItem>
                                                )}

                                            </Select>
                                            <FormHelperText>{errors.transactionId}</FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} className="mt-1" >
                                            <FormControl sx={{ width: '100%' }}  >
                                                <FMSelectDocumentType autoWidth={true} variant={"standard"} name="documentTypeId" ></FMSelectDocumentType>
                                            </FormControl></FormControl>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMSelectCustomButlerModel autoWidth={true} variant={"standard"} name="modelId" ></FMSelectCustomButlerModel>
                                        </FormControl>
                                        {/* <Autocomplete

                                            options={ButlerModelData}
                                            getOptionLabel={(option) => option.name}
                                            value={selectedOptionValue}
                                            onChange={handleOptionChange}
                                          
                                            renderInput={(params) => (

                                                <TextField {...params} label="Select Model" name="modelId" variant="outlined" />
                                            )}
                                        /> */}
                                    </Grid>
                                    <Grid item xs={6} >
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectPartner autoWidth={true} variant={"standard"} name="partnersId" ></FMSelectPartner>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectTrueFalseCustom autoWidth={true} variant={"standard"} name="isRequired" ></FMSelectTrueFalseCustom>
                                        </FormControl>
                                    </Grid>
                                </Grid>


                            </DialogContent>
                            <DialogActions>
                                <button className=" btn-2 btn d-flex btn-primary search-btn " type="button" onClick={() => { handleClose() }} >Cancel</button>
                                <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                            </DialogActions>
                        </Form>

                    )}
                </Formik>
            </React.Fragment >
        </Dialog >
    );
}
