import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, LinearProgress, Link, MenuItem, Paper, Select, SelectChangeEvent, SelectProps, Stack, Typography, } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import {
    DataGrid,
    DataGridProps,
    getGridStringOperators,
    GridCellParams,
    GridColDef,
    GridColumnMenuContainer,
    GridColumnMenuProps,
    GridColumnsMenuItem,
    GridFilterMenuItem,
    GridFilterModel,
    GridFilterOperator,
    GridRowId,
    GridRowModes,
    GridRowModesModel,
    GridSelectionModel,
    GridSortModel,
    GridToolbar,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    HideGridColMenuItem,
    SortGridMenuItems,
    // GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { Form, Navigate, useNavigate, useParams } from "react-router-dom";
import { RiExpandUpDownLine } from "react-icons/ri";
import React from "react";
import { FMFilters, NewCompletedFilingsModel, getCompletedFilings__Resp, getPolicyFilingAndReport } from "../../models/NewCompletedFilingsModel";
import { AddEditResubmitSingleDocument, ReqDocuments } from "../../components/AddEdit/AddEditResubmitSingleDocument";
import { PolicyDocumentsPopup } from "./PolicyDocumentsPopup";
import { PolicyErrorPopup } from "./PolicyErrorsPopup";
import { FaRegEdit } from "react-icons/fa";
import moment from "moment";
import { MenuDropdown } from "../../components/Elements/MenuDropdown";
import Swal from "sweetalert2";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";
import { Formik } from "formik";
import { FMTextField } from "../../components/Elements/input";
import FilerNotes from "../../components/Elements/filerNotes";
import { format } from 'date-fns';
import { CustomerPolicyDetailsModel } from "../../models/CustomerPolicyDetailsModel";
import formatPrice from "../../helper/currencyFormatter";
import { PolicyStatuses } from "../../components/Elements/PolicyStatuses";
import { getRowsPerPageOptions } from "../../components/AddEdit/CustomFunctions";
import debounce from "lodash.debounce";
import Tabs, { FMTabs } from "../Tabs";


interface Iprops {
    NewCompletedFilingsCL: getCompletedFilings__Resp;
    getCustomerPolicyListbyTaxType: (
        action: string,
        customerPolicyIds: number[],
        option: string
    ) => void;
    setSelectedRows: React.Dispatch<React.SetStateAction<NewCompletedFilingsModel[] | null>>
    selectedRows: NewCompletedFilingsModel[] | null
    getCustomerPolicyDetails: (customerPolicyIds: number) => Promise<string>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>
    _currentPage: number
    setPageSize: React.Dispatch<React.SetStateAction<number>>
    _pageSize: number
    queryOptions: FMFilters | undefined
    //setQueryOptions: React.Dispatch<React.SetStateAction<GridFilterModel>>
    debouncedOnFilterChange: ((filterModel: GridFilterModel, tabValue: number) => void)
    fetchDataForFilter: (action: string, pageSize: number, pageNumber: number, newFilters?: FMFilters) => void;
    sortModelRef: React.MutableRefObject<GridSortModel>
}

export const RemovedFilingQueue: React.FC<Iprops> = ({
    NewCompletedFilingsCL,
    getCustomerPolicyListbyTaxType,
    setSelectedRows,
    selectedRows,
    getCustomerPolicyDetails,
    setCurrentPage,
    _currentPage,
    setPageSize,
    _pageSize,
    queryOptions,
    debouncedOnFilterChange,
    fetchDataForFilter,
    sortModelRef

}) => {
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const navigate = useNavigate();
    const [Formdata, setFormData] = useState([]);
    const [Open, setOpen] = useState(false);
    const params = useParams();
    const [OpenError, setOpenError] = useState(false);
    const [editableSelectedRows, seteditableSelectedRows] = useState<NewCompletedFilingsModel[]>();
    const [ErrorFormdata, setErrorFormData] = useState([]);
    const [custPolId, setcustPolId] = React.useState("0");
    const [OpenDocResubmission, setDocResubmission] = React.useState(false);
    const [selectedRowStatus, setselectedRowStatus] = useState("");
    const [openNotesDialog, setOpenNotesDialog] = useState<string>('');
    const [openPolicyDueDateNullDialog, setOpenPolicyDueDateNullDialog] = useState(false);
    const [notes, setNotes] = useState<string>("");
    const [currentPolicySubStatus, setCurrentPolicySubStatus] = useState<string>('');

    const statusOptions = ['Not Started', 'Waiting on Client']

    const handleOpenNotesDialog = (status: string) => {
        setOpenNotesDialog(status);
    };

    const handleCloseNotesDialog = () => {
        setOpenNotesDialog('');
    };

    const handleSubmitNotesDialog = (data: { notes: string }) => {

        console.log('Form data:', data);
        // Perform any action with the submitted data
        setNotes(data.notes)
        console.log('Form data:', notes);
        setOpenNotesDialog('');
    };

    const onFilterChange = React.useCallback((filterModel: GridFilterModel) => {
        debouncedOnFilterChange(filterModel, FMTabs.OutStandingFiling);
    }, []);

    const handleSubmitNotes = (data: { status: string, noteType: string, noteDetail: string, noteUser: string, firstRequestDate: string, secondRequestDate: string, thirdRequestDate: string, suspendedDate: string, noteDate?: string, policySubStatus?: string, dateDeclarationFiled?: string }) => {
        const { firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteType, noteDetail, noteUser, noteDate, status, policySubStatus, dateDeclarationFiled } = data;
        setOpenNotesDialog('');
        updateStatus(status, selectedRows, noteDetail, noteUser, firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteDate, policySubStatus, dateDeclarationFiled);
    };

    const handleDocumentReubmission = async (custPolId: number, status: string) => {
        try {
            setloading(true);
            setloading(false);
            setcustPolId(String(custPolId));
            setDocResubmission(true);

        } catch (error) {
            setloading(false);
            console.log(error);
        }
    };

    const onRowsSelectionHandler = (ids: GridSelectionModel | undefined) => {

        const selectedIDs = new Set(ids);
        const uniqueRowsIds = new Set();
        const selectedRowData = NewCompletedFilingsCL.getCompletedFilings_Respdata.filter((row) => {
            if (selectedIDs.has(row?.rowNum ?? "") && !uniqueRowsIds.has(row?.rowNum ?? "")) {
                uniqueRowsIds.add(row?.rowNum ?? "");
                return true;
            }
            return false;
        });

        if (ids?.[0] != null || undefined) {
            setselectedRowStatus(
                NewCompletedFilingsCL.getCompletedFilings_Respdata.find((x) => x.customerpolicyid === Number(ids?.[0]))?.status ?? ""
            );
        } else {
            setselectedRowStatus("");
        }
        if (ids?.[0] != null || undefined) {
            setselectedState(
                NewCompletedFilingsCL.getCompletedFilings_Respdata.find((x) => x.rowNum === Number(ids?.[0]))?.homeState ?? ""
            );
            setselectedTransactionType(
                NewCompletedFilingsCL.getCompletedFilings_Respdata.find((x) => x.customerpolicyid === Number(ids?.[0]))?.transactionTypeName ?? ""
            );
        } else {
            setselectedState("");
            setselectedTransactionType("");
        }

        setSelectedRows(selectedRowData);
        // const rowsWithNullDueDate = selectedRowData?.filter(x => x.dueDate === null);
        // SetCustPolicyNullDueDateData(rowsWithNullDueDate);
    };

    const SumitToSLA = () => {

        var MultiplePolicyIds: string = selectedRows?.map((x) => x.customerpolicyid).join(",") ?? "";
        //const hasNonReadyToFile = selectedRows?.some(row => row.status !== "Ready to File");
        const readyToFileRows = selectedRows?.filter(row => row.status === "Ready to File");
        MultiplePolicyIds = readyToFileRows?.map(x => x.customerpolicyid).join(",") ?? "";


        Swal.fire({
            title: 'Confirmation!',
            text: 'You will not be able to submit this selected for rows that are not "Ready to File". Proceed anyway?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                if (MultiplePolicyIds === "") {
                    alert("Please Select at least one Filing or Policy id does not exist for selected Filing.");
                    return;
                }
                setloading(true);
                fetch(
                    config.url.API_URL +
                    `/SLIPSubmission/SubmitPolicyToState?custPolicyIdList=${MultiplePolicyIds}`,
                    {
                        method: "GET",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        }),
                    }
                )
                    .then((response) => {
                        setloading(false);
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error("Something went wrong");
                    })
                    .then((responseJson) => {
                        setloading(false);

                        alert(responseJson.data);
                        if (MultiplePolicyIds === "") {
                            alert("Please Select at least one Filing");
                            return;
                        }
                    })
                    .catch((error) => {
                        setloading(false);
                        console.log(error);
                    });
            }
            else {
                setloading(false);
                return;
            }
        });

    };

    const handleNextPreviousPage = (newPage: number) => {

        setCurrentPage(newPage);
        fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, newPage, queryOptions)

    };

    const handlePageSize = (newPageSize: number) => {

        setPageSize(newPageSize);
        fetchDataForFilter("getAllRemovedTransactionQueueData", newPageSize, _currentPage, queryOptions)

    };

    const handleSendEmail = () => {

        Swal.fire({
            title: 'Confirmation!',
            text: 'You can send email only for "Flagged" filing. Proceed anyway?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (!selectedRows || selectedRows.length === 0) {
                    // If no rows are selected, show a message to the user
                    alert("Please select row before sending an email");
                    return;
                }
                setloading(true);
                var MultiplePolicyIds: string = selectedRows?.map(x => x.customerpolicyid).join(",") ?? ""
                const FlaggedRows = selectedRows?.filter(row => row.status === "Flagged");
                MultiplePolicyIds = FlaggedRows?.map(x => x.customerpolicyid).join(",") ?? "";
                fetch(config.url.API_URL + `/ProcessPolicyChecks/SendChecksEmailMultiple?customerPolicyIdList=${MultiplePolicyIds}`,
                    {
                        method: "POST",
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        })
                    }).then((response) => {
                        setloading(false);
                        if (response.ok) {

                            return response;
                        }
                        throw new Error('Something went wrong');
                    })
                    .then((responseJson) => {
                        setloading(false);
                        // Do something with the response
                        alert("Email sent");
                        // fetchData();
                    }).catch((error) => {
                        setloading(false);
                        console.log(error)

                    });
            }
            else {
                setloading(false);
                return;
            }
        });

    };

    const handleExport = (option: string) => {
        var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
        getCustomerPolicyListbyTaxType("getCustomerPolicyListbyTaxType", customerPolicyIds, option);
        // window.location.reload();

    };

    const handleMultipleDownload = () => {
        var MultiplePolicyIds: string =
            selectedRows?.map((x) => x.policyId).join(",") ?? "";

        const readyToFileRows = selectedRows?.filter(row => row.status === "Ready to File");
        MultiplePolicyIds = readyToFileRows?.map(x => x.customerpolicyid).join(",") ?? "";

        Swal.fire({
            title: 'Confirmation!',
            text: 'You can download document only for "Ready to File" Filings. Proceed anyway?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Proceed',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {
                if (MultiplePolicyIds === "") {
                    alert("Please Select at least one Filing or Policy Id does not exist for selected Filing.");
                    return;
                }
                setloading(true);
                //call api to save data
                fetch(
                    config.url.API_URL +
                    `/PolicyFilings/getMultiplePolicyBatchSubmissionData?MultiplePolicyIds=${MultiplePolicyIds}`,
                    {
                        method: "POST",
                        headers: new Headers({
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        }),
                        // body: JSON.stringify(MultiplePolicyIds)  // <-- Post parameters
                    }
                )
                    .then((response) => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error("Something went wrong");
                    })
                    .then((res) => {
                        setloading(false);

                        if (res.resp === 1) {

                            alert(res.message);
                            setloading(false);
                            return;
                        }
                        console.log(res.data, "readyTofiledata");
                        if (res.data != "") {

                            window.open(res.data, "_blank");
                            // RefreshListData("getReadyToFile", 8);

                            fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, _currentPage, queryOptions)
                            // navigate(-1);

                        } else {
                            alert("Something went wrong");
                        }
                        // alert(res.data)
                    })
                    .catch((error) => {
                        setloading(false);
                        console.log(error);
                    });
            }
            else {
                setloading(false);
                return;
            }
        });

    };
    const handleEdit = (custPolId: number) => {
        fetch(
            config.url.API_URL +
            `/PolicyDashBoard/getCustomerPolicyDocuments?CustPolicyId=${custPolId}`,
            {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }),
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response
                console.log(responseJson);
                setFormData(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });

        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setOpenError(false);
        setDocResubmission(false);
        setOpenPolicyDueDateNullDialog(false);
        seteditableSelectedRows(undefined);
    };

    const [selectedState, setselectedState] = useState("");
    const [selectedTransactionType, setselectedTransactionType] = useState("");



    const initialPartnerData = { partnersId: 0, customerName: "" };
    const [SLIPCustomer, setSLIPCustomer] = useState([initialPartnerData]);
    const fetchSLIPCustomer = () => {
        fetch(config.url.API_URL + "/Partners/getCASLAPartners", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((responseJson) => {
                // Do something with the response

                setSLIPCustomer(responseJson.data);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchSLIPCustomer();
        const newRowModesModel: { [key: number]: { mode: GridRowModes } } = {};
        NewCompletedFilingsCL?.getCompletedFilings_Respdata?.forEach((row) => {
            newRowModesModel[row.customerpolicyid] = { mode: GridRowModes.Edit };
        });
        setRowModesModel(newRowModesModel);
    }, []);
    const parseDueDate = (dueDateStr: string): Date => {
        const currentDate = new Date();
        const [month, day] = dueDateStr.split('-').map(Number);
        const year = currentDate.getFullYear();
        return new Date(year, month - 1, day);
    };
    const isDueDateWithinRange = (dueDateStr: string): boolean => {
        const currentDate = new Date();
        const dueDate = parseDueDate(dueDateStr);
        const dateDifference = dueDate.getTime() - currentDate.getTime();
        const dayDifference = dateDifference / (1000 * 3600 * 24);
        return dayDifference >= 0 && dayDifference <= 9;
    };
    var count = 0;
    function updateStatus(status: string, selectedRow: any, notes: string = '', filerName: string = '', firstRequestDate: string = '', secondRequestDate: string = '', thirdRequestDate: string = '', suspendedDate: string = '', noteDate?: string, policySubStatus: string = '', dateDeclarationFiled: string = '') {
        setloading(true);
        if (noteDate === null || noteDate === '' || noteDate === undefined) {
            const newDate = new Date().toISOString().slice(0, 10);
            noteDate = moment(newDate).format("MM/DD/YYYY")          
        }
        if (status === "Filed") {
            var customerPolicyIds = selectedRows?.map(m => m.customerpolicyid) ?? [];
            if (count === 0) {

                (async () => {
                    try {
                        const response = await getTaxTypeData('getIsPolicyFilingAndReportByCustPolIds', customerPolicyIds);

                        if (response != null && response.length > 0) {
                            const dueDateNotValid: NewCompletedFilingsModel[] = []; // where due date is not prior 10 days of due date
                            response.forEach((e: getPolicyFilingAndReport) => {
                                if (e.report == true) {
                                    var newData = selectedRows?.find(m => m.customerpolicyid == e.customerPolicyId);
                                    if (!isDueDateWithinRange(newData?.dueDate) && newData !== undefined) {
                                        dueDateNotValid.push(newData);
                                    }
                                }
                            });

                            const DueDateNotValid = new Set(dueDateNotValid.map(item => item.customerpolicyid));
                            const filteredSelectedIds = selectedRows?.filter(item => !DueDateNotValid.has(item.customerpolicyid!))
                            console.log("filteredSelectedIds :", filteredSelectedIds);
                            count++;
                            if (dueDateNotValid.length > 0) {
                                seteditableSelectedRows(filteredSelectedIds)
                                SetCustPolicyNullDueDateData(dueDateNotValid);
                                setOpenPolicyDueDateNullDialog(true);
                            }
                            else {
                                SetCustPolicyNullDueDateData(null);
                                setOpenPolicyDueDateNullDialog(false);
                                seteditableSelectedRows(undefined);
                                updateStatus("Filed", filteredSelectedIds, notes, filerName, firstRequestDate, secondRequestDate, thirdRequestDate, suspendedDate, noteDate, policySubStatus, dateDeclarationFiled);
                            }
                        }
                        setloading(false);
                        return;
                    } catch (error) {
                        console.error("Error fetching tax type data:", error);
                        return;
                    }
                })();
                return;
            }
        }
        setOpenPolicyDueDateNullDialog(false);

        fetch(config.url.API_URL + `/PolicyDashBoard/updateCustomerPolicyStatus?status=${status}&&notes=${notes}&&filerName=${user?.userFirstName + ' ' + user?.userlastName}&&filerDate=${noteDate}&&firstRequestDate=${firstRequestDate}&&secondRequestDate=${secondRequestDate}&&thirdRequestDate=${thirdRequestDate}&&suspendedDate=${suspendedDate}&&policySubStatus=${policySubStatus}&&dateDeclarationFiled=${dateDeclarationFiled}`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
            body: JSON.stringify(selectedRow)
        })
            .then((response) => {
                setloading(false);
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Something went wrong");
            })
            .then((res) => {
                setloading(false);
                if (res !== null && res !== undefined) {

                    Swal.fire({
                        title: res.data,
                        text: res.message,
                        iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                        confirmButtonColor: '#02A4E3',
                        confirmButtonText: 'OK',
                        customClass: {
                            confirmButton: 'btn-radius',
                            cancelButton: 'btn-radius',
                            popup: 'card-radius'
                        }
                    })
                        .then((result) => {

                            setSelectedRows([]);
                            fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, _currentPage, queryOptions)
                        })
                }
            })
            .catch((error) => {
                console.log(error);
                setloading(false);
            });
    }

    const getTaxTypeData = async (action: string, customerPolicyIds: number[]) => {
        try {
            //  setloading(true);

            const initValueExport = {
                customerPolicyIds: customerPolicyIds,
                option: ''
            };

            const response = await fetch(
                config.url.API_URL + `/PolicyDashBoard/${action}`,
                {
                    method: "POST",
                    headers: new Headers({
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    }),
                    body: JSON.stringify(initValueExport)
                }
            );

            if (!response.ok) {
                throw new Error("Something went wrong");
            }

            const res = await response.json();

            if (res.data !== null) {
                //  setloading(false);
                return res.data;
            } else {
                // setloading(false);
                throw new Error("Response data is null");
            }
        } catch (error) {
            // setloading(false);
            throw error;
        }
    };

    const initialdata: NewCompletedFilingsModel = {
        customerpolicyid: 0,
        homeState: "",
        policyId: 0,
        dueDate: ""
    }
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState<GridRowId[]>([]);
    const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds: GridRowId[]) => {
        setDetailPanelExpandedRowIds(newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,);
    },
        [],
    );

    const getDetailPanelContent = React.useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(({ row }) => <DetailPanelContent row={row} />, []);
    const getDetailPanelHeight = React.useCallback(() => 200, []);
    function DetailPanelContent({ row }: { row: NewCompletedFilingsModel }) {
        const [TaxNamAndData, setTaxNamAndData] = useState<NewCompletedFilingsModel[]>([initialdata]);

        const TaxNameAndDate = () => {

            setTaxNamAndData([])
            fetch(config.url.API_URL + `/PolicyDashBoard/getaxTypeandDateByCustpolicyid?customerpolicyId=${row.customerpolicyid}`,
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong');
                })
                .then((responseJson) => {
                    // Do something with the response
                    if (responseJson.data != null) {

                        setTaxNamAndData(responseJson.data)
                        console.log("taxData", responseJson)

                    }


                }).catch((error) => {

                    console.log(error)

                });
        }
        useEffect(() => {
            TaxNameAndDate();
        }, [row])

        const rows = [
            {
                TaxNamAndData
            }
        ];
        return (

            <Stack direction="column" spacing={1} sx={{ height: 1 }} className="hide-border-12" >

                <DataGridPro
                    columns={[
                        {
                            field: 'taxType',
                            type: 'string',
                            headerName: 'Tax Type',
                            flex: 1,
                            headerAlign: "center",
                            hideSortIcons: true,
                            renderHeader: (params) => (
                                <div>
                                    <span className="table-card-title" >{params.colDef.headerName}</span>
                                    <RiExpandUpDownLine />
                                </div>
                            ),
                            align: "center",
                        },

                        {
                            field: 'taxAmt', hideSortIcons: true,
                            renderHeader: (params) => (
                                <div>
                                    <span className="table-card-title" >{params.colDef.headerName}</span>
                                    <RiExpandUpDownLine />
                                </div>
                            ), type: 'string', headerName: 'Tax Amount', flex: 0.5, align: 'center', headerAlign: 'center', renderCell: (params) => {
                                const value = params.value || '0'; // Default to '0' if value is null or undefined
                                return (
                                    <Box display="flex" alignItems="left" justifyContent="left">

                                        {formatPrice(value)}

                                    </Box>
                                )
                            }


                        },


                    ]}
                    rows={TaxNamAndData}
                    getRowId={(r) => r?.rowNum ?? 0}
                    sx={{ flex: 1 }}
                    hideFooter
                />
            </Stack>
        );
    }

    const filterOperators = getGridStringOperators().filter(({ value }) => ['contains'].includes(value));
    const equalOperators: GridFilterOperator<any, string | number | null, any>[] =  getGridStringOperators().filter(({ value }) => ['equals'].includes(value));
      


    const columns: GridColDef[] = [
        {
            field: 'dueDate',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div className="d-flex align-items-center">
                    <span className="table-card-title word-wrap-1 p-0">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            type: 'string',
            headerName: 'Filing Due Date',
            flex: 0.5,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                if (!params.value) {
                    return <span style={{ color: 'inherit' }}>{params.value}</span>;
                }

                let dueDate;
                try {
                    dueDate = new Date(params.value);
                    if (isNaN(dueDate.getTime())) {
                        throw new Error('Invalid date');
                    }
                } catch (error) {
                    console.error('Invalid date value:', params.value, error);
                    return <span style={{ color: 'inherit' }}>{params.value}</span>;
                }

                const currentDate = new Date();
                const isLate = dueDate < currentDate;

                return (
                    <span style={{ color: isLate ? 'red' : 'inherit' }}>
                        {format(dueDate, 'MM/dd/yyyy')}
                    </span>
                );
            }
        },
        {
            field: 'agency', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Client', flex: 0.5, align: 'center', headerAlign: 'center', filterOperators
        },
        {
            field: 'invoiceNumber', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Invoice Number', flex: 0.7, align: 'center', headerAlign: 'center', filterOperators
        },

        {
            field: 'insuredName',
            type: 'string',
            headerName: 'Insured Name',
            flex: 1,
            headerAlign: "center",
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center", filterOperators
        },
        {
            field: 'policyNumber',
            type: 'string',
            headerName: 'Policy Number',
            flex: 0.6,
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),

            headerAlign: "center",
            align: "center", filterOperators
        },
        {
            field: "homeState",
            type: "string",
            headerName: "Home State",
            flex: 0.5,
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center",
            headerAlign: "center", filterOperators
        },

        {
            field: "transactionTypeName",
            type: "string",
            headerName: "Transaction Type",
            flex: 0.7, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            align: "center",
            headerAlign: "center", filterOperators
        },
        {
            field: 'invoiceDate', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title " >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Invoice Date', flex: 0.7, align: 'center', headerAlign: 'center', filterOperators: equalOperators, 
            renderCell: (params) =>
            (
                <div>
                    {(params.row.invoiceDate != null && params.row.invoiceDate != '') ? moment(params.row.invoiceDate).format("MM/DD/YYYY") : ""}
                </div>
            )
        },
        {
            field: 'effectiveDate', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title " >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Policy Effective Date', flex: 0.7, align: 'center', headerAlign: 'center', filterOperators: equalOperators,
            renderCell: (params) =>
            (
                <div>
                    {(params.row.effectiveDate != null && params.row.effectiveDate != '') ? moment(params.row.effectiveDate).format("MM/DD/YYYY") : ""}
                </div>
            )
        },
        {
            field: 'policyExpDate', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title " >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Policy Expiration Date', flex: 0.7, align: 'center', filterOperators: equalOperators, 
            headerAlign: 'center', renderCell: (params) =>
            (
                <div>
                    {(params.row.policyExpDate != null && params.row.policyExpDate != '') ? moment(params.row.policyExpDate).format("MM/DD/YYYY") : ""}
                </div>
            ),

        },
        {
            field: 'grossPremium',
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title">{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ),
            type: 'string',
            headerName: 'Gross Premium',
            flex: 0.5,
            align: 'left',
            headerAlign: 'center',
            filterOperators,
            renderCell: (params) => {
                let grossPremium = params.value || ''; // Default to an empty string if null or undefined
        
                // Trim any spaces and remove the dollar sign and commas
                let cleanedValue = grossPremium.replace(/[,\$]/g, "").trim();
        
                // If the value is in parentheses, it's negative, so handle that
                if (cleanedValue.startsWith("(") && cleanedValue.endsWith(")")) {
                    cleanedValue = "-" + cleanedValue.slice(1, -1); // Remove parentheses and add a negative sign
                }
        
                // Convert to number and pass to formatPrice
                const numericValue = parseFloat(cleanedValue);
        
                // If numericValue is a valid number, format it; otherwise, return the original string
                return isNaN(numericValue) ? grossPremium : `${formatPrice(numericValue.toString())}`;
            }
        },
        {
            field: 'status', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), type: 'string', headerName: 'Status', flex: 0.4, align: 'center', headerAlign: 'center', filterOperators,
            renderCell: (params) => {
                return <div className={`custom-cell w-100 h-100`}>
                    <MenuDropdown
                        type={'none'}
                        menuTitle={params.value}
                        items={statusOptions}
                        onItemClick={item => {
                            setSelectedRows([params.row])
                            handleStatus(item)
                        }}
                    />
                </div>
            },
        }
    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer  >
                <GridToolbarFilterButton className="me-3 sky-blue" />
                <GridToolbarColumnsButton className="me-3 sky-blue" />
                {/* <GridToolbarExport className="me-3 sky-blue" /> */}
            </GridToolbarContainer>

        );
    }
    function checkSelectedRowsForNY(): boolean {
        if (selectedRows?.find(x => x.homeState === 'NY')) {
            if (selectedRows.find(x => x.homeState !== 'NY')) {
                Swal.fire({
                    title: 'Warning!',
                    text: "Please select filings either specifically for New York or those that exclude New York, as there is a special rule for changing the status in New York.",
                    iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                    // showCancelButton: true,
                    confirmButtonColor: '#02A4E3',
                    // cancelButtonColor: '#d33',
                    // confirmButtonText: 'Proceed',
                    customClass: {
                        confirmButton: 'btn-radius',
                        // cancelButton: 'btn-radius',
                        popup: 'card-radius'
                    }
                })
                    .then((result) => {

                    });
                return false;
            }


        }
        return true;
    }
    function handleStatus(status: string) {
        if (!checkSelectedRowsForNY()) {
            return;
        }
        setCurrentPolicySubStatus('');
        const isNewYorkFiling = selectedRows && selectedRows?.length === 1 && selectedRows[0].homeState === 'NY' && (selectedRows[0].transactionTypeName === 'New Business' || selectedRows[0].transactionTypeName === 'Renewal Business')
        if (status === 'Waiting on Client' || (status === 'Filed' && isNewYorkFiling)) {
            (async () => {
                var response = await getCustomerPolicyDetails(selectedRows !== null ? selectedRows[0].customerpolicyid : 0)
                setCurrentPolicySubStatus(response);
                handleOpenNotesDialog(status);
                
            })();
        }
        else if (selectedRows?.find(m => m.homeState === "NY") && selectedRows.length !== 1 && status === "Filed") {
            Swal.fire({
                title: "",
                text: "Please select one filing for 'New York' state",
                icon: 'warning',
                confirmButtonText: "OK",
            });
            return;
        }
        else {
            Swal.fire({
                title: 'Confirmation!',
                text: "Do you want to update customer policy's status as " + status + "?",
                iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
                showCancelButton: true,
                confirmButtonColor: '#02A4E3',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Proceed',
                customClass: {
                    confirmButton: 'btn-radius',
                    cancelButton: 'btn-radius',
                    popup: 'card-radius'
                }
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        setloading(true);
                        if (status !== undefined && status !== '' && status !== null) {
                            setCurrentPolicySubStatus('');
                            updateStatus(status, selectedRows);

                        }
                    }
                });

            setloading(false);
        }
    }


    function EditToolbar() {
        return (

            <div className=" p-2 d-flex justify-content-end datagrid-butons">
                <div className="ml-2"><CustomToolbar /></div>


                <div style={{ display: "flex" }} className="align-items-center" >
                    {/* <button
                        className=" me-2 active-btn-blue "
                        onClick={handleExport}
                        title="Export Customer Policies" >
                        Export
                    </button> */}

                    {selectedRowStatus === "Ready to File" ?
                        <button
                            className=" me-2 active-btn-blue "
                            onClick={handleMultipleDownload}
                            title="Download" >
                            Download
                        </button>
                        : null}
                    {selectedRowStatus === "Flagged" ?

                        <button
                            className="btn  btn-primary active-btn-blue "
                            style={{ color: "white", margin: '4px' }}
                            onClick={handleSendEmail}>
                            Send Email
                        </button>
                        : null}
                    {selectedRowStatus === "Ready to File" ?
                        <button
                            className="  active-btn-blue "
                            onClick={SumitToSLA}
                            title="Submit to SLA" >
                            Submit SLA
                        </button>
                        : null}
                    {selectedRows?.length !== 0 && selectedRows?.length !== undefined ?

                        <div className="dropdowm-class ms-2" style={{ zIndex: "9" }} >
                            <MenuDropdown
                                menuTitle={'Status'}
                                items={status}
                                onItemClick={handleStatus} />
                        </div>
                        : ''}
                    <button
                        className=" ms-2  active-btn-blue-export-12  "
                        onClick={() => { handleExport('Detailed') }}
                        title="Export" >
                        Export
                    </button>
                </div>
            </div>
        );
    }

    const status = [
        'Not Started', 'Waiting on Client', 'Ready to File', 'Filed', 'Pending Autofile'
        // 'Missing Documents', 'Pending Documents', 'Flagged','Waiting on Client', 'Ready to File', 'Pending Autofile', 'Pending state review', 'State Flagged', 'Filed', 'IsClosed', 
    ]
    if (user?.userRoles.split(",").includes("Super Admin")) {
        status.push('Removed');
    }

    const exportOptions = [
        'Short', 'Detailed'
    ]
    const exportOptionsAdmin = [
        'Short', 'Detailed', 'All'
    ]
    const handleSortModelChange = React.useCallback((sortModel: GridSortModel) => {
        sortModelRef.current = sortModel;

        fetchDataForFilter("getAllRemovedTransactionQueueData", _pageSize, _currentPage, queryOptions)
    }, []);

    interface newProp {
        handleClose: () => void;
        open: boolean;

    }
    const columnsForNullDueDate: GridColDef[] = [
        { field: 'customerpolicyid', headerName: 'Customer Policy Id', width: 150, align: "center", headerAlign: "center" },
        { field: 'policyNumber', headerName: 'Policy Number', width: 150, align: "center", headerAlign: "center" },
        { field: 'insuredName', headerName: 'Insured Name', width: 150, align: "center", headerAlign: "center" },
        { field: 'dueDate', headerName: 'Due Date', width: 150, align: "center", headerAlign: "center" }
    ];

    function submit() {
        count++;
        updateStatus("Filed", editableSelectedRows);
    }
    const [CustPolicyNullDueDateData, SetCustPolicyNullDueDateData] = React.useState<any>();
    const ShowNullFilings = ({ open, handleClose }: newProp) => {
        return (
            <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
                <DialogTitle className='card-title'>Customer Policy </DialogTitle>
                <DialogContent>
                    <span className="text-danger">Below filings cannot be updated to status "Filed" until 10 days prior to the report due date!</span>
                </DialogContent>
                <DialogContent>
                    <div style={{ height: '50vh', width: '100%' }} >
                        <DataGrid
                            rows={CustPolicyNullDueDateData ?? null}
                            columns={columnsForNullDueDate}
                            pageSize={50}
                            getRowId={(row) => row.rowNum}
                            sx={{ border: "none" }}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className='btn-2 btn d-flex btn-primary search-btn' type='button' onClick={handleClose}>Cancel</button>
                    {editableSelectedRows !== undefined && editableSelectedRows !== null && editableSelectedRows?.length > 0 ?
                        <button className='btn btn-primary active-btn-blue ' type='button' onClick={submit}>Update Rest</button>
                        : ""
                    }
                </DialogActions>
            </Dialog>
        );
    };

    function CustomColumnMenu(props: GridColumnMenuProps) {
        const { hideMenu, currentColumn, color, ...other } = props;

        return (
            <GridColumnMenuContainer
                hideMenu={hideMenu}
                currentColumn={currentColumn}
                {...other}
            >
                <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
                <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
                <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
                <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
            </GridColumnMenuContainer>
        );
    }

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    return (
        <div style={{ height: "59vh" }}>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <DataGridPro
                rowHeight={60}
                getRowId={(r) => r?.rowNum}
                rows={NewCompletedFilingsCL?.getCompletedFilings_Respdata}
                columns={columns}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}

                pageSize={_pageSize}
                pagination
                rowCount={NewCompletedFilingsCL?.totalCount}
                paginationMode="server"
                onPageChange={(newPage) => handleNextPreviousPage(newPage + 1)}
                onPageSizeChange={(newPageSize) => handlePageSize(newPageSize)}

                filterMode="server"
                onFilterModelChange={onFilterChange}

                detailPanelExpandedRowIds={detailPanelExpandedRowIds}
                onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}

                sortingMode="server"
                onSortModelChange={handleSortModelChange}
                sortModel={sortModelRef.current}
                sortingOrder={['desc', 'asc']}        
                rowsPerPageOptions={getRowsPerPageOptions(NewCompletedFilingsCL?.totalCount, _pageSize)}

                rowModesModel={rowModesModel}
                checkboxSelection={
                    user?.userRoles.split(",").includes("Super Admin") ||
                        user?.userRoles.split(",").includes("MISC Filer") ||
                        user?.userRoles.split(",").includes("MISC State Reporter")
                        ? true : false
                }
                onSelectionModelChange={user?.userRoles.split(",").includes("Super Admin") ||
                    user?.userRoles.split(",").includes("MISC Filer") ||
                    user?.userRoles.split(",").includes("MISC State Reporter")
                    ? (newRowSelectionModel) => {
                        onRowsSelectionHandler(newRowSelectionModel);
                    } : undefined}
                selectionModel={selectedRows?.map((row) => row.rowNum) as GridRowId[]}
                disableSelectionOnClick
                initialState={{ pinnedColumns: { left: ['dueDate'] } }}

                components={{
                    Toolbar: EditToolbar,
                    ColumnMenu: CustomColumnMenu
                }}

                sx={{
                    border: "0px",
                    fontSize: "16px",
                    '& .MuiDataGrid-columnHeaderTitle': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        wordWrap: 'break-word',
                        textOverflow: 'clip',
                    },
                    '& .MuiDataGrid-cell': {
                        overflow: 'visible',
                        whiteSpace: 'normal',
                        lineHeight: 'normal',
                        wordWrap: 'break-word',
                        textOverflow: 'clip',
                    },
                    ".custom-cell": {
                        button: {
                            padding: 0,
                            width: '100%',
                            height: '100%',
                            textAlign: 'center',
                            WebkitLineClamp: 2,
                            lineClamp: 2,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                        }
                    },
                }}

            />
            <PolicyDocumentsPopup
                handleClose={handleClose}
                Open={Open}
                documentList={Formdata}
            />
            <PolicyErrorPopup
                handleClose={handleClose}
                Open={OpenError}
                documentList={ErrorFormdata}
            />
            {OpenDocResubmission ? <AddEditResubmitSingleDocument open={OpenDocResubmission} handleClose={handleClose} custPolicyID={custPolId} /> : ""}

            {openNotesDialog && <FilerNotes
                open={!!openNotesDialog}
                handleClose={handleCloseNotesDialog}
                handleSubmit={handleSubmitNotes}
                selectedRows={selectedRows}
                currentStatus={openNotesDialog}
                currentPolicySubStatus={currentPolicySubStatus}
            />}
            <ShowNullFilings handleClose={handleClose} open={openPolicyDueDateNullDialog} />
        </div>
    );
};