import * as yup from 'yup'

export interface CstomerInvoiceModel {
    customerName:string,
    userId: number,
    customerInvoiceId: number,
    description: string,
    invoiceId: number,
    amount: number,
    isPaid: boolean,
    dueDate: Date,
    zipCode: number,
    naic: string,
    taxId: string,
    memo: string,
    invoiceType:string,
    accountingContactName: string,
    accountingContactEmail: string,
    accountingContactPhone: string
    FeeList?:Fee[]
}

interface Fee{
    Amount:string,
    Memo:string
}

export const CstomerInvoiceModel_VS = yup.object({
    customerName: yup.string().required("Please enter Customer Name").typeError("inavlid value"),

    description: yup.string().required("Please enter Description").typeError("inavlid value"),
    zipCode:yup.number().required("Please enter Zip Code").typeError("inavlid value"),
    naic: yup.string().required('Enter NAIC').typeError("inavlid value"),
    taxId: yup.string().required('Enter TaxId').typeError("inavlid value"),
    accountingContactName: yup.string().required('Enter Contact Name').typeError("inavlid value"),
    accountingContactEmail: yup.string().required('Enter Contact Email').typeError("inavlid value"),
    accountingContactPhone: yup.string().required('Enter Contact Phone').typeError("inavlid value"),


  
})