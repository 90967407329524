import * as Sentry from "@sentry/react";
import { config } from "../Constants";

interface LogInfo {
  level: Sentry.SeverityLevel,
  message: string,
  timestamp: number,
  extra?: { [key: string]: any },
}

const API_URL = config.url.API_URL

const apiClient = async (url: RequestInfo | URL, options: RequestInit = {}, logInfo?: Partial<LogInfo> ) => {
  try {
    const response = await fetch(API_URL + url, options)
    if (!response.ok) {
      const res = {
        status: response.status,
        statusText: response.statusText,
        url: response.url,
      }
      const resp = await response.json().then(json => {
        const errors = JSON.stringify(json.errors)
        return { ...res, ...json, errors }
      }).catch(err => {
        return res
      })
      console.error("Fetch Error: ", { url, response: resp })
      alert("Fetch Error: " + url)
      Sentry.captureMessage(`Fetch Error: ${url}`, {
        level: 'error',
        extra: {
          url,
          options,
          responseData: resp,
        }
      })
      return
    }
    if (logInfo) {
      Sentry.captureEvent({
        message: logInfo.message || '',
        level: logInfo.level || 'info',
        extra: logInfo.extra || {},
      })
    }
    return await response.json()
  } catch (error: any) {
    alert("Unexpected error during fetch: " + url)
    Sentry.captureMessage('Unexpected error during fetch: ', {
      level: 'error',
      extra: {
        url,
        options,
      }
    })
    return
    // throw error
  }
}

export {
  apiClient
}