var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// This error will
// - fail in compilation if the val does not satisfy never type
// - throw in production if it truly gets an unexpected value
//
// Usage: default throw in switch statements involving enums.
// The example below will fail to compile if you remove the
// cases for Foo or Bar.
//
// enum EnumType {
//   Foo = 'FOO',
//   Bar = 'BAR',
// }
// switch (enumVal) {
//   case EnumType.Foo:
//     doSomething();
//   case EnumType.Bar:
//     doSomething();
//   default:
//     throw new TypesafeUnreachableError(enumVal);
// }
var TypesafeUnreachableError = /** @class */ (function (_super) {
    __extends(TypesafeUnreachableError, _super);
    function TypesafeUnreachableError(val) {
        return _super.call(this, "Unreachable case: ".concat(val)) || this;
    }
    return TypesafeUnreachableError;
}(Error));
export { TypesafeUnreachableError };
