import * as yup from 'yup'

export interface CustomerPaymentLinkModel{
    customerPaymentLinkId: number,
    payee: string,
    emailAddress: string,
    amount: number,
    notes: string,
    isExpired: boolean,
    dateCreated?: Date,
    dateUpdated?: Date,
    status?:string,
    paymentDate?:Date
}

export interface getAllCustomerPaymenLinkLinkModel{
    customerPaymentLinkId?: number,
    customerName?: string,
    payee?: string,
    paymentMethod?: string,
    amount?: number,
    fee?: string,
    net?: string,
    paymentDate?: Date,
    status?: string
   
}




export const CustomerPaymentLinkModel_VS = yup.object({
    payee :yup.string().required("Please enter Payee Name"),
  
    amount :yup.number().required("Please Enter Amount").min(1,"Please enter Valid Amount"),
})
