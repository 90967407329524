import { jsx as _jsx } from "react/jsx-runtime";
import { MimeType } from 'common/types/DocumentLabelerTypes';
import React, { useEffect } from 'react';
import { Page, pdfjs } from 'react-pdf';
// Serve pdf worker from CDN because the CRA build
// does not work properly for this dependency.
// https://butlerinc.atlassian.net/browse/BUTL-96
pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/".concat(pdfjs.version, "/pdf.worker.js");
var DEFAULT_SCALE = 1;
/**
 * Reusable hook for the purpose of displaying a multi page
 * document.
 *
 * @param stepLabels
 */
export var useDocumentDisplayer = function (mimeType, size, docUri) {
    var isPdf = mimeType === MimeType.Pdf;
    // State for image rendering
    var _a = React.useState(size.height), renderedImgHeight = _a[0], setRenderedImageHeight = _a[1];
    // State for PDF rendering
    // - pages: array of Page components for the PDF
    // - renderedHeights: map of page index to rendered height
    var _b = React.useState([]), pages = _b[0], setPages = _b[1];
    var _c = React.useState([]), renderedHeights = _c[0], setRenderedHeights = _c[1];
    // When a new document is loaded, we clear out the existing pages and page heights
    // To ensure that the newly rendered pages generate correctly
    useEffect(function () {
        setPages([]);
        setRenderedHeights([]);
    }, [docUri]);
    // Get heights for each page
    // - PDF: then convert renderedHeights map to an array
    // - image: assume single page with component height
    var pageHeights = isPdf
        ? renderedHeights
        : [renderedImgHeight];
    // When the document data is successfully loaded
    // - render a Page component for each page of fixed width
    // - on render success save the rendered height
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    var onPdfDocumentLoadSuccess = function (pdf) {
        var newPages = [];
        var _loop_1 = function (pageIndex) {
            // The onRenderSuccess prop is typed incorrectly for the react-pdf
            // Page component as a function with no arguments.
            //
            // - Declare a function with an argument that contains the fields
            //   we need to properly determine the rendered height.
            // - Force cast to function with no arguments when passing in prop
            //   to silence compiler errors.
            var onRenderSuccess = function (renderedPage) {
                setRenderedHeights(function (prev) {
                    var newArray = prev.slice();
                    // Get the default viewport
                    var defaultViewPort = renderedPage.getViewport({
                        scale: DEFAULT_SCALE,
                        rotate: renderedPage.rotate,
                    });
                    // Calculate the actual scale based on rendered page.
                    // Can be be different than default scale if rendered page is scaled
                    // for some reason (ex. window resize etc)
                    var scale = (DEFAULT_SCALE * renderedPage.width) / defaultViewPort.width;
                    // Get the actual current viewport
                    var viewPort = renderedPage.getViewport({
                        scale: scale,
                        rotate: renderedPage.rotate,
                    });
                    // If page is rotated an odd multiple of 90 degrees
                    // use the viewport width as the page height instead.
                    //
                    // One case where this can happen if the PDF is configured to
                    // display pages in landscape mode
                    if ((renderedPage.rotate / 90 + 2) % 2 === 1) {
                        newArray[pageIndex] = viewPort.width;
                    }
                    else {
                        newArray[pageIndex] = viewPort.height;
                    }
                    return newArray;
                });
            };
            newPages.push(_jsx(Page, { pageIndex: pageIndex, width: size.width, onRenderSuccess: onRenderSuccess, renderAnnotationLayer: false }, pageIndex));
        };
        for (var pageIndex = 0; pageIndex < pdf.numPages; ++pageIndex) {
            _loop_1(pageIndex);
        }
        setPages(newPages);
    };
    // When the image is rendered successfully, render the height
    // so that the blocks are rendered correctly
    var onImgDocumentLoadSuccess = function (height) {
        setRenderedImageHeight(height);
    };
    // Tells the consumer of this hook when the document is readily viewable
    var documentIsVisible = (isPdf && pages.length > 0 && renderedHeights.length === pages.length) ||
        (!isPdf && renderedImgHeight > 0);
    return {
        pages: pages,
        pageHeights: pageHeights,
        loaders: {
            onPdfDocumentLoadSuccess: onPdfDocumentLoadSuccess,
            onImgDocumentLoadSuccess: onImgDocumentLoadSuccess,
        },
        documentIsVisible: documentIsVisible,
    };
};
