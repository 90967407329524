import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { LineOfBusinessModel, LineOfBusinessModel_VS } from '../../models/LineOfBusinessModel';
import { Form, Formik } from 'formik';
import { FMTextField } from '../Elements/input';
import { FMSelectState } from '../Elements/select';
import { config } from '../../Constants';
import useAuth from "../../context/AuthProvider";
import reactSelect from 'react-select';
import { useState } from 'react';
import { string } from 'yup';
import { initial } from 'lodash';
import { apiClient } from '../../helper/api';


interface Iprops {
    LineOfBusinessCL: LineOfBusinessModel,

    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void,
    initValues: LineOfBusinessModel
}

// const [selectedPayoutTypeId, setselectedStateID] = useState([initial]);

// const handleChange = (event: SelectChangeEvent) => {

//     setselectedStateID(event.target.value);
// }

export const AddEditLineOfBusiness: React.FC<Iprops> = ({ handleClose, Open, LineOfBusinessCL, handleSubmit, initValues }) => {
    const initialValues: LineOfBusinessModel = initValues ? LineOfBusinessCL : initValues;

    const { user } = useAuth();
    const token = user?.token;




    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle className='card-title' >Line Of Business</DialogTitle>
            <Formik
                initialValues={initialValues}
                validateOnChange={true}
                validationSchema={LineOfBusinessModel_VS}
                onSubmit={(data, { setSubmitting }) => {
                    console.log("lobdata", JSON.stringify(data));
                    apiClient('/LineOfBusiness/Update',
                        {

                            method: 'POST',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        }).then((res) => {
                            if (!res) return
                            // Do something with the response
                            handleSubmit();
                            setSubmitting(true);
                        })
                }} >
                {({ values, isSubmitting }) => (
                    <Form>

                        <DialogContent>
                            <Box sx={{ display: 'flex', m: 2 }}>
                                <FMTextField name="businessCode" autoFocus margin="dense" placeholder="Coverage Name" label="Coverage Name" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                            </Box>
                            <Box sx={{ display: 'flex', m: 2 }}>
                                <FMTextField name="coverageName" autoFocus margin="dense" placeholder="Coverage Code" label="Coverage Code" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                            </Box>
                            {LineOfBusinessCL.stateId === 2 || LineOfBusinessCL.stateId === 3 ?
                                <Box sx={{ display: 'flex', m: 2 }}>
                                    <FMTextField name="typeofRisk" autoFocus margin="dense" placeholder="TypeofRisk" label="TypeofRisk" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                </Box>
                                : ""}

                            {/* <Box sx={{ display: 'flex', m: 2, }}>

                                <FMSelectState autoWidth={true} name="stateId"  ></FMSelectState>

                            </Box> */}

                            {/* { selectedPayoutTypeId == '2' ? (
                            <Box sx={{ display: 'flex', m:2 }}>
                                <FMTextField name="ClassofBusinessTypeofRisk" autoFocus margin="dense" placeholder="Class of Business/Type of Risk" label="Class of Business/Type of Risk" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                            </Box>
                            ):"" 
                            } */}



                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn " onClick={handleClose} type="button" >Cancel</button>
                            <button    className="btn  btn-primary active-btn-blue  search-btn " type="submit" disabled={isSubmitting} >Submit</button>
                        </DialogActions>

                    </Form>

                )}


            </Formik>
        </Dialog>

    );
}

