import *  as yup from 'yup'
export interface PartnerAgencyDetailsModel {
    partnerAgencyDetailsId: number,
    firstName?: string,
    lastName?: string,
    partnersId?: number,
    stateID?: number,
    isDeleted?: boolean,
    licenseNumber?: string,
    slBrokerNumber?: string,
    dateCreated?: Date,
    dateModified?: Date,
    agencyLicenseNumber ?:string,
    latestTransactionNumber ? : string,
    nationalProducerNumber? : string,
    previousCredit?:number,
    originalAmount?:number,
    carryForward?:number,
    taxId ?: string,
    individualNPN ?:string
    dateLicensed?: string
}


export const PartnerAgencyDetailsModelVS = yup.object({
    // licenseNumber: yup.string().required("Please enter name").min(1).trim().typeError('Invalid selection'),



    licenseNumber: yup.string()
    .trim('The licenseNumber cannot include leading and trailing spaces')
    .strict(true)
    .min(1, 'The license Number needs to be at least 1 char')
    .max(512, 'The license Number cannot exceed 512 char')
    .required('This field is required'),

    latestTransactionNumber: yup.string().ensure()
      .when('stateID',{is : 30, then : yup.string().matches(/^J\d{4}-\d{2}-\d{5}$/,'Invalid format, Format consists of (Agent #)-(Last 2 digits of TRANSACTION year)-(5 digit consecutive number) ie. J1526-23-00001')
      .required('This field is required')}),

    dateLicensed: yup.date()
    .when('stateID', {
      is: 23,
      then: yup.date().required('This field is required'),
      otherwise: yup.date().nullable(),
    })

    // nationalProducerNumber: yup.string()
    // .trim(' National Producer Number cannot include leading and trailing spaces')
    // .strict(true)
    // .min(1, 'The National Producer Number needs to be at least 1 char')
    // .max(512, 'The National Producer Number cannot exceed 512 char')
    // .required('This field is required'),
  });
  
