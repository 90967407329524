import { LinearProgress, FormHelperText, Checkbox, FormControl, Button, Link, Grid, SelectProps, InputLabel, Select, MenuItem, ListItem } from "@mui/material";
import { Formik, Form, Field, FieldAttributes, useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FMTextField } from "../Elements/input";
import { CASlaJsonData } from "../../models/CASlaJsonDataModel";
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import CancelIcon from "@mui/icons-material/Cancel";
import OutlinedInput from "@mui/material/OutlinedInput";
import CircularProgress from '@mui/material/CircularProgress';
import { apiClient } from "../../helper/api";

export interface LObList {
    id: number;
    businessCode: string;
    coverageName: string;
    stateId: number;
}



export const AddEditCASlaJsonData = () => {

    const [loading, setloading] = useState(true);
    const [loadngSelectedSyndicate, setloadngSelectedSyndicate] = useState(true);

    const { user } = useAuth();
    const token = user?.token;
    const params = useParams();
    const navigate = useNavigate();
    const [Open, setOpen] = React.useState(false);

    const initValues: CASlaJsonData = {
        includedCAARP: false,
        isGoodDriver: false,
        isLessThan3Insurer: false,
        wasSubmittedToAdmittedInsurers: false,
        caarpIneligible: false
    }


    const [JsonData, setJsonData] = useState(initValues)

    const goBack = () => {
        navigate(-1);
    }
    const handleClose = () => {

        setOpen(false);
    };
    const fetchData = () => {
        setloading(true);
        apiClient(`/SLIPSubmission/getJsonData?custPolicyId=${params.CustomerPolicyId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((responseJson) => {
                if (responseJson?.data == null) return
                // Do something with the response
                
                console.log("slipdata", responseJson)

                if (responseJson.data.getCustomerPolicyDetails !== null || responseJson.data.getSLIPPolicySubmissionData !== null) {
                    var data = responseJson.data.getCustomerPolicyDetails !== null ? responseJson.data.getCustomerPolicyDetails : responseJson.data.getSLIPPolicySubmissionData;

                    data.effectiveDate = moment(data.effectiveDate).format("YYYY/MM/DD");
                    data.experationDate = moment(data.experationDate).format("YYYY/MM/DD");
                    data.dateSigned = moment(data.dateSigned).format("YYYY/MM/DD");
                    data.invoiceDate = moment(data.invoiceDate).format("YYYY/MM/DD");

                    setJsonData(data);

                    // responseJson.data.effectiveDate = moment(responseJson.data.effectiveDate).format("YYYY/MM/DD");
                    // responseJson.data.experationDate = moment(responseJson.data.experationDate).format("YYYY/MM/DD");
                    // responseJson.data.dateSigned = moment(responseJson.data.dateSigned).format("YYYY/MM/DD");
                    // responseJson.data.invoiceDate = moment(responseJson.data.invoiceDate).format("YYYY/MM/DD");

                    // //setJsonData(responseJson.data.getSLIPPolicySubmissionData);
                    // if (responseJson.data.getCustomerPolicyDetails !== null) {
                    //     setJsonData(responseJson.data.getCustomerPolicyDetails);
                    // } else {
                    //     setJsonData(responseJson.data.getSLIPPolicySubmissionData);
                    // }
                }

            }).finally(() => {
                setloading(false);
            });
    }
    const FMSelectTrueFalseCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";

        return (
            <React.Fragment>
                <FormControl error={!!errorText} className="border-round select-input-border" fullWidth>

                    <Select {...field} label={null}
                        IconComponent={ExpandMoreIcon} input={<OutlinedInput label="Multiple Select" />}
                    >

                        <MenuItem value={true as any}>True</MenuItem>
                        <MenuItem value={false as any}>False</MenuItem>

                    </Select>

                    <FormHelperText>{errorText}</FormHelperText>
                </FormControl>
            </React.Fragment>

        )
    }

    const [selectedSyndicateList, setselectedSyndicateList] = React.useState<string[] | string>([]);

    function fetchSyndicateListData() {
        setloadngSelectedSyndicate(true);
        apiClient(`/MOSurplusLinesInsurers/getSyndicateList?custPolicyId=${params.CustomerPolicyId}`,

            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((responseJson) => {
                if (!responseJson) return
                let abc = responseJson.data?.split(',') ?? [];
                console.log("abc", abc);

                setselectedSyndicateList(abc);

            }).then(() => {
                // setTimeout(() => {
                setloadngSelectedSyndicate(false);
                // }, 5000);
            })


    }


    const initialdata = { carrierCode: 0, company: "", moSurplusLinesInsurersID: 0 };
    const [SLInsurer, setSLInsurer] = React.useState([initialdata]);

    function fetchSLInsurerData() {

        apiClient('/MOSurplusLinesInsurers/getAllSLInsurer',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            }).then((responseJson) => {
                if (!responseJson) return
                setSLInsurer(responseJson.data)
                console.log("SLInsurer", responseJson);


            }).then(() => {
                fetchSyndicateListData();
            })


    }

    const FMSelectSLInsurer: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {

        const [field, meta, helpers] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";


        return (

            <FormControl error={!!errorText} fullWidth sx={{}} >

                <InputLabel id="State-Select-Label">SL-Insurer</InputLabel>

                <Select
                    {...field}
                    label="SL-Insurer"
                    multiple
                    labelId="SL-Insurer"
                    value={selectedSyndicateList} // Ensure this is an array of selected IDs
                    onChange={(e) => {
                        setselectedSyndicateList(e.target.value); // Set the selected IDs array
                    }}
                    renderValue={(selected) => (
                        <Stack gap={3} direction="row" flexWrap="wrap">
                            {
                                selectedSyndicateList.toLocaleString().split(',').map((value) =>
                                (
                                    <Chip
                                        key={value}
                                        label={
                                            SLInsurer.find(x => x.moSurplusLinesInsurersID?.toString() === value?.toString())?.company
                                        }
                                        onDelete={() =>
                                            setselectedSyndicateList(
                                                selectedSyndicateList?.toLocaleString().split(',').filter((item) => item !== value)
                                            )
                                        }
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                            />

                                        }
                                    />
                                ))
                            }
                        </Stack>
                    )}
                    input={<OutlinedInput label="SL-Insurer" />}
                >
                    {SLInsurer.map((line) => (
                        <MenuItem key={line.moSurplusLinesInsurersID} value={line.moSurplusLinesInsurersID?.toString()}>
                            {`${line.carrierCode} - ${line.company}`}

                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>

        )
    }


    const FMCustomLineOfBusiness: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;
        const initialLOBdata = { id: 0, businessCode: "", coverageName: "", stateId: 0 };
        const [LineOfBusiness, setLineOfBusiness] = useState([initialLOBdata])

        const fetchData = () => {

            apiClient('/LineOfBusiness/getLineOBusinessForSelectMO',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                }).then((responseJson) => {

                    // Do something with the response
                    if (responseJson?.data != null) {
                        //const filteredData = responseJson.data.filter(x => x.stateid === 25);
                        // setLineOfBusiness(filteredData);
                        setLineOfBusiness(responseJson.data);
                        let selectedValue = JsonData.moLobId;
                        let slOP = LineOfBusiness.find(x => x.id === selectedValue);

                    }
                    console.log("lobdata", setLineOfBusiness)


                })


        }
        useEffect(() => {
            fetchData();
        }, [])

        return (
            <FormControl error={!!errorText} fullWidth sx={{}} >
                <InputLabel id="State-Select-Label">Line of business</InputLabel>
                <Select {...field}
                    labelId="State-Select-Label"
                    label="Line of business"
                >
                    {LineOfBusiness.map((line, key) =>
                        <MenuItem key={key} value={line.id}> {line.businessCode} - {line.coverageName} </MenuItem>
                    )}

                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>



        )
    }

    useEffect(() => {
        fetchData();
        fetchSLInsurerData();


    }, [])
    return (

        <React.Fragment>

            <Formik
                enableReinitialize={true}
                initialValues={JsonData}
                //validateOnChange={true}
                // validationSchema={StateStampListModel_VS}

                onSubmit={(data, { setSubmitting }) => {
                    
                    data.carrierCompany = selectedSyndicateList.toString();
                    console.log("FinalData", data);
                    setloading(true);
                    data.customPolicyId = Number(params.CustomerPolicyId);
                    // data.moLobId = selectedOption?.id;

                    setselectedSyndicateList([]);
                    apiClient(`/SLIPSubmission/SaveJsonData`,
                        {
                            method: 'POST',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            body: JSON.stringify(data)  // <-- Post parameters

                        })
                        .then((res) => {
                            if (!res) return
                            // Do something with the response
                            if (res.resp == 0) {
                                navigate("/8");
                            }


                        }).finally(() => {
                            setSubmitting(false);
                            setloading(false);

                        });

                }} >
                {({ submitForm, values, setFieldValue, isSubmitting }) =>
                (
                    <Form>
                        {loading && <LinearProgress style={{ margin: 2 }} />}
                        <div className=" col-lg-12 main-form">

                            <Grid container spacing={2} >
                                <Grid item xs={12} >
                                    <span className="card-title">Update Submission Data</span>
                                    <div className="mt-3" >
                                        <span className="sub-card-title" >Surplus Lines Broker</span>
                                    </div>

                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Surplus Lines Broker Name
                                        </FormHelperText>
                                        {/* <FMTextField name="broker" InputLabelProps={{ shrink: true }} autoFocus margin="dense" label="Broker" type="text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="slBroker" InputLabelProps={{ shrink: true }} autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Surplus Lines Broker License Number
                                        </FormHelperText>
                                        {/* <FMTextField name="brokerLicenseNumber" InputLabelProps={{ shrink: true }} label="Broker License Number" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="slBrokerLicenseNumber" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}> <FormHelperText className='formtexthelper-heading' >
                                    Retail Agent Name
                                </FormHelperText>
                                    <FormControl sx={{ width: '100%' }} >

                                        <FMTextField name="broker" InputLabelProps={{ shrink: true }} autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Retail Agency Name
                                        </FormHelperText>
                                        {/* <FMTextField name="agency" InputLabelProps={{ shrink: true }} label="Agency" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="agency" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}> <FormHelperText className='formtexthelper-heading' >
                                    Retail Agency License Number
                                </FormHelperText>
                                    <FormControl sx={{ width: '100%' }} >

                                        {/* <FMTextField name="agencyLicenseNumber" InputLabelProps={{ shrink: true }} label="Agency License Number" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="agencyLicenseNumber" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>



                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Retail Agent License Number
                                        </FormHelperText>
                                        <FMTextField name="brokerLicenseNumber" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Surplus Lines Agency Name
                                        </FormHelperText>
                                        <FMTextField name="slAgency" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Surplus Lines Agency Number
                                        </FormHelperText>
                                        <FMTextField name="slAgencyLicense" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} display='flex' flexDirection='row'>
                                    <span className="sub-card-title " >Insured</span>
                                </Grid>

                                {/* <Grid item xs={3}>
                                                                                <FormControl sx={{ width: '100%' }}>
                                                                                    <FMTextField name="binderNumber" InputLabelProps={{ shrink: true }} label="Binder Number" autoFocus margin="dense" type="text" variant="outlined" sx={{}} />

                                                                                </FormControl >
                                                                            </Grid> */}
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Name
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredName" InputLabelProps={{ shrink: true }} label="Insured Name" type="text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="insuredName" InputLabelProps={{ shrink: true }} label={null} type="text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            DBA
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredName" InputLabelProps={{ shrink: true }} label="Insured Name" type="text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="dba" InputLabelProps={{ shrink: true }} label={null} type="text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Street Address
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredAddressLine1" InputLabelProps={{ shrink: true }} label="InsuredAddress Line 1" type="text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="insuredAddressLine1" InputLabelProps={{ shrink: true }} label={null} type="text" fullWidth variant="outlined" sx={{}} />

                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Street Address Line 2
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredAddressLine2" InputLabelProps={{ shrink: true }} label="Insured Address Line2" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="insuredAddressLine2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />

                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            City
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredCity" InputLabelProps={{ shrink: true }} label="Insured City" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="insuredCity" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            State
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredStateOrProvince" InputLabelProps={{ shrink: true }} label="Insured State Or Province" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="insuredStateOrProvince" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Zip Code
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredPostalCode" InputLabelProps={{ shrink: true }} label="Insured Postal Code" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="insuredPostalCode" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            County
                                        </FormHelperText>
                                        {/* <FMTextField name="insuredPostalCode" InputLabelProps={{ shrink: true }} label="Insured Postal Code" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="county" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} display='flex' flexDirection='row'>
                                    <span className="sub-card-title " >Policy</span>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Inusured Country
                                        </FormHelperText>
                                        <FMTextField name="inusuredCountry" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Number
                                        </FormHelperText>
                                        {/* <FMTextField name="policyNumber" InputLabelProps={{ shrink: true }} label="Policy Number" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="policyNumber" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Effective Date
                                        </FormHelperText>
                                        <FMTextField name="effectiveDate" InputLabelProps={{ shrink: true }} label={null} placeholder="yyyy-mm-dd" type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Expiration Date
                                        </FormHelperText>
                                        <FMTextField name="experationDate" InputLabelProps={{ shrink: true }} label={null} placeholder="yyyy-mm-dd" type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Carrier Name
                                        </FormHelperText>
                                        {/* <FMTextField name="insuranceCompanyName" InputLabelProps={{ shrink: true }} label="Insurance Company Name" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="insuranceCompanyName" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Carrier NAIC Number
                                        </FormHelperText>
                                        {/* <FMTextField name="naicNumber" InputLabelProps={{ shrink: true }} label="Naic Number" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="naicNumber" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />

                                    </FormControl>
                                </Grid>

                                {JsonData.insuredStateOrProvince === "MO" ?
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FMTextField name="carrierCode" InputLabelProps={{ shrink: true }} label="Carrier Code" type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    : ""}
                                {/* <Grid item xs={3}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <FMTextField name="customTransactionId" InputLabelProps={{shrink:true}}  label="Custom Transaction Id" type="Text" fullWidth variant="outlined" sx={{}} />
                                                                                </FormControl>
                                                                            </Grid> */}
                                {/* <Grid item xs={3}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <FMTextField name="transactionTypeName" InputLabelProps={{shrink:true}} label="Transaction Type" type="Text" fullWidth variant="outlined" sx={{}} />
                                                                                </FormControl>
                                                                            </Grid> */}
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Line Of Business
                                        </FormHelperText>
                                        <FMTextField name="lineOfBusiness" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Invoice Date
                                        </FormHelperText>
                                        <FMTextField name="invoiceDate" InputLabelProps={{ shrink: true }} label={null} type="Text" placeholder="yyyy-mm-dd" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Date Signed
                                        </FormHelperText>
                                        <FMTextField name="dateSigned" InputLabelProps={{ shrink: true }} label={null} placeholder="yyyy-mm-dd" type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Risk Description
                                        </FormHelperText>
                                        <FMTextField name="riskDescription" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={3}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <FMTextField name="AddressLine1" InputLabelProps={{ shrink: true }} label="Risk Address" type="Text" fullWidth variant="outlined" sx={{}} />
                                                                                </FormControl>
                                                                            </Grid> */}
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Risk Street Address
                                        </FormHelperText>
                                        {/* <FMTextField name="riskLocationAddressLine1" InputLabelProps={{ shrink: true }} label="Risk Location Address Line1" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="riskLocationAddressLine1" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />

                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Risk Street Address Line 2
                                        </FormHelperText>
                                        {/* <FMTextField name="riskLocationAddressline2" InputLabelProps={{ shrink: true }} label='Risk Location Address line2' type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="riskLocationAddressline2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Risk City
                                        </FormHelperText>
                                        {/* <FMTextField name="riskLocationCity" InputLabelProps={{ shrink: true }} label="Risk Location City" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="riskLocationCity" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />

                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Risk State
                                        </FormHelperText>
                                        {/* <FMTextField name="riskLocationState" InputLabelProps={{ shrink: true }} label="Risk Location State" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="riskLocationState" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Risk Zip Code
                                        </FormHelperText>
                                        {/* <FMTextField name="riskLocationPostalCode" InputLabelProps={{ shrink: true }} label="Risk Location Postal Code" type="number" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="riskLocationPostalCode" InputLabelProps={{ shrink: true }} label={null} type="number" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Risk County
                                        </FormHelperText>
                                        {/* <FMTextField name="riskLocationPostalCode" InputLabelProps={{ shrink: true }} label="Risk Location Postal Code" type="number" fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="riskCounty" InputLabelProps={{ shrink: true }} label={null} type="text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} display='flex' flexDirection='row'>
                                    <span className="sub-card-title " >Premium & Taxes</span>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Premium
                                        </FormHelperText>
                                        <FMTextField name="premium" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>


                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Policy Fee
                                        </FormHelperText>
                                        <FMTextField name="policyFee" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Admin Fee
                                        </FormHelperText>
                                        <FMTextField name="adminFee" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Misc Fee
                                        </FormHelperText>
                                        <FMTextField name="miscFee" label={null} type="Text" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Broker Fee
                                        </FormHelperText>
                                        {/* <FMTextField name="fee" label="Fee" type="Text" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{}} /> */}
                                        <FMTextField name="brokerFee" label={null} type="Text" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{}} />

                                    </FormControl>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Stamping Fee
                                        </FormHelperText>
                                        <FMTextField name="stampingFee" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={3}>
                                                                                <FormControl sx={{ width: '100%' }} >
                                                                                    <FMTextField name="maintenanceFee" label="Maintenance Fee" type="Text" InputLabelProps={{ shrink: true }} fullWidth variant="outlined" sx={{}} />
                                                                                </FormControl>
                                                                            </Grid> */}
                                <Grid item xs={12} display='flex' flexDirection='row'>
                                    <span className="sub-card-title" >Diligent Search Efforts</span>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Name Of Search Performer
                                        </FormHelperText>
                                        <FMTextField name="nameOfSearchPerformer" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Purchasing Group Address
                                        </FormHelperText>
                                        <FMTextField name="purchasingGroupAddress" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Purchasing Group Name
                                        </FormHelperText>
                                        <FMTextField name="purchasingGroupName" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Submitted To Admitted Insurers
                                        </FormHelperText>
                                        <FMSelectTrueFalseCustom name="wasSubmittedToAdmittedInsurers" />
                                        {/* <FMTextField name="wasSubmittedToAdmittedInsurers" InputLabelProps={{shrink:true}}  label="Was Submitted To Admitted Insurers" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                    </FormControl>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xl={6} lg={6} md={12} sm={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Admitted Insurer 1
                                            </FormHelperText>
                                            <FMTextField name="admittedInsurer1" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xl={6} lg={6} md={12} sm={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Representative 1
                                            </FormHelperText>
                                            <FMTextField name="representative1" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xl={6} lg={6} md={12} sm={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Phone Number1
                                            </FormHelperText>
                                            <FMTextField name="phoneorEmail1" InputLabelProps={{ shrink: true }} label="" type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xl={6} lg={6} md={12} sm={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Website1
                                            </FormHelperText>
                                            <FMTextField name="declinationWebsite1" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xl={6} lg={6} md={12} sm={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Date of Declination 1
                                            </FormHelperText>
                                            <FMTextField name="dateofDeclination1" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xl={6} lg={6} md={12} sm={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Declination Code 1
                                            </FormHelperText>
                                            <FMTextField name="declinationCode" InputLabelProps={{ shrink: true }} label="" type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                < Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Reason for Declining1
                                        </FormHelperText>
                                        <FMTextField name="reasonforDeclining1" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>



                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Admitted Insurer 2
                                            </FormHelperText>
                                            <FMTextField name="admittedInsurer2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Representative 2
                                            </FormHelperText>
                                            <FMTextField name="representative2" InputLabelProps={{ shrink: true }} label="" type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                </Grid>


                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Phone Number2
                                            </FormHelperText>
                                            <FMTextField name="phoneorEmail2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Website2
                                            </FormHelperText>
                                            <FMTextField name="declinationWebsite2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Date of Declination 2
                                            </FormHelperText>
                                            <FMTextField name="dateofDeclination2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Declination Code 2
                                            </FormHelperText>
                                            <FMTextField name="declinationCode2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>


                                </Grid>

                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Reason for Declining2
                                        </FormHelperText>
                                        <FMTextField name="reasonforDeclining2" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>


                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Admitted Insurer 3
                                            </FormHelperText>
                                            <FMTextField name="admittedInsurer3" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Representative 3
                                            </FormHelperText>
                                            <FMTextField name="representative3" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Phone Number3
                                            </FormHelperText>
                                            <FMTextField name="phoneorEmail3" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Website3
                                            </FormHelperText>
                                            <FMTextField name="declinationWebsite3" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>

                                </Grid>
                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} sx={{ display: "flex" }}>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Date of Declination 3
                                            </FormHelperText>
                                            <FMTextField name="dateofDeclination3" InputLabelProps={{ shrink: true }} label="" type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={0.5}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Declination Code 3
                                            </FormHelperText>
                                            <FMTextField name="declinationCode3" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid>

                                </Grid>



                                <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FormHelperText className='formtexthelper-heading' >
                                            Reason for Declining3
                                        </FormHelperText>
                                        <FMTextField name="reasonforDeclining3" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                    </FormControl>
                                </Grid>



                                <Grid item xs={12} display='flex' flexDirection='row'>
                                    <span className="sub-card-title" >State Specific Information</span>
                                </Grid>

                                {JsonData.insuredStateOrProvince === "AR" ?
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Expense of Underwriting
                                            </FormHelperText>
                                            <FMTextField name="expenseUnderwriting" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid> : ""}
                                {JsonData.insuredStateOrProvince === "IL" ?
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Fire Marshall Tax
                                            </FormHelperText>
                                            <FMTextField name="fireMarshallTax" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid> : ""}

                                {JsonData.insuredStateOrProvince === "KY" ?
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Municipal Tax
                                            </FormHelperText>
                                            <FMTextField name="municipalTax" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid> : ""}

                                {JsonData.insuredStateOrProvince === "OR" ?
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Fire Tax
                                            </FormHelperText>
                                            <FMTextField name="fireTax" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid> : ""}



                                {JsonData.insuredStateOrProvince === "OK" || JsonData.insuredStateOrProvince === "SD" || JsonData.insuredStateOrProvince === "TN" || JsonData.insuredStateOrProvince === "WY" ?
                                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <FormControl sx={{ width: '100%' }} >
                                            <FormHelperText className='formtexthelper-heading' >
                                                Clearing House Fee
                                            </FormHelperText>
                                            <FMTextField name="clearingHouseFee" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />
                                        </FormControl>
                                    </Grid> : ""}
                                {JsonData.insuredStateOrProvince === "CA" ?

                                    <Grid item xs={12} >
                                        <Grid item xs={12} sx={{ display: "flex" }}>
                                            <Grid item xs={6}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        Broker Batch Number
                                                    </FormHelperText>

                                                    <FMTextField name="brokerBatchNumber" InputLabelProps={{ shrink: true }} label={null} type="Text" fullWidth variant="outlined" sx={{}} />

                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={.3}>

                                            </Grid >
                                            <Grid item xs={6}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        CAARP ineligible
                                                    </FormHelperText>
                                                    <FMSelectTrueFalseCustom name="caarpIneligible" label={null} />

                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={.3}>

                                            </Grid>

                                            <Grid item xs={6}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        Is Good Driver
                                                    </FormHelperText>
                                                    <FMSelectTrueFalseCustom name="isGoodDriver" label={null} />
                                                    {/* <FMTextField name="isGoodDriver" InputLabelProps={{shrink:true}} label="IsGood Driver" type="Text" fullWidth variant="outlined" sx={{}} /> */}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={.3}></Grid>
                                            <Grid item xs={6}>
                                                <FormControl sx={{ width: '100%' }} >
                                                    <FormHelperText className='formtexthelper-heading' >
                                                        Included CAARP
                                                    </FormHelperText>
                                                    <FMSelectTrueFalseCustom name="includedCAARP" />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Grid> : ""}
                                {JsonData.insuredStateOrProvince === "MO" ?
                                    <Grid item xs={3}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >

                                            <FMCustomLineOfBusiness autoWidth={true} variant={"standard"} name="moLobId"></FMCustomLineOfBusiness>
                                        </FormControl>
                                    </Grid>
                                    : ""}
                                {JsonData.insuredStateOrProvince === "MO" ?
                                    <Grid item xs={6}>
                                        <FormControl sx={{ width: '100%', minWidth: "200px" }} >
                                            <FMSelectSLInsurer autoWidth={true} variant={"standard"} name="carrierCompany"></FMSelectSLInsurer>
                                        </FormControl>
                                    </Grid>
                                    : ""}

                                <Grid item xs={12}>
                                    <div style={{ display: "flex", marginBottom: "20px" }}>
                                        <button className="active-btn-blue" type='submit' disabled={isSubmitting} >Save</button>
                                        {loading && <CircularProgress style={{ margin: 2 }} />}

                                        {/* <Button sx={{ width: "200px", height: "50px", margin: 2 }} variant="outlined" color="secondary" onClick={goBack} >Cancel</Button> */}
                                    </div>

                                </Grid>

                            </Grid>

                        </div>


                    </Form>


                )}
            </Formik>

        </React.Fragment>
    )
}





