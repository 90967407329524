import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useAuth from "../../context/AuthProvider";
import DialogTitle from '@mui/material/DialogTitle';
import { InsuranceCompanyModel, InsuranceCompanyModel_VS } from '../../models/insuranceCompanyModel';
import { Form, Formik } from 'formik';
import { config } from '../../Constants';
import { Box, FormControl, Grid, LinearProgress } from '@mui/material';
import { FMTextField } from '../Elements/input';
import { FMSelectActiveInactive, FMSelectState } from '../Elements/select';
import { StateCheckBox } from '../Elements/StateCheckBox';
import { stateOptions } from './AddEditPartners';
import { SetStateAction, useState } from 'react';
import { LineOfBusinessCheckBox } from '../Elements/LineOfBusinessCheckBox';
import { lineOfBusinessModel } from '../Lists/CarrierManagementList';
import { TurnLeftTwoTone } from '@mui/icons-material';

interface Iprops {
    InsuranceCompanyCL: InsuranceCompanyModel,
    handleClose: () => void,
    Open: boolean,
    currentState: number,
    handleSubmit: () => void,
    initValues: InsuranceCompanyModel
    selectedStates: stateOptions[]
    States: stateOptions[],
    setselectedStates: React.Dispatch<SetStateAction<stateOptions[]>>,
    setStates: React.Dispatch<SetStateAction<stateOptions[]>>,
    handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectedLineOfBusiness: lineOfBusinessModel[],
    LineOfBusiness: lineOfBusinessModel[]
    handleLineOfBusinessCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    //handleStartDate: (event: React.ChangeEvent<HTMLInputElement>) => void,
    selectAll: () => void
    
}

interface CustomFormData {

}


export const    AddEditCarrierManageLineOfBussinessCheckBox: React.FC<Iprops> = ({ selectAll, currentState, handleClose, Open, InsuranceCompanyCL, handleSubmit, initValues, selectedStates, States, setselectedStates, setStates, handleCheckBoxChange, selectedLineOfBusiness, LineOfBusiness, handleLineOfBusinessCheckBoxChange }) => {
    //const initialValues: InsuranceCompanyModel = initValues ? InsuranceCompanyCL : initValues;
    const { user } = useAuth();
    const token = user?.token;

    let initFomvalues: CustomFormData = {
    }
    const [loading, setloading] = useState(false);

    return (

        <Dialog open={Open} onClose={handleClose} fullWidth maxWidth={'lg'}>
            <DialogTitle className='card-title' >Line of business</DialogTitle>
            <Formik initialValues={initFomvalues}

                onSubmit={(data, { setSubmitting }) => {
                    setloading(true);
                    //data.selectedStates = selectedStates.map(x => x.stateId).join(",");
                    // data.selectedLineOfBusiness = selectedLineOfBusiness.map(x => x.id.toString()).join(",");
                    console.log(JSON.stringify(data));
                    handleSubmit()
                    setloading(false);
                }} >
                {({ values, isSubmitting }) => (

                    <Form>

                        {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                        <DialogContent style={{ minHeight: "70vh", minWidth: "80%" }}  >
                            <Grid container spacing={2} xs={12}  >

                                <Grid item xs={12} >
                                    <LineOfBusinessCheckBox selectedLineOfbusiness={selectedLineOfBusiness} lineOfBusiness={LineOfBusiness} handleCheckBoxChange={handleLineOfBusinessCheckBoxChange} />
                                </Grid>
                            </Grid>
                            {/* <LineOfBusinessCheckBox selectedLineOfbusiness={selectedLineOfBusiness} lineOfBusiness={LineOfBusiness} handleCheckBoxChange={handleCheckBoxChange} /> */}
                        </DialogContent>


                    
                            {/* <Button variant="outlined" color="secondary" onClick={handleClose}  >Cancel</Button> */}
                            <button className='active-btn-blue' type="submit" disabled={isSubmitting} >Save</button>

                    </Form>

                )}
            </Formik>
        </Dialog >





    );
}

