import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, IconButton, LinearProgress, Link, SelectProps } from "@mui/material";
// import { DataGrid, GridColDef, GridRenderCellParams, GridToolbarFilterButton } from '@mui/x-data-grid';
import { FieldAttributes } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { AddEditTaxFeeDate, TaxesAndFeeDataModel } from "../AddEdit/AddEditTaxFeeDate";
import { RiExpandUpDownLine } from 'react-icons/ri';
import { DataGridPro, GridColDef, GridRenderCellParams, GridToolbarFilterButton } from '@mui/x-data-grid-pro';
import { apiClient } from '../../helper/api';


interface Iprops {
    taxesAndFeeList: TaxesAndFeeDataModel[],
    fetchData: () => void
    selectedState: string
    FMSelectStateCustom: React.FC<FieldAttributes<SelectProps<unknown>>>
}

export const TaxesAndFeeScheduleList: React.FC<Iprops> = ({ taxesAndFeeList, fetchData, selectedState, FMSelectStateCustom }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    const [RadioSelectedValue, setRadioSelectedValue] = React.useState<string>('');
    const [RadioSelectedValueZeroFiling, setRadioSelectedValueZeroFiling] = React.useState<string>('');
    const [RadioSelectValueDueImmediatlyUponInvoicing, setRadioSelectedValueDueImmeditaly] = React.useState<string>('');

    let inivalue: TaxesAndFeeDataModel = {
        paymentFilingScheduleId: 0,
        stateId: 0,
        taxFeename: "",
        taxesAndFeesFilingSchedulePeriodId: 0,
        jan: "",
        feb: "",
        mar: "",
        apr: "",
        may: "",
        june: "",
        july: "",
        aug: "",
        sept: "",
        nov: "",
        oct: "",
        dec: "",
        annualDate: "",
        quarter1: "",
        quarter2: "",
        quarter3: "",
        quarter4: "",
        semiAnnualFirstHalf: "",
        semiAnnualSecondHalf: "",
        zeroFiling: "",
        stateName: "",
        taxesAndFilingSchedulePeriodId: 0,
        monthlyReportingDueDate: "",
        withInDays: "",
        withInForteenDays: false,
        dueImmediatelyUponInvoicing: false,
        payment: false,
        report: false,
        wetSignature: false,
        notaryStamp: false,
        zeroIndividual: false,
        zeroEntity: false,
        paymentType: '',
        notes: '',
        dueDate: '',
        name: ''
    }

    const [open, setOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<TaxesAndFeeDataModel>(inivalue);

    const fetchTaxSchedulesById = (paymentFilingScheduleId: Number) => {

        apiClient(`/TaxesAndFeesFilingSchedule/getTaxesAndFilingScheduleById?paymentFilingScheduleId=${paymentFilingScheduleId}`,


            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                // Do something with the response

                if (responseJson?.data != null) {

                    handClickOpen(responseJson.data);
                    console.log("feild data", responseJson)
                }

            })
    }

    function handClickOpen(data: TaxesAndFeeDataModel) {

        //    fetchTaxSchedulesById(data.paymentFilingScheduleId) 

        setFormData(data);
        console.log("FormData", data);
        data.apr = data.apr === null ? "" : data?.apr
        data.jan = data.jan === null ? "" : data?.jan
        data.feb = data.feb === null ? "" : data?.feb
        data.mar = data.mar === null ? "" : data?.mar
        data.may = data.may === null ? "" : data?.may
        data.june = data.june === null ? "" : data?.june
        data.july = data.july === null ? "" : data?.july
        data.aug = data.aug === null ? "" : data?.aug
        data.sept = data.sept === null ? "" : data?.sept
        data.oct = data.oct === null ? "" : data?.oct
        data.nov = data.nov === null ? "" : data?.nov
        data.dec = data.dec === null ? "" : data?.dec
        data.annualDate = data.annualDate === null ? "" : data?.annualDate
        data.semiAnnualFirstHalf = data.semiAnnualFirstHalf === null ? "" : data.semiAnnualFirstHalf
        data.semiAnnualSecondHalf = data.semiAnnualSecondHalf === null ? "" : data.semiAnnualSecondHalf

        data.quarter1 = data.quarter1 === null ? "" : data?.quarter1
        data.quarter2 = data.quarter2 === null ? "" : data?.quarter2
        data.quarter3 = data.quarter3 === null ? "" : data?.quarter3
        data.quarter4 = data.quarter4 === null ? "" : data.quarter4
        data.zeroFiling = data.zeroFiling === null ? "" : data.zeroFiling
        data.monthlyReportingDueDate = data.monthlyReportingDueDate === null ? "" : data.monthlyReportingDueDate
        data.withInDays = data.withInDays === null ? "" : data.withInDays
        data.withInForteenDays = data.withInForteenDays === null ? false : (data.withInForteenDays)
        data.dueImmediatelyUponInvoicing = data.dueImmediatelyUponInvoicing === null ? false : data.dueImmediatelyUponInvoicing

        data.payment = data.payment === null ? false : data.payment
        data.report = data.report === null ? false : data.report
        data.paymentType = data.paymentType === null ? "" : data.paymentType
        data.wetSignature = data.wetSignature === null ? false : data.wetSignature
        data.notaryStamp = data.notaryStamp === null ? false : data.notaryStamp
        data.zeroEntity = data.zeroEntity === null ? false : data.zeroEntity
        data.zeroIndividual = data.zeroIndividual === null ? false : data.zeroIndividual
        data.stateId = data.stateId
        data.paymentFilingScheduleId = data.paymentFilingScheduleId

        setRadioSelectedValue(data.taxesAndFilingSchedulePeriodId?.toString());

        setRadioSelectedValueZeroFiling(data.zeroFiling?.toString() != '' ? "7" : '');
        console.log("openData", data)
        setOpen(true);
    }
    function handleClickClose() {
        setFormData(inivalue);
        setOpen(false)
        fetchData();
    }

    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        })
            .then((result) => {
                if (result.isConfirmed) {
                    apiClient("/TaxesAndFeesFilingSchedule/Delete?id=" + `${id}`,
                        {
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`,
                            }),
                            method: "Delete",
                        }
                    )
                        .then((res) => {
                            if (!res) return
                            Swal.fire(
                                'Deleted!',
                                'Your file has been deleted.',
                                'success'
                            )
                            fetchData();
                            setOpen(false);
                        })

                }
            })



    };










    const columns: GridColDef[] = [

        {
            field: 'stateName', type: 'text', headerName: 'State Name', flex: 0.7, hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center',
        },

        {
            field: 'dueDate', type: 'text', headerName: 'Due Date', flex: 0.6, hideSortIcons: true, renderHeader: (params) => (
                <div>


                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) =>
            (



                moment(params.row.dueDate, 'YYYY-MM-DD', true).isValid() ? moment(params.row.dueDate).format("D-MMM") : params.row.dueDate

            ),
        },


        {
            field: 'name', type: 'text', headerName: 'Name', flex: 1.8, hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align:'center' ,renderCell: (params) => (
                <span style={{wordBreak: 'break-word' , whiteSpace:'normal'}}>{params.row.name}</span>
            ) 
        },

        {
            field: 'payment', type: 'text', headerName: 'Payment', flex: 0.6, hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) => (params.row.payment ? "X" : "")
        },
        {
            field: 'report', type: 'text', headerName: 'Report', flex: 0.5, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) => (params.row.report ? "X" : "")
        },
        {
            field: 'paymentType', type: 'text', headerName: 'Payment Type', flex: 0.9, headerAlign: 'center', hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), align: 'center'
        },
        {
            field: 'wetSignature', type: 'text', headerName: 'Wet Signature', flex: 0.8, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) => (params.row.wetSignature ? "X" : "")
        },
        {
            field: 'notaryStamp', type: 'text', headerName: 'Notary Stamp', flex: 0.8, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) => (params.row.notaryStamp ? "X" : "")
        },
        {
            field: 'zeroIndividual', type: 'text', headerName: 'Zero Individual', flex: 0.9, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) => (params.row.zeroIndividual ? "X" : "")
        },
        {
            field: 'notes', type: 'text', headerName: 'Notes', hideSortIcons: true, renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', flex: 1.6
        },

        {
            field: 'zeroEntity', type: 'text', headerName: 'Zero Entity', flex: 0.7, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', valueGetter: (params) => (params.row.zeroEntity ? "X" : "")
        },

        {
            field: 'Edit', flex: 0.8, headerName: "Actions", headerAlign: 'center', align: 'center', renderCell: (params) => {
                return (<div className='' >
                    <IconButton
                        color="primary"
                        onClick={() => { fetchTaxSchedulesById(params.row.paymentFilingScheduleId) }}
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </IconButton>
                    {user?.userRoles.split(",").includes("Super Admin") ?
                        <IconButton
                            color="error"
                            onClick={() => { handleDelete(params.row.paymentFilingScheduleId); }}
                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </IconButton>
                        :
                        ""}
                </div>);
            }
        },
    ];
    useEffect(() => {
        fetchData();

    }, [])
    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="  d-flex justify-content-between pb-3 align-items-center">
                    <div className="=">
                        <span className='card-title'>Filing Schedule</span>
                    </div>
                    <div></div>
                    <div className="col-lg-2">
                        <span>
                            {/* <FMSelectStateCustom name='state' /> */}
                        </span>
                    </div>
                    <div className='d-flex' >
                        <div className="me-2" style={{ textAlign: "center" }}>
                            <span className="card-title  filter-blue " > <GridToolbarFilterButton /></span>
                        </div>
                        <div className="" >
                            <span >
                                <button
                                    onClick={() => {
                                        inivalue.stateId = Number(selectedState)
                                        handClickOpen(inivalue)
                                    }}
                                    style={{ float: "right" }}
                                    className=" btn-2 btn d-flex btn-primary active-btn-blue"
                                >
                                    Add New
                                </button>

                            </span>
                        </div>
                    </div>

                </div>
            </Box>

        );

    }

    const [pageSize, setPageSize] = useState(25)

    return (

        <div style={{ height: '75vh', width: '100%', overflow: "scroll" }} className="xscrollbar table-repsonsive">


            <div style={{ width: '100%', height: '69vh', }}>
                <DataGridPro

                    getRowId={(r) => r.paymentFilingScheduleId + '_' + r.taxFeeAndFilingPeriod}
                    rows={taxesAndFeeList}
                    columns={columns}
                    pagination
                    rowsPerPageOptions={[25, 50, 100]}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    style={{ textAlign: 'center' }}
                    disableColumnResize={false}
                    components={{
                        Toolbar: EditToolbar,
                    }}

                    sx={
                        {
                            fontFamily: "'Proxima Nova', sans-serif;",
                            border: "0px",
                            fontSize: "16px",
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            '& .MuiDataGrid-columnHeaderTitle': {
                                whiteSpace: 'break-spaces',
                                lineHeight: 1,
                            },
                            '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
                                pl: 1,
                            },
                            // width: "100vw"

                        }
                    }
                />
            </div>

            <AddEditTaxFeeDate formValues={formData} handleClose={handleClickClose} Open={open} handleSubmit={fetchData} RadioSelectedValue={RadioSelectedValue} setRadioSelectedValue={setRadioSelectedValue} RadioSelectedValueZeroFiling={RadioSelectedValueZeroFiling} setRadioSelectedValueZeroFiling={setRadioSelectedValueZeroFiling} RadioSelectValueDueImmediatlyUponInvoicing={RadioSelectValueDueImmediatlyUponInvoicing} setRadioSelectedValueDueImmeditaly={setRadioSelectedValueDueImmeditaly} />
        </div>


    )
}