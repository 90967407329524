import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Chip, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, SelectProps, Stack } from '@mui/material';
import { taxesandfeesModel, UsersModel_VS } from '../../models/TaxesAndFeesModel'
import { FieldAttributes, Form, Formik, useField } from 'formik';
import { config } from '../../Constants';
import { FMSelectLOBField, FMSelectRateTypeField, FMSelectStateField, FMSelectTaxAndFees, FMSelectTaxTypeField, FMSelectTransactionType } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from 'react-router-dom';
import { error } from 'console';
import Swal from 'sweetalert2';
import CancelIcon from "@mui/icons-material/Cancel";
import { OTRModelDTO, OTRModuleModel } from '../../models/OTRModuleModel';
import { apiClient } from '../../helper/api';

interface Iprops {
    opentaxreportCL: OTRModelDTO,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: OTRModelDTO,
    states: { stateId: number, stateName: string }[],
    Transactions: { transactionId: number, transactionTypeName: string }[],

}

export const AddEditOTR: React.FC<Iprops> = ({ handleClose, Open, opentaxreportCL, handleSubmit, initValues, states, Transactions }) => {
    const { user } = useAuth();
    const token = user?.token;
    const initialValues: OTRModelDTO = initValues ? opentaxreportCL : initValues;

    const [TaxandFees, setTaxandFees] = useState([""]);
    const params = useParams();

    const fetchData = () => {
        apiClient(`/TaxesAndFees/GetAllTaxandFeeName`, {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        }).then((responseJson) => {
            if (responseJson?.data == null) return
            setTaxandFees(responseJson.data);
        })
    };

    // const fetchTransactionListData = () => {
    //     fetch(config.url.API_URL + `/TransactionType/getAllTransactionType`, {
    //         method: "GET",
    //         headers: new Headers({
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${token}`,
    //         }),
    //     })
    //         .then((response) => {
    //             if (response.ok) {
    //                 return response.json();
    //             }
    //             throw new Error("Something went wrong");
    //         })
    //         .then((responseJson) => {
    //             if (responseJson.data != null) {
    //                 setTransactions(responseJson.data);
    //             }

    //             console.log("setTransactionList", responseJson.data);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // };
    useEffect(() => {
        fetchData();
    }, []);


    // const FMSelectTransactionTypeCustom: React.FC<FieldAttributes<SelectProps>> = ({
    //     variant,
    //     ...props
    // }) => {
    //     const [field, meta] = useField<{}>(props);
    //     const errorText = meta.error && meta.touched ? meta.error : "";
    //     const { user } = useAuth();
    //     const token = user?.token;


    //     return (
    //         <FormControl error={!!errorText} fullWidth>
    //             <InputLabel id="State-Select-Label">Transaction Type</InputLabel>
    //             <Select {...field} labelId="State-Select-Label" label="TransactionType"
    //                 className="border-round main-form-2"
    //                 IconComponent={ExpandMoreIcon}
    //             >
    //                 <MenuItem key={0} value={0}>
    //                     Select Transaction{" "}
    //                 </MenuItem>
    //                 {Transactions.map((transactions, key) => (
    //                     <MenuItem key={key} value={transactions.transactionId}>
    //                         {" "}
    //                         {transactions.transactionTypeName}{" "}
    //                     </MenuItem>
    //                 ))}
    //             </Select>

    //             <FormHelperText>{errorText}</FormHelperText>
    //         </FormControl>
    //     );
    // };

    const FMSelectTaxAndFeesustom: React.FC<FieldAttributes<SelectProps>> = ({
        variant,
        ...props
    }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error && meta.touched ? meta.error : "";
        const { user } = useAuth();
        const token = user?.token;


        return (
            <FormControl error={!!errorText} fullWidth>
                <InputLabel id="State-Select-Label">TaxandFees</InputLabel>
                <Select {...field} labelId="State-Select-Label" label="TaxandFees"
                    className="border-round main-form-2"
                    IconComponent={ExpandMoreIcon}
                >

                    {TaxandFees.map((taxandfees, key) => (
                        <MenuItem key={key} value={taxandfees}>
                            {" "}
                            {taxandfees}{" "}
                        </MenuItem>
                    ))}
                </Select>

                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        );
    };




    const [loading, setloading] = useState(false);

    return (


        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle className='card-title'   >Open Tax Report</DialogTitle>
            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
            <Formik initialValues={initialValues}
                // validateOnChange={true}
                // validationSchema={UsersModel_VS}
                onSubmit={(data, { setSubmitting }) => {
                    let openTaxReport: OTRModelDTO[] = []
                    openTaxReport.push(data);

                    // console.log("finaldata", JSON.stringify(openTaxReport));
                    setloading(true);
                    apiClient('/OpenTaxReport/SaveOrUpdate', {
                        method: "POST",
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        }),

                        body: JSON.stringify(openTaxReport)
                    }).then((res) => {
                        if (!res) return
                        handleSubmit()
                        setSubmitting(true)
                        Swal.fire({
                            title: "Data Successfully Updated",
                            text: "",
                            icon: "success",
                            confirmButtonText: "OK",
                        });
                    }).finally(() => {
                        setloading(false)
                    })


                }} >
                {({ values, isSubmitting, errors, setFieldValue }) => (
                    <Form>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="invoiceNumber" autoFocus margin="dense" placeholder="Invoice Number" label="Invoice Number" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="insuredName" autoFocus margin="dense" placeholder="Insured Name" label="Insured Name" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="policyNumber" autoFocus margin="dense" placeholder="Policy Number" label="Policy Number" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl error={!!errors.stateId} sx={{ width: '100%' }}>
                                        <Select
                                            value={initialValues.stateId ?? 0}
                                            id="test-select-label"
                                            label={null}
                                            onChange={(e) => {
                                                setFieldValue("stateId", e.target.value);
                                                initialValues.stateId = Number(e.target.value);
                                            }}
                                            name="stateId"
                                            className="border-round custom-menu-item"
                                            IconComponent={ExpandMoreIcon}
                                            displayEmpty
                                        >
                                            <MenuItem key={0} value={0}>
                                                Select State
                                            </MenuItem>
                                            {/* <MenuItem value={"0"}>State</MenuItem> */}
                                            {states.map((state, key) => (
                                                <MenuItem key={key} value={state.stateId}>
                                                    {state.stateName}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                        <FormHelperText>{errors.stateId}</FormHelperText>
                                    </FormControl>


                                </Grid>

                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMSelectTaxAndFeesustom autoWidth={true} name="taxType"></FMSelectTaxAndFeesustom>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="taxPayee" autoFocus margin="dense" placeholder="Tax Payee" label="Tax Payee" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        {/* <FMTextField name="transactionType" autoFocus margin="dense" placeholder="Transaction Type" label="Transaction Type" type="text" fullWidth variant="outlined" sx={{ m: 2 }} /> */}
                                        {/* <FMSelectTransactionTypeCustom autoWidth={true} name="transactionTypeId" ></FMSelectTransactionTypeCustom> */}
                                        <Select
                                            value={initialValues.transactionTypeId}
                                            id="test-select-label"
                                            label={null}
                                            onChange={(e) => {
                                                setFieldValue("transactionTypeId", e.target.value);
                                                initialValues.transactionTypeId = Number(e.target.value);
                                            }}
                                            name="stateId"
                                            className="border-round custom-menu-item"
                                            IconComponent={ExpandMoreIcon}
                                            displayEmpty
                                        >
                                            <MenuItem key={0} value={0}>
                                                Select Transaction Type{" "}
                                            </MenuItem>
                                            {/* <MenuItem value={"0"}>State</MenuItem> */}
                                            {Transactions.map((transactions, key) => (
                                                <MenuItem key={key} value={transactions.transactionId}>
                                                    {transactions.transactionTypeName}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="coverage" autoFocus margin="dense" placeholder="Coverage" label="Coverage" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="grossPremium" autoFocus margin="dense" placeholder="Gross Premium" label="Gross Premium" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="taxAmt" autoFocus margin="dense" placeholder="Tax Amount" label="Tax Amount" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="client" autoFocus margin="dense" placeholder="Client" label="Client" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="clientNotes" autoFocus margin="dense" multiline placeholder="Client Notes" label="Client Notes" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl sx={{ width: '100%' }} >
                                        <FMTextField name="filerNotes" autoFocus margin="dense" multiline placeholder="Filer Notes" label="Filer Notes" type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>

                            </Grid>


                        </DialogContent>

                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}  >Cancel</button>
                            <button className=" btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>

                )}


            </Formik>
        </Dialog>
    )
}
