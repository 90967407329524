import { Button, FormControl, FormHelperText, Grid, InputLabel, LinearProgress, Link, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";
import { FieldAttributes, useField } from "formik";
import { useState, useEffect } from "react";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { FMSelectPartners } from "../components/Elements/select";
import { TransactionFeeList } from "../components/Lists/TransactionFeeList";
import { RightNav } from "../components/Nav/RightNav";
import { config } from "../Constants";
import useAuth from "../context/AuthProvider";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { apiClient } from "../helper/api";
export const TransactionFeePartner = () => {

    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);
    const initialdata = { partnersId: 0, customerName: "" };
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const [TransactionFee, setTransactionFee] = useState([]);
    const [PartnerId, setPartnerId] = useState<string>('0');
    let partId = '0';

    const FMSelectPartnersCustom: React.FC<SelectProps> = ({ ...props }) => {

        const { user } = useAuth();
        const token = user?.token;
        const initialdata = { partnersId: '0', customerName: "" };
        const [Partner, setpartnerData] = useState([initialdata]);

        const handleChange = (event: SelectChangeEvent) => {
            setloading(true);
            setPartnerId(event.target.value);
            partId = event.target.value;
            // console.log(event.target.value);
            fetchData();
        }


        const fetchPartner = () => {
            apiClient('/Partners/getPartners',
                {
                    method: "GET",
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    })
                })
                .then((responseJson) => {
                    if (!responseJson) return
                    // Do something with the response

                    setpartnerData(responseJson.data)

                })
        }


        useEffect(() => {
            fetchPartner();

        }, [])

        return (

            <FormControl fullWidth  >
                <InputLabel id="State-Select-Label">Partner</InputLabel>
                <Select

                    onChange={handleChange}
                    labelId="State-Select-Label"
                    label="State"
                    value={PartnerId}
                >
                    <MenuItem value={'0'}> All </MenuItem>
                    {Partner.map((Name, key) =>
                        <MenuItem key={key} value={Name.partnersId.toString()}> {Name.customerName} </MenuItem>

                    )}
                </Select>
            </FormControl >

        )
    }


    const fetchData = () => {
        setloading(true);
        apiClient(`/TransactionFee/getAccountingTransactionFees?partnersId=${partId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                console.log(responseJson.data);

                if (responseJson.data != null) {
                    setTransactionFee(responseJson.data)

                }
            }).finally(() => {
                setloading(false);
            });


    }

    useEffect(() => {
        fetchData();

    }, [])


    return (
        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

                            <LoggedInUserInfo ButtonText="" Link=""/>


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">

                                        <div className="card dashboard-table">
                                            <div style={{marginBottom: "10px"}}>
                                                {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}
                                            </div>

                                            <div className="row col-lg-12">
                                                <div className="col-lg-4 d-flex">
                                                    <div style={{marginRight:"2px"}}>
                                                        <Link href="/">
                                                            <Button type="button" title="Home"
                                                                className="btn action-btn" ><HomeIcon />
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <Button

                                                            className=" btn action-btn"
                                                            onClick={goBack}
                                                            title="Back"
                                                        >
                                                            <ArrowBackIosIcon />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4"></div>
                                                <div className="col-lg-4">
                                                    <div>
                                                        <FMSelectPartnersCustom name="partnerId" />

                                                    </div>

                                                </div>
                                            </div>
                                            <div className="table-responsive">



                                                <TransactionFeeList TransactionFeeCL={TransactionFee} fetchData={fetchData} />

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            {/* <RightNav /> */}
                        </div>
                    </div>
                </div>

            </main>
        </section >


    )

}