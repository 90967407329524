import { CheckboxUtil } from 'common/checkbox/CheckboxUtil';
import { TypesafeUnreachableError } from 'common/util/error';
import { DocumentLabelerConstants } from 'documentLabeler/constants/DocumentLabelerConstants';
import { BlockType, FieldType, } from 'common/types/DocumentLabelerTypes';
import pluralize from 'pluralize';
var getCheckboxFieldDisplay = function (field) {
    var firstCheckboxBox = field.blocks.find(function (result) { return result.blockType === BlockType.Checkbox; });
    var hasRegion = Boolean(field.region);
    return firstCheckboxBox === undefined
        ? hasRegion
            ? DocumentLabelerConstants.REGION_SELECTED
            : DocumentLabelerConstants.EMPTY
        : CheckboxUtil.generateCheckboxDisplayFromValue(firstCheckboxBox.text);
};
var getTextFieldDisplay = function (field) {
    var hasBlocks = field.blocks.length > 0;
    var hasRegion = Boolean(field.region);
    return hasBlocks
        ? field.blocks.map(function (block) { return block.text; }).join(' ')
        : hasRegion
            ? DocumentLabelerConstants.REGION_SELECTED
            : DocumentLabelerConstants.EMPTY;
};
/**
 * Returns the display value for the field given its type
 * @param field
 * @returns
 */
var getTextValueFromField = function (field) {
    if (field.textOverride) {
        return field.textOverride;
    }
    switch (field.type) {
        case FieldType.Text:
            return getTextFieldDisplay(field);
        case FieldType.Checkbox:
            return getCheckboxFieldDisplay(field);
        case FieldType.Signature:
            return field.region
                ? DocumentLabelerConstants.REGION_SELECTED
                : DocumentLabelerConstants.EMPTY;
        case FieldType.Table:
            throw new Error('Field Labels cannot be of type table (tables are stored in Table Labels)');
        default:
            throw new TypesafeUnreachableError(field.type);
    }
};
var getTextValueFromCell = function (cell) {
    return cell.textOverride
        ? cell.textOverride
        : cell.region
            ? DocumentLabelerConstants.REGION_SELECTED
            : cell.blocks.map(function (block) { return block.text; }).join(' ');
};
/**
 * Returns the text value from a table to display in the fields panel
 * @param field
 * @returns
 */
var getTextValueFromTable = function (field) {
    return "".concat(pluralize('row', field.rows.length, true));
};
export var FieldsPanelDisplayUtils = {
    getTextValueFromField: getTextValueFromField,
    getTextValueFromTable: getTextValueFromTable,
    getTextValueFromCell: getTextValueFromCell,
};
