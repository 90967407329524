import * as yup from 'yup'
export interface CustomerPolicyModel {
    customerPolicyId: number,
    masterPolicyTypeId: number,
    documentId: number,
    policyStatus: string,
    policyNumber: string,
    isSubmitted: boolean,
    isProcessed: boolean,
    userId: number,
    policyId: number,
    isDuplicate?: boolean
    stateId?: number
}




export const CustomerPolicyModel_VS =
    yup.object().shape({
     
    });


