var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { makeStyles } from '@material-ui/core';
import { ColumnSpecsUtil } from 'common/dataTable/columnSpecsUtil/ColumnSpecsUtil';
import { DataTable } from 'common/dataTable/DataTable';
var useStyles = makeStyles(function (theme) { return ({
    Container: {
        height: theme.spacing(29),
        overflowY: 'scroll',
    },
}); });
/**
 * Reusable Display Container for the Data Table portion
 * of the Extracted Table Editor and Display components
 * @param Props
 */
export var ExtractedDataTableContainer = function (_a) {
    var numColumns = _a.numColumns, children = _a.children;
    var classes = useStyles();
    return (_jsx(DataTable, __assign({ classes: { container: classes.Container }, elevation: 0, columnSpecs: ColumnSpecsUtil.calcColumnSpecs(numColumns) }, { children: children })));
};
