import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, IconButton, LinearProgress, Link } from "@mui/material";
import useAuth from "../../context/AuthProvider";
import { TransactionFeeModel } from "../../models/TransactionModel";
import { FMSelectPartners } from "../Elements/select";
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

interface Iprops {
    TransactionFeeCL: TransactionFeeModel[],
    fetchData: () => void
}

export const TransactionFeeList: React.FC<Iprops> = ({ TransactionFeeCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
   

    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <GridToolbar/>
                


            </Box>

        );

    }


    const columns: GridColDef[] = [

        { field: 'policyNumber', type: 'text', headerName: 'Policy Number', flex: 1.5 },
        { field: 'insuredName', type: 'text', headerName: 'Insured Name' , flex: 1 , headerAlign: 'center',  align: 'center'},
        { field: 'premium', type: 'number', headerName: 'Premium' , flex: 1 , headerAlign: 'center',  align: 'center'},
        { field: 'userFirstName', type: 'text', headerName: 'User Name' , flex: 1 , headerAlign: 'center',  align: 'center'},
        { field: 'statePostablAbbv', type: 'text', headerName: 'State' , flex: 0.5 , headerAlign: 'center',  align: 'center'},
        { field: 'description', type: 'text', headerName: 'Fee Name' , flex: 1 , headerAlign: 'center',  align: 'center'},
        { field: 'memo', type: 'number', headerName: 'Memo' , flex: 1 , headerAlign: 'center',  align: 'center'},
        { field: 'taxOrFeeAmount', type: 'number', headerName: 'Tax/Fee Amount' , flex: 1 , headerAlign: 'center',  align: 'center' },
       



        { field: 'isPaid', type: 'boolean', headerName: 'Is Paid', flex: 0.5 , headerAlign: 'center',  align: 'center'},
        {
            field: 'policyEffectiveDate', headerName: 'Effective Date', flex: 1, headerAlign: 'center',  align: 'center', renderCell:(params)=>
            (
              <div>
                {params.row.policyEffectiveDate?.toString().split('T')[0]}
              </div>
               
            )
         },
        
        {
            field: 'policyId',
            headerName: 'View',
            flex: 0.5,
            renderCell: (params: GridRenderCellParams<number>) => (
                <button type="button" className="btn btn-primary action-btn"><i className="fa-solid fa-right-long"></i></button>
                // <Link href={'/AddEditStateStamps/' + params.value} sx={{textDecoration : "none "}}>
                //    <button type="button" className="btn btn-primary action-btn">Edit <i className="fa-solid fa-right-long"></i></button>

                // </Link>

            ),
        },
       
       
       

    ];

    return (

        <div style={{ height: '80vh', width: '100%' }}>
             
             
            <DataGrid
                getRowId={(r) => r.transactionFeeId}
                rows={TransactionFeeCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                components={{
                    Toolbar: EditToolbar ,
                }}
                
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif;",
                        border: "0px",
                        fontSize: "16px"
                    }
                }
            />
        </div>


    )
}