import * as yup from 'yup'

export interface UserMappingRequestLog {
    userMappingRequestLogId: number,
    carrierName?: string,
    lOB?: string,
    examplePolicyDocument?: string,
    requestMessage?: string,
    stateId?: Number,

}

// export const UserMappingRequestLog_VS = yup.object({
//     carrierName: yup.string().required("Please enter Carrier Name").typeError("inavlid value").trim(),
//     lOB: yup.string().required("Please Select LOB").typeError("inavlid value"),
//     stateId:yup.number().required("Please select a state").typeError("inavlid value").min(1,"Please select a state"),
//     examplePolicyDocument: yup.string().required("Please Select LOB").typeError("inavlid value"),

   
// })


export const UserMappingRequestLog_VS = yup.object({
    carrierName: yup.string().required("Please enter Carrier Name").trim().typeError("Invalid value"),
    lOB: yup.string().required("Please Select LOB").typeError("Invalid value"),
    stateId: yup.number().required("Please select a state").typeError("Invalid value").min(1, "Please select a state"),
    // examplePolicyDocument: yup.string().required("Please Select LOB").typeError("Invalid value"),
});
