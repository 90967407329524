var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Confidence, FieldType, } from 'common/types/DocumentLabelerTypes';
import { FieldsPanelDisplayUtils } from 'documentLabeler/common/util/FieldsPanelDisplayUtils';
export var LabelingSelectionType;
(function (LabelingSelectionType) {
    LabelingSelectionType["Block"] = "Block";
    LabelingSelectionType["Region"] = "Region";
})(LabelingSelectionType || (LabelingSelectionType = {}));
/** Generates initial state from initializer data */
var generateInitialState = function (data, rootRef) {
    return {
        docInfo: data,
        localState: {
            activeField: undefined,
            selectionType: LabelingSelectionType.Block,
            rootRef: rootRef,
        },
    };
};
var convertInternalStateToOutputData = function (state) {
    var trainingSimpleFields = state.docInfo.results.fields.filter(function (field) {
        return field.type === FieldType.Text || field.type === FieldType.Checkbox;
    });
    var trainingSignatureFields = state.docInfo.results.fields.filter(function (field) { return field.type === FieldType.Signature; });
    var trainingTables = state.docInfo.results.tables.map(function (table) { return (__assign(__assign({}, table), { type: FieldType.Table, rows: table.rows })); });
    var trainingDocumentLabels = {
        modelId: state.docInfo.modelId,
        docId: state.docInfo.docId,
        results: {
            fields: trainingSimpleFields,
            signatures: trainingSignatureFields,
            tables: trainingTables,
        },
    };
    var extractedFormFields = state.docInfo.results.fields.map(function (field) { return ({
        fieldName: field.name,
        value: FieldsPanelDisplayUtils.getTextValueFromField(field),
        confidenceScore: field.confidenceScore,
    }); });
    var extractedTables = state.docInfo.results.tables.map(function (table) { return ({
        tableName: table.name,
        confidenceScore: table.confidenceScore,
        rows: table.rows.map(function (row) { return (__assign(__assign({}, row), { cells: row.cells.map(function (cell, idx) { return ({
                columnName: table.columns[idx].name,
                value: FieldsPanelDisplayUtils.getTextValueFromCell(cell),
                confidenceScore: cell.confidenceScore,
            }); }) })); }),
    }); });
    var extractionResult = __assign(__assign({}, state.docInfo), { documentId: state.docInfo.docId, documentStatus: 'Completed', confidenceScore: Confidence.UserReviewed, formFields: extractedFormFields, tables: extractedTables });
    return {
        trainingDocumentLabels: trainingDocumentLabels,
        extractionResult: extractionResult,
    };
};
export var DocumentLabelerState = {
    generateInitialState: generateInitialState,
    convertInternalStateToOutputData: convertInternalStateToOutputData,
};
