/**
 * Converts the browser coordinates of of an event
 * into coordinates within the context of a specified ref
 */
var convertEventCoordsToRefSpace = function (ref, evt) {
    var refCoords = ref.current
        ? ref.current.getBoundingClientRect()
        : { height: 0, width: 0, x: 0, y: 0 };
    return {
        x: evt.clientX - refCoords.x,
        y: evt.clientY - refCoords.y,
    };
};
/**
 * Converts a rectangle into its min and max x/y values.
 * @param rect
 */
var toMinMax = function (rect) {
    var _a = rect.start.x <= rect.end.x
        ? { minX: rect.start.x, maxX: rect.end.x }
        : { minX: rect.end.x, maxX: rect.start.x }, minX = _a.minX, maxX = _a.maxX;
    var _b = rect.start.y <= rect.end.y
        ? { minY: rect.start.y, maxY: rect.end.y }
        : { minY: rect.end.y, maxY: rect.start.y }, minY = _b.minY, maxY = _b.maxY;
    return {
        minX: minX,
        maxX: maxX,
        minY: minY,
        maxY: maxY,
    };
};
/**
 * Given a block, and page information, will determine the
 * exact renderable coordinates for that block.
 * @param block
 * @param docPageHeights
 * @param docRenderWidth
 */
var getBlockRenderCoords = function (boundingBox, docPageHeights, docRenderWidth) {
    // Height of page the block belongs on.
    // The `top` and `height` of the bounding box is relative to this height.
    var pageHeight = docPageHeights[boundingBox.page];
    // Cumulative height of pages before this page
    // The `top` of the bounding box should be offset by this height.
    var pageOffset = 0;
    for (var i = 0; i < boundingBox.page; ++i) {
        pageOffset += docPageHeights[i];
    }
    var left = boundingBox.left * docRenderWidth;
    var top = pageOffset + boundingBox.top * pageHeight;
    var height = boundingBox.height * pageHeight;
    var width = boundingBox.width * docRenderWidth;
    return {
        left: left,
        top: top,
        height: height,
        width: width,
    };
};
/**
 * Returns true if the specified coordinate is inside of the specified block
 * @param coordinate
 * @param block
 * @param zoomScale
 */
var coordinateIsInBlock = function (coordinate, block) {
    var scaledBlock = {
        left: block.left,
        width: block.width,
        top: block.top,
        height: block.height,
    };
    var xCoordIsValid = coordinate.x >= scaledBlock.left &&
        coordinate.x <= scaledBlock.left + scaledBlock.width;
    var yCoordIsValid = coordinate.y >= scaledBlock.top &&
        coordinate.y <= scaledBlock.top + scaledBlock.height;
    return xCoordIsValid && yCoordIsValid;
};
/**
 * Returns the word blocks to render given a specific mouse
 * coordinate
 * @param wordBlocks
 * @param mouseCoordinates
 * @param docPageHeights
 * @param docRenderWidth
 * @param zoomScale
 */
var getBlocksUnderMouse = function (blocks, mouseCoordinates, docPageHeights, docRenderWidth) {
    return blocks.filter(function (b) {
        return coordinateIsInBlock(mouseCoordinates, getBlockRenderCoords(b.boundingBox, docPageHeights, docRenderWidth));
    });
};
/**
 * Returns the regions under the mouse cooredinates
 * @param wordBlocks
 * @param mouseCoordinates
 * @param docPageHeights
 * @param docRenderWidth
 * @param zoomScale
 */
var getRegionsUnderMouse = function (regions, mouseCoordinates, docPageHeights, docRenderWidth) {
    return regions.filter(function (r) {
        return coordinateIsInBlock(mouseCoordinates, getBlockRenderCoords(r.region, docPageHeights, docRenderWidth));
    });
};
/**
 * Determines if two rectangles intersect.
 * @param rectA
 * @param rectB
 */
var doRectanglesIntersect = function (rectA, rectB) {
    var minMaxA = toMinMax(rectA);
    var minMaxB = toMinMax(rectB);
    var aLeftOfB = minMaxA.maxX < minMaxB.minX;
    var aRightOfB = minMaxA.minX > minMaxB.maxX;
    var aBelowB = minMaxA.minY > minMaxB.maxY;
    var aAboveB = minMaxA.maxY < minMaxB.minY;
    return !(aLeftOfB || aRightOfB || aAboveB || aBelowB);
};
var convertBoundingBoxToRectCoords = function (boundingBox) { return ({
    start: { x: boundingBox.left, y: boundingBox.top },
    end: {
        x: boundingBox.left + boundingBox.width,
        y: boundingBox.top + boundingBox.height,
    },
}); };
/**
 * Returns a list of all word blocks that are inside a
 * given rectangle.
 *
 * NOTE: Word blocks must be iterated over in correct sequential
 *       order since the ordering impacts the read order of the blocks
 * @param rectCoords
 * @param wordBlocks
 * @param docPageHeights
 * @param docRenderWidth
 */
var getWordBlocksInsideRectangle = function (rectCoords, wordBlocks, docPageHeights, docRenderWidth) {
    return wordBlocks.filter(function (w) {
        var blockRenderCoords = getBlockRenderCoords(w.boundingBox, docPageHeights, docRenderWidth);
        return doRectanglesIntersect(rectCoords, convertBoundingBoxToRectCoords(blockRenderCoords));
    });
};
/**
 * Utility functions used to help determine which blocks
 * should be rendered on the End User Block Layer
 */
export var EndUserBlockRenderUtils = {
    getBlockRenderCoords: getBlockRenderCoords,
    getBlocksUnderMouse: getBlocksUnderMouse,
    getRegionsUnderMouse: getRegionsUnderMouse,
    getWordBlocksInsideRectangle: getWordBlocksInsideRectangle,
    convertEventCoordsToRefSpace: convertEventCoordsToRefSpace,
    toMinMax: toMinMax,
    doRectanglesIntersect: doRectanglesIntersect,
    convertBoundingBoxToRectCoords: convertBoundingBoxToRectCoords,
};
