import React from 'react'
import useAuth from '../../context/AuthProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HomeIcon from '@mui/icons-material/Home';
import { Button, FormControl, Grid, InputLabel, LinearProgress, Link, MenuItem, Select, SelectChangeEvent, SelectProps, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { link } from 'fs';

interface Iprops{
    ButtonText : string
    Link : string
    show? : boolean
}

export const LoggedInUserInfo: React.FC<Iprops> = ({ButtonText , Link, show}) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    return (

        <div className="me-3 flex-fill pt-4 ps-md-3 dashboard-content pb-2 row col-lg-12 d-flex     align-items-center " style={{ alignItems: "flex-end", marginTop: "-20px" }}>
            <div className="col-lg-4 d-flex">
                <span className="logo_name"> <img style={{ width: "170px" }} src="/assets/brand/FMDarkLogo.png" className="img-logo" alt="..." /></span>
            </div>

            <div className="col-lg-8 mt-2 px-2 align-self-center d-sm-flex search-form d-none justify-content-end align-items-center" style={{alignItems : "flex-end"}}>
                <a href="/" className="btn btn-secondary me-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#DDD" className="bi bi-house" viewBox="0 0 16 16">
                        <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z" />
                    </svg>
                    Home
                </a>
                <button className="btn btn-secondary me-3" onClick={goBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#DDD" className="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg>

                    Back
                </button>
                {
                    Link !== "" && show != null? 
                
                <a href={Link} className="btn btn-secondary ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#DDD" className="bi bi-plus" viewBox="0 0 16 16">
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    {ButtonText}
                </a>
                :""}
            </div>
        </div>

    )
}
