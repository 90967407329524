import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import useAuth from "../../context/AuthProvider";
import "react-datepicker/dist/react-datepicker.css";
import { CustomerPolicyStatusLogsModel } from '../../models/CustomerPolicyStatusLogsModel';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DialogActions } from '@mui/material';


interface Iprops {
    handleClose: () => void,
    Open: boolean,
    CustomerPolicyStatusLogsData: CustomerPolicyStatusLogsModel[]

}
export const AddEditCustomerPolicyStatusLogs: React.FC<Iprops> = ({ CustomerPolicyStatusLogsData, handleClose, Open }) => {

    const columns: GridColDef[] = [
        { field: 'userName', headerName: 'Modified By', width: 150, align: "center", headerAlign: "center" },
        { 
            field: 'modifiedDate', 
            headerName: 'Date Modified', 
            width: 200, 
            align: "center", 
            headerAlign: "center",
            valueGetter: (params) => {
                const dateString = params.value as string; 
                const date = new Date(dateString); 
                if (!isNaN(date.getTime())) {  
                    return `${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}-${date.getFullYear()}`;
                }
                return ''; 
            }
        },
        { field: 'previousStatus', headerName: 'Original Status', width: 300, align: "center", headerAlign: "center" },
        { field: 'newStatus', headerName: 'Status Changed To', width: 200, align: "center", headerAlign: "center" },
        
    ];
    if (!CustomerPolicyStatusLogsData) 
        return null;
    return (
        <Dialog open={Open} onClose={handleClose} maxWidth={'md'} fullWidth  >
            <DialogTitle className='card-title'  >Manual Status Change Logs</DialogTitle>
            <div style={{ height: '50vh', width: '100%' }} >
                <DataGrid
                    rows={CustomerPolicyStatusLogsData ?? null}
                    columns={columns}
                    pageSize={50}
                    getRowId={(row) => row.customerPolicyStatusLogsId}
                    sx={{ border: "none" }}
                />
            </div>
            <DialogActions>
                <button className=' btn-2 btn d-flex btn-primary search-btn ' type='button' onClick={handleClose}  >Close</button>
            </DialogActions>
        </Dialog >
    )
}