var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EndUserBlockRenderUtils, } from 'documentLabeler/components/documentPanel/documentBlockLayer/utils/EndUserBlockRenderUtils';
import { DocumentLabelerReducerUtils } from 'documentLabeler/state/DocumentLabelerReducerUtils';
import { LabelingSelectionType, } from 'documentLabeler/state/DocumentLabelerState';
import { BlockType, FieldType, } from 'common/types/DocumentLabelerTypes';
// Returns true if given label is associated with Field of type Text or Checkbox
var isTextOrCheckBoxBlock = function (block) {
    return block.sourceFieldId !== undefined;
};
// Returns true if given label is associated with Table cell
var isCellLabel = function (block) {
    return block.tableId !== undefined;
};
// Converts a rectangle generated in the UI doc displayer into a
// bounding box that can be saved to the backend
var getRegionFromRectangle = function (rectangle, pageWidth, docPageHeights) {
    var _a = EndUserBlockRenderUtils.toMinMax(rectangle), minX = _a.minX, maxX = _a.maxX, minY = _a.minY, maxY = _a.maxY;
    // Increase page number until the Cumulative height of pages before
    // this page is greater than the beginning of the region
    var labelingPageIdx = 0;
    var pageHeightSum = 0;
    while (pageHeightSum + docPageHeights[labelingPageIdx] < minY) {
        pageHeightSum += docPageHeights[labelingPageIdx];
        labelingPageIdx += 1;
    }
    var currentPageHeight = docPageHeights[labelingPageIdx];
    // scale down pixels by current page dimensions
    return {
        left: minX / pageWidth,
        top: (minY - pageHeightSum) / currentPageHeight,
        width: (maxX - minX) / pageWidth,
        height: (maxY - minY) / currentPageHeight,
        page: labelingPageIdx,
    };
};
var getColoredRegions = function (state) {
    var _a = DocumentLabelerReducerUtils.getAllColoredFields(state.docInfo), fields = _a.fields, tables = _a.tables;
    var simpleRegionsToDisplay = fields
        .filter(
    // Only display selected field region if a selected field exists
    function (field) {
        return !state.localState.activeField ||
            field.info.id === state.localState.activeField.id;
    })
        .filter(function (field) { return field.info.region !== undefined; })
        .map(function (field) { return ({
        id: field.info.id,
        color: field.color,
        // safe type cast as we literally filtered out the undefined regions above
        region: field.info.region,
        sourceFieldId: field.info.id,
        sourceFieldType: field.info.type,
    }); });
    var tableRegionsToDisplay = tables
        .filter(
    // Only display selected field region if a selected field exists
    function (table) {
        return !state.localState.activeField ||
            table.info.id === state.localState.activeField.id;
    })
        // convert each table into a flat array of their cells
        .map(function (table) {
        return table.info.rows.flatMap(function (tableRow) {
            return tableRow.cells.flatMap(function (cell) { return ({
                id: "".concat(table.info.id).concat(cell.columnId).concat(tableRow.id),
                sourceFieldId: table.info.id,
                tableId: table.info.id,
                columnId: cell.columnId,
                rowId: tableRow.id,
                color: table.color,
                region: cell.region,
                sourceFieldType: FieldType.Table,
            }); });
        });
    })
        .map(function (flatTableCells) {
        return flatTableCells.filter(function (cell) { return cell.region !== undefined; });
    })
        .flatMap(function (flatTableCells) {
        return flatTableCells.map(function (cell) { return (__assign(__assign({}, cell), { region: cell.region })); });
    });
    return simpleRegionsToDisplay.concat(tableRegionsToDisplay);
};
var getColoredRegionsToDisplay = function (state, regions) {
    return regions.filter(function (region) {
        if (state.localState.activeField) {
            return region.sourceFieldId === state.localState.activeField.id;
        }
        else {
            return true;
        }
    });
};
var getColoredBlocks = function (state) {
    var _a = DocumentLabelerReducerUtils.getAllColoredFields(state.docInfo), fields = _a.fields, tables = _a.tables;
    var fieldColoredBlocks = fields.flatMap(function (field) {
        return field.info.blocks.map(function (block) { return ({
            color: field.color,
            block: block,
            sourceFieldId: field.info.id,
            sourceFieldType: field.info.type,
        }); });
    });
    var tableCellColoredBlocks = tables.flatMap(function (table) {
        return table.info.rows.flatMap(function (row) {
            return row.cells.flatMap(function (cell) {
                return cell.blocks.map(function (block) { return ({
                    color: table.color,
                    block: block,
                    tableId: table.info.id,
                    rowId: row.id,
                    columnId: cell.columnId,
                    sourceFieldType: FieldType.Table,
                }); });
            });
        });
    });
    return fieldColoredBlocks.concat(tableCellColoredBlocks);
};
var getColoredBlocksToDisplay = function (state, coloredBlocks) {
    return coloredBlocks.filter(function (block) {
        if (state.localState.activeField) {
            if (BlockUtils.isTextOrCheckBoxBlock(block)) {
                return block.sourceFieldId === state.localState.activeField.id;
            }
            else if (BlockUtils.isCellLabel(block)) {
                return block.tableId === state.localState.activeField.id;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    });
};
var getFilteredUnhighlightedBlocks = function (selectedField, coloredBlocks, wordBlocks, selectionType) {
    if (selectionType === LabelingSelectionType.Region ||
        (selectedField && selectedField.type === FieldType.Signature))
        return [];
    var isTextFieldSelected = selectedField && selectedField.type === FieldType.Text;
    var isTableFieldSelected = selectedField && selectedField.type === FieldType.Table;
    // Filter out the word blocks of selected Field
    var filteredColoredBlocks = coloredBlocks.filter(function (block) {
        var _a;
        if (isTextFieldSelected && block.block.blockType === BlockType.Word) {
            var fieldId = BlockUtils.isTextOrCheckBoxBlock(block)
                ? block.sourceFieldId
                : block.tableId;
            return fieldId === (selectedField === null || selectedField === void 0 ? void 0 : selectedField.id);
        }
        else if (isTableFieldSelected) {
            if (BlockUtils.isCellLabel(block)) {
                return (block.columnId === ((_a = selectedField.activeCell) === null || _a === void 0 ? void 0 : _a.columnId) &&
                    block.rowId === selectedField.activeCell.rowId &&
                    block.tableId === selectedField.id);
            }
            return false;
        }
        else {
            return true;
        }
    });
    // Filter out the already labeled blocks except Word block of selected field
    return wordBlocks
        .filter(function (block) {
        return !filteredColoredBlocks.find(function (coloredBlock) { return coloredBlock.block.id === block.id; });
    })
        .filter(function (block) {
        if (selectedField && selectedField.type === FieldType.Checkbox) {
            return block.blockType === BlockType.Checkbox;
        }
        else if (selectedField &&
            (selectedField.type === FieldType.Text ||
                selectedField.type === FieldType.Table)) {
            return block.blockType !== BlockType.Checkbox;
        }
        else {
            return true;
        }
    });
};
// Returns true if given the colored block is associated with the active cell
var isActiveCellBlock = function (coloredBlock, selectedField) {
    if (selectedField &&
        selectedField.type === FieldType.Table &&
        selectedField.activeCell) {
        return (coloredBlock.tableId === selectedField.id &&
            coloredBlock.columnId === selectedField.activeCell.columnId &&
            coloredBlock.rowId === selectedField.activeCell.rowId);
    }
    else {
        return false;
    }
};
/**
 * Returns the opacity for colored block based on these cases
 * 1. Return 1 if coloredBlock is associated with the field not cell
 * 2. Return 1 if coloredBlock is associated with the cell but there's no active cell selected
 * 3. Return 1 if coloredBlock is associated with the cell and it's direct associated with active cell
 * 4. Else Return 0.3
 * @param coloredBlock
 * @param activeCell
 * @returns
 */
var getColoredBlockOpacity = function (coloredBlock, selectedField) {
    return selectedField &&
        isCellLabel(coloredBlock) &&
        !isActiveCellBlock(coloredBlock, selectedField)
        ? 0.3
        : 1;
};
/**
 * Returns the sorted blocks as they appear on the document
 * @param blocks
 * @returns
 */
var sortBlocks = function (blocks) {
    return __spreadArray([], blocks, true).sort(function (A, B) {
        var a = __assign({}, A.boundingBox);
        var b = __assign({}, B.boundingBox);
        a.top = a.top + a.page;
        b.top = b.top + b.page;
        var maxBlockHeight = Math.max(a.height, b.height);
        var topDiff = Math.abs(a.top - b.top);
        var isInSingleLine = topDiff <= maxBlockHeight / 2;
        if (isInSingleLine) {
            if (a.left < b.left) {
                return -1;
            }
            else if (a.left > b.left) {
                return 1;
            }
            else {
                return 0;
            }
        }
        else {
            if (a.top > b.top) {
                return 1;
            }
            else if (a.top < b.top) {
                return -1;
            }
            else {
                return 0;
            }
        }
    });
};
var getActiveRegion = function (state, regionsToDisplay) {
    var _a;
    if (!state.localState.activeField)
        return undefined;
    if (state.localState.activeField.type === FieldType.Table) {
        // cannot label a table region with no active cell
        if (!state.localState.activeField.activeCell)
            return undefined;
        var cell = DocumentLabelerReducerUtils.getCellInfoFromTable(DocumentLabelerReducerUtils.getSelectedTable(state), state.localState.activeField.activeCell).cell;
        if (!cell) {
            throw new Error('Cannot find active cell');
        }
        return cell.region;
    }
    else {
        return (_a = regionsToDisplay[0]) === null || _a === void 0 ? void 0 : _a.region;
    }
};
export var BlockUtils = {
    isTextOrCheckBoxBlock: isTextOrCheckBoxBlock,
    isCellLabel: isCellLabel,
    isActiveCellBlock: isActiveCellBlock,
    getColoredBlockOpacity: getColoredBlockOpacity,
    sortBlocks: sortBlocks,
    getRegionFromRectangle: getRegionFromRectangle,
    getColoredRegions: getColoredRegions,
    getColoredRegionsToDisplay: getColoredRegionsToDisplay,
    getColoredBlocks: getColoredBlocks,
    getColoredBlocksToDisplay: getColoredBlocksToDisplay,
    getFilteredUnhighlightedBlocks: getFilteredUnhighlightedBlocks,
    getActiveRegion: getActiveRegion,
};
