import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Button, LinearProgress, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { DataGrid, GridColDef, GridToolbarFilterButton } from '@mui/x-data-grid';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { config } from "../../Constants";
import useAuth from "../../context/AuthProvider";
import { ButlerMappings } from "../../models/ButlerMappings";
import { AddEditButlerMappings } from "../AddEdit/AddEditButlerMappings";
import CloseIcon from '@mui/icons-material/Close';
import { lineOfBusinessModel } from './CarrierManagementList';
import { RiExpandUpDownLine } from "react-icons/ri";
import { apiClient } from '../../helper/api';
interface Iprops {
    ButlerMappingCL: ButlerMappings[],
    fetchData: () => void,
}

export interface lobData {
    id?: number;
    businessCode: string;
    coverageName: string;
}
export interface butlerList {
    modelId: number, name: string
}
const butlerlist: butlerList = {
    modelId: 0,
    name: ""
}
export const ButlerMappingList: React.FC<Iprops> = ({ ButlerMappingCL, fetchData }) => {
    const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const [Open, setOpen] = React.useState(false);

    const [SelectedLOB, setSelectedLOB] = React.useState<string>('');
    const [SelectedState, setSelectedState] = React.useState<string>('');
    const [Value, setValue] = React.useState(1);

    const handleClose = () => {

        setOpen(false);
        setSelectedOption(butlerlist);
        setFormData(initialLBData);
        setSelectedLOB('');
        setSelectedState('');
    };
    const [SelectedTrans, setSelectedTrans] = React.useState<string[] | string>([]);
    const handleSubmit = () => {
        fetchData();
        setOpen(false);
    };
    const handleEdit = (butlermappingData: ButlerMappings) => {
        const commaSeparatedIds: string[] = butlermappingData.commaSeparatedTransactionIds?.split(',') || [];


        const transactionIds: number[] = commaSeparatedIds.map(id => Number(id));

        let CheckData: ButlerMappings = {
            documentTypeId: butlermappingData.documentTypeId,
            stateId: butlermappingData.stateId,
            transactionId: transactionIds,
            modelId: butlermappingData.modelId,
            commaSeparatedTransactionIds: butlermappingData.commaSeparatedTransactionIds,
            carrier: butlermappingData.carrier,
            partnersId: butlermappingData.partnersId,
            insuranceCompanyId: butlermappingData.insuranceCompanyId,
            id: butlermappingData.id,
            isRequired: butlermappingData.isRequired,
            mappingId: butlermappingData.mappingId


        }


        //setFormData(butlermappingData);
        console.log("formData", CheckData);
        //console.log("row data",butlermappingData);
        let fg: butlerList = {
            modelId: butlermappingData.modelId ?? 0,
            name: butlermappingData.butlerModel ?? ""

        }
        let abc = butlermappingData.commaSeparatedTransactionIds?.split(',') ?? [];


        let f = abc.map(e => e.trim());
        setSelectedTrans(f);
        setSelectedOption(fg)
        setFormData(CheckData);
        
        fetchLOBByStateId(butlermappingData.stateId?.toString() ?? '');
        setSelectedState(butlermappingData.stateId?.toString() ?? '');
        console.log("Selectedstatesss", butlermappingData.stateId?.toString())
        setSelectedLOB(butlermappingData.id?.toString() ?? '0');

        setOpen(true);
        setValue(2);
    };

    const handleClickOpen = () => {
        setFormData(initialLBData);
        setSelectedTrans([]);
        setOpen(true);
        setValue(1);
    };

    const initialLOBdata: lobData = { id: undefined, businessCode: "", coverageName: "" };
    const [Lineofbusiness, setLineOfBusiness] = useState<lobData[] | null>([])

    const fetchLOBByStateId = (stateId: string) => {

        apiClient(`/LineOfBusiness/getLineOBusinessByStateId?stateId=${stateId}`,
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                // Do something with the response

                if (responseJson?.data != null) {

                    setLineOfBusiness(responseJson.data);

                }

            })
    }






    const handleMappingsUpdate = () => {


        Swal.fire({
            title: 'Do you want to push mappings to live?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Update mappings!'
        }).then((result) => {
            if (result.isConfirmed) {
                setloading(true);
                apiClient("/ButlerMappings/UpdateTables",
                    {
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        method: "Get",
                    }
                )
                    .then((res) => {
                        if (!res) return
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Data updated',
                            showConfirmButton: false,
                            timer: 1500
                        })

                    }).finally(() => {
                        setloading(false);
                    });

            }
        });

    }

    const initialLBData: ButlerMappings = {
        mappingId: 0,
        carrier: "",
        insuranceCompanyId: 0,
        lOB: "",
        id: undefined,
        butlerModel: "",
        modelId: 0,
        transactionName: "",

        documentName: "",
        documentTypeId: 0,
        partnerName: "",
        partnersId: 0,
        isActive: false,
        isRequired: false
    }
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }
    const [Formdata, setFormData] = React.useState(initialLBData);
    const [selectedOption, setSelectedOption] = useState<butlerList | null>(null);

    function EditToolbar() {

        return (

            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row align-items-center col-lg-12" style={{ marginBottom: "10px" }}>

                    <div className="col-lg-4 col-md-4 col-sm-6" style={{ fontSize: "32px" }}>
                        <span className="d-flex card-title">Butler Mapping</span>
                    </div>
                    <div className="col-lg-2 col-md-2 col-sm-6 " >
                        <span className="card-title filter-blue" ><GridToolbarFilterButton placeholder={""} /></span>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12" style={{ textAlign: "right" }}>
                        {
                            config.url.API_URL ===  "http://localhost:5274/api" || ("https://filemule-api.azurewebsites.net/api")
                             //true
                                ?
                                < button
                                    style={{ float: "right" }}
                                    className=" btn-2 btn d-flex btn-primary active-btn-blue"
                                    onClick={handleMappingsUpdate}
                                >
                                    Push to live
                                </button>
                                : ""
                        }

                        <button
                            style={{ float: "right", marginRight: "10px" }}
                            className=" btn-2 btn d-flex btn-primary search-btn "
                            onClick={handleClickOpen}
                        >
                            Add new mapping
                        </button>

                    </div>

                </div>

            </Box>

        );

    }



    const handleDelete = (id: number) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: null, // Remove the icon property
            iconHtml: '<svg width="70" height="70" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="31" cy="31" r="31" fill="#02A4E3"/><path d="M31.0003 25.6667V31M31.0003 36.3334H31.0137M44.3337 31C44.3337 38.3638 38.3641 44.3334 31.0003 44.3334C23.6365 44.3334 17.667 38.3638 17.667 31C17.667 23.6362 23.6365 17.6667 31.0003 17.6667C38.3641 17.6667 44.3337 23.6362 44.3337 31Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
            showCancelButton: true,
            confirmButtonColor: '#02A4E3',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Delete',
            customClass: {
                confirmButton: 'btn-radius',
                cancelButton: 'btn-radius',
                popup: 'card-radius'
            }
        }).then((result) => {
            if (result.isConfirmed) {

                apiClient(`/ButlerMappings/Delete?id=${id}`,
                    {
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        method: "Delete",
                    }
                )
                    .then((res) => {
                        if (!res) return

                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success')
                        fetchData();
                        setOpen(false);
                    }).catch((error) => {
                        console.log(error)
                    });


            }
        })
        // fetch(config.url.API_URL + "/ButlerMappings/Delete?id=" + `${id}`,
        //     {
        //         headers: new Headers({
        //             'Content-Type': 'application/json',
        //             'Authorization': `Bearer ${token}`,
        //         }),
        //         method: "Delete",
        //     }
        // )
        //     .then((response) => {
        //         if (response.ok) {
        //             return response.json();
        //         }
        //         throw new Error('Something went wrong');
        //     })
        //     .then((res) => {
        //         alert("Data Successfully Deleted")
        //         fetchData();
        //         setOpen(false);
        //     }).catch((error) => {
        //         console.log(error)
        //     });


    };

    const columns: GridColDef[] = [


        {
            field: 'carrier', type: 'text', headerName: 'Carrier Name', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'lob', type: 'text', headerName: 'Line Of Business', flex: 0.7, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'butlerModel', type: 'text', headerName: 'ButlerModel Name', flex: 1.2, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'transactionName', type: 'text', headerName: 'Transaction Type', flex: 0.7, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'documentName', type: 'text', headerName: 'Document Name', flex: 0.7, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'partnerName', type: 'text', headerName: 'Partner Name', flex: 1, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center'
        },
        {
            field: 'isActive', type: 'text', headerName: 'Status', flex: 0.5, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerAlign: 'center', align: 'center', renderCell: (params) => (
                params.row.isActive ? "Active" : "InActive")
        },
        {
            field: 'IsRequired', flex: 0.5, hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), headerName: "Is Required", renderCell: (params) => (params.row.isRequired ?
                <IconButton
                >
                    <CheckIcon />
                </IconButton>
                : <IconButton
                >
                    <CloseIcon />
                </IconButton>
            )
        },
        {
            field: 'mapppingId', 
            flex: 0.5, 
            hideSortIcons: true,
            renderHeader: (params) => (
                <div>
                    <span className="table-card-title" >{params.colDef.headerName}</span>
                    <RiExpandUpDownLine />
                </div>
            ), 
            headerName: "Action", 
            renderCell: (params) => {
                return (<div>

                    <IconButton
                        color="primary"
                        onClick={() => { handleEdit(params.row); }}

                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 3C17.2626 2.73735 17.5744 2.52901 17.9176 2.38687C18.2608 2.24473 18.6286 2.17157 19 2.17157C19.3714 2.17157 19.7392 2.24473 20.0824 2.38687C20.4256 2.52901 20.7374 2.73735 21 3C21.2626 3.26264 21.471 3.57444 21.6131 3.9176C21.7553 4.26077 21.8284 4.62856 21.8284 5C21.8284 5.37143 21.7553 5.73923 21.6131 6.08239C21.471 6.42555 21.2626 6.73735 21 7L7.5 20.5L2 22L3.5 16.5L17 3Z" stroke="#02A4E3" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </IconButton>
                    {user?.userRoles.split(",").includes("Super Admin") ?
                        <IconButton
                            color="error"
                            onClick={() => { handleDelete(params.row.mappingId); }}

                        >
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6" stroke="#162E3C" strokeWidth="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>

                        </IconButton>
                        : ""}
                </div>);
            }
        },



    ];



    return (
        <div style={{ height: '75vh', width: '100%' }}>

            {loading && <LinearProgress sx={{ backgroundColor: "#132D3D" }} />}


            <DataGrid
                getRowId={(r) => r.mappingId}
                rows={ButlerMappingCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                density="comfortable"
                style={{ textAlign: 'center' }}
                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Proxima Nova', sans-serif",
                        border: "0px",
                        fontSize: "16px",
                    }
                }
            />

            <AddEditButlerMappings handleClose={handleClose} Value={Value} Open={Open} Lineofbusiness={Lineofbusiness} setLineOfBusiness={setLineOfBusiness} fetchLOBByStateId={fetchLOBByStateId} SelectedState={SelectedState} setSelectedState={setSelectedState} setSelectedLOB={setSelectedLOB} SelectedLOB={SelectedLOB} handleSubmit={handleSubmit} initvalues={Formdata} fetchData={fetchData} selectedOptionValue={selectedOption} setSselectedOption={setSelectedOption} setSelectedTrans={setSelectedTrans} SelectedTrans={SelectedTrans} />

        </div>
    )
}
