import { TypesafeUnreachableError } from 'common/util/error';
import { ActiveFieldReducerUtils, } from 'documentLabeler/state/ActiveFieldReducerUtils';
import { BlockReducerUtils, } from 'documentLabeler/state/BlockReducerUtils';
import { FieldReducerUtils, } from 'documentLabeler/state/FieldReducerUtils';
import { RegionReducerUtils, } from 'documentLabeler/state/RegionReducerUtils';
/**
 * Reducer for handling new state generation in response
 * to a new action with a current state.
 */
export var documentLabelerReducer = function (state, action) {
    switch (action.type) {
        case 'setActiveField':
            return ActiveFieldReducerUtils.setActiveField(state, action);
        case 'addBlocksToActiveField':
            return BlockReducerUtils.addBlockToActiveField(state, action);
        case 'removeBlockFromField':
            return BlockReducerUtils.removeBlockFromField(state, action);
        case 'addRegionToActiveField':
            return RegionReducerUtils.addRegionToActiveField(state, action);
        case 'clearRegionFromField':
            return RegionReducerUtils.clearRegionFromField(state, action);
        case 'removeAllBlocksFromField':
            return FieldReducerUtils.removeAllBlocksFromField(state, action);
        case 'setFieldTextOverride':
            return FieldReducerUtils.setTextFieldOverride(state, action);
        case 'setTableCellTextOverride':
            return FieldReducerUtils.setTableCellTextOverride(state, action);
        case 'addRowToTable':
            return FieldReducerUtils.addRowToTable(state, action);
        case 'removeRowFromTable':
            return FieldReducerUtils.removeRowFromTable(state, action);
        default:
            throw new TypesafeUnreachableError(action);
    }
};
