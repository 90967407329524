import { Copyright } from "@mui/icons-material";
import { Box, Container, Grid, Paper, Toolbar } from "@mui/material";
import { LoggedInUserInfo } from "../components/Elements/loggedInUserInfo";
import { RightNav } from "../components/Nav/RightNav";

export const ComingSoon = () => {
    return (

        <section className="dashboard">
            <main className="container-fluid py-3">
                <div className="row">
                    <div className="col-12 mx-auto">
                        <div className="d-flex">
                            <div className="mx-3 flex-fill pt-4 ps-md-3 dashboard-content">

                            <LoggedInUserInfo ButtonText={''} Link={''}/>


                                <div className="card-body">
                                    <div className="col-lg-12 col col-margin">
                                        <div className="card dashboard-table">



                                            <div className="table-responsive">
                                                <h1>Coming Soon</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <RightNav /> */}

                        </div>
                    </div>
                </div>

            </main>
        </section>

    )

}