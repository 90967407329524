import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel, GridToolbar, GridToolbarFilterButton, GridValueGetterParams } from '@mui/x-data-grid';
import { Box, Button, Link, IconButton, SelectChangeEvent, SelectProps, FormControl, FormHelperText, InputLabel, MenuItem, Select, LinearProgress } from "@mui/material";
import { config } from "../../Constants";
import { InsuranceCompanyModel } from "../../models/insuranceCompanyModel";
import { AddEditInsuranceCompany } from "../AddEdit/AddEditInsuranceCompany";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Moment from 'moment';
import useAuth from "../../context/AuthProvider";
import { ReportModuleModel, ReportModuleModel_Resp } from "../../models/ReportModuleModel";
import { FieldAttributes, useField } from "formik";
import { TIMEOUT } from "dns";
import HomeIcon from '@mui/icons-material/Home';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2";
import { style } from "@mui/system";
import { EmailReportModuleModel } from "../../models/EmailReportModuleModel";
import { FaRegEdit } from "react-icons/fa";
import { RiExpandUpDownLine } from "react-icons/ri";

interface Iprops {
    ReportModuleCL: EmailReportModuleModel[],
    fetchData: () => void,
}


export const EmailReportModuleList: React.FC<Iprops> = ({ ReportModuleCL, fetchData }) => {
    // const [loading, setloading] = useState(false);
    const { user } = useAuth();
    const token = user?.token;
    const [loading, setloading] = useState(false);

    const initialLBData: EmailReportModuleModel = {
        emailReportId: 0,
        customerName: "",
        templateName: "",
        emailSent: false,
        stateName: "",
        emailSendDate: ""
    }

    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    }

    function EditToolbar() {

        return (
            <Box
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                    p: 1,
                }}
            >
                <div className="row col-lg-12 align-items-center" style={{ marginBottom: "10px" }}>

                    <div className="col-lg-4" style={{ fontSize: "32px" }}>
                        <span className="d-flex card-title">Email Reports</span>
                    </div>
                    <div className="col-lg-4" >
                        <span className="filter-blue" ><GridToolbarFilterButton /></span>
                    </div>

                </div>

            </Box>
        );

    }

    const columns: GridColDef[] = [


        { field: 'customerName', type: 'text', headerName: 'Customer', flex: 0.5,hideSortIcons: true,
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), headerAlign: 'center', align: 'center' },
        { field: 'templateName', type: 'text', headerName: 'Template Name', flex: 0.5,hideSortIcons: true,
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), headerAlign: 'center', align: 'center' },

        {
            field: 'emailSent', type: 'text', headerName: 'Email sent', flex: 0.5,hideSortIcons: true,
            renderHeader: (params) => (
              <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
              </div>
            ), headerAlign: 'center', align: 'center', renderCell: (params) => (
                params.row.emailSent ? "Email send" : "Email not send")
        },
        { field: 'emailSendDate', type: 'text', headerName: 'Email Send Date', flex: 0.5,hideSortIcons: true,
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), headerAlign: 'center', align: 'center' },
        { field: 'mailSentTo', type: 'text', headerName: 'Mail sent to', flex: 0.5,hideSortIcons: true,
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), headerAlign: 'center', align: 'center' },
        { field: 'stateName', type: 'text', headerName: 'State', flex: 0.5,hideSortIcons: true,
        renderHeader: (params) => (
          <div>
            <span className="table-card-title" >{params.colDef.headerName}</span>
            <RiExpandUpDownLine />
          </div>
        ), headerAlign: 'center', align: 'center' },
        {
            field: 'Action', flex: 0.5,hideSortIcons: true,
            renderHeader: (params) => (
              <div>
                <span className="table-card-title" >{params.colDef.headerName}</span>
                <RiExpandUpDownLine />
              </div>
            ), headerName: "Action", headerAlign: 'center', align: 'center', renderCell: (params) =>
            (
            <div style={{ display: 'flex', justifyItems: 'center', justifyContent: "center"}}>

                <Link href={'/Reporting/EmailReportModuleDetails/' + params.row?.emailReportId} color="#1976D2">
                    {
                            (<IconButton
                                // style={{ margin: '4px' }}
                                // className="btn btn-primary action-btn"

            
                                title="Reporting Detail"
                            >
                                <FaRegEdit />
                            </IconButton>)
                    }
                </Link>
            </div>
            )


        },


    ];

    return (

        <div style={{ height: '70vh', width: '100%' }}>



            <DataGrid
                getRowId={(r) => r.emailReportId}
                rows={ReportModuleCL}
                columns={columns}
                pageSize={50}
                rowsPerPageOptions={[50]}
                style={{ textAlign: 'center' }}


                components={{
                    Toolbar: EditToolbar,
                }}
                sx={
                    {
                        fontFamily: "'Nunito', sans-serif",
                        border: "0px",
                        fontSize: "16px"



                    }
                }
            />


        </div>



    )
}


