import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { taxesandfeesModel, UsersModel_VS } from '../../models/TaxesAndFeesModel'
import Moment from 'moment';
import { Field, FieldAttributes, Form, Formik, useField, useFormikContext } from 'formik';
import { config } from '../../Constants';
import { FMSelectLOBField, FMSelectOperator, FMSelectPartner, FMSelectRateTypeField, FMSelectState, FMSelectTaxTypeField, FMSelectTrueFalse } from '../Elements/select';
import { FMTextField } from '../Elements/input';
import useAuth from "../../context/AuthProvider";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from 'sweetalert2';
import { StateLoginsModel, StateLoginsModel_VS } from '../../models/StateLoginsModel';
import { apiClient } from '../../helper/api';

interface Iprops {
    item: StateLoginsModel,
    handleClose: () => void,
    Open: boolean,
    handleSubmit: () => void
    initValues: StateLoginsModel
}
interface stateList {
    stateId: string, stateName: string
}

export const AddEditStateLogins: React.FC<Iprops> = ({ handleClose, Open, item, handleSubmit, initValues }) => {
    const initialValues: StateLoginsModel = initValues ? item : initValues;

    const { user } = useAuth();
    const token = user?.token;
    const initialStateData = { stateId: "", stateName: "" };
    const [states, setStates] = useState<stateList[]>([initialStateData])

    const initialPartnerdata = { partnersId: 0, customerName: "" };
    const [partners, setpartnerData] = React.useState([initialPartnerdata]);

    function fetchStateData() {
        apiClient("/States/getStates", {
            method: "GET",
            headers: new Headers({
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            }),
        })
            .then((responseJson) => {
                // Do something with the response
                if (responseJson?.data != null) {
                    setStates(responseJson.data);
                }

                console.log("statesDAta", responseJson.data);
            })
    };
    const fetchPartnersData = () => {
        apiClient('/Partners/getPartners',
            {
                method: "GET",
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                })
            })
            .then((responseJson) => {
                if (!responseJson) return
                // Do something with the response
                setpartnerData(responseJson.data)
            })
    }

    useEffect(() => {
        fetchStateData()
        fetchPartnersData()
    }, [])

    const FMSelectPartnersCustom: React.FC<FieldAttributes<SelectProps>> = ({ variant, ...props }) => {
        const [field, meta] = useField<{}>(props);
        const errorText = meta.error ? meta.error : "";
        useEffect(() => {
        }, [])
        return (
            <FormControl error={!!errorText} fullWidth >
                <FormHelperText className='formtexthelper-heading' >
                    Client
                </FormHelperText>
                <Select
                    {...field}
                    label={null}
                    labelId="State-Select-Label"
                    className="border-round custom-menu-item"
                    IconComponent={ExpandMoreIcon}
                    displayEmpty
                >
                    <MenuItem key={0} value={0}>
                        Select Client{" "}
                    </MenuItem>
                    {partners.map((Name, key) =>
                        <MenuItem key={key} value={Name.partnersId}> {Name.customerName} </MenuItem>
                    )}
                </Select>
            </FormControl>
        )
    }

    return (

        <Dialog open={Open} onClose={handleClose}>
            <DialogTitle className='card-title' >State Login</DialogTitle>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validateOnChange={true}
                validationSchema={StateLoginsModel_VS}
                onSubmit={(data, { setSubmitting }) => {
                    console.log("finaldata", data);
                    // debugger
                    apiClient('/StateLogins/SaveOrUpdate', {
                        method: 'POST',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        }),
                        body: JSON.stringify(data)  // <-- Post parameters
                    }).then((res) => {
                        if (!res) return
                        // Do something with the response
                        handleSubmit()
                        setSubmitting(true)
                        Swal.fire({
                            title: "Data Successfully saved",
                            text: "",
                            icon: "success",
                            confirmButtonText: "OK",
                        });
                    })
                }} >
                {({ values, isSubmitting, setFieldValue, errors }) => (
                    <Form className='main-form' >
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormHelperText className='formtexthelper-heading'>
                                            Licensee
                                        </FormHelperText>
                                        <FMTextField name="licensee" autoComplete='off' autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl error={!!errors.stateId} fullWidth>
                                        <FormHelperText className='formtexthelper-heading'>
                                            State
                                        </FormHelperText>
                                        <Select
                                            value={initialValues.stateId}
                                            id="test-select-label"
                                            autoWidth
                                            label={null}
                                            onChange={(e) => {
                                                setFieldValue("stateId", e.target.value);
                                                initialValues.stateId = Number(e.target.value);
                                            }}
                                            name="stateId"
                                            className="border-round custom-menu-item"
                                            IconComponent={ExpandMoreIcon}
                                            displayEmpty
                                        >
                                            <MenuItem key={0} value={0}>
                                                Select State{" "}
                                            </MenuItem>
                                            {states.map((state, key) => (
                                                <MenuItem key={key} value={state.stateId}>
                                                    {state.stateName}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                        <FormHelperText>{errors.stateId}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormHelperText className='formtexthelper-heading'>
                                            Website
                                        </FormHelperText>
                                        <FMTextField name="website" autoComplete='off' autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormHelperText className='formtexthelper-heading'>
                                            Username
                                        </FormHelperText>
                                        <FMTextField name="username" autoComplete='off' autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormHelperText className='formtexthelper-heading'>
                                            Password1
                                        </FormHelperText>
                                        <FMTextField name="password1" autoComplete='off' autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormHelperText className='formtexthelper-heading'>
                                            Password2
                                        </FormHelperText>
                                        <FMTextField name="password2" autoComplete='off' autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <FormHelperText className='formtexthelper-heading'>
                                            Notes
                                        </FormHelperText>
                                        <FMTextField name="notes" autoComplete='off' autoFocus margin="dense" label={null} type="text" fullWidth variant="outlined" sx={{ m: 2 }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FMSelectPartnersCustom name='partnersId' />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <button className=" btn-2 btn d-flex btn-primary search-btn" type='button' onClick={handleClose}  >Cancel</button>
                            <button className="btn  btn-primary active-btn-blue  search-btn " type='submit' disabled={isSubmitting} >Submit</button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog >
    )
}
