import { Password } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { FieldAttributes, FieldProps, useField, useFormikContext } from "formik";
import React, { FC, InputHTMLAttributes, useState } from "react";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// components/FormikDatePicker.tsx
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import { DateInputProps } from "@mui/x-date-pickers/internals/components/PureDateInput";
import { DateTimePickerProps } from "@mui/lab";

type Props<TInputDate, TDate> = {
  name: string;
} & Omit<DatePickerProps<TInputDate, TDate>, "onChange" | "value">;
type InputElement = HTMLInputElement | HTMLTextAreaElement;
type InputChangeEvent = React.ChangeEvent<InputElement>;

// import DatePicker from "react-datepicker";



export const FMTextField: React.FC<FieldAttributes<TextFieldProps>> = ({placeholder,inputProps,hidden,type,label,variant,id,InputLabelProps,InputProps,multiline,rows,disabled,onChange,defaultValue, autoComplete, value, ...props}) => {
    const [field, meta] = useField<{}>(props);
    const errorText = meta.error && meta.touched ? meta.error : "";
    return (
        <TextField placeholder={placeholder} variant={variant} type={type} id={id}
        label={label}  InputLabelProps={InputLabelProps} hidden={hidden} autoComplete={autoComplete}  disabled={disabled}  InputProps={InputProps} multiline={multiline} rows={rows} defaultValue={defaultValue} {...field} value={field.value || ""} helperText={errorText} error={!!errorText}></TextField>

    )
}

// 

// export const FMDatePicker: React.FC<FieldAttributes<TextFieldProps> & DatePickerProps<Date, Date>> = ({placeholder,hidden,type,label,InputProps,disabled, ...props}) => {
//     // const errorText = meta.error && meta.touched ? meta.error : "";
//     return (
//         <TextField placeholder={placeholder}  type={type} id="outlined-required"

//         label={label}   hidden={hidden} disabled={disabled} ></TextField>

//     )
// }
interface CustomInputProps extends FieldProps {
    type?: string;
    
}

export const CustomInputComponent: React.FC<CustomInputProps> = ({
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    type,
    ...props
}) => {
    const errorText = touched[field.name] && errors[field.name] ? errors[field.name] : "";
    return (
        <div>
            <input className="form-control" type={type} {...field} {...props} />
            <div className="error">{errorText?.toString()}</div>
        </div>
    )
};


export const CustomInputComponentPassword: React.FC<CustomInputProps> = ({
    
    field, // { name, value, onChange, onBlur }
    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    type,
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const errorText = touched[field.name] && errors[field.name] ? errors[field.name] : "";
    return (
        <div className="position-relative" >
            <input className="form-control"      type={showPassword ? 'text' : type} {...field} {...props}  style={{ paddingRight: '2.5rem' }} />
            <div className="error">{errorText?.toString()}</div>
            {type === 'password' && (
                <InputAdornment position="end" style={{ position: 'absolute', right: '2rem', top: '4rem' ,color:"white"}}>
                    <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? <i className="fa-regular fa-eye" style={{color:"white"}}></i> : <i className="fa-solid fa-eye-slash" style={{color:"white"}}></i>}
                    </IconButton>
                </InputAdornment>
            )}
        </div>
    )
};
// export const FormikDatePicker = <TInputDate, TDate = TInputDate>(
  //     props: Props<TInputDate, TDate>
  //   ) => {
  //     const { name, ...restProps } = props;
  //     const [field] = useField(name);
  //     const { setFieldValue } = useFormikContext();
  //     return (
  //       <DatePicker
  //         {...restProps}
  //         value={field.value ?? null}
  //         onChange={(val) => setFieldValue(name, val)}
  //       />
  //     );
  //   };
  

// interface NewFMTextFieldProps {
//         value?: string;
        
//         placeholder?: string;
       
//         name?: string;
//         type?: string;
//       }

// export const TestField: React.FC<NewFMTextFieldProps> =({name,type, value,placeholder,...props})=>
// {
//     return(
//         <input type={type} name={name} placeholder={placeholder} {...props} ></input>
//     )
// }


// interface abcTextFieldProps {
//   Value: string;
//  // onChange: (val: string) => void;
//   placeholder?: string;
//   autoFocus?: boolean;
//   Name?: string;
//   type?: 'email' | 'password' | 'text';
//   textarea?: boolean;
// }

// const abcTextField = React.forwardRef<InputElement, abcTextFieldProps>(
    
//   ({  textarea = false,Value, Name,...rest }, ref) => {
//     const InputElement = textarea ? 'textarea' : 'input';
//     return (
//       <InputElement
//          ref={ref as any}
//          name = {Name}
//         // onChange={({ target: { value } }: InputChangeEvent) => onChange(value)}
       
        
//         className={`rounded-md w-full border border-gray-400 p-3 mb-5 ${
//           textarea ? 'h-32' : ''
//         }`}
//         // onChange={({ target: { value } }: InputChangeEvent) => onChange(value)}
//         {...rest}
//       />
//     );
//   }
// );

// export default abcTextField;