import * as yup from 'yup'

export interface TaxScheduleByJurisdictionModel {
  taxScheduleByJurisdictionId?: number,
  taxCode?: string,
  taxingJurisdiction?: string,
  jurisdictionCode?: string,
  rateType?: number,
  rate?: number,
  minTaxFee?: number,
  zipCode?: string,
  lobid?: number,
  stateId?: number,
  dateCreated?: Date,
  dateModified?: Date,
  isDeleted?: boolean
}


export const TaxScheduleByJurisdictionModel_VS = yup.object({
  taxingJurisdiction: yup.string().required("Please enter taxing Jurisdiction").trim().typeError("invalid value"),
  jurisdictionCode: yup.string().required("Please enter jurisdiction Code").trim().typeError("invalid value"),
  rateType: yup.number().required("Please Rate type").typeError("invalid value"),
  rate: yup.number().required("Please Rate ").typeError("invalid value"),
  zipCode: yup.string().required("Please enter Zip Code").trim().typeError("invalid value"),
  lobid: yup.number().min(1,"Please select a Line of business").required("Please select a Line of business").typeError("invalid value"),
  stateId: yup.number().min(1,"Please select a state").required("Please select a state").typeError("invalid value"),
})