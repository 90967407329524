var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useRef } from 'react';
import { DocumentLabelerProvider } from 'documentLabeler/DocumentLabelerProvider';
import { DocumentLabelerContent } from 'documentLabeler/DocumentLabelerContent';
import { makeStyles } from '@material-ui/core';
var useStyles = makeStyles(function () { return ({
    Root: {
        display: 'flex',
        width: '100%',
        height: '100%',
    },
}); });
/**
 * Top level component containing the Embedded Document Labeler.  Takes in document info
 * as the data property, Handles positioning and rendering of the sub components
 * @param Props
 */
export var DocumentLabeler = function (_a) {
    var data = _a.data;
    var classes = useStyles();
    var rootRef = useRef(null);
    return (_jsx(DocumentLabelerProvider, __assign({ data: data, rootRef: rootRef.current }, { children: _jsx("div", __assign({ ref: rootRef, className: classes.Root }, { children: _jsx(DocumentLabelerContent, {}) })) })));
};
